@charset "UTF-8";

.card,
body {
    color: var(--bs-body-color)
}

.form-floating,
sub,
sup {
    position: relative
}

.card,
.toast-body,
code {
    word-wrap: break-word
}

::file-selector-button,
[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

label,
output {
    display: inline-block
}

.img-fluid,
.img-thumbnail {
    max-width: 100%;
    height: auto
}

:root {
    --pc-heading-color: #1d2630;
    --pc-active-background: #f3f5f7;
    --pc-sidebar-background: transparent;
    --pc-sidebar-color: #5b6b79;
    --pc-sidebar-color-rgb: 91, 107, 121;
    --pc-sidebar-active-color: #0d6efd;
    --pc-sidebar-shadow: none;
    --pc-sidebar-caption-color: #3e4853;
    --pc-sidebar-border: 1px dashed #bec8d0;
    --pc-sidebar-user-background: #f3f5f7;
    --pc-header-background: rgba(var(--bs-body-bg-rgb), 0.7);
    --pc-header-color: #5b6b79;
    --pc-header-shadow: none;
    --pc-card-box-shadow: none;
    --pc-header-submenu-background: #ffffff;
    --pc-header-submenu-color: #5b6b79;
    --bs-breakpoint-xs: 0;
    --bs-breakpoint-sm: 576px;
    --bs-breakpoint-md: 768px;
    --bs-breakpoint-lg: 992px;
    --bs-breakpoint-xl: 1200px;
    --bs-breakpoint-xxl: 1400px
}

body {
    font-feature-settings: "salt";
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}

:root,
[data-bs-theme=light] {
    --bs-blue: #4680ff;
    --bs-indigo: #6610f2;
    --bs-purple: #673ab7;
    --bs-pink: #e83e8c;
    --bs-red: #dc2626;
    --bs-orange: #fd7e14;
    --bs-yellow: #e58a00;
    --bs-green: #2ca87f;
    --bs-teal: #008080;
    --bs-cyan: #3ec9d6;
    --bs-black: #000000;
    --bs-white: #ffffff;
    --bs-gray: #5b6b79;
    --bs-gray-dark: #1d2630;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #f3f5f7;
    --bs-gray-300: #dbe0e5;
    --bs-gray-400: #bec8d0;
    --bs-gray-500: #8996a4;
    --bs-gray-600: #5b6b79;
    --bs-gray-700: #3e4853;
    --bs-gray-800: #1d2630;
    --bs-gray-900: #131920;
    --bs-theme: #ff3366;
    --bs-primary: #4680ff;
    --bs-secondary: #5b6b79;
    --bs-success: #2ca87f;
    --bs-info: #3ec9d6;
    --bs-warning: #e58a00;
    --bs-danger: #dc2626;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-theme-rgb: 255, 51, 102;
    --bs-primary-rgb: 70, 128, 255;
    --bs-secondary-rgb: 91, 107, 121;
    --bs-success-rgb: 44, 168, 127;
    --bs-info-rgb: 62, 201, 214;
    --bs-warning-rgb: 229, 138, 0;
    --bs-danger-rgb: 220, 38, 38;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-primary-text-emphasis: #052c65;
    --bs-secondary-text-emphasis: #2b2f32;
    --bs-success-text-emphasis: #0a3622;
    --bs-info-text-emphasis: #055160;
    --bs-warning-text-emphasis: #664d03;
    --bs-danger-text-emphasis: #58151c;
    --bs-light-text-emphasis: #495057;
    --bs-dark-text-emphasis: #495057;
    --bs-primary-bg-subtle: #c8d9ff;
    --bs-secondary-bg-subtle: #f8f9fa;
    --bs-success-bg-subtle: #c0e5d9;
    --bs-info-bg-subtle: #c5eff3;
    --bs-warning-bg-subtle: #f7dcb3;
    --bs-danger-bg-subtle: #f5bebe;
    --bs-light-bg-subtle: #fcfcfd;
    --bs-dark-bg-subtle: #bec8d0;
    --bs-primary-border-subtle: #a3c0ff;
    --bs-secondary-border-subtle: #f3f5f7;
    --bs-success-border-subtle: #96d4bf;
    --bs-info-border-subtle: #9fe4eb;
    --bs-warning-border-subtle: #f2c580;
    --bs-danger-border-subtle: #ee9393;
    --bs-light-border-subtle: #f3f5f7;
    --bs-dark-border-subtle: #8996a4;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-font-sans-serif: "Inter var", sans-serif;
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 0.875rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #131920;
    --bs-body-color-rgb: 19, 25, 32;
    --bs-body-bg: #f8f9fa;
    --bs-body-bg-rgb: 248, 249, 250;
    --bs-emphasis-color: #000;
    --bs-emphasis-color-rgb: 0, 0, 0;
    --bs-secondary-color: rgba(33, 37, 41, 0.75);
    --bs-secondary-color-rgb: 33, 37, 41;
    --bs-secondary-bg: #e9ecef;
    --bs-secondary-bg-rgb: 233, 236, 239;
    --bs-tertiary-color: rgba(33, 37, 41, 0.5);
    --bs-tertiary-color-rgb: 33, 37, 41;
    --bs-tertiary-bg: #f8f9fa;
    --bs-tertiary-bg-rgb: 248, 249, 250;
    --bs-heading-color: #262626;
    --bs-link-color: #4680ff;
    --bs-link-color-rgb: 70, 128, 255;
    --bs-link-decoration: none;
    --bs-link-hover-color: #3866cc;
    --bs-link-hover-color-rgb: 56, 102, 204;
    --bs-link-hover-decoration: underline;
    --bs-code-color: #e83e8c;
    --bs-highlight-color: #212529;
    --bs-highlight-bg: #fcf8e3;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #e7eaee;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 8px;
    --bs-border-radius-sm: 6px;
    --bs-border-radius-lg: 10px;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-xxl: 2rem;
    --bs-border-radius-2xl: var(--bs-border-radius-xxl);
    --bs-border-radius-pill: 50rem;
    --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-focus-ring-width: 0.25rem;
    --bs-focus-ring-opacity: 0.25;
    --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
    --bs-form-valid-color: #198754;
    --bs-form-valid-border-color: #198754;
    --bs-form-invalid-color: #dc3545;
    --bs-form-invalid-border-color: #dc3545
}

[data-bs-theme=dark] {
    color-scheme: dark;
    --bs-body-color: #dee2e6;
    --bs-body-color-rgb: 222, 226, 230;
    --bs-body-bg: #212529;
    --bs-body-bg-rgb: 33, 37, 41;
    --bs-emphasis-color: #fff;
    --bs-emphasis-color-rgb: 255, 255, 255;
    --bs-secondary-color: rgba(222, 226, 230, 0.75);
    --bs-secondary-color-rgb: 222, 226, 230;
    --bs-secondary-bg: #343a40;
    --bs-secondary-bg-rgb: 52, 58, 64;
    --bs-tertiary-color: rgba(222, 226, 230, 0.5);
    --bs-tertiary-color-rgb: 222, 226, 230;
    --bs-tertiary-bg: #2b3035;
    --bs-tertiary-bg-rgb: 43, 48, 53;
    --bs-primary-text-emphasis: #6ea8fe;
    --bs-secondary-text-emphasis: #a7acb1;
    --bs-success-text-emphasis: #75b798;
    --bs-info-text-emphasis: #6edff6;
    --bs-warning-text-emphasis: #ffda6a;
    --bs-danger-text-emphasis: #ea868f;
    --bs-light-text-emphasis: #f8f9fa;
    --bs-dark-text-emphasis: #dee2e6;
    --bs-primary-bg-subtle: #031633;
    --bs-secondary-bg-subtle: #161719;
    --bs-success-bg-subtle: #051b11;
    --bs-info-bg-subtle: #032830;
    --bs-warning-bg-subtle: #332701;
    --bs-danger-bg-subtle: #2c0b0e;
    --bs-light-bg-subtle: #343a40;
    --bs-dark-bg-subtle: #1a1d20;
    --bs-primary-border-subtle: #084298;
    --bs-secondary-border-subtle: #41464b;
    --bs-success-border-subtle: #0f5132;
    --bs-info-border-subtle: #087990;
    --bs-warning-border-subtle: #997404;
    --bs-danger-border-subtle: #842029;
    --bs-light-border-subtle: #495057;
    --bs-dark-border-subtle: #343a40;
    --bs-heading-color: inherit;
    --bs-link-color: #6ea8fe;
    --bs-link-hover-color: #8bb9fe;
    --bs-link-color-rgb: 110, 168, 254;
    --bs-link-hover-color-rgb: 139, 185, 254;
    --bs-code-color: #e685b5;
    --bs-highlight-color: #dee2e6;
    --bs-highlight-bg: #664d03;
    --bs-border-color: #495057;
    --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
    --bs-form-valid-color: #75b798;
    --bs-form-valid-border-color: #75b798;
    --bs-form-invalid-color: #ea868f;
    --bs-form-invalid-border-color: #ea868f
}

*,
::after,
::before {
    box-sizing: border-box
}

@media (prefers-reduced-motion:no-preference) {
    :root {
        scroll-behavior: smooth
    }
}

hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: .25
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .2rem;
    font-weight: 600;
    line-height: 1.2
}

address,
dl,
ol,
p,
pre,
ul {
    margin-bottom: 1rem
}

blockquote,
figure {
    margin: 0 0 1rem
}

.h1,
h1 {
    font-size: calc(1.3625rem + 1.35vw)
}

@media (min-width:1200px) {

    .h1,
    h1 {
        font-size: 2.375rem
    }
}

.h2,
h2 {
    font-size: calc(1.3125rem + .75vw)
}

@media (min-width:1200px) {

    .h2,
    h2 {
        font-size: 1.875rem
    }
}

.h3,
h3 {
    font-size: calc(1.275rem + .3vw)
}

@media (min-width:1200px) {

    .h3,
    h3 {
        font-size: 1.5rem
    }
}

.h4,
h4 {
    font-size: 1.25rem
}

.h5,
h5 {
    font-size: 1rem
}

.h6,
h6 {
    font-size: .875rem
}

dl,
ol,
p,
ul {
    margin-top: 0
}

abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}
/* 
.form-control-color:not(:disabled):not([readonly]),
.form-control[type=file]:not(:disabled):not([readonly]),
[role=button],
[type=button]:not(:disabled),
[type=file].custom-select:not(:disabled):not([readonly]),
[type=file].dataTable-input:not(:disabled):not([readonly]),
[type=file].dataTable-selector:not(:disabled):not([readonly]),
[type=file].datatable-input:not(:disabled):not([readonly]),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled), */
summary {
    cursor: pointer
}

address {
    font-style: normal;
    line-height: inherit
}

ol,
ul {
    padding-left: 2rem
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

.small,
small {
    font-size: 80%
}

.mark,
mark {
    padding: .2em;
    color: var(--bs-highlight-color);
    background-color: var(--bs-highlight-bg)
}

sub,
sup {
    font-size: .75em;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
    text-decoration: none
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}

code,
kbd,
pre,
samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em
}

pre {
    display: block;
    margin-top: 0;
    overflow: auto;
    font-size: 80%
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

code,
kbd {
    font-size: 80%
}

code {
    color: var(--bs-code-color)
}

a>code {
    color: inherit
}

kbd {
    padding: .1875rem .375rem;
    color: var(--bs-body-bg);
    background-color: var(--bs-body-color);
    border-radius: 6px
}

kbd kbd {
    padding: 0;
    font-size: 1em
}

img,
svg {
    vertical-align: middle
}

table {
    caption-side: bottom;
    border-collapse: collapse
}

caption {
    padding-top: .9rem;
    padding-bottom: .9rem;
    color: #5b6b79;
    text-align: left
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0
}

button {
    border-radius: 0
}

fieldset,
iframe {
    border: 0
}

button:focus:not(:focus-visible) {
    outline: 0
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
select {
    text-transform: none
}

select {
    word-wrap: normal
}

select:disabled {
    opacity: 1
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
    display: none !important
}

::-moz-focus-inner {
    padding: 0;
    border-style: none
}

textarea {
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0
}

legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: calc(1.275rem + .3vw);
    line-height: inherit
}

@media (min-width:1200px) {
    legend {
        font-size: 1.5rem
    }
}

legend+* {
    clear: left
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
    padding: 0
}

::-webkit-inner-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
    padding: 0
}

::file-selector-button {
    font: inherit
}

.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
    font-weight: 300;
    line-height: 1.2
}

summary {
    display: list-item
}

progress {
    vertical-align: baseline
}

[hidden] {
    display: none !important
}

.figure,
.form-check-inline,
.list-inline-item {
    display: inline-block
}

.lead {
    font-size: 1.09375rem;
    font-weight: 300
}

.display-1 {
    font-size: calc(1.625rem + 4.5vw)
}

@media (min-width:1200px) {
    .display-1 {
        font-size: 5rem
    }
}

.display-2 {
    font-size: calc(1.575rem + 3.9vw)
}

@media (min-width:1200px) {
    .display-2 {
        font-size: 4.5rem
    }
}

.display-3 {
    font-size: calc(1.525rem + 3.3vw)
}

@media (min-width:1200px) {
    .display-3 {
        font-size: 4rem
    }
}

.display-4 {
    font-size: calc(1.475rem + 2.7vw)
}

@media (min-width:1200px) {
    .display-4 {
        font-size: 3.5rem
    }
}

.display-5 {
    font-size: calc(1.425rem + 2.1vw)
}

@media (min-width:1200px) {
    .display-5 {
        font-size: 3rem
    }
}

.display-6 {
    font-size: calc(1.375rem + 1.5vw)
}

@media (min-width:1200px) {
    .display-6 {
        font-size: 2.5rem
    }
}

.list-inline,
.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 80%;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.09375rem
}

.blockquote>:last-child {
    margin-bottom: 0
}

.blockquote-footer {
    margin-bottom: 1rem;
    font-size: 80%;
    color: #5b6b79
}

.blockquote-footer::before {
    content: "— "
}

.img-thumbnail {
    padding: .25rem;
    background-color: #f8f9fa;
    border: 1px solid #e7eaee;
    border-radius: 8px
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: 90%;
    color: #5b6b79
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto
}


@media (min-width:576px) {

    .container,
    .container-sm {
        max-width: 540px
    }
}

@media (min-width:768px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 720px
    }
}

@media (min-width:992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px
    }
}

@media (min-width:1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px
    }
}

@media (min-width:1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1320px
    }
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x))
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y)
}

.col {
    flex: 1 0 0%
}

.row-cols-auto>* {
    flex: 0 0 auto;
    width: auto
}

.row-cols-1>* {
    flex: 0 0 auto;
    width: 100%
}

.row-cols-2>* {
    flex: 0 0 auto;
    width: 50%
}

.row-cols-3>* {
    flex: 0 0 auto;
    width: 33.33333333%
}

.row-cols-4>* {
    flex: 0 0 auto;
    width: 25%
}

.row-cols-5>* {
    flex: 0 0 auto;
    width: 20%
}

.row-cols-6>* {
    flex: 0 0 auto;
    width: 16.66666667%
}

.col-auto {
    flex: 0 0 auto;
    width: auto
}

.col-1 {
    flex: 0 0 auto;
    width: 8.33333333%
}

.col-2,
.col-3 {
    flex: 0 0 auto
}

.col-2 {
    width: 16.66666667%
}

.col-3 {
    width: 25%
}

.col-4,
.col-5 {
    flex: 0 0 auto
}

.col-4 {
    width: 33.33333333%
}

.col-5 {
    width: 41.66666667%
}

.col-6,
.col-7 {
    flex: 0 0 auto
}

.col-6 {
    width: 50%
}

.col-7 {
    width: 58.33333333%
}

.col-8,
.col-9 {
    flex: 0 0 auto
}

.col-8 {
    width: 66.66666667%
}

.col-9 {
    width: 75%
}

.col-10 {
    flex: 0 0 auto;
    width: 83.33333333%
}

.col-11 {
    flex: 0 0 auto;
    width: 91.66666667%
}

.col-12 {
    flex: 0 0 auto;
    width: 100%
}

.offset-1 {
    margin-left: 8.33333333%
}

.offset-2 {
    margin-left: 16.66666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333333%
}

.offset-5 {
    margin-left: 41.66666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333333%
}

.offset-8 {
    margin-left: 66.66666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333333%
}

.offset-11 {
    margin-left: 91.66666667%
}

.g-0,
.gx-0 {
    --bs-gutter-x: 0
}

.g-0,
.gy-0 {
    --bs-gutter-y: 0
}

.g-1,
.gx-1 {
    --bs-gutter-x: 0.25rem
}

.g-1,
.gy-1 {
    --bs-gutter-y: 0.25rem
}

.g-2,
.gx-2 {
    --bs-gutter-x: 0.5rem
}

.g-2,
.gy-2 {
    --bs-gutter-y: 0.5rem
}

.g-3,
.gx-3 {
    --bs-gutter-x: 1rem
}

.g-3,
.gy-3 {
    --bs-gutter-y: 1rem
}

.g-4,
.gx-4 {
    --bs-gutter-x: 1.5rem
}

.g-4,
.gy-4 {
    --bs-gutter-y: 1.5rem
}

.g-5,
.gx-5 {
    --bs-gutter-x: 3rem
}

.g-5,
.gy-5 {
    --bs-gutter-y: 3rem
}

@media (min-width:576px) {
    .col-sm {
        flex: 1 0 0%
    }

    .row-cols-sm-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-sm-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-sm-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-sm-3>* {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .row-cols-sm-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-sm-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-sm-6>* {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333333%
    }

    .offset-sm-2 {
        margin-left: 16.66666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333333%
    }

    .offset-sm-5 {
        margin-left: 41.66666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333333%
    }

    .offset-sm-8 {
        margin-left: 66.66666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333333%
    }

    .offset-sm-11 {
        margin-left: 91.66666667%
    }

    .g-sm-0,
    .gx-sm-0 {
        --bs-gutter-x: 0
    }

    .g-sm-0,
    .gy-sm-0 {
        --bs-gutter-y: 0
    }

    .g-sm-1,
    .gx-sm-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-sm-1,
    .gy-sm-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-sm-2,
    .gx-sm-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-sm-2,
    .gy-sm-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-sm-3,
    .gx-sm-3 {
        --bs-gutter-x: 1rem
    }

    .g-sm-3,
    .gy-sm-3 {
        --bs-gutter-y: 1rem
    }

    .g-sm-4,
    .gx-sm-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-sm-4,
    .gy-sm-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-sm-5,
    .gx-sm-5 {
        --bs-gutter-x: 3rem
    }

    .g-sm-5,
    .gy-sm-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:768px) {
    .col-md {
        flex: 1 0 0%
    }

    .row-cols-md-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-md-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-md-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-md-3>* {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .row-cols-md-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-md-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-md-6>* {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-md-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-md-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-md-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-md-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-md-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-md-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-md-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-md-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-md-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333333%
    }

    .offset-md-2 {
        margin-left: 16.66666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333333%
    }

    .offset-md-5 {
        margin-left: 41.66666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333333%
    }

    .offset-md-8 {
        margin-left: 66.66666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333333%
    }

    .offset-md-11 {
        margin-left: 91.66666667%
    }

    .g-md-0,
    .gx-md-0 {
        --bs-gutter-x: 0
    }

    .g-md-0,
    .gy-md-0 {
        --bs-gutter-y: 0
    }

    .g-md-1,
    .gx-md-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-md-1,
    .gy-md-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-md-2,
    .gx-md-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-md-2,
    .gy-md-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-md-3,
    .gx-md-3 {
        --bs-gutter-x: 1rem
    }

    .g-md-3,
    .gy-md-3 {
        --bs-gutter-y: 1rem
    }

    .g-md-4,
    .gx-md-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-md-4,
    .gy-md-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-md-5,
    .gx-md-5 {
        --bs-gutter-x: 3rem
    }

    .g-md-5,
    .gy-md-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:992px) {
    .col-lg {
        flex: 1 0 0%
    }

    .row-cols-lg-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-lg-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-lg-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-lg-3>* {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .row-cols-lg-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-lg-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-lg-6>* {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333333%
    }

    .offset-lg-2 {
        margin-left: 16.66666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333333%
    }

    .offset-lg-5 {
        margin-left: 41.66666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333333%
    }

    .offset-lg-8 {
        margin-left: 66.66666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333333%
    }

    .offset-lg-11 {
        margin-left: 91.66666667%
    }

    .g-lg-0,
    .gx-lg-0 {
        --bs-gutter-x: 0
    }

    .g-lg-0,
    .gy-lg-0 {
        --bs-gutter-y: 0
    }

    .g-lg-1,
    .gx-lg-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-lg-1,
    .gy-lg-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-lg-2,
    .gx-lg-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-lg-2,
    .gy-lg-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-lg-3,
    .gx-lg-3 {
        --bs-gutter-x: 1rem
    }

    .g-lg-3,
    .gy-lg-3 {
        --bs-gutter-y: 1rem
    }

    .g-lg-4,
    .gx-lg-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-lg-4,
    .gy-lg-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-lg-5,
    .gx-lg-5 {
        --bs-gutter-x: 3rem
    }

    .g-lg-5,
    .gy-lg-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:1200px) {
    .col-xl {
        flex: 1 0 0%
    }

    .row-cols-xl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xl-3>* {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .row-cols-xl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xl-6>* {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333333%
    }

    .offset-xl-2 {
        margin-left: 16.66666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333333%
    }

    .offset-xl-5 {
        margin-left: 41.66666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333333%
    }

    .offset-xl-8 {
        margin-left: 66.66666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333333%
    }

    .offset-xl-11 {
        margin-left: 91.66666667%
    }

    .g-xl-0,
    .gx-xl-0 {
        --bs-gutter-x: 0
    }

    .g-xl-0,
    .gy-xl-0 {
        --bs-gutter-y: 0
    }

    .g-xl-1,
    .gx-xl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xl-1,
    .gy-xl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xl-2,
    .gx-xl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xl-2,
    .gy-xl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xl-3,
    .gx-xl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xl-3,
    .gy-xl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xl-4,
    .gx-xl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xl-4,
    .gy-xl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xl-5,
    .gx-xl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xl-5,
    .gy-xl-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:1400px) {
    .col-xxl {
        flex: 1 0 0%
    }

    .row-cols-xxl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xxl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xxl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xxl-3>* {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .row-cols-xxl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xxl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xxl-6>* {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xxl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xxl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xxl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xxl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.33333333%
    }

    .offset-xxl-2 {
        margin-left: 16.66666667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.33333333%
    }

    .offset-xxl-5 {
        margin-left: 41.66666667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.33333333%
    }

    .offset-xxl-8 {
        margin-left: 66.66666667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.33333333%
    }

    .offset-xxl-11 {
        margin-left: 91.66666667%
    }

    .g-xxl-0,
    .gx-xxl-0 {
        --bs-gutter-x: 0
    }

    .g-xxl-0,
    .gy-xxl-0 {
        --bs-gutter-y: 0
    }

    .g-xxl-1,
    .gx-xxl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xxl-1,
    .gy-xxl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xxl-2,
    .gx-xxl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xxl-2,
    .gy-xxl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xxl-3,
    .gx-xxl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xxl-3,
    .gy-xxl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xxl-4,
    .gx-xxl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xxl-4,
    .gy-xxl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xxl-5,
    .gx-xxl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xxl-5,
    .gy-xxl-5 {
        --bs-gutter-y: 3rem
    }
}

.table {
    --bs-table-color-type: initial;
    --bs-table-bg-type: initial;
    --bs-table-color-state: initial;
    --bs-table-bg-state: initial;
    --bs-table-color: #131920;
    --bs-table-bg: transparent;
    --bs-table-border-color: #e7eaee;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #131920;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #131920;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: #131920;
    --bs-table-hover-bg: rgba(70, 128, 255, 0.02);
    width: 100%;
    margin-bottom: 1rem;
    vertical-align: top;
    border-color: var(--bs-table-border-color)
}

.table>:not(caption)>*>* {
    padding: .9rem .75rem;
    color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)))
}

.table-danger,
.table-info,
.table-light,
.table-primary,
.table-secondary,
.table-success,
.table-warning {
    color: var(--bs-table-color)
}

.table>tbody {
    vertical-align: inherit
}

.table>thead {
    vertical-align: bottom
}

.table-group-divider {
    border-top: calc(1px * 2) solid currentcolor
}

.table-danger,
.table-dark,
.table-info,
.table-light,
.table-primary,
.table-secondary,
.table-success,
.table-warning {
    border-color: var(--bs-table-border-color)
}

.caption-top {
    caption-side: top
}

.table-sm>:not(caption)>*>* {
    padding: .3rem
}

.table-bordered>:not(caption)>* {
    border-width: 1px 0
}

.table-bordered>:not(caption)>*>* {
    border-width: 0 1px
}

.table-borderless>:not(caption)>*>* {
    border-bottom-width: 0
}

.table-borderless>:not(:first-child) {
    border-top-width: 0
}

.table-striped-columns>:not(caption)>tr>:nth-child(even),
.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-color-type: var(--bs-table-striped-color);
    --bs-table-bg-type: var(--bs-table-striped-bg)
}

.table-active {
    --bs-table-color-state: var(--bs-table-active-color);
    --bs-table-bg-state: var(--bs-table-active-bg)
}

.table-hover>tbody>tr:hover>* {
    --bs-table-color-state: var(--bs-table-hover-color);
    --bs-table-bg-state: var(--bs-table-hover-bg)
}

.table-primary {
    --bs-table-color: #212529;
    --bs-table-bg: #dae6ff;
    --bs-table-border-color: #c8d3ea;
    --bs-table-striped-bg: #d1dcf4;
    --bs-table-striped-color: #212529;
    --bs-table-active-bg: #c8d3ea;
    --bs-table-active-color: #212529;
    --bs-table-hover-bg: #d6e2fb;
    --bs-table-hover-color: #212529
}

.table-secondary {
    --bs-table-color: #212529;
    --bs-table-bg: #dee1e4;
    --bs-table-border-color: #cbced1;
    --bs-table-striped-bg: #d5d8db;
    --bs-table-striped-color: #212529;
    --bs-table-active-bg: #cbced1;
    --bs-table-active-color: #ffffff;
    --bs-table-hover-bg: #dadde0;
    --bs-table-hover-color: #212529
}

.table-success {
    --bs-table-color: #212529;
    --bs-table-bg: #d5eee5;
    --bs-table-border-color: #c3dad2;
    --bs-table-striped-bg: #cce4dc;
    --bs-table-striped-color: #212529;
    --bs-table-active-bg: #c3dad2;
    --bs-table-active-color: #212529;
    --bs-table-hover-bg: #d1eae1;
    --bs-table-hover-color: #212529
}

.table-info {
    --bs-table-color: #212529;
    --bs-table-bg: #d8f4f7;
    --bs-table-border-color: #c6dfe2;
    --bs-table-striped-bg: #cfeaed;
    --bs-table-striped-color: #212529;
    --bs-table-active-bg: #c6dfe2;
    --bs-table-active-color: #212529;
    --bs-table-hover-bg: #d4f0f3;
    --bs-table-hover-color: #212529
}

.table-warning {
    --bs-table-color: #212529;
    --bs-table-bg: #fae8cc;
    --bs-table-border-color: #e4d5bc;
    --bs-table-striped-bg: #efdec4;
    --bs-table-striped-color: #212529;
    --bs-table-active-bg: #e4d5bc;
    --bs-table-active-color: #212529;
    --bs-table-hover-bg: #f6e4c9;
    --bs-table-hover-color: #212529
}

.table-danger {
    --bs-table-color: #212529;
    --bs-table-bg: #f8d4d4;
    --bs-table-border-color: #e3c3c3;
    --bs-table-striped-bg: #edcbcb;
    --bs-table-striped-color: #212529;
    --bs-table-active-bg: #e3c3c3;
    --bs-table-active-color: #ffffff;
    --bs-table-hover-bg: #f4d1d1;
    --bs-table-hover-color: #212529
}

.table-light {
    --bs-table-color: #212529;
    --bs-table-bg: #f8f9fa;
    --bs-table-border-color: #e3e4e5;
    --bs-table-striped-bg: #edeef0;
    --bs-table-striped-color: #212529;
    --bs-table-active-bg: #e3e4e5;
    --bs-table-active-color: #212529;
    --bs-table-hover-bg: #f4f5f6;
    --bs-table-hover-color: #212529
}

.col-form-label,
.form-label {
    color: var(--pc-heading-color)
}

.table-dark {
    --bs-table-color: #ffffff;
    --bs-table-bg: #212529;
    --bs-table-border-color: #373b3e;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #ffffff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #ffffff;
    --bs-table-hover-bg: #25292d;
    --bs-table-hover-color: #ffffff
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

@media (max-width:575.98px) {
    .table-responsive-sm {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:767.98px) {
    .table-responsive-md {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:991.98px) {
    .table-responsive-lg {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:1199.98px) {
    .table-responsive-xl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:1399.98px) {
    .table-responsive-xxl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

.form-label {
    margin-bottom: .5rem
}

.col-form-label {
    padding-top: calc(.8rem + 1px);
    padding-bottom: calc(.8rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(.775rem + 1px);
    padding-bottom: calc(.775rem + 1px);
    font-size: 1.09375rem
}

.col-form-label-sm {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    font-size: .765625rem
}

.form-text {
    margin-top: .25rem;
    font-size: 80%;
    color: #5b6b79
}

.custom-select,
.dataTable-input,
.dataTable-selector,
.datatable-input,
.form-control {
    display: block;
    width: 100%;
    padding: .8rem .75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #131920;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #bec8d0;
    border-radius: 8px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {

    .custom-select,
    .dataTable-input,
    .dataTable-selector,
    .datatable-input,
    .form-control {
        transition: none
    }
}

.form-control[type=file],
[type=file].custom-select,
[type=file].dataTable-input,
[type=file].dataTable-selector,
[type=file].datatable-input {
    overflow: hidden
}

.custom-select:focus,
.dataTable-input:focus,
.dataTable-selector:focus,
.datatable-input:focus,
.form-control:focus {
    color: #131920;
    background-color: #f8f9fa;
    border-color: var(--bs-primary);
    outline: 0;
    box-shadow: 0 0 0 1px rgba(var(--bs-primary-rgb), .1)
}

.custom-select::-webkit-date-and-time-value,
.dataTable-input::-webkit-date-and-time-value,
.dataTable-selector::-webkit-date-and-time-value,
.datatable-input::-webkit-date-and-time-value,
.form-control::-webkit-date-and-time-value {
    min-width: 85px;
    height: 1.5em;
    margin: 0
}

.custom-select::-webkit-datetime-edit,
.dataTable-input::-webkit-datetime-edit,
.dataTable-selector::-webkit-datetime-edit,
.datatable-input::-webkit-datetime-edit,
.form-control::-webkit-datetime-edit {
    display: block;
    padding: 0
}

.custom-select::-moz-placeholder,
.dataTable-input::-moz-placeholder,
.dataTable-selector::-moz-placeholder,
.datatable-input::-moz-placeholder,
.form-control::-moz-placeholder {
    color: #bec8d0;
    opacity: 1
}

.custom-select::placeholder,
.dataTable-input::placeholder,
.dataTable-selector::placeholder,
.datatable-input::placeholder,
.form-control::placeholder {
    color: #bec8d0;
    opacity: 1
}

.custom-select:disabled,
.dataTable-input:disabled,
.dataTable-selector:disabled,
.datatable-input:disabled,
.form-control:disabled {
    background-color: #f3f5f7;
    opacity: 1
}

.custom-select::file-selector-button,
.dataTable-input::file-selector-button,
.dataTable-selector::file-selector-button,
.datatable-input::file-selector-button,
.form-control::file-selector-button {
    padding: .8rem .75rem;
    margin: -.8rem -.75rem;
    margin-inline-end: .75rem;
    color: #131920;
    background-color: #f8f9fa;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {

    .custom-select::file-selector-button,
    .dataTable-input::file-selector-button,
    .dataTable-selector::file-selector-button,
    .datatable-input::file-selector-button,
    .form-control::file-selector-button {
        transition: none
    }
}

.custom-select:hover:not(:disabled):not([readonly])::file-selector-button,
.dataTable-input:hover:not(:disabled):not([readonly])::file-selector-button,
.dataTable-selector:hover:not(:disabled):not([readonly])::file-selector-button,
.datatable-input:hover:not(:disabled):not([readonly])::file-selector-button,
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #ecedee
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .8rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #262626;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext:focus {
    outline: 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    min-height: calc(1.5em + .75rem + 2px);
    padding: .375rem .7rem;
    font-size: .765625rem;
    border-radius: 6px
}

.form-control-sm::file-selector-button {
    padding: .375rem .7rem;
    margin: -.375rem -.7rem;
    margin-inline-end: .7rem
}

.form-control-lg {
    min-height: calc(1.5em + 1.55rem + 2px);
    padding: .775rem .85rem;
    font-size: 1.09375rem;
    border-radius: 10px
}

.form-control-lg::file-selector-button {
    padding: .775rem .85rem;
    margin: -.775rem -.85rem;
    margin-inline-end: .85rem
}

textarea.custom-select,
textarea.dataTable-input,
textarea.dataTable-selector,
textarea.datatable-input,
textarea.form-control {
    min-height: calc(1.5em + 1.6rem + 2px)
}

textarea.form-control-sm {
    min-height: calc(1.5em + .75rem + 2px)
}

textarea.form-control-lg {
    min-height: calc(1.5em + 1.55rem + 2px)
}

.form-control-color {
    width: 3rem;
    height: calc(1.5em + 1.6rem + 2px);
    padding: .8rem
}

.form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: 8px
}

.form-control-color::-webkit-color-swatch {
    border: 0 !important;
    border-radius: 8px
}

.form-control-color.form-control-sm {
    height: calc(1.5em + .75rem + 2px)
}

.form-control-color.form-control-lg {
    height: calc(1.5em + 1.55rem + 2px)
}

.datatable-selector,
.form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%231d2630' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    display: block;
    width: 100%;
    padding: .8rem 2rem .8rem .75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #131920;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #bec8d0;
    border-radius: 8px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-check-input,
.form-range {
    -webkit-appearance: none;
    -moz-appearance: none
}

@media (prefers-reduced-motion:reduce) {

    .datatable-selector,
    .form-select {
        transition: none
    }
}

.datatable-selector:focus,
.form-select:focus {
    border-color: var(--bs-primary);
    outline: 0;
    box-shadow: 0 0 0 1px var(--bs-primary)
}

.form-select[multiple],
.form-select[size]:not([size="1"]),
[multiple].datatable-selector,
[size].datatable-selector:not([size="1"]) {
    padding-right: .75rem;
    background-image: none
}

.datatable-selector:disabled,
.form-select:disabled {
    color: #5b6b79;
    background-color: #f3f5f7
}

.datatable-selector:-moz-focusring,
.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #131920
}

.form-select-sm {
    padding-top: .375rem;
    padding-bottom: .375rem;
    padding-left: .7rem;
    font-size: .765625rem;
    border-radius: 6px
}

.form-select-lg {
    padding-top: .775rem;
    padding-bottom: .775rem;
    padding-left: .85rem;
    font-size: 1.09375rem;
    border-radius: 10px
}

[data-bs-theme=dark] .datatable-selector,
[data-bs-theme=dark] .form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e")
}

.form-check {
    display: block;
    min-height: 1.3125rem;
    padding-left: 1.75em;
    margin-bottom: .125rem
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.75em
}

.form-check-reverse {
    padding-right: 1.75em;
    padding-left: 0;
    text-align: right
}

.form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.75em;
    margin-left: 0
}

.form-check-input {
    --bs-form-check-bg: #ffffff;
    flex-shrink: 0;
    width: 1.25em;
    height: 1.25em;
    margin-top: .125em;
    vertical-align: top;
    appearance: none;
    background-color: var(--bs-form-check-bg);
    background-image: var(--bs-form-check-bg-image);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    print-color-adjust: exact;
    transition: background-color .15s ease-in-out, background-position .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-check-input:checked,
.form-check-input[type=checkbox]:indeterminate {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary)
}

@media (prefers-reduced-motion:reduce) {
    .form-check-input {
        transition: none
    }
}

.form-check-input[type=checkbox] {
    border-radius: 6px
}

.form-check-input[type=radio] {
    border-radius: 50%
}

.form-check-input:active {
    filter: brightness(90%)
}

.form-check-input:focus {
    border-color: var(--bs-primary);
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(var(--bs-primary), .25)
}

.form-check-input:checked[type=checkbox] {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check-input:checked[type=radio] {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e")
}

.form-check-input[type=checkbox]:indeterminate {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}

.form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: .5
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    cursor: default;
    opacity: .5
}

.form-switch {
    padding-left: 2.5em
}

.form-switch .form-check-input {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    width: 2em;
    margin-left: -2.5em;
    background-image: var(--bs-form-switch-bg);
    background-position: left center;
    border-radius: 2em;
    transition: background-position .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-switch .form-check-input {
        transition: none
    }
}

.form-switch .form-check-input:focus {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='var%28--bs-primary%29'/%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
    background-position: right center;
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e")
}

.form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0
}

.form-switch.form-check-reverse .form-check-input {
    margin-right: -2.5em;
    margin-left: 0
}

.form-check-inline {
    margin-right: 1rem
}

.btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.btn-check:disabled+.btn,
.btn-check[disabled]+.btn,
.introjs-tooltip .btn-check:disabled+.introjs-button,
.introjs-tooltip .btn-check[disabled]+.introjs-button {
    pointer-events: none;
    filter: none;
    opacity: .65
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e")
}

.form-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    appearance: none;
    background-color: transparent
}

.form-range:focus {
    outline: 0
}

.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #f8f9fa, 0 0 0 1px rgba(var(--bs-primary-rgb), .1)
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #f8f9fa, 0 0 0 1px rgba(var(--bs-primary-rgb), .1)
}

.form-range::-moz-focus-outer {
    border: 0
}

.form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--bs-primary);
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: rgba(var(--bs-primary-rgb), .8)
}

.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #f8f9fa;
    border-color: transparent;
    border-radius: 1rem
}

.form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--bs-primary);
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}

.form-range::-moz-range-thumb:active {
    background-color: rgba(var(--bs-primary-rgb), .8)
}

.form-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #f8f9fa;
    border-color: transparent;
    border-radius: 1rem
}

.form-range:disabled {
    pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: #8996a4
}

.form-range:disabled::-moz-range-thumb {
    background-color: #8996a4
}

.form-floating>.custom-select,
.form-floating>.dataTable-input,
.form-floating>.dataTable-selector,
.form-floating>.datatable-input,
.form-floating>.datatable-selector,
.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
    height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    line-height: 1.25
}

.form-floating>label {
    position: absolute;
    left: 0;
    z-index: 2;
    height: 100%;
    padding: 1rem .75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-floating>label {
        transition: none
    }
}

.btn,
.introjs-tooltip .introjs-button,
.page-link {
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-floating>.custom-select,
.form-floating>.dataTable-input,
.form-floating>.dataTable-selector,
.form-floating>.datatable-input,
.form-floating>.form-control,
.form-floating>.form-control-plaintext {
    padding: 1rem .75rem
}

.form-floating>.custom-select::-moz-placeholder,
.form-floating>.dataTable-input::-moz-placeholder,
.form-floating>.dataTable-selector::-moz-placeholder,
.form-floating>.datatable-input::-moz-placeholder,
.form-floating>.form-control-plaintext::-moz-placeholder,
.form-floating>.form-control::-moz-placeholder {
    color: transparent
}

.form-floating>.custom-select::placeholder,
.form-floating>.dataTable-input::placeholder,
.form-floating>.dataTable-selector::placeholder,
.form-floating>.datatable-input::placeholder,
.form-floating>.form-control-plaintext::placeholder,
.form-floating>.form-control::placeholder {
    color: transparent
}

.form-floating>.custom-select:not(:-moz-placeholder-shown),
.form-floating>.dataTable-input:not(:-moz-placeholder-shown),
.form-floating>.dataTable-selector:not(:-moz-placeholder-shown),
.form-floating>.datatable-input:not(:-moz-placeholder-shown),
.form-floating>.form-control-plaintext:not(:-moz-placeholder-shown),
.form-floating>.form-control:not(:-moz-placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.custom-select:focus,
.form-floating>.custom-select:not(:placeholder-shown),
.form-floating>.dataTable-input:focus,
.form-floating>.dataTable-input:not(:placeholder-shown),
.form-floating>.dataTable-selector:focus,
.form-floating>.dataTable-selector:not(:placeholder-shown),
.form-floating>.datatable-input:focus,
.form-floating>.datatable-input:not(:placeholder-shown),
.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown),
.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.custom-select:-webkit-autofill,
.form-floating>.dataTable-input:-webkit-autofill,
.form-floating>.dataTable-selector:-webkit-autofill,
.form-floating>.datatable-input:-webkit-autofill,
.form-floating>.form-control-plaintext:-webkit-autofill,
.form-floating>.form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.datatable-selector,
.form-floating>.form-select {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.custom-select:not(:-moz-placeholder-shown)~label,
.form-floating>.dataTable-input:not(:-moz-placeholder-shown)~label,
.form-floating>.dataTable-selector:not(:-moz-placeholder-shown)~label,
.form-floating>.datatable-input:not(:-moz-placeholder-shown)~label,
.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.custom-select:focus~label,
.form-floating>.custom-select:not(:placeholder-shown)~label,
.form-floating>.dataTable-input:focus~label,
.form-floating>.dataTable-input:not(:placeholder-shown)~label,
.form-floating>.dataTable-selector:focus~label,
.form-floating>.dataTable-selector:not(:placeholder-shown)~label,
.form-floating>.datatable-input:focus~label,
.form-floating>.datatable-input:not(:placeholder-shown)~label,
.form-floating>.datatable-selector~label,
.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    color: rgba(var(--bs-body-color-rgb), 1);
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.custom-select:not(:-moz-placeholder-shown)~label::after,
.form-floating>.dataTable-input:not(:-moz-placeholder-shown)~label::after,
.form-floating>.dataTable-selector:not(:-moz-placeholder-shown)~label::after,
.form-floating>.datatable-input:not(:-moz-placeholder-shown)~label::after,
.form-floating>.form-control:not(:-moz-placeholder-shown)~label::after {
    position: absolute;
    inset: 1rem .375rem;
    z-index: -1;
    height: 1.5em;
    content: "";
    background-color: #fff;
    border-radius: 8px
}

.form-floating>.custom-select:focus~label::after,
.form-floating>.custom-select:not(:placeholder-shown)~label::after,
.form-floating>.dataTable-input:focus~label::after,
.form-floating>.dataTable-input:not(:placeholder-shown)~label::after,
.form-floating>.dataTable-selector:focus~label::after,
.form-floating>.dataTable-selector:not(:placeholder-shown)~label::after,
.form-floating>.datatable-input:focus~label::after,
.form-floating>.datatable-input:not(:placeholder-shown)~label::after,
.form-floating>.datatable-selector~label::after,
.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-select~label::after {
    position: absolute;
    inset: 1rem .375rem;
    z-index: -1;
    height: 1.5em;
    content: "";
    background-color: #fff;
    border-radius: 8px
}

.form-floating>.custom-select:-webkit-autofill~label,
.form-floating>.dataTable-input:-webkit-autofill~label,
.form-floating>.dataTable-selector:-webkit-autofill~label,
.form-floating>.datatable-input:-webkit-autofill~label,
.form-floating>.form-control:-webkit-autofill~label {
    color: rgba(var(--bs-body-color-rgb), 1);
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control-plaintext~label {
    border-width: 1px 0
}

.form-floating>.form-control:disabled~label,
.form-floating>:disabled~label {
    color: #6c757d
}

.form-floating>.form-control:disabled~label::after,
.form-floating>:disabled~label::after {
    background-color: #f3f5f7
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group>.custom-select,
.input-group>.dataTable-input,
.input-group>.dataTable-selector,
.input-group>.datatable-input,
.input-group>.datatable-selector,
.input-group>.form-control,
.input-group>.form-floating,
.input-group>.form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
}

.input-group>.custom-select:focus,
.input-group>.dataTable-input:focus,
.input-group>.dataTable-selector:focus,
.input-group>.datatable-input:focus,
.input-group>.datatable-selector:focus,
.input-group>.form-control:focus,
.input-group>.form-floating:focus-within,
.input-group>.form-select:focus {
    z-index: 5
}

.input-group .btn,
.input-group .introjs-tooltip .introjs-button,
.introjs-tooltip .input-group .introjs-button {
    position: relative;
    z-index: 2
}

.input-group .btn:focus,
.input-group .introjs-tooltip .introjs-button:focus,
.introjs-tooltip .input-group .introjs-button:focus {
    z-index: 5
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .8rem .75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #131920;
    text-align: center;
    white-space: nowrap;
    background-color: #f8f9fa;
    border: 1px solid #bec8d0;
    border-radius: 8px
}

.input-group-lg>.btn,
.input-group-lg>.custom-select,
.input-group-lg>.dataTable-input,
.input-group-lg>.dataTable-selector,
.input-group-lg>.datatable-input,
.input-group-lg>.datatable-selector,
.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.introjs-tooltip .input-group-lg>.introjs-button {
    padding: .775rem .85rem;
    font-size: 1.09375rem;
    border-radius: 10px
}

.input-group-sm>.btn,
.input-group-sm>.custom-select,
.input-group-sm>.dataTable-input,
.input-group-sm>.dataTable-selector,
.input-group-sm>.datatable-input,
.input-group-sm>.datatable-selector,
.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.introjs-tooltip .input-group-sm>.introjs-button {
    padding: .375rem .7rem;
    font-size: .765625rem;
    border-radius: 6px
}

.input-group-lg>.datatable-selector,
.input-group-lg>.form-select,
.input-group-sm>.datatable-selector,
.input-group-sm>.form-select {
    padding-right: 2.75rem
}

.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.custom-select,
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.dataTable-input,
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.dataTable-selector,
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.datatable-input,
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.datatable-selector,
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-control,
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-select,
.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.custom-select,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.dataTable-input,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.dataTable-selector,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.datatable-input,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.datatable-selector,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select,
.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: calc(1px * -1);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.form-floating:not(:first-child)>.custom-select,
.input-group>.form-floating:not(:first-child)>.dataTable-input,
.input-group>.form-floating:not(:first-child)>.dataTable-selector,
.input-group>.form-floating:not(:first-child)>.datatable-input,
.input-group>.form-floating:not(:first-child)>.datatable-selector,
.input-group>.form-floating:not(:first-child)>.form-control,
.input-group>.form-floating:not(:first-child)>.form-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #2ca87f
}

.form-control-color.is-invalid,
.form-control-color.is-valid,
.was-validated .form-control-color:invalid,
.was-validated .form-control-color:valid {
    width: calc(3rem + calc(1.5em + 1.6rem))
}

.invalid-tooltip,
.valid-tooltip {
    font-size: .765625rem;
    line-height: 1.5;
    border-radius: 8px;
    top: 100%
}

.valid-tooltip {
    position: absolute;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    color: #fff;
    background-color: rgba(44, 168, 127, .9)
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
    display: block
}

.form-control.is-valid,
.is-valid.custom-select,
.is-valid.dataTable-input,
.is-valid.dataTable-selector,
.is-valid.datatable-input,
.was-validated .custom-select:valid,
.was-validated .dataTable-input:valid,
.was-validated .dataTable-selector:valid,
.was-validated .datatable-input:valid,
.was-validated .form-control:valid {
    border-color: #2ca87f;
    padding-right: calc(1.5em + 1.6rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232ca87f' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .4rem) center;
    background-size: calc(.75em + .8rem) calc(.75em + .8rem)
}

.form-control.is-valid:focus,
.is-valid.custom-select:focus,
.is-valid.dataTable-input:focus,
.is-valid.dataTable-selector:focus,
.is-valid.datatable-input:focus,
.was-validated .custom-select:valid:focus,
.was-validated .dataTable-input:valid:focus,
.was-validated .dataTable-selector:valid:focus,
.was-validated .datatable-input:valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #2ca87f;
    box-shadow: 0 0 0 .2rem rgba(44, 168, 127, .25)
}

.was-validated textarea.custom-select:valid,
.was-validated textarea.dataTable-input:valid,
.was-validated textarea.dataTable-selector:valid,
.was-validated textarea.datatable-input:valid,
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid,
textarea.is-valid.custom-select,
textarea.is-valid.dataTable-input,
textarea.is-valid.dataTable-selector,
textarea.is-valid.datatable-input {
    padding-right: calc(1.5em + 1.6rem);
    background-position: top calc(.375em + .4rem) right calc(.375em + .4rem)
}

.form-select.is-valid,
.is-valid.datatable-selector,
.was-validated .datatable-selector:valid,
.was-validated .form-select:valid {
    border-color: #2ca87f
}

.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.is-valid.datatable-selector:not([multiple]):not([size]),
.is-valid.datatable-selector:not([multiple])[size="1"],
.was-validated .datatable-selector:valid:not([multiple]):not([size]),
.was-validated .datatable-selector:valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232ca87f' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    padding-right: calc(.75em + 3.95rem);
    background-position: right .75rem center, center right 2.75rem;
    background-size: 16px 12px, calc(.75em + .8rem) calc(.75em + .8rem)
}

.form-select.is-valid:focus,
.is-valid.datatable-selector:focus,
.was-validated .datatable-selector:valid:focus,
.was-validated .form-select:valid:focus {
    border-color: #2ca87f;
    box-shadow: 0 0 0 .2rem rgba(44, 168, 127, .25)
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
    border-color: #2ca87f
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
    background-color: #2ca87f
}

.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
    box-shadow: 0 0 0 .2rem rgba(44, 168, 127, .25)
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #2ca87f
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: .5em
}

.input-group>.custom-select:not(:focus).is-valid,
.input-group>.dataTable-input:not(:focus).is-valid,
.input-group>.dataTable-selector:not(:focus).is-valid,
.input-group>.datatable-input:not(:focus).is-valid,
.input-group>.datatable-selector:not(:focus).is-valid,
.input-group>.form-control:not(:focus).is-valid,
.input-group>.form-floating:not(:focus-within).is-valid,
.input-group>.form-select:not(:focus).is-valid,
.was-validated .input-group>.custom-select:not(:focus):valid,
.was-validated .input-group>.dataTable-input:not(:focus):valid,
.was-validated .input-group>.dataTable-selector:not(:focus):valid,
.was-validated .input-group>.datatable-input:not(:focus):valid,
.was-validated .input-group>.datatable-selector:not(:focus):valid,
.was-validated .input-group>.form-control:not(:focus):valid,
.was-validated .input-group>.form-floating:not(:focus-within):valid,
.was-validated .input-group>.form-select:not(:focus):valid {
    z-index: 3
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc2626
}

.invalid-tooltip {
    position: absolute;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    color: #fff;
    background-color: rgba(220, 38, 38, .9)
}

.dropdown-header,
.dropdown-item,
.dropdown-item-text,
.dropdown-menu.show,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
    display: block
}

.form-control.is-invalid,
.is-invalid.custom-select,
.is-invalid.dataTable-input,
.is-invalid.dataTable-selector,
.is-invalid.datatable-input,
.was-validated .custom-select:invalid,
.was-validated .dataTable-input:invalid,
.was-validated .dataTable-selector:invalid,
.was-validated .datatable-input:invalid,
.was-validated .form-control:invalid {
    border-color: #dc2626;
    padding-right: calc(1.5em + 1.6rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc2626'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc2626' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .4rem) center;
    background-size: calc(.75em + .8rem) calc(.75em + .8rem)
}

.form-control.is-invalid:focus,
.is-invalid.custom-select:focus,
.is-invalid.dataTable-input:focus,
.is-invalid.dataTable-selector:focus,
.is-invalid.datatable-input:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .dataTable-input:invalid:focus,
.was-validated .dataTable-selector:invalid:focus,
.was-validated .datatable-input:invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #dc2626;
    box-shadow: 0 0 0 .2rem rgba(220, 38, 38, .25)
}

.was-validated textarea.custom-select:invalid,
.was-validated textarea.dataTable-input:invalid,
.was-validated textarea.dataTable-selector:invalid,
.was-validated textarea.datatable-input:invalid,
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid,
textarea.is-invalid.custom-select,
textarea.is-invalid.dataTable-input,
textarea.is-invalid.dataTable-selector,
textarea.is-invalid.datatable-input {
    padding-right: calc(1.5em + 1.6rem);
    background-position: top calc(.375em + .4rem) right calc(.375em + .4rem)
}

.form-select.is-invalid,
.is-invalid.datatable-selector,
.was-validated .datatable-selector:invalid,
.was-validated .form-select:invalid {
    border-color: #dc2626
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.is-invalid.datatable-selector:not([multiple]):not([size]),
.is-invalid.datatable-selector:not([multiple])[size="1"],
.was-validated .datatable-selector:invalid:not([multiple]):not([size]),
.was-validated .datatable-selector:invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc2626'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc2626' stroke='none'/%3e%3c/svg%3e");
    padding-right: calc(.75em + 3.95rem);
    background-position: right .75rem center, center right 2.75rem;
    background-size: 16px 12px, calc(.75em + .8rem) calc(.75em + .8rem)
}

.form-select.is-invalid:focus,
.is-invalid.datatable-selector:focus,
.was-validated .datatable-selector:invalid:focus,
.was-validated .form-select:invalid:focus {
    border-color: #dc2626;
    box-shadow: 0 0 0 .2rem rgba(220, 38, 38, .25)
}

.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
    border-color: #dc2626
}

.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
    background-color: #dc2626
}

.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 38, 38, .25)
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: #dc2626
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: .5em
}

.input-group>.custom-select:not(:focus).is-invalid,
.input-group>.dataTable-input:not(:focus).is-invalid,
.input-group>.dataTable-selector:not(:focus).is-invalid,
.input-group>.datatable-input:not(:focus).is-invalid,
.input-group>.datatable-selector:not(:focus).is-invalid,
.input-group>.form-control:not(:focus).is-invalid,
.input-group>.form-floating:not(:focus-within).is-invalid,
.input-group>.form-select:not(:focus).is-invalid,
.was-validated .input-group>.custom-select:not(:focus):invalid,
.was-validated .input-group>.dataTable-input:not(:focus):invalid,
.was-validated .input-group>.dataTable-selector:not(:focus):invalid,
.was-validated .input-group>.datatable-input:not(:focus):invalid,
.was-validated .input-group>.datatable-selector:not(:focus):invalid,
.was-validated .input-group>.form-control:not(:focus):invalid,
.was-validated .input-group>.form-floating:not(:focus-within):invalid,
.was-validated .input-group>.form-select:not(:focus):invalid {
    z-index: 4
}

.btn,
.introjs-tooltip .introjs-button {
    --bs-btn-padding-x: 1rem;
    --bs-btn-padding-y: 0.562rem;
    --bs-btn-font-size: 0.875rem;
    --bs-btn-font-weight: 500;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-width: 1px;
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: 20px;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.2rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg)
}

@media (prefers-reduced-motion:reduce) {

    .btn,
    .introjs-tooltip .introjs-button {
        transition: none
    }
}

.btn:hover,
.introjs-tooltip .introjs-button:hover {
    color: var(--bs-btn-hover-color);
    text-decoration: none;
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color)
}

.btn-check+.btn:hover,
.introjs-tooltip .btn-check+.introjs-button:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color)
}

.btn-check:focus-visible+.btn,
.btn:focus-visible,
.introjs-tooltip .btn-check:focus-visible+.introjs-button,
.introjs-tooltip .introjs-button:focus-visible {
    border-color: var(--bs-btn-hover-border-color);
    box-shadow: var(--bs-btn-focus-box-shadow);
    outline: 0
}

.btn:focus-visible,
.introjs-tooltip .introjs-button:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg)
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
.introjs-tooltip .active.introjs-button,
.introjs-tooltip .btn-check:checked+.introjs-button,
.introjs-tooltip .introjs-button:first-child:active,
.introjs-tooltip .show.introjs-button,
.introjs-tooltip :not(.btn-check)+.introjs-button:active,
:not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color)
}

.btn-check:checked+.btn:focus-visible,
.btn-check:checked:focus-visible+.btn,
.btn.active:focus-visible,
.btn.show:focus-visible,
.btn:first-child:active:focus-visible,
.introjs-tooltip .active.introjs-button:focus-visible,
.introjs-tooltip .btn-check:checked+.introjs-button:focus-visible,
.introjs-tooltip .btn-check:checked:focus-visible+.introjs-button,
.introjs-tooltip .introjs-button:first-child:active:focus-visible,
.introjs-tooltip .show.introjs-button:focus-visible,
.introjs-tooltip :not(.btn-check)+.introjs-button:active:focus-visible,
:not(.btn-check)+.btn:active:focus-visible {
    box-shadow: var(--bs-btn-focus-box-shadow)
}

.btn.disabled,
.btn:disabled,
.introjs-tooltip .disabled.introjs-button,
.introjs-tooltip .introjs-button:disabled,
.introjs-tooltip fieldset:disabled .introjs-button,
fieldset:disabled .btn,
fieldset:disabled .introjs-tooltip .introjs-button {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity)
}

.dropdown-toggle::after,
.dropup .dropdown-toggle::after {
    vertical-align: .255em;
    border-right: .3em solid transparent;
    border-left: .3em solid transparent;
    content: ""
}

.btn-theme {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #ff3366;
    --bs-btn-border-color: #ff3366;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #d92b57;
    --bs-btn-hover-border-color: #cc2952;
    --bs-btn-focus-shadow-rgb: 255, 82, 125;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #cc2952;
    --bs-btn-active-border-color: #bf264d;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #ff3366;
    --bs-btn-disabled-border-color: #ff3366
}

.btn-primary,
.introjs-tooltip .introjs-button.introjs-nextbutton {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #4680ff;
    --bs-btn-border-color: #4680ff;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #3c6dd9;
    --bs-btn-hover-border-color: #3866cc;
    --bs-btn-focus-shadow-rgb: 98, 147, 255;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #3866cc;
    --bs-btn-active-border-color: #3560bf;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #4680ff;
    --bs-btn-disabled-border-color: #4680ff
}

.btn-secondary,
.introjs-tooltip .introjs-button.introjs-prevbutton {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #5b6b79;
    --bs-btn-border-color: #5b6b79;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #4d5b67;
    --bs-btn-hover-border-color: #495661;
    --bs-btn-focus-shadow-rgb: 116, 129, 141;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #495661;
    --bs-btn-active-border-color: #44505b;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #5b6b79;
    --bs-btn-disabled-border-color: #5b6b79
}

.btn-success {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #2ca87f;
    --bs-btn-border-color: #2ca87f;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #258f6c;
    --bs-btn-hover-border-color: #238666;
    --bs-btn-focus-shadow-rgb: 76, 181, 146;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #238666;
    --bs-btn-active-border-color: #217e5f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #2ca87f;
    --bs-btn-disabled-border-color: #2ca87f
}

.btn-info {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #3ec9d6;
    --bs-btn-border-color: #3ec9d6;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #35abb6;
    --bs-btn-hover-border-color: #32a1ab;
    --bs-btn-focus-shadow-rgb: 91, 209, 220;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #32a1ab;
    --bs-btn-active-border-color: #2f97a1;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #3ec9d6;
    --bs-btn-disabled-border-color: #3ec9d6
}

.btn-warning {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #e58a00;
    --bs-btn-border-color: #e58a00;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #c37500;
    --bs-btn-hover-border-color: #b76e00;
    --bs-btn-focus-shadow-rgb: 233, 156, 38;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #b76e00;
    --bs-btn-active-border-color: #ac6800;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #e58a00;
    --bs-btn-disabled-border-color: #e58a00
}

.btn-danger {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #dc2626;
    --bs-btn-border-color: #dc2626;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #bb2020;
    --bs-btn-hover-border-color: #b01e1e;
    --bs-btn-focus-shadow-rgb: 225, 71, 71;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #b01e1e;
    --bs-btn-active-border-color: #a51d1d;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #dc2626;
    --bs-btn-disabled-border-color: #dc2626
}

.btn-light {
    --bs-btn-color: #212529;
    --bs-btn-bg: #f8f9fa;
    --bs-btn-border-color: #f8f9fa;
    --bs-btn-hover-color: #212529;
    --bs-btn-hover-bg: #d3d4d5;
    --bs-btn-hover-border-color: #c6c7c8;
    --bs-btn-focus-shadow-rgb: 216, 217, 219;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #c6c7c8;
    --bs-btn-active-border-color: #babbbc;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #212529;
    --bs-btn-disabled-bg: #f8f9fa;
    --bs-btn-disabled-border-color: #f8f9fa
}

.btn-dark {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #212529;
    --bs-btn-border-color: #212529;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #424649;
    --bs-btn-hover-border-color: #373b3e;
    --bs-btn-focus-shadow-rgb: 66, 70, 73;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #4d5154;
    --bs-btn-active-border-color: #373b3e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #212529;
    --bs-btn-disabled-border-color: #212529
}

.btn-outline-theme {
    --bs-btn-color: #ff3366;
    --bs-btn-border-color: #ff3366;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #ff3366;
    --bs-btn-hover-border-color: #ff3366;
    --bs-btn-focus-shadow-rgb: 255, 51, 102;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #ff3366;
    --bs-btn-active-border-color: #ff3366;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ff3366;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #ff3366;
    --bs-gradient: none
}

.btn-outline-primary {
    --bs-btn-color: #4680ff;
    --bs-btn-border-color: #4680ff;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #4680ff;
    --bs-btn-hover-border-color: #4680ff;
    --bs-btn-focus-shadow-rgb: 70, 128, 255;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #4680ff;
    --bs-btn-active-border-color: #4680ff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #4680ff;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #4680ff;
    --bs-gradient: none
}

.btn-outline-secondary {
    --bs-btn-color: #5b6b79;
    --bs-btn-border-color: #5b6b79;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #5b6b79;
    --bs-btn-hover-border-color: #5b6b79;
    --bs-btn-focus-shadow-rgb: 91, 107, 121;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #5b6b79;
    --bs-btn-active-border-color: #5b6b79;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #5b6b79;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #5b6b79;
    --bs-gradient: none
}

.btn-outline-success {
    --bs-btn-color: #2ca87f;
    --bs-btn-border-color: #2ca87f;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #2ca87f;
    --bs-btn-hover-border-color: #2ca87f;
    --bs-btn-focus-shadow-rgb: 44, 168, 127;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #2ca87f;
    --bs-btn-active-border-color: #2ca87f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #2ca87f;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #2ca87f;
    --bs-gradient: none
}

.btn-outline-info {
    --bs-btn-color: #3ec9d6;
    --bs-btn-border-color: #3ec9d6;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #3ec9d6;
    --bs-btn-hover-border-color: #3ec9d6;
    --bs-btn-focus-shadow-rgb: 62, 201, 214;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #3ec9d6;
    --bs-btn-active-border-color: #3ec9d6;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #3ec9d6;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #3ec9d6;
    --bs-gradient: none
}

.btn-outline-warning {
    --bs-btn-color: #e58a00;
    --bs-btn-border-color: #e58a00;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #e58a00;
    --bs-btn-hover-border-color: #e58a00;
    --bs-btn-focus-shadow-rgb: 229, 138, 0;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #e58a00;
    --bs-btn-active-border-color: #e58a00;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #e58a00;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #e58a00;
    --bs-gradient: none
}

.btn-outline-danger {
    --bs-btn-color: #dc2626;
    --bs-btn-border-color: #dc2626;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #dc2626;
    --bs-btn-hover-border-color: #dc2626;
    --bs-btn-focus-shadow-rgb: 220, 38, 38;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #dc2626;
    --bs-btn-active-border-color: #dc2626;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #dc2626;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #dc2626;
    --bs-gradient: none
}

.btn-outline-light {
    --bs-btn-color: #f8f9fa;
    --bs-btn-border-color: #f8f9fa;
    --bs-btn-hover-color: #212529;
    --bs-btn-hover-bg: #f8f9fa;
    --bs-btn-hover-border-color: #f8f9fa;
    --bs-btn-focus-shadow-rgb: 248, 249, 250;
    --bs-btn-active-color: #212529;
    --bs-btn-active-bg: #f8f9fa;
    --bs-btn-active-border-color: #f8f9fa;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #f8f9fa;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #f8f9fa;
    --bs-gradient: none
}

.btn-outline-dark {
    --bs-btn-color: #212529;
    --bs-btn-border-color: #212529;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #212529;
    --bs-btn-hover-border-color: #212529;
    --bs-btn-focus-shadow-rgb: 33, 37, 41;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #212529;
    --bs-btn-active-border-color: #212529;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #212529;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #212529;
    --bs-gradient: none
}

.btn-link {
    --bs-btn-font-weight: 400;
    --bs-btn-color: #4680ff;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #3866cc;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: #3866cc;
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #5b6b79;
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: 0 0 0 #000;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    text-decoration: none
}

.btn-link:focus-visible,
.btn-link:hover {
    text-decoration: underline
}

.btn-link:focus-visible {
    color: var(--bs-btn-color)
}

.btn-link:hover {
    color: var(--bs-btn-hover-color)
}

.btn-group-lg>.btn,
.btn-lg,
.introjs-tooltip .btn-group-lg>.introjs-button {
    --bs-btn-padding-y: 1rem;
    --bs-btn-padding-x: 1.3rem;
    --bs-btn-font-size: 1.09375rem;
    --bs-btn-border-radius: 26px
}

.btn-group-sm>.btn,
.btn-sm,
.introjs-tooltip .btn-group-sm>.introjs-button {
    --bs-btn-padding-y: 0.25rem;
    --bs-btn-padding-x: 0.5rem;
    --bs-btn-font-size: 0.765625rem;
    --bs-btn-border-radius: 15px
}

.fade {
    transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none
    }
}

.collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing.collapse-horizontal {
        transition: none
    }
}

.dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    border-top: .3em solid;
    border-bottom: 0
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    --bs-dropdown-zindex: 1026;
    --bs-dropdown-min-width: 12rem;
    --bs-dropdown-padding-x: 0.5rem;
    --bs-dropdown-padding-y: 0.5rem;
    --bs-dropdown-spacer: 0.125rem;
    --bs-dropdown-font-size: 0.875rem;
    --bs-dropdown-color: #131920;
    --bs-dropdown-bg: #ffffff;
    --bs-dropdown-border-color: rgba(0, 0, 0, 0.15);
    --bs-dropdown-border-radius: 8px;
    --bs-dropdown-border-width: 0;
    --bs-dropdown-inner-border-radius: 8px;
    --bs-dropdown-divider-bg: #f3f5f7;
    --bs-dropdown-divider-margin-y: 0.5rem;
    --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
    --bs-dropdown-link-color: #131920;
    --bs-dropdown-link-hover-color: var(--bs-dropdown-link-color);
    --bs-dropdown-link-hover-bg: #f3f5f7;
    --bs-dropdown-link-active-color: var(--bs-dropdown-link-color);
    --bs-dropdown-link-active-bg: var(--pc-active-background);
    --bs-dropdown-link-disabled-color: #5b6b79;
    --bs-dropdown-item-padding-x: 0.95rem;
    --bs-dropdown-item-padding-y: 0.65rem;
    --bs-dropdown-header-color: #5b6b79;
    --bs-dropdown-header-padding-x: 0.95rem;
    --bs-dropdown-header-padding-y: 0.5rem;
    position: absolute;
    z-index: var(--bs-dropdown-zindex);
    display: none;
    min-width: var(--bs-dropdown-min-width);
    margin: 0;
    font-size: var(--bs-dropdown-font-size);
    color: var(--bs-dropdown-color);
    text-align: left;
    list-style: none;
    background-color: var(--bs-dropdown-bg);
    background-clip: padding-box;
    border-radius: var(--bs-dropdown-border-radius)
}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer)
}

.dropdown-menu-start {
    --bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0
}

.dropdown-menu-end {
    --bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto
}

@media (min-width:576px) {
    .dropdown-menu-sm-start {
        --bs-position: start
    }

    .dropdown-menu-sm-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-end {
        --bs-position: end
    }

    .dropdown-menu-sm-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:768px) {
    .dropdown-menu-md-start {
        --bs-position: start
    }

    .dropdown-menu-md-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-end {
        --bs-position: end
    }

    .dropdown-menu-md-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:992px) {
    .dropdown-menu-lg-start {
        --bs-position: start
    }

    .dropdown-menu-lg-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-end {
        --bs-position: end
    }

    .dropdown-menu-lg-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:1200px) {
    .dropdown-menu-xl-start {
        --bs-position: start
    }

    .dropdown-menu-xl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-end {
        --bs-position: end
    }

    .dropdown-menu-xl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:1400px) {
    .dropdown-menu-xxl-start {
        --bs-position: start
    }

    .dropdown-menu-xxl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xxl-end {
        --bs-position: end
    }

    .dropdown-menu-xxl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: var(--bs-dropdown-spacer)
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    border-top: 0;
    border-bottom: .3em solid
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: var(--bs-dropdown-spacer)
}

.dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid;
    vertical-align: 0
}

.dropend .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: var(--bs-dropdown-spacer)
}

.dropstart .dropdown-toggle::after {
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    display: none
}

.dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent;
    vertical-align: 0
}

.dropstart .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-divider {
    height: 0;
    margin: var(--bs-dropdown-divider-margin-y) 0;
    overflow: hidden;
    border-top: 1px solid var(--bs-dropdown-divider-bg);
    opacity: 1
}

.dropdown-item {
    width: 100%;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    clear: both;
    font-weight: 400;
    color: var(--bs-dropdown-link-color);
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    border-radius: var(--bs-dropdown-item-border-radius, 0)
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: var(--bs-dropdown-link-hover-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-hover-bg)
}

.dropdown-item.active,
.dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg)
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent
}

.dropdown-header {
    padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
    margin-bottom: 0;
    font-size: .765625rem;
    color: var(--bs-dropdown-header-color);
    white-space: nowrap
}

.dropdown-item-text {
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    color: var(--bs-dropdown-link-color)
}

.dropdown-menu-dark {
    --bs-dropdown-color: #dbe0e5;
    --bs-dropdown-bg: #1d2630;
    --bs-dropdown-border-color: rgba(0, 0, 0, 0.15);
    --bs-dropdown-link-color: #dbe0e5;
    --bs-dropdown-link-hover-color: #ffffff;
    --bs-dropdown-divider-bg: #f3f5f7;
    --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
    --bs-dropdown-link-active-color: var(--bs-dropdown-link-color);
    --bs-dropdown-link-active-bg: var(--pc-active-background);
    --bs-dropdown-link-disabled-color: #8996a4;
    --bs-dropdown-header-color: #8996a4
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn,
.introjs-tooltip .btn-group-vertical>.introjs-button,
.introjs-tooltip .btn-group>.introjs-button {
    position: relative;
    flex: 1 1 auto
}

.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover,
.introjs-tooltip .btn-group-vertical>.active.introjs-button,
.introjs-tooltip .btn-group-vertical>.btn-check:checked+.introjs-button,
.introjs-tooltip .btn-group-vertical>.btn-check:focus+.introjs-button,
.introjs-tooltip .btn-group-vertical>.introjs-button:active,
.introjs-tooltip .btn-group-vertical>.introjs-button:focus,
.introjs-tooltip .btn-group-vertical>.introjs-button:hover,
.introjs-tooltip .btn-group>.active.introjs-button,
.introjs-tooltip .btn-group>.btn-check:checked+.introjs-button,
.introjs-tooltip .btn-group>.btn-check:focus+.introjs-button,
.introjs-tooltip .btn-group>.introjs-button:active,
.introjs-tooltip .btn-group>.introjs-button:focus,
.introjs-tooltip .btn-group>.introjs-button:hover {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.accordion-button,
.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group,
.card-img,
.card-img-bottom,
.card-img-top,
.introjs-tooltip .btn-group-vertical>.introjs-button,
.list-group-item-action,
.modal,
.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link,
.progress-stacked>.progress>.progress-bar {
    width: 100%
}

.btn-group {
    border-radius: 20px
}

.btn-group>.btn-group:not(:first-child),
.btn-group>:not(.btn-check:first-child)+.btn,
.introjs-tooltip .btn-group>:not(.btn-check:first-child)+.introjs-button {
    margin-left: calc(1px * -1)
}

.card>hr,
.dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
    margin-left: 0
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn.dropdown-toggle-split:first-child,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.introjs-tooltip .btn-group>.btn-group:not(:last-child)>.introjs-button,
.introjs-tooltip .btn-group>.dropdown-toggle-split.introjs-button:first-child,
.introjs-tooltip .btn-group>.introjs-button:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:nth-child(n+3),
.btn-group>:not(.btn-check)+.btn,
.introjs-tooltip .btn-group>.btn-group:not(:first-child)>.introjs-button,
.introjs-tooltip .btn-group>.introjs-button:nth-child(n+3),
.introjs-tooltip .btn-group>:not(.btn-check)+.introjs-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.dropstart .dropdown-toggle-split::before {
    margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split,
.introjs-tooltip .btn-group-sm>.introjs-button+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split,
.introjs-tooltip .btn-group-lg>.introjs-button+.dropdown-toggle-split {
    padding-right: .975rem;
    padding-left: .975rem
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child),
.introjs-tooltip .btn-group-vertical>.introjs-button:not(:first-child) {
    margin-top: calc(1px * -1)
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.introjs-tooltip .btn-group-vertical>.btn-group:not(:last-child)>.introjs-button,
.introjs-tooltip .btn-group-vertical>.introjs-button:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn~.btn,
.introjs-tooltip .btn-group-vertical>.btn-group:not(:first-child)>.introjs-button,
.introjs-tooltip .btn-group-vertical>.btn~.introjs-button,
.introjs-tooltip .btn-group-vertical>.introjs-button~.btn,
.introjs-tooltip .btn-group-vertical>.introjs-button~.introjs-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav {
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-color: var(--bs-link-color);
    --bs-nav-link-hover-color: var(--bs-link-hover-color);
    --bs-nav-link-disabled-color: #5b6b79;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--bs-nav-link-color);
    background: 0 0;
    border: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .nav-link {
        transition: none
    }
}

.nav-link:focus,
.nav-link:hover {
    color: var(--bs-nav-link-hover-color);
    text-decoration: none
}

.nav-link:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.nav-link.disabled,
.nav-link:disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    --bs-nav-tabs-border-width: 1px;
    --bs-nav-tabs-border-color: var(--bs-border-color);
    --bs-nav-tabs-border-radius: 8px;
    --bs-nav-tabs-link-hover-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-border-color);
    --bs-nav-tabs-link-active-color: var(--bs-body-color);
    --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
    --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
    border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color)
}

.nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius)
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    isolation: isolate;
    border-color: var(--bs-nav-tabs-link-hover-border-color)
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color)
}

.nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills {
    --bs-nav-pills-border-radius: 8px;
    --bs-nav-pills-link-active-color: #ffffff;
    --bs-nav-pills-link-active-bg: var(--bs-primary)
}

.nav-pills .nav-link {
    border-radius: var(--bs-nav-pills-border-radius)
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg)
}

.nav-underline {
    --bs-nav-underline-gap: 1rem;
    --bs-nav-underline-border-width: 0.125rem;
    --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
    gap: var(--bs-nav-underline-gap)
}

.nav-underline .nav-link {
    padding-right: 0;
    padding-left: 0;
    border-bottom: var(--bs-nav-underline-border-width) solid transparent
}

.nav-underline .nav-link:focus,
.nav-underline .nav-link:hover {
    border-bottom-color: currentcolor
}

.nav-underline .nav-link.active,
.nav-underline .show>.nav-link {
    font-weight: 700;
    color: var(--bs-nav-underline-link-active-color);
    border-bottom-color: currentcolor
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    --bs-navbar-padding-x: 0;
    --bs-navbar-padding-y: calc(1rem / 2);
    --bs-navbar-color: rgba(0, 0, 0, 0.55);
    --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
    --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
    --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
    --bs-navbar-brand-padding-y: 0.3359375rem;
    --bs-navbar-brand-margin-end: 1rem;
    --bs-navbar-brand-font-size: 1.09375rem;
    --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
    --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
    --bs-navbar-nav-link-padding-x: 0.5rem;
    --bs-navbar-toggler-padding-y: 0.25rem;
    --bs-navbar-toggler-padding-x: 0.75rem;
    --bs-navbar-toggler-font-size: 1.09375rem;
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
    --bs-navbar-toggler-border-radius: 20px;
    --bs-navbar-toggler-focus-width: 0.2rem;
    --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x)
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    padding-top: var(--bs-navbar-brand-padding-y);
    padding-bottom: var(--bs-navbar-brand-padding-y);
    margin-right: var(--bs-navbar-brand-margin-end);
    font-size: var(--bs-navbar-brand-font-size);
    color: var(--bs-navbar-brand-color);
    white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
    color: var(--bs-navbar-brand-hover-color);
    text-decoration: none
}

.navbar-nav {
    --bs-nav-link-padding-x: 0;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-color: var(--bs-navbar-color);
    --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
    --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    color: var(--bs-navbar-active-color)
}

.navbar-text,
.navbar-toggler {
    color: var(--bs-navbar-color)
}

.navbar-nav .dropdown-menu {
    position: static
}

.navbar-text {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-text a,
.navbar-text a:focus,
.navbar-text a:hover {
    color: var(--bs-navbar-active-color)
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    background-color: transparent;
    border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
    border-radius: var(--bs-navbar-toggler-border-radius);
    transition: var(--bs-navbar-toggler-transition)
}

@media (prefers-reduced-motion:reduce) {
    .navbar-toggler {
        transition: none
    }
}

.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width)
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-image: var(--bs-navbar-toggler-icon-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%
}

.navbar-nav-scroll {
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: auto
}

@media (min-width:576px) {
    .navbar-expand-sm {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler,
    .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-sm .offcanvas {
        position: static;
        z-index: auto;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: transparent !important;
        border: 0 !important;
        transform: none !important;
        transition: none
    }

    .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:768px) {
    .navbar-expand-md {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler,
    .navbar-expand-md .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-md .offcanvas {
        position: static;
        z-index: auto;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: transparent !important;
        border: 0 !important;
        transform: none !important;
        transition: none
    }

    .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler,
    .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-lg .offcanvas {
        position: static;
        z-index: auto;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: transparent !important;
        border: 0 !important;
        transform: none !important;
        transition: none
    }

    .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler,
    .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-xl .offcanvas {
        position: static;
        z-index: auto;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: transparent !important;
        border: 0 !important;
        transform: none !important;
        transition: none
    }

    .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:1400px) {
    .navbar-expand-xxl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xxl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-xxl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xxl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xxl .navbar-toggler,
    .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-xxl .offcanvas {
        position: static;
        z-index: auto;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: transparent !important;
        border: 0 !important;
        transform: none !important;
        transition: none
    }

    .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

.navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start
}

.list-group-horizontal,
.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x)
}

.navbar-expand .navbar-nav-scroll {
    overflow: visible
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler,
.navbar-expand .offcanvas .offcanvas-header {
    display: none
}

.navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none
}

.navbar-expand .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible
}

.card-body,
.comment-block .comment {
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x)
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
    --bs-navbar-color: rgba(255, 255, 255, 0.55);
    --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
    --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
    --bs-navbar-active-color: #ffffff;
    --bs-navbar-brand-color: #ffffff;
    --bs-navbar-brand-hover-color: #ffffff;
    --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

[data-bs-theme=dark] .navbar-toggler-icon {
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.card {
    --bs-card-spacer-y: 25px;
    --bs-card-spacer-x: 25px;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 1px;
    --bs-card-border-color: #e7eaee;
    --bs-card-border-radius: 12px;
    --bs-card-box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.08);
    --bs-card-inner-border-radius: calc(12px - 1px);
    --bs-card-cap-padding-y: 25px;
    --bs-card-cap-padding-x: 25px;
    --bs-card-cap-bg: transparent;
    --bs-card-bg: #ffffff;
    --bs-card-img-overlay-padding: 1.25rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius)
}

.card>hr {
    margin-right: 0
}

.card>.list-group {
    border-top: inherit;
    border-bottom: inherit
}

.card>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: var(--bs-card-inner-border-radius);
    border-top-right-radius: var(--bs-card-inner-border-radius)
}

.card>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: var(--bs-card-inner-border-radius);
    border-bottom-left-radius: var(--bs-card-inner-border-radius)
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
    border-top: 0
}

.card-body {
    flex: 1 1 auto;
    color: var(--bs-card-color)
}

.card-title {
    margin-bottom: var(--bs-card-title-spacer-y);
    color: var(--bs-card-title-color)
}

.card-header,
.card-subtitle,
.card-text:last-child {
    margin-bottom: 0
}

.card-subtitle {
    margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
    color: var(--bs-card-subtitle-color)
}

.card-footer,
.card-header {
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
    color: var(--bs-card-cap-color);
    background-color: var(--bs-card-cap-bg)
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: var(--bs-card-spacer-x)
}

.card-header-pills,
.card-header-tabs {
    margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
    margin-left: calc(-.5 * var(--bs-card-cap-padding-x))
}

.card-header {
    border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color)
}

.card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0
}

.card-footer {
    border-top: var(--bs-card-border-width) solid var(--bs-card-border-color)
}

.card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius)
}

.card-header-tabs {
    margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
    border-bottom: 0
}

.card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg)
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: var(--bs-card-img-overlay-padding);
    border-radius: var(--bs-card-inner-border-radius)
}

.card-img,
.card-img-top {
    border-top-left-radius: var(--bs-card-inner-border-radius);
    border-top-right-radius: var(--bs-card-inner-border-radius)
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: var(--bs-card-inner-border-radius);
    border-bottom-left-radius: var(--bs-card-inner-border-radius)
}

.card-group>.card {
    margin-bottom: var(--bs-card-group-margin)
}

@media (min-width:576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-header,
    .card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-footer,
    .card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-header,
    .card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-footer,
    .card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.accordion {
    --bs-accordion-bg: #ffffff;
    --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    --bs-accordion-border-color: var(--bs-border-color);
    --bs-accordion-border-width: 1px;
    --bs-accordion-border-radius: 8px;
    --bs-accordion-inner-border-radius: 7px;
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: #131920;
    --bs-accordion-btn-bg: var(--bs-accordion-bg);
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23131920'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233f73e6'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(var(--bs-primary), 0.25);
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: #3f73e6;
    --bs-accordion-active-bg: #edf2ff;
    --bs-accordion-color: #131920
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: .875rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition)
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button {
        transition: none
    }
}

.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color)
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform)
}

.accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition)
}

.accordion-button:hover {
    z-index: 2
}

.accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow)
}

.accordion-header {
    margin-bottom: 0
}

.accordion-item {
    color: var(--bs-accordion-color);
    background-color: var(--bs-accordion-bg);
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color)
}

.accordion-flush>.accordion-item:first-child,
.accordion-item:not(:first-of-type) {
    border-top: 0
}

.accordion-flush>.accordion-item:last-child,
.analytics-tab,
.checkout-tabs {
    border-bottom: 0
}

.accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius)
}

.accordion-item:first-of-type>.accordion-header .accordion-button {
    border-top-left-radius: var(--bs-accordion-inner-border-radius);
    border-top-right-radius: var(--bs-accordion-inner-border-radius)
}

.accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius)
}

.accordion-item:last-of-type>.accordion-header .accordion-button.collapsed {
    border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
    border-bottom-left-radius: var(--bs-accordion-inner-border-radius)
}

.accordion-item:last-of-type>.accordion-collapse {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius)
}

.accordion-body {
    padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x)
}

.accordion-flush>.accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.accordion-flush>.accordion-item>.accordion-collapse,
.accordion-flush>.accordion-item>.accordion-header .accordion-button,
.accordion-flush>.accordion-item>.accordion-header .accordion-button.collapsed {
    border-radius: 0
}

[data-bs-theme=dark] .accordion-button::after {
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.breadcrumb {
    --bs-breadcrumb-padding-x: 0;
    --bs-breadcrumb-padding-y: 2;
    --bs-breadcrumb-margin-bottom: 1rem;
    --bs-breadcrumb-divider-color: #5b6b79;
    --bs-breadcrumb-item-padding-x: 0.5rem;
    --bs-breadcrumb-item-active-color: #5b6b79;
    display: flex;
    flex-wrap: wrap;
    padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
    margin-bottom: var(--bs-breadcrumb-margin-bottom);
    font-size: var(--bs-breadcrumb-font-size);
    list-style: none;
    background-color: var(--bs-breadcrumb-bg);
    border-radius: var(--bs-breadcrumb-border-radius)
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: var(--bs-breadcrumb-item-padding-x)
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='14' height='14' stroke='%235b6b79' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E"))
}

.breadcrumb-item.active {
    color: var(--bs-breadcrumb-item-active-color)
}

.pagination {
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.375rem;
    --bs-pagination-font-size: 0.875rem;
    --bs-pagination-color: var(--bs-link-color);
    --bs-pagination-bg: #ffffff;
    --bs-pagination-border-width: 1px;
    --bs-pagination-border-color: #dbe0e5;
    --bs-pagination-border-radius: 8px;
    --bs-pagination-hover-color: var(--bs-link-hover-color);
    --bs-pagination-hover-bg: #f3f5f7;
    --bs-pagination-hover-border-color: #dbe0e5;
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: #f3f5f7;
    --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(var(--bs-primary), 0.25);
    --bs-pagination-active-color: #ffffff;
    --bs-pagination-active-bg: var(--bs-primary);
    --bs-pagination-active-border-color: var(--bs-primary);
    --bs-pagination-disabled-color: #5b6b79;
    --bs-pagination-disabled-bg: #ffffff;
    --bs-pagination-disabled-border-color: #dbe0e5;
    display: flex;
    padding-left: 0;
    list-style: none
}

.page-link {
    position: relative;
    display: block;
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
    font-size: var(--bs-pagination-font-size);
    color: var(--bs-pagination-color);
    background-color: var(--bs-pagination-bg);
    border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color)
}

@media (prefers-reduced-motion:reduce) {

    .accordion-button::after,
    .page-link {
        transition: none
    }
}

.page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    text-decoration: none;
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color)
}

.page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow)
}

.active>.page-link,
.page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color)
}

.disabled>.page-link,
.page-link.disabled {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color)
}

.page-item:not(:first-child) .page-link {
    margin-left: -1px
}

.page-item .page-link {
    border-radius: var(--bs-pagination-border-radius)
}

.pagination-lg {
    --bs-pagination-padding-x: 1.5rem;
    --bs-pagination-padding-y: 0.75rem;
    --bs-pagination-font-size: 1.09375rem;
    --bs-pagination-border-radius: 10px
}

.pagination-sm {
    --bs-pagination-padding-x: 0.5rem;
    --bs-pagination-padding-y: 0.25rem;
    --bs-pagination-font-size: 0.765625rem;
    --bs-pagination-border-radius: 6px
}

.badge {
    --bs-badge-padding-x: 0.8em;
    --bs-badge-padding-y: 0.45em;
    --bs-badge-font-size: 0.75em;
    --bs-badge-font-weight: 500;
    --bs-badge-color: #ffffff;
    --bs-badge-border-radius: 6px;
    display: inline-block;
    padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
    font-size: var(--bs-badge-font-size);
    font-weight: var(--bs-badge-font-weight);
    line-height: 1;
    color: var(--bs-badge-color);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: var(--bs-badge-border-radius)
}

.badge:empty,
.toast:not(.show) {
    display: none
}

.btn .badge,
.introjs-tooltip .introjs-button .badge {
    position: relative;
    top: -1px
}

.alert {
    --bs-alert-bg: transparent;
    --bs-alert-padding-x: 1.25rem;
    --bs-alert-padding-y: 0.75rem;
    --bs-alert-margin-bottom: 1rem;
    --bs-alert-color: inherit;
    --bs-alert-border-color: transparent;
    --bs-alert-border: 1px solid var(--bs-alert-border-color);
    --bs-alert-border-radius: 8px;
    --bs-alert-link-color: inherit;
    position: relative;
    padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
    margin-bottom: var(--bs-alert-margin-bottom);
    color: var(--bs-alert-color);
    background-color: var(--bs-alert-bg);
    border: var(--bs-alert-border);
    border-radius: var(--bs-alert-border-radius)
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700;
    color: var(--bs-alert-link-color)
}

.alert-dismissible {
    padding-right: 3.75rem
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: .9375rem 1.25rem
}

.alert-theme {
    --bs-alert-color: var(--bs-theme-text-emphasis);
    --bs-alert-bg: var(--bs-theme-bg-subtle);
    --bs-alert-border-color: var(--bs-theme-border-subtle);
    --bs-alert-link-color: var(--bs-theme-text-emphasis)
}

.alert-primary {
    --bs-alert-color: var(--bs-primary-text-emphasis);
    --bs-alert-bg: var(--bs-primary-bg-subtle);
    --bs-alert-border-color: var(--bs-primary-border-subtle);
    --bs-alert-link-color: var(--bs-primary-text-emphasis)
}

.alert-secondary {
    --bs-alert-color: var(--bs-secondary-text-emphasis);
    --bs-alert-bg: var(--bs-secondary-bg-subtle);
    --bs-alert-border-color: var(--bs-secondary-border-subtle);
    --bs-alert-link-color: var(--bs-secondary-text-emphasis)
}

.alert-success {
    --bs-alert-color: var(--bs-success-text-emphasis);
    --bs-alert-bg: var(--bs-success-bg-subtle);
    --bs-alert-border-color: var(--bs-success-border-subtle);
    --bs-alert-link-color: var(--bs-success-text-emphasis)
}

.alert-info {
    --bs-alert-color: var(--bs-info-text-emphasis);
    --bs-alert-bg: var(--bs-info-bg-subtle);
    --bs-alert-border-color: var(--bs-info-border-subtle);
    --bs-alert-link-color: var(--bs-info-text-emphasis)
}

.alert-warning {
    --bs-alert-color: var(--bs-warning-text-emphasis);
    --bs-alert-bg: var(--bs-warning-bg-subtle);
    --bs-alert-border-color: var(--bs-warning-border-subtle);
    --bs-alert-link-color: var(--bs-warning-text-emphasis)
}

.alert-danger {
    --bs-alert-color: var(--bs-danger-text-emphasis);
    --bs-alert-bg: var(--bs-danger-bg-subtle);
    --bs-alert-border-color: var(--bs-danger-border-subtle);
    --bs-alert-link-color: var(--bs-danger-text-emphasis)
}

.alert-light {
    --bs-alert-color: var(--bs-light-text-emphasis);
    --bs-alert-bg: var(--bs-light-bg-subtle);
    --bs-alert-border-color: var(--bs-light-border-subtle);
    --bs-alert-link-color: var(--bs-light-text-emphasis)
}

.alert-dark {
    --bs-alert-color: var(--bs-dark-text-emphasis);
    --bs-alert-bg: var(--bs-dark-bg-subtle);
    --bs-alert-border-color: var(--bs-dark-border-subtle);
    --bs-alert-link-color: var(--bs-dark-text-emphasis)
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

.progress,
.progress-stacked {
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.65625rem;
    --bs-progress-bg: #f8f9fa;
    --bs-progress-border-radius: 8px;
    --bs-progress-box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
    --bs-progress-bar-color: #ffffff;
    --bs-progress-bar-bg: #4680ff;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius)
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--bs-progress-bar-bg);
    transition: var(--bs-progress-bar-transition)
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: var(--bs-progress-height) var(--bs-progress-height)
}

.progress-stacked>.progress {
    overflow: visible
}

.progress-bar-animated {
    animation: 1s linear infinite progress-bar-stripes
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar {
        transition: none
    }

    .progress-bar-animated {
        animation: none
    }
}

.list-group {
    --bs-list-group-bg: #ffffff;
    --bs-list-group-border-color: #e7eaee;
    --bs-list-group-border-width: 1px;
    --bs-list-group-border-radius: 8px;
    --bs-list-group-item-padding-x: 25px;
    --bs-list-group-item-padding-y: 16.6666666667px;
    --bs-list-group-action-color: #3e4853;
    --bs-list-group-action-hover-color: #3e4853;
    --bs-list-group-action-hover-bg: #f8f9fa;
    --bs-list-group-action-active-color: #131920;
    --bs-list-group-action-active-bg: #f3f5f7;
    --bs-list-group-disabled-color: #dbe0e5;
    --bs-list-group-disabled-bg: #ffffff;
    --bs-list-group-active-color: #ffffff;
    --bs-list-group-active-bg: var(--bs-primary);
    --bs-list-group-active-border-color: var(--bs-primary);
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: var(--bs-list-group-border-radius)
}

.list-group-numbered {
    list-style-type: none;
    counter-reset: section
}

.list-group-numbered>.list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section
}

.list-group-item-action {
    color: var(--bs-list-group-action-color);
    text-align: inherit
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg)
}

.list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg)
}

.list-group-item {
    position: relative;
    display: block;
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color)
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg)
}

.list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color)
}

.btn-close,
.btn-close:hover {
    color: var(--bs-btn-close-color)
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: calc(-1 * var(--bs-list-group-border-width));
    border-top-width: var(--bs-list-group-border-width)
}

.list-group-horizontal>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width)
}

@media (min-width:576px) {
    .list-group-horizontal-sm {
        flex-direction: row
    }

    .list-group-horizontal-sm>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }
}

@media (min-width:768px) {
    .list-group-horizontal-md {
        flex-direction: row
    }

    .list-group-horizontal-md>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-md>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }
}

@media (min-width:992px) {
    .list-group-horizontal-lg {
        flex-direction: row
    }

    .list-group-horizontal-lg>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }

    .modal-lg,
    .modal-xl {
        --bs-modal-width: 800px
    }
}

@media (min-width:1200px) {
    .list-group-horizontal-xl {
        flex-direction: row
    }

    .list-group-horizontal-xl>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }

    .modal-xl {
        --bs-modal-width: 1140px
    }
}

@media (min-width:1400px) {
    .list-group-horizontal-xxl {
        flex-direction: row
    }

    .list-group-horizontal-xxl>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }
}

.list-group-flush {
    border-radius: 0
}

.list-group-flush>.list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width)
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-theme {
    --bs-list-group-color: var(--bs-theme-text-emphasis);
    --bs-list-group-bg: var(--bs-theme-bg-subtle);
    --bs-list-group-border-color: var(--bs-theme-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-theme-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-theme-border-subtle);
    --bs-list-group-active-color: var(--bs-theme-bg-subtle);
    --bs-list-group-active-bg: var(--bs-theme-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-theme-text-emphasis)
}

.list-group-item-primary {
    --bs-list-group-color: var(--bs-primary-text-emphasis);
    --bs-list-group-bg: var(--bs-primary-bg-subtle);
    --bs-list-group-border-color: var(--bs-primary-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
    --bs-list-group-active-color: var(--bs-primary-bg-subtle);
    --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-primary-text-emphasis)
}

.list-group-item-secondary {
    --bs-list-group-color: var(--bs-secondary-text-emphasis);
    --bs-list-group-bg: var(--bs-secondary-bg-subtle);
    --bs-list-group-border-color: var(--bs-secondary-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
    --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
    --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis)
}

.list-group-item-success {
    --bs-list-group-color: var(--bs-success-text-emphasis);
    --bs-list-group-bg: var(--bs-success-bg-subtle);
    --bs-list-group-border-color: var(--bs-success-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
    --bs-list-group-active-color: var(--bs-success-bg-subtle);
    --bs-list-group-active-bg: var(--bs-success-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-success-text-emphasis)
}

.list-group-item-info {
    --bs-list-group-color: var(--bs-info-text-emphasis);
    --bs-list-group-bg: var(--bs-info-bg-subtle);
    --bs-list-group-border-color: var(--bs-info-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
    --bs-list-group-active-color: var(--bs-info-bg-subtle);
    --bs-list-group-active-bg: var(--bs-info-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-info-text-emphasis)
}

.list-group-item-warning {
    --bs-list-group-color: var(--bs-warning-text-emphasis);
    --bs-list-group-bg: var(--bs-warning-bg-subtle);
    --bs-list-group-border-color: var(--bs-warning-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
    --bs-list-group-active-color: var(--bs-warning-bg-subtle);
    --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-warning-text-emphasis)
}

.list-group-item-danger {
    --bs-list-group-color: var(--bs-danger-text-emphasis);
    --bs-list-group-bg: var(--bs-danger-bg-subtle);
    --bs-list-group-border-color: var(--bs-danger-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
    --bs-list-group-active-color: var(--bs-danger-bg-subtle);
    --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-danger-text-emphasis)
}

.list-group-item-light {
    --bs-list-group-color: var(--bs-light-text-emphasis);
    --bs-list-group-bg: var(--bs-light-bg-subtle);
    --bs-list-group-border-color: var(--bs-light-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
    --bs-list-group-active-color: var(--bs-light-bg-subtle);
    --bs-list-group-active-bg: var(--bs-light-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-light-text-emphasis)
}

.list-group-item-dark {
    --bs-list-group-color: var(--bs-dark-text-emphasis);
    --bs-list-group-bg: var(--bs-dark-bg-subtle);
    --bs-list-group-border-color: var(--bs-dark-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
    --bs-list-group-active-color: var(--bs-dark-bg-subtle);
    --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-dark-text-emphasis)
}

.btn-close {
    --bs-btn-close-color: #000000;
    --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 16 16'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
    --bs-btn-close-opacity: 0.5;
    --bs-btn-close-hover-opacity: 0.75;
    --bs-btn-close-focus-shadow: 0 0 0 0.2rem rgba(var(--bs-primary), 0.25);
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em;
    background: center/1em auto no-repeat var(--bs-btn-close-bg);
    border: 0;
    border-radius: 8px;
    opacity: var(--bs-btn-close-opacity)
}

.introjs-tooltip,
.modal-content,
.popover,
.toast,
.toast-header {
    background-clip: padding-box
}

.btn-close:hover {
    text-decoration: none;
    opacity: var(--bs-btn-close-hover-opacity)
}

.btn-close:focus {
    outline: 0;
    box-shadow: var(--bs-btn-close-focus-shadow);
    opacity: var(--bs-btn-close-focus-opacity)
}

.btn-close.disabled,
.btn-close:disabled {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    opacity: var(--bs-btn-close-disabled-opacity)
}

.modal-backdrop.fade,
.toast.showing {
    opacity: 0
}

.btn-close-white,
[data-bs-theme=dark] .btn-close {
    filter: var(--bs-btn-close-white-filter)
}

.toast {
    --bs-toast-zindex: 1090;
    --bs-toast-padding-x: 0.75rem;
    --bs-toast-padding-y: 0.25rem;
    --bs-toast-spacing: 1.5rem;
    --bs-toast-max-width: 350px;
    --bs-toast-font-size: 0.875rem;
    --bs-toast-bg: rgba(255, 255, 255, 0.85);
    --bs-toast-border-width: 1px;
    --bs-toast-border-color: rgba(0, 0, 0, 0.1);
    --bs-toast-border-radius: 0.25rem;
    --bs-toast-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    --bs-toast-header-color: #5b6b79;
    --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
    --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
    width: var(--bs-toast-max-width);
    max-width: 100%;
    font-size: var(--bs-toast-font-size);
    color: var(--bs-toast-color);
    pointer-events: auto;
    background-color: var(--bs-toast-bg);
    border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
    box-shadow: var(--bs-toast-box-shadow);
    border-radius: var(--bs-toast-border-radius)
}

.toast-container {
    --bs-toast-zindex: 1090;
    position: absolute;
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    pointer-events: none
}

.toast-container>:not(:last-child) {
    margin-bottom: var(--bs-toast-spacing)
}

.toast-header {
    display: flex;
    align-items: center;
    padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
    color: var(--bs-toast-header-color);
    background-color: var(--bs-toast-header-bg);
    border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
    border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
    border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width))
}

.toast-header .btn-close {
    margin-right: calc(-.5 * var(--bs-toast-padding-x));
    margin-left: var(--bs-toast-padding-x)
}

.toast-body {
    padding: var(--bs-toast-padding-x)
}

.modal {
    --bs-modal-zindex: 1055;
    --bs-modal-width: 500px;
    --bs-modal-padding: 1.25rem;
    --bs-modal-margin: 0.5rem;
    --bs-modal-bg: #ffffff;
    --bs-modal-border-color: rgba(0, 0, 0, 0.2);
    --bs-modal-border-width: 1px;
    --bs-modal-border-radius: 10px;
    --bs-modal-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
    --bs-modal-inner-border-radius: 9px;
    --bs-modal-header-padding-x: 1.5625rem;
    --bs-modal-header-padding-y: 1.25rem;
    --bs-modal-header-padding: 1.25rem 1.5625rem;
    --bs-modal-header-border-color: #e7eaee;
    --bs-modal-header-border-width: 1px;
    --bs-modal-title-line-height: 1.5;
    --bs-modal-footer-gap: 0.5rem;
    --bs-modal-footer-border-color: #e7eaee;
    --bs-modal-footer-border-width: 1px;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: var(--bs-modal-margin);
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, -50px)
}

@media (prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    transform: none
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    height: calc(100% - var(--bs-modal-margin) * 2)
}

.modal-dialog-scrollable .introjs-tooltip,
.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2)
}

.introjs-tooltip,
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0
}

.modal-backdrop {
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #000000;
    --bs-backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg)
}

.modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity)
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding: var(--bs-modal-header-padding);
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius)
}

.modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto
}

.modal-title {
    margin-bottom: 0;
    line-height: var(--bs-modal-title-line-height)
}

.popover,
.tooltip {
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    line-break: auto;
    word-wrap: break-word
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: var(--bs-modal-padding)
}

.modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
    background-color: var(--bs-modal-footer-bg);
    border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
    border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius)
}

.modal-footer>* {
    margin: calc(var(--bs-modal-footer-gap) * .5)
}

@media (min-width:576px) {
    .modal {
        --bs-modal-margin: 1.75rem;
        --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5)
    }

    .modal-dialog {
        max-width: var(--bs-modal-width);
        margin-right: auto;
        margin-left: auto
    }

    .modal-sm {
        --bs-modal-width: 300px
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
}

.modal-fullscreen .introjs-tooltip,
.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
}

.modal-fullscreen .modal-footer,
.modal-fullscreen .modal-header {
    border-radius: 0
}

.modal-fullscreen .modal-body {
    overflow-y: auto
}

@media (max-width:575.98px) {
    .modal-fullscreen-sm-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-sm-down .introjs-tooltip,
    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-footer,
    .modal-fullscreen-sm-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width:767.98px) {
    .modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-md-down .introjs-tooltip,
    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-footer,
    .modal-fullscreen-md-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width:991.98px) {
    .modal-fullscreen-lg-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-lg-down .introjs-tooltip,
    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-footer,
    .modal-fullscreen-lg-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width:1199.98px) {
    .modal-fullscreen-xl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xl-down .introjs-tooltip,
    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-footer,
    .modal-fullscreen-xl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width:1399.98px) {
    .modal-fullscreen-xxl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xxl-down .introjs-tooltip,
    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-footer,
    .modal-fullscreen-xxl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto
    }
}

.tooltip {
    --bs-tooltip-zindex: 1080;
    --bs-tooltip-max-width: 200px;
    --bs-tooltip-padding-x: 0.5rem;
    --bs-tooltip-padding-y: 0.25rem;
    --bs-tooltip-margin: 0;
    --bs-tooltip-font-size: 0.765625rem;
    --bs-tooltip-color: #ffffff;
    --bs-tooltip-bg: #000000;
    --bs-tooltip-border-radius: 8px;
    --bs-tooltip-opacity: 0.9;
    --bs-tooltip-arrow-width: 0.8rem;
    --bs-tooltip-arrow-height: 0.4rem;
    z-index: var(--bs-tooltip-zindex);
    display: block;
    margin: var(--bs-tooltip-margin);
    font-family: var(--bs-font-sans-serif);
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    word-spacing: normal;
    font-size: var(--bs-tooltip-font-size);
    opacity: 0
}

.tooltip.show {
    opacity: var(--bs-tooltip-opacity)
}

.tooltip .tooltip-arrow {
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height)
}

.tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
    bottom: calc(-1 * var(--bs-tooltip-arrow-height))
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-top-color: var(--bs-tooltip-bg)
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
    left: calc(-1 * var(--bs-tooltip-arrow-height));
    width: var(--bs-tooltip-arrow-height);
    height: var(--bs-tooltip-arrow-width)
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-right-color: var(--bs-tooltip-bg)
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
    top: calc(-1 * var(--bs-tooltip-arrow-height))
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg)
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
    right: calc(-1 * var(--bs-tooltip-arrow-height));
    width: var(--bs-tooltip-arrow-height);
    height: var(--bs-tooltip-arrow-width)
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg)
}

.tooltip-inner {
    max-width: var(--bs-tooltip-max-width);
    padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
    color: var(--bs-tooltip-color);
    text-align: center;
    background-color: var(--bs-tooltip-bg);
    border-radius: var(--bs-tooltip-border-radius)
}

.popover {
    --bs-popover-zindex: 1070;
    --bs-popover-max-width: 276px;
    --bs-popover-font-size: 0.765625rem;
    --bs-popover-bg: #ffffff;
    --bs-popover-border-width: 1px;
    --bs-popover-border-color: rgba(0, 0, 0, 0.2);
    --bs-popover-border-radius: 10px;
    --bs-popover-inner-border-radius: 9px;
    --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --bs-popover-header-padding-x: 0.75rem;
    --bs-popover-header-padding-y: 0.5rem;
    --bs-popover-header-font-size: 1rem;
    --bs-popover-header-color: #262626;
    --bs-popover-header-bg: #f7f7f7;
    --bs-popover-body-padding-x: 1rem;
    --bs-popover-body-padding-y: 1rem;
    --bs-popover-body-color: #131920;
    --bs-popover-arrow-width: 1rem;
    --bs-popover-arrow-height: 0.5rem;
    --bs-popover-arrow-border: var(--bs-popover-border-color);
    z-index: var(--bs-popover-zindex);
    display: block;
    max-width: var(--bs-popover-max-width);
    font-family: var(--bs-font-sans-serif);
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    word-spacing: normal;
    font-size: var(--bs-popover-font-size);
    background-color: var(--bs-popover-bg);
    border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
    border-radius: var(--bs-popover-border-radius)
}

.popover .popover-arrow {
    display: block;
    width: var(--bs-popover-arrow-width);
    height: var(--bs-popover-arrow-height)
}

.popover .popover-arrow::after,
.popover .popover-arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-width: 0
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow,
.bs-popover-top>.popover-arrow {
    bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width))
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::after,
.bs-popover-top>.popover-arrow::before {
    border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::before {
    bottom: 0;
    border-top-color: var(--bs-popover-arrow-border)
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.bs-popover-top>.popover-arrow::after {
    bottom: var(--bs-popover-border-width);
    border-top-color: var(--bs-popover-bg)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow,
.bs-popover-end>.popover-arrow {
    left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
    width: var(--bs-popover-arrow-height);
    height: var(--bs-popover-arrow-width)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::after,
.bs-popover-end>.popover-arrow::before {
    border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::before {
    left: 0;
    border-right-color: var(--bs-popover-arrow-border)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.bs-popover-end>.popover-arrow::after {
    left: var(--bs-popover-border-width);
    border-right-color: var(--bs-popover-bg)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow,
.bs-popover-bottom>.popover-arrow {
    top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width))
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::after,
.bs-popover-bottom>.popover-arrow::before {
    border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::before {
    top: 0;
    border-bottom-color: var(--bs-popover-arrow-border)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.bs-popover-bottom>.popover-arrow::after {
    top: var(--bs-popover-border-width);
    border-bottom-color: var(--bs-popover-bg)
}

.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: var(--bs-popover-arrow-width);
    margin-left: calc(-.5 * var(--bs-popover-arrow-width));
    content: "";
    border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg)
}

.carousel,
.carousel-inner,
.carousel-item,
.ratio {
    position: relative
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow,
.bs-popover-start>.popover-arrow {
    right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
    width: var(--bs-popover-arrow-height);
    height: var(--bs-popover-arrow-width)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::after,
.bs-popover-start>.popover-arrow::before {
    border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::before {
    right: 0;
    border-left-color: var(--bs-popover-arrow-border)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.bs-popover-start>.popover-arrow::after {
    right: var(--bs-popover-border-width);
    border-left-color: var(--bs-popover-bg)
}

.popover-header {
    padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
    margin-bottom: 0;
    font-size: var(--bs-popover-header-font-size);
    color: var(--bs-popover-header-color);
    background-color: var(--bs-popover-header-bg);
    border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
    border-top-left-radius: var(--bs-popover-inner-border-radius);
    border-top-right-radius: var(--bs-popover-inner-border-radius)
}


.popover-header:empty {
    display: none
}

.popover-body {
    padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
    color: var(--bs-popover-body-color)
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block
}

.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
    transform: translateX(100%)
}

.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
}

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {

    .carousel-fade .active.carousel-item-end,
    .carousel-fade .active.carousel-item-start {
        transition: none
    }
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {

    .carousel-control-next,
    .carousel-control-prev {
        transition: none
    }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}



.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

.carousel-dark .carousel-indicators [data-bs-target],
.offcanvas-backdrop,
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target],
[data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
    background-color: #000
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center
}

.carousel-dark .carousel-caption,
[data-bs-theme=dark] .carousel .carousel-caption,
[data-bs-theme=dark].carousel .carousel-caption {
    color: #000
}

.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
    filter: invert(1) grayscale(100)
}

[data-bs-theme=dark] .carousel .carousel-control-next-icon,
[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon,
[data-bs-theme=dark].carousel .carousel-control-prev-icon {
    filter: invert(1) grayscale(100)
}

.spinner-border,
.spinner-grow {
    display: inline-block;
    width: var(--bs-spinner-width);
    height: var(--bs-spinner-height);
    vertical-align: var(--bs-spinner-vertical-align);
    border-radius: 50%;
    animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name)
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg)
    }
}

.spinner-border {
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-border-width: 0.25em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: spinner-border;
    border: var(--bs-spinner-border-width) solid currentcolor;
    border-right-color: transparent
}

.spinner-border-sm {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem;
    --bs-spinner-border-width: 0.2em
}

@keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1;
        transform: none
    }
}

.spinner-grow {
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: spinner-grow;
    background-color: currentcolor;
    opacity: 0
}

.spinner-grow-sm {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem
}

@media (prefers-reduced-motion:reduce) {
    .carousel-indicators [data-bs-target] {
        transition: none
    }

    .spinner-border,
    .spinner-grow {
        --bs-spinner-animation-speed: 1.5s
    }
}

.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
    --bs-offcanvas-zindex: 1045;
    --bs-offcanvas-width: 470px;
    --bs-offcanvas-height: 33vh;
    --bs-offcanvas-padding-x: 1.25rem;
    --bs-offcanvas-padding-y: 1.25rem;
    --bs-offcanvas-bg: #ffffff;
    --bs-offcanvas-border-width: 1px;
    --bs-offcanvas-border-color: rgba(0, 0, 0, 0.2);
    --bs-offcanvas-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
    --bs-offcanvas-transition: transform 0.3s ease-in-out;
    --bs-offcanvas-title-line-height: 1.5
}

@media (max-width:575.98px) {
    .offcanvas-sm {
        position: fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: var(--bs-offcanvas-transition)
    }

    .offcanvas-sm.offcanvas-bottom,
    .offcanvas-sm.offcanvas-top {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%
    }

    .offcanvas-sm.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }

    .offcanvas-sm.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }

    .offcanvas-sm.offcanvas-top {
        top: 0;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }

    .offcanvas-sm.offcanvas-bottom {
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }

    .offcanvas-sm.show:not(.hiding),
    .offcanvas-sm.showing {
        transform: none
    }

    .offcanvas-sm.hiding,
    .offcanvas-sm.show,
    .offcanvas-sm.showing {
        visibility: visible
    }
}

@media (max-width:575.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-sm {
        transition: none
    }
}

@media (min-width:576px) {
    .offcanvas-sm {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-sm .offcanvas-header {
        display: none
    }

    .offcanvas-sm .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}

@media (max-width:767.98px) {
    .offcanvas-md {
        position: fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: var(--bs-offcanvas-transition)
    }

    .offcanvas-md.offcanvas-bottom,
    .offcanvas-md.offcanvas-top {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%
    }

    .offcanvas-md.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }

    .offcanvas-md.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }

    .offcanvas-md.offcanvas-top {
        top: 0;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }

    .offcanvas-md.offcanvas-bottom {
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }

    .offcanvas-md.show:not(.hiding),
    .offcanvas-md.showing {
        transform: none
    }

    .offcanvas-md.hiding,
    .offcanvas-md.show,
    .offcanvas-md.showing {
        visibility: visible
    }
}

@media (max-width:767.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-md {
        transition: none
    }
}

@media (min-width:768px) {
    .offcanvas-md {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-md .offcanvas-header {
        display: none
    }

    .offcanvas-md .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}

@media (max-width:991.98px) {
    .offcanvas-lg {
        position: fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: var(--bs-offcanvas-transition)
    }

    .offcanvas-lg.offcanvas-bottom,
    .offcanvas-lg.offcanvas-top {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%
    }

    .offcanvas-lg.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }

    .offcanvas-lg.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }

    .offcanvas-lg.offcanvas-top {
        top: 0;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }

    .offcanvas-lg.offcanvas-bottom {
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }

    .offcanvas-lg.show:not(.hiding),
    .offcanvas-lg.showing {
        transform: none
    }

    .offcanvas-lg.hiding,
    .offcanvas-lg.show,
    .offcanvas-lg.showing {
        visibility: visible
    }
}

@media (max-width:991.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-lg {
        transition: none
    }
}

@media (min-width:992px) {
    .offcanvas-lg {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-lg .offcanvas-header {
        display: none
    }

    .offcanvas-lg .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}

@media (max-width:1199.98px) {
    .offcanvas-xl {
        position: fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: var(--bs-offcanvas-transition)
    }

    .offcanvas-xl.offcanvas-bottom,
    .offcanvas-xl.offcanvas-top {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%
    }

    .offcanvas-xl.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }

    .offcanvas-xl.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }

    .offcanvas-xl.offcanvas-top {
        top: 0;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }

    .offcanvas-xl.offcanvas-bottom {
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }

    .offcanvas-xl.show:not(.hiding),
    .offcanvas-xl.showing {
        transform: none
    }

    .offcanvas-xl.hiding,
    .offcanvas-xl.show,
    .offcanvas-xl.showing {
        visibility: visible
    }
}

@media (max-width:1199.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-xl {
        transition: none
    }
}

@media (min-width:1200px) {
    .offcanvas-xl {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-xl .offcanvas-header {
        display: none
    }

    .offcanvas-xl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}

@media (max-width:1399.98px) {
    .offcanvas-xxl {
        position: fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: var(--bs-offcanvas-transition)
    }

    .offcanvas-xxl.offcanvas-bottom,
    .offcanvas-xxl.offcanvas-top {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%
    }

    .offcanvas-xxl.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }

    .offcanvas-xxl.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }

    .offcanvas-xxl.offcanvas-top {
        top: 0;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }

    .offcanvas-xxl.offcanvas-bottom {
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }

    .offcanvas-xxl.show:not(.hiding),
    .offcanvas-xxl.showing {
        transform: none
    }

    .offcanvas-xxl.hiding,
    .offcanvas-xxl.show,
    .offcanvas-xxl.showing {
        visibility: visible
    }
}

@media (max-width:1399.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-xxl {
        transition: none
    }
}

@media (min-width:1400px) {
    .offcanvas-xxl {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }

    .offcanvas-xxl .offcanvas-header {
        display: none
    }

    .offcanvas-xxl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}

.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition)
}

.text-bg-danger,
.text-bg-info,
.text-bg-primary,
.text-bg-secondary,
.text-bg-success,
.text-bg-theme,
.text-bg-warning {
    color: #fff !important
}

@media (prefers-reduced-motion:reduce) {
    .offcanvas {
        transition: none
    }
}

.offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%)
}

.offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%)
}

.offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%)
}

.offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%)
}

.offcanvas.show:not(.hiding),
.offcanvas.showing {
    transform: none
}

.offcanvas.hiding,
.offcanvas.show,
.offcanvas.showing {
    visibility: visible
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh
}

.placeholder,
.vr {
    min-height: 1em;
    background-color: currentcolor
}

.offcanvas-backdrop.fade {
    opacity: 0
}

.offcanvas-backdrop.show {
    opacity: .5
}

.offcanvas-header {
    display: flex;
    align-items: center;
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x)
}

.offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin: calc(-.5 * var(--bs-offcanvas-padding-y)) calc(-.5 * var(--bs-offcanvas-padding-x)) calc(-.5 * var(--bs-offcanvas-padding-y)) auto
}

.offcanvas-title {
    margin-bottom: 0;
    line-height: var(--bs-offcanvas-title-line-height)
}

.lh-1,
[data-pc-direction=rtl] .lh-1 {
    line-height: 1 !important
}

.offcanvas-body {
    flex-grow: 1;
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
    overflow-y: auto
}

.placeholder {
    display: inline-block;
    vertical-align: middle;
    cursor: wait;
    opacity: .5
}


.introjs-tooltip .placeholder.introjs-button::before,
.placeholder.btn::before {
    display: inline-block;
    content: ""
}

.placeholder-xs {
    min-height: .6em
}

.placeholder-sm {
    min-height: .8em
}

.placeholder-lg {
    min-height: 1.2em
}

.placeholder-glow .placeholder {
    animation: placeholder-glow 2s ease-in-out infinite
}

@keyframes placeholder-glow {
    50% {
        opacity: .2
    }
}

.placeholder-wave {
    -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, .8) 75%, #000 95%);
    mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, .8) 75%, #000 95%);
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
    animation: placeholder-wave 2s linear infinite
}

@keyframes placeholder-wave {
    100% {
        -webkit-mask-position: -200% 0;
        mask-position: -200% 0
    }
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.text-bg-theme {
    background-color: RGBA(var(--bs-theme-rgb), var(--bs-bg-opacity, 1)) !important
}

.text-bg-primary {
    background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important
}

.text-bg-secondary {
    background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important
}

.text-bg-success {
    background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important
}

.text-bg-info {
    background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important
}

.text-bg-warning {
    background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important
}

.text-bg-danger {
    background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important
}

.text-bg-light {
    color: #212529 !important;
    background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important
}

.text-bg-dark {
    color: #fff !important;
    background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important
}

.link-theme {
    color: RGBA(var(--bs-theme-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-theme-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-theme-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-theme:focus,
.link-theme:hover {
    color: RGBA(204, 41, 82, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(204, 41, 82, var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(204, 41, 82, var(--bs-link-underline-opacity, 1)) !important
}

.link-primary {
    color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-primary:focus,
.link-primary:hover {
    color: RGBA(56, 102, 204, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(56, 102, 204, var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(56, 102, 204, var(--bs-link-underline-opacity, 1)) !important
}

.link-secondary {
    color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-secondary:focus,
.link-secondary:hover {
    color: RGBA(73, 86, 97, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(73, 86, 97, var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(73, 86, 97, var(--bs-link-underline-opacity, 1)) !important
}

.link-success {
    color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-success:focus,
.link-success:hover {
    color: RGBA(35, 134, 102, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(35, 134, 102, var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(35, 134, 102, var(--bs-link-underline-opacity, 1)) !important
}

.link-info {
    color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-info:focus,
.link-info:hover {
    color: RGBA(50, 161, 171, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(50, 161, 171, var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(50, 161, 171, var(--bs-link-underline-opacity, 1)) !important
}

.link-warning {
    color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-warning:focus,
.link-warning:hover {
    color: RGBA(183, 110, 0, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(183, 110, 0, var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(183, 110, 0, var(--bs-link-underline-opacity, 1)) !important
}

.link-danger {
    color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-danger:focus,
.link-danger:hover {
    color: RGBA(176, 30, 30, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(176, 30, 30, var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(176, 30, 30, var(--bs-link-underline-opacity, 1)) !important
}

.link-light {
    color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-light:focus,
.link-light:hover {
    color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important
}

.link-dark {
    color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-dark:focus,
.link-dark:hover {
    color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important
}

.link-body-emphasis {
    color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-body-emphasis:focus,
.link-body-emphasis:hover {
    color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, .75)) !important;
    -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, .75)) !important;
    text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, .75)) !important
}

.focus-ring:focus {
    outline: 0;
    box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color)
}

.icon-link {
    display: inline-flex;
    gap: .375rem;
    align-items: center;
    -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, .5));
    text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, .5));
    text-underline-offset: .25em;
    backface-visibility: hidden
}

.icon-link>.bi {
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    fill: currentcolor;
    transition: .2s ease-in-out transform
}

@media (prefers-reduced-motion:reduce) {
    .icon-link>.bi {
        transition: none
    }
}

.icon-link-hover:focus-visible>.bi,
.icon-link-hover:hover>.bi {
    transform: var(--bs-icon-link-transform, translate3d(.25em, 0, 0))
}

.ratio {
    width: 100%
}

.ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""
}

.hstack,
.vstack {
    display: flex;
    align-self: stretch
}

.ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.ratio-1x1 {
    --bs-aspect-ratio: 100%
}

.ratio-4x3 {
    --bs-aspect-ratio: 75%
}

.ratio-16x9 {
    --bs-aspect-ratio: 56.25%
}

.ratio-21x9 {
    --bs-aspect-ratio: 42.8571428571%
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

.sticky-bottom,
.sticky-top {
    position: sticky;
    z-index: 1020
}

.sticky-top {
    top: 0
}

.sticky-bottom {
    bottom: 0
}

@media (min-width:576px) {
    .sticky-sm-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-sm-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width:768px) {
    .sticky-md-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-md-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width:992px) {
    .sticky-lg-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-lg-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width:1200px) {
    .sticky-xl-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-xl-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width:1400px) {
    .sticky-xxl-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-xxl-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

.hstack {
    flex-direction: row;
    align-items: center
}

.vstack {
    flex: 1 1 auto;
    flex-direction: column
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important
}

.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption),
.visually-hidden:not(caption) {
    position: absolute !important
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""
}

.position-static {
    position: static !important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.overflow-auto {
    overflow: auto !important
}

.text-wrap {
    white-space: normal !important
}

.vr {
    display: inline-block;
    align-self: stretch;
    width: var(--bs-border-width);
    opacity: .25
}

.min-vh-100 {
    min-height: 100vh !important
}

.bg-theme {
    background-color: rgba(var(--bs-theme-rgb), var(--bs-bg-opacity)) !important
}

.opacity-0 {
    opacity: 0 !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.float-start {
    float: left !important
}

.float-end {
    float: right !important
}

.float-none {
    float: none !important
}

.object-fit-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important
}

.object-fit-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important
}

.object-fit-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important
}

.object-fit-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important
}

.object-fit-none {
    -o-object-fit: none !important;
    object-fit: none !important
}

.opacity-25 {
    opacity: .25 !important
}

.opacity-50 {
    opacity: .5 !important
}

.opacity-75 {
    opacity: .75 !important
}

.opacity-100 {
    opacity: 1 !important
}

.overflow-hidden {
    overflow: hidden !important
}

.overflow-visible {
    overflow: visible !important
}

.overflow-scroll {
    overflow: scroll !important
}

.overflow-x-auto {
    overflow-x: auto !important
}

.overflow-x-hidden {
    overflow-x: hidden !important
}

.overflow-x-visible {
    overflow-x: visible !important
}

.overflow-x-scroll {
    overflow-x: scroll !important
}

.overflow-y-auto {
    overflow-y: auto !important
}

.overflow-y-hidden {
    overflow-y: hidden !important
}

.overflow-y-visible {
    overflow-y: visible !important
}

.overflow-y-scroll {
    overflow-y: scroll !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-grid {
    display: grid !important
}

.d-inline-grid {
    display: inline-grid !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

.d-none {
    display: none !important
}

.shadow {
    box-shadow: var(--bs-box-shadow) !important
}

.shadow-sm {
    box-shadow: var(--bs-box-shadow-sm) !important
}

.shadow-lg {
    box-shadow: var(--bs-box-shadow-lg) !important
}

.shadow-none {
    box-shadow: none !important
}

.focus-ring-theme {
    --bs-focus-ring-color: rgba(var(--bs-theme-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-primary {
    --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-secondary {
    --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-success {
    --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-info {
    --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-warning {
    --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-danger {
    --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-light {
    --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity))
}

.focus-ring-dark {
    --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity))
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: sticky !important
}

.top-0 {
    top: 0 !important
}

.top-50 {
    top: 50% !important
}

.top-100 {
    top: 100% !important
}

.bottom-0 {
    bottom: 0 !important
}

.bottom-50 {
    bottom: 50% !important
}

.bottom-100 {
    bottom: 100% !important
}

.start-0 {
    left: 0 !important
}

.start-50 {
    left: 50% !important
}

.start-100 {
    left: 100% !important
}

.end-0 {
    right: 0 !important
}

.end-50 {
    right: 50% !important
}

.end-100 {
    right: 100% !important
}

.translate-middle {
    transform: translate(-50%, -50%) !important
}

.translate-middle-x {
    transform: translateX(-50%) !important
}

.translate-middle-y {
    transform: translateY(-50%) !important
}

.border {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-0 {
    border: 0 !important
}

.border-top {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-end {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-end-0 {
    border-right: 0 !important
}

.border-bottom {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-start {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}

.border-start-0 {
    border-left: 0 !important
}

.border-theme {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-theme-rgb), var(--bs-border-opacity)) !important
}

.border-primary {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important
}

.border-secondary {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important
}

.border-success {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important
}

.border-info {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important
}

.border-warning {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important
}

.border-danger {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important
}

.border-light {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important
}

.border-dark {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important
}

.border-black {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important
}

.border-white {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important
}

.border-primary-subtle {
    border-color: var(--bs-primary-border-subtle) !important
}

.border-secondary-subtle {
    border-color: var(--bs-secondary-border-subtle) !important
}

.border-success-subtle {
    border-color: var(--bs-success-border-subtle) !important
}

.border-info-subtle {
    border-color: var(--bs-info-border-subtle) !important
}

.border-warning-subtle {
    border-color: var(--bs-warning-border-subtle) !important
}

.border-danger-subtle {
    border-color: var(--bs-danger-border-subtle) !important
}

.border-light-subtle {
    border-color: var(--bs-light-border-subtle) !important
}

.border-dark-subtle {
    border-color: var(--bs-dark-border-subtle) !important
}

.border-0 {
    border-width: 0 !important
}

.border-1 {
    border-width: 1px !important
}

.border-2 {
    border-width: 2px !important
}

.border-3 {
    border-width: 3px !important
}

.border-4 {
    border-width: 4px !important
}

.border-5 {
    border-width: 5px !important
}

.border-opacity-10 {
    --bs-border-opacity: 0.1
}

.border-opacity-25 {
    --bs-border-opacity: 0.25
}

.border-opacity-50 {
    --bs-border-opacity: 0.5
}

.border-opacity-75 {
    --bs-border-opacity: 0.75
}

.border-opacity-100 {
    --bs-border-opacity: 1
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.mw-100 {
    max-width: 100% !important
}

.vw-100 {
    width: 100vw !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.mh-100 {
    max-height: 100% !important
}

.vh-100 {
    height: 100vh !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.justify-content-evenly {
    justify-content: space-evenly !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

.order-first {
    order: -1 !important
}

.order-0 {
    order: 0 !important
}

.order-1 {
    order: 1 !important
}

.order-2 {
    order: 2 !important
}

.order-3 {
    order: 3 !important
}

.order-4 {
    order: 4 !important
}

.order-5 {
    order: 5 !important
}

.order-last {
    order: 6 !important
}

.m-0 {
    margin: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.m-5 {
    margin: 3rem !important
}

.m-auto {
    margin: auto !important
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
}

.mx-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
}

.mx-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
}

.my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
}

.mt-0 {
    margin-top: 0 !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mt-3 {
    margin-top: 1rem !important
}

.mt-4 {
    margin-top: 1.5rem !important
}

.mt-5 {
    margin-top: 3rem !important
}

.mt-auto {
    margin-top: auto !important
}

.me-0 {
    margin-right: 0 !important
}

.me-1 {
    margin-right: .25rem !important
}

.me-2 {
    margin-right: .5rem !important
}

.me-3 {
    margin-right: 1rem !important
}

.me-4 {
    margin-right: 1.5rem !important
}

.me-5 {
    margin-right: 3rem !important
}

.me-auto {
    margin-right: auto !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.mb-3 {
    margin-bottom: 1rem !important
}

.mb-4 {
    margin-bottom: 1.5rem !important
}

.mb-5 {
    margin-bottom: 3rem !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ms-0 {
    margin-left: 0 !important
}

.ms-1 {
    margin-left: .25rem !important
}

.ms-2 {
    margin-left: .5rem !important
}

.ms-3 {
    margin-left: 1rem !important
}

.ms-4 {
    margin-left: 1.5rem !important
}

.ms-5 {
    margin-left: 3rem !important
}

.ms-auto {
    margin-left: auto !important
}

.m-n1 {
    margin: -.25rem !important
}

.m-n2 {
    margin: -.5rem !important
}

.m-n3 {
    margin: -1rem !important
}

.m-n4 {
    margin: -1.5rem !important
}

.m-n5 {
    margin: -3rem !important
}

.mx-n1 {
    margin-right: -.25rem !important;
    margin-left: -.25rem !important
}

.mx-n2 {
    margin-right: -.5rem !important;
    margin-left: -.5rem !important
}

.mx-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important
}

.mx-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important
}

.mx-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important
}

.my-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important
}

.my-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important
}

.my-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important
}

.my-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important
}

.my-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important
}

.mt-n1 {
    margin-top: -.25rem !important
}

.mt-n2 {
    margin-top: -.5rem !important
}

.mt-n3 {
    margin-top: -1rem !important
}

.mt-n4 {
    margin-top: -1.5rem !important
}

.mt-n5 {
    margin-top: -3rem !important
}

.me-n1 {
    margin-right: -.25rem !important
}

.me-n2 {
    margin-right: -.5rem !important
}

.me-n3 {
    margin-right: -1rem !important
}

.me-n4 {
    margin-right: -1.5rem !important
}

.me-n5 {
    margin-right: -3rem !important
}

.mb-n1 {
    margin-bottom: -.25rem !important
}

.mb-n2 {
    margin-bottom: -.5rem !important
}

.mb-n3 {
    margin-bottom: -1rem !important
}

.mb-n4 {
    margin-bottom: -1.5rem !important
}

.mb-n5 {
    margin-bottom: -3rem !important
}

.ms-n1 {
    margin-left: -.25rem !important
}

.ms-n2 {
    margin-left: -.5rem !important
}

.ms-n3 {
    margin-left: -1rem !important
}

.ms-n4 {
    margin-left: -1.5rem !important
}

.ms-n5 {
    margin-left: -3rem !important
}

.p-0 {
    padding: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.p-5 {
    padding: 3rem !important
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
}

.px-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
}

.px-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
}

.pt-0 {
    padding-top: 0 !important
}

.pt-1 {
    padding-top: .25rem !important
}

.pt-2 {
    padding-top: .5rem !important
}

.pt-3 {
    padding-top: 1rem !important
}

.pt-4 {
    padding-top: 1.5rem !important
}

.pt-5 {
    padding-top: 3rem !important
}

.pe-0 {
    padding-right: 0 !important
}

.pe-1 {
    padding-right: .25rem !important
}

.pe-2 {
    padding-right: .5rem !important
}

.pe-3 {
    padding-right: 1rem !important
}

.pe-4 {
    padding-right: 1.5rem !important
}

.pe-5 {
    padding-right: 3rem !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pb-1 {
    padding-bottom: .25rem !important
}

.pb-2 {
    padding-bottom: .5rem !important
}

.pb-3 {
    padding-bottom: 1rem !important
}

.pb-4 {
    padding-bottom: 1.5rem !important
}

.pb-5 {
    padding-bottom: 3rem !important
}

.ps-0 {
    padding-left: 0 !important
}

.ps-1 {
    padding-left: .25rem !important
}

.ps-2 {
    padding-left: .5rem !important
}

.ps-3 {
    padding-left: 1rem !important
}

.ps-4 {
    padding-left: 1.5rem !important
}

.ps-5 {
    padding-left: 3rem !important
}

.gap-0 {
    gap: 0 !important
}

.gap-1 {
    gap: .25rem !important
}

.gap-2 {
    gap: .5rem !important
}

.gap-3 {
    gap: 1rem !important
}

.gap-4 {
    gap: 1.5rem !important
}

.gap-5 {
    gap: 3rem !important
}

.row-gap-0 {
    row-gap: 0 !important
}

.row-gap-1 {
    row-gap: .25rem !important
}

.row-gap-2 {
    row-gap: .5rem !important
}

.row-gap-3 {
    row-gap: 1rem !important
}

.row-gap-4 {
    row-gap: 1.5rem !important
}

.row-gap-5 {
    row-gap: 3rem !important
}

.column-gap-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important
}

.column-gap-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important
}

.column-gap-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important
}

.column-gap-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important
}

.column-gap-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important
}

.column-gap-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important
}

.fs-2 {
    font-size: calc(1.3125rem + .75vw) !important
}

.fs-3 {
    font-size: calc(1.275rem + .3vw) !important
}

.fs-4 {
    font-size: 1.25rem !important
}

.fs-5 {
    font-size: 1rem !important
}

.fs-6 {
    font-size: .875rem !important
}

.fs-8 {
    font-size: .75rem !important
}

.fs-9 {
    font-size: 12px !important
}

.fs-10 {
    font-size: .625rem !important
}

.fst-italic {
    font-style: italic !important
}

.fst-normal {
    font-style: normal !important
}

.fw-lighter {
    font-weight: lighter !important
}

.fw-light,
[data-pc-direction=rtl] .fw-light {
    font-weight: 300 !important
}

.fw-normal {
    font-weight: 400 !important
}

.fw-medium {
    font-weight: 500 !important
}

.fw-semibold {
    font-weight: 600 !important
}

.fw-bold {
    font-weight: 700 !important
}

.fw-bolder {
    font-weight: bolder !important
}

.lh-sm {
    line-height: 1.25 !important
}

.lh-base {
    line-height: 1.5 !important
}

.lh-lg {
    line-height: 2 !important
}

.text-start {
    text-align: left !important
}

.text-end,
[data-pc-direction=rtl] .text-start {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

.text-decoration-underline {
    text-decoration: underline !important
}

.text-decoration-line-through {
    text-decoration: line-through !important
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important
}

.text-theme {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-theme-rgb), var(--bs-text-opacity)) !important
}

.text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important
}

.text-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important
}

.text-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important
}

.text-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important
}

.text-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important
}

.text-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important
}

.text-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important
}

.text-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important
}

.text-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important
}

.text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important
}

.text-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important
}

.text-muted {
    --bs-text-opacity: 1;
    color: var(--bs-secondary-color) !important
}

.text-black-50 {
    --bs-text-opacity: 1;
    color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, .5) !important
}

.text-body-secondary {
    --bs-text-opacity: 1;
    color: var(--bs-secondary-color) !important
}

.text-body-tertiary {
    --bs-text-opacity: 1;
    color: var(--bs-tertiary-color) !important
}

.text-body-emphasis {
    --bs-text-opacity: 1;
    color: var(--bs-emphasis-color) !important
}

.text-reset {
    --bs-text-opacity: 1;
    color: inherit !important
}

.text-opacity-25 {
    --bs-text-opacity: 0.25
}

.text-opacity-50 {
    --bs-text-opacity: 0.5
}

.text-opacity-75 {
    --bs-text-opacity: 0.75
}

.text-opacity-100 {
    --bs-text-opacity: 1
}

.text-primary-emphasis {
    color: var(--bs-primary-text-emphasis) !important
}

.text-secondary-emphasis {
    color: var(--bs-secondary-text-emphasis) !important
}

.text-success-emphasis {
    color: var(--bs-success-text-emphasis) !important
}

.text-info-emphasis {
    color: var(--bs-info-text-emphasis) !important
}

.text-warning-emphasis {
    color: var(--bs-warning-text-emphasis) !important
}

.text-danger-emphasis {
    color: var(--bs-danger-text-emphasis) !important
}

.text-light-emphasis {
    color: var(--bs-light-text-emphasis) !important
}

.text-dark-emphasis {
    color: var(--bs-dark-text-emphasis) !important
}

.link-opacity-10,
.link-opacity-10-hover:hover {
    --bs-link-opacity: 0.1
}

.link-opacity-25,
.link-opacity-25-hover:hover {
    --bs-link-opacity: 0.25
}

.link-opacity-50,
.link-opacity-50-hover:hover {
    --bs-link-opacity: 0.5
}

.link-opacity-75,
.link-opacity-75-hover:hover {
    --bs-link-opacity: 0.75
}

.link-opacity-100,
.link-opacity-100-hover:hover {
    --bs-link-opacity: 1
}

.link-offset-1,
.link-offset-1-hover:hover {
    text-underline-offset: .125em !important
}

.link-offset-2,
.link-offset-2-hover:hover {
    text-underline-offset: .25em !important
}

.link-offset-3,
.link-offset-3-hover:hover {
    text-underline-offset: .375em !important
}

.link-underline-theme {
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-theme-rgb), var(--bs-link-underline-opacity)) !important;
    text-decoration-color: rgba(var(--bs-theme-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-primary {
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
    text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-secondary {
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
    text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-success {
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
    text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-info {
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
    text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-warning {
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
    text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-danger {
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
    text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-light {
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
    text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline-dark {
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
    text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important
}

.link-underline {
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
    text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important
}

.link-underline-opacity-0,
.link-underline-opacity-0-hover:hover {
    --bs-link-underline-opacity: 0
}

.link-underline-opacity-10,
.link-underline-opacity-10-hover:hover {
    --bs-link-underline-opacity: 0.1
}

.link-underline-opacity-25,
.link-underline-opacity-25-hover:hover {
    --bs-link-underline-opacity: 0.25
}

.link-underline-opacity-50,
.link-underline-opacity-50-hover:hover {
    --bs-link-underline-opacity: 0.5
}

.link-underline-opacity-75,
.link-underline-opacity-75-hover:hover {
    --bs-link-underline-opacity: 0.75
}

.link-underline-opacity-100,
.link-underline-opacity-100-hover:hover {
    --bs-link-underline-opacity: 1
}

.bg-theme {
    --bs-bg-opacity: 1
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important
}

.bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important
}

.bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important
}

.bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important
}

.bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important
}

.bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important
}

.bg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important
}

.bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important
}

.bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important
}

.bg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important
}

.bg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important
}

.bg-body-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important
}

.bg-body-tertiary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important
}

.bg-opacity-10 {
    --bs-bg-opacity: 0.1
}

.bg-opacity-25 {
    --bs-bg-opacity: 0.25
}

.bg-opacity-50 {
    --bs-bg-opacity: 0.5
}

.bg-opacity-75 {
    --bs-bg-opacity: 0.75
}

.bg-opacity-100 {
    --bs-bg-opacity: 1
}

.bg-primary-subtle {
    background-color: var(--bs-primary-bg-subtle) !important
}

.bg-secondary-subtle {
    background-color: var(--bs-secondary-bg-subtle) !important
}

.bg-success-subtle {
    background-color: var(--bs-success-bg-subtle) !important
}

.bg-info-subtle {
    background-color: var(--bs-info-bg-subtle) !important
}

.bg-warning-subtle {
    background-color: var(--bs-warning-bg-subtle) !important
}

.bg-danger-subtle {
    background-color: var(--bs-danger-bg-subtle) !important
}

.bg-light-subtle {
    background-color: var(--bs-light-bg-subtle) !important
}

.bg-dark-subtle {
    background-color: var(--bs-dark-bg-subtle) !important
}

.bg-gradient {
    background-image: var(--bs-gradient) !important
}

.user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    user-select: all !important
}

.user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    user-select: auto !important
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important
}

.pe-none {
    pointer-events: none !important
}

.pe-auto {
    pointer-events: auto !important
}

.rounded {
    border-radius: var(--bs-border-radius) !important
}

.rounded-0 {
    border-radius: 0 !important
}

.rounded-1 {
    border-radius: var(--bs-border-radius-sm) !important
}

.rounded-2 {
    border-radius: var(--bs-border-radius) !important
}

.rounded-3 {
    border-radius: var(--bs-border-radius-lg) !important
}

.rounded-4 {
    border-radius: var(--bs-border-radius-xl) !important
}

.rounded-5 {
    border-radius: var(--bs-border-radius-xxl) !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-pill {
    border-radius: var(--bs-border-radius-pill) !important
}

.rounded-top {
    border-top-left-radius: var(--bs-border-radius) !important;
    border-top-right-radius: var(--bs-border-radius) !important
}

.rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important
}

.rounded-top-1 {
    border-top-left-radius: var(--bs-border-radius-sm) !important;
    border-top-right-radius: var(--bs-border-radius-sm) !important
}

.rounded-top-2 {
    border-top-left-radius: var(--bs-border-radius) !important;
    border-top-right-radius: var(--bs-border-radius) !important
}

.rounded-top-3 {
    border-top-left-radius: var(--bs-border-radius-lg) !important;
    border-top-right-radius: var(--bs-border-radius-lg) !important
}

.rounded-top-4 {
    border-top-left-radius: var(--bs-border-radius-xl) !important;
    border-top-right-radius: var(--bs-border-radius-xl) !important
}

.rounded-top-5 {
    border-top-left-radius: var(--bs-border-radius-xxl) !important;
    border-top-right-radius: var(--bs-border-radius-xxl) !important
}

.rounded-top-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important
}

.rounded-top-pill {
    border-top-left-radius: var(--bs-border-radius-pill) !important;
    border-top-right-radius: var(--bs-border-radius-pill) !important
}

.rounded-end {
    border-top-right-radius: var(--bs-border-radius) !important;
    border-bottom-right-radius: var(--bs-border-radius) !important
}

.rounded-end-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important
}

.rounded-end-1 {
    border-top-right-radius: var(--bs-border-radius-sm) !important;
    border-bottom-right-radius: var(--bs-border-radius-sm) !important
}

.rounded-end-2 {
    border-top-right-radius: var(--bs-border-radius) !important;
    border-bottom-right-radius: var(--bs-border-radius) !important
}

.rounded-end-3 {
    border-top-right-radius: var(--bs-border-radius-lg) !important;
    border-bottom-right-radius: var(--bs-border-radius-lg) !important
}

.rounded-end-4 {
    border-top-right-radius: var(--bs-border-radius-xl) !important;
    border-bottom-right-radius: var(--bs-border-radius-xl) !important
}

.rounded-end-5 {
    border-top-right-radius: var(--bs-border-radius-xxl) !important;
    border-bottom-right-radius: var(--bs-border-radius-xxl) !important
}

.rounded-end-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important
}

.rounded-end-pill {
    border-top-right-radius: var(--bs-border-radius-pill) !important;
    border-bottom-right-radius: var(--bs-border-radius-pill) !important
}

.rounded-bottom {
    border-bottom-right-radius: var(--bs-border-radius) !important;
    border-bottom-left-radius: var(--bs-border-radius) !important
}

.rounded-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important
}

.rounded-bottom-1 {
    border-bottom-right-radius: var(--bs-border-radius-sm) !important;
    border-bottom-left-radius: var(--bs-border-radius-sm) !important
}

.rounded-bottom-2 {
    border-bottom-right-radius: var(--bs-border-radius) !important;
    border-bottom-left-radius: var(--bs-border-radius) !important
}

.rounded-bottom-3 {
    border-bottom-right-radius: var(--bs-border-radius-lg) !important;
    border-bottom-left-radius: var(--bs-border-radius-lg) !important
}

.rounded-bottom-4 {
    border-bottom-right-radius: var(--bs-border-radius-xl) !important;
    border-bottom-left-radius: var(--bs-border-radius-xl) !important
}

.rounded-bottom-5 {
    border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
    border-bottom-left-radius: var(--bs-border-radius-xxl) !important
}

.rounded-bottom-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important
}

.rounded-bottom-pill {
    border-bottom-right-radius: var(--bs-border-radius-pill) !important;
    border-bottom-left-radius: var(--bs-border-radius-pill) !important
}

.rounded-start {
    border-bottom-left-radius: var(--bs-border-radius) !important;
    border-top-left-radius: var(--bs-border-radius) !important
}

.rounded-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important
}

.rounded-start-1 {
    border-bottom-left-radius: var(--bs-border-radius-sm) !important;
    border-top-left-radius: var(--bs-border-radius-sm) !important
}

.rounded-start-2 {
    border-bottom-left-radius: var(--bs-border-radius) !important;
    border-top-left-radius: var(--bs-border-radius) !important
}

.rounded-start-3 {
    border-bottom-left-radius: var(--bs-border-radius-lg) !important;
    border-top-left-radius: var(--bs-border-radius-lg) !important
}

.rounded-start-4 {
    border-bottom-left-radius: var(--bs-border-radius-xl) !important;
    border-top-left-radius: var(--bs-border-radius-xl) !important
}

.rounded-start-5 {
    border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
    border-top-left-radius: var(--bs-border-radius-xxl) !important
}

.rounded-start-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important
}

.rounded-start-pill {
    border-bottom-left-radius: var(--bs-border-radius-pill) !important;
    border-top-left-radius: var(--bs-border-radius-pill) !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

.z-n1 {
    z-index: -1 !important
}

.z-0 {
    z-index: 0 !important
}

.z-1 {
    z-index: 1 !important
}

.z-2 {
    z-index: 2 !important
}

.z-3 {
    z-index: 3 !important
}

@media (min-width:576px) {
    .float-sm-start {
        float: left !important
    }

    .float-sm-end {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }

    .object-fit-sm-contain {
        -o-object-fit: contain !important;
        object-fit: contain !important
    }

    .object-fit-sm-cover {
        -o-object-fit: cover !important;
        object-fit: cover !important
    }

    .object-fit-sm-fill {
        -o-object-fit: fill !important;
        object-fit: fill !important
    }

    .object-fit-sm-scale {
        -o-object-fit: scale-down !important;
        object-fit: scale-down !important
    }

    .object-fit-sm-none {
        -o-object-fit: none !important;
        object-fit: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-grid {
        display: grid !important
    }

    .d-sm-inline-grid {
        display: inline-grid !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }

    .d-sm-none {
        display: none !important
    }

    .flex-sm-fill {
        flex: 1 1 auto !important
    }

    .flex-sm-row {
        flex-direction: row !important
    }

    .flex-sm-column {
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-sm-start {
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        justify-content: center !important
    }

    .justify-content-sm-between {
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        justify-content: space-around !important
    }

    .justify-content-sm-evenly {
        justify-content: space-evenly !important
    }

    .align-items-sm-start {
        align-items: flex-start !important
    }

    .align-items-sm-end {
        align-items: flex-end !important
    }

    .align-items-sm-center {
        align-items: center !important
    }

    .align-items-sm-baseline {
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        align-items: stretch !important
    }

    .align-content-sm-start {
        align-content: flex-start !important
    }

    .align-content-sm-end {
        align-content: flex-end !important
    }

    .align-content-sm-center {
        align-content: center !important
    }

    .align-content-sm-between {
        align-content: space-between !important
    }

    .align-content-sm-around {
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        align-content: stretch !important
    }

    .align-self-sm-auto {
        align-self: auto !important
    }

    .align-self-sm-start {
        align-self: flex-start !important
    }

    .align-self-sm-end {
        align-self: flex-end !important
    }

    .align-self-sm-center {
        align-self: center !important
    }

    .align-self-sm-baseline {
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        align-self: stretch !important
    }

    .order-sm-first {
        order: -1 !important
    }

    .order-sm-0 {
        order: 0 !important
    }

    .order-sm-1 {
        order: 1 !important
    }

    .order-sm-2 {
        order: 2 !important
    }

    .order-sm-3 {
        order: 3 !important
    }

    .order-sm-4 {
        order: 4 !important
    }

    .order-sm-5 {
        order: 5 !important
    }

    .order-sm-last {
        order: 6 !important
    }

    .m-sm-0 {
        margin: 0 !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .m-sm-3 {
        margin: 1rem !important
    }

    .m-sm-4 {
        margin: 1.5rem !important
    }

    .m-sm-5 {
        margin: 3rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-sm-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-sm-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-sm-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-sm-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-sm-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-sm-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-sm-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-sm-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-sm-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-sm-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-sm-0 {
        margin-top: 0 !important
    }

    .mt-sm-1 {
        margin-top: .25rem !important
    }

    .mt-sm-2 {
        margin-top: .5rem !important
    }

    .mt-sm-3 {
        margin-top: 1rem !important
    }

    .mt-sm-4 {
        margin-top: 1.5rem !important
    }

    .mt-sm-5 {
        margin-top: 3rem !important
    }

    .mt-sm-auto {
        margin-top: auto !important
    }

    .me-sm-0 {
        margin-right: 0 !important
    }

    .me-sm-1 {
        margin-right: .25rem !important
    }

    .me-sm-2 {
        margin-right: .5rem !important
    }

    .me-sm-3 {
        margin-right: 1rem !important
    }

    .me-sm-4 {
        margin-right: 1.5rem !important
    }

    .me-sm-5 {
        margin-right: 3rem !important
    }

    .me-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-0 {
        margin-bottom: 0 !important
    }

    .mb-sm-1 {
        margin-bottom: .25rem !important
    }

    .mb-sm-2 {
        margin-bottom: .5rem !important
    }

    .mb-sm-3 {
        margin-bottom: 1rem !important
    }

    .mb-sm-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-sm-5 {
        margin-bottom: 3rem !important
    }

    .mb-sm-auto {
        margin-bottom: auto !important
    }

    .ms-sm-0 {
        margin-left: 0 !important
    }

    .ms-sm-1 {
        margin-left: .25rem !important
    }

    .ms-sm-2 {
        margin-left: .5rem !important
    }

    .ms-sm-3 {
        margin-left: 1rem !important
    }

    .ms-sm-4 {
        margin-left: 1.5rem !important
    }

    .ms-sm-5 {
        margin-left: 3rem !important
    }

    .ms-sm-auto {
        margin-left: auto !important
    }

    .m-sm-n1 {
        margin: -.25rem !important
    }

    .m-sm-n2 {
        margin: -.5rem !important
    }

    .m-sm-n3 {
        margin: -1rem !important
    }

    .m-sm-n4 {
        margin: -1.5rem !important
    }

    .m-sm-n5 {
        margin: -3rem !important
    }

    .mx-sm-n1 {
        margin-right: -.25rem !important;
        margin-left: -.25rem !important
    }

    .mx-sm-n2 {
        margin-right: -.5rem !important;
        margin-left: -.5rem !important
    }

    .mx-sm-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-sm-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-sm-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .my-sm-n1 {
        margin-top: -.25rem !important;
        margin-bottom: -.25rem !important
    }

    .my-sm-n2 {
        margin-top: -.5rem !important;
        margin-bottom: -.5rem !important
    }

    .my-sm-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-sm-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-sm-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .mt-sm-n1 {
        margin-top: -.25rem !important
    }

    .mt-sm-n2 {
        margin-top: -.5rem !important
    }

    .mt-sm-n3 {
        margin-top: -1rem !important
    }

    .mt-sm-n4 {
        margin-top: -1.5rem !important
    }

    .mt-sm-n5 {
        margin-top: -3rem !important
    }

    .me-sm-n1 {
        margin-right: -.25rem !important
    }

    .me-sm-n2 {
        margin-right: -.5rem !important
    }

    .me-sm-n3 {
        margin-right: -1rem !important
    }

    .me-sm-n4 {
        margin-right: -1.5rem !important
    }

    .me-sm-n5 {
        margin-right: -3rem !important
    }

    .mb-sm-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-sm-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-sm-n3 {
        margin-bottom: -1rem !important
    }

    .mb-sm-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-sm-n5 {
        margin-bottom: -3rem !important
    }

    .ms-sm-n1 {
        margin-left: -.25rem !important
    }

    .ms-sm-n2 {
        margin-left: -.5rem !important
    }

    .ms-sm-n3 {
        margin-left: -1rem !important
    }

    .ms-sm-n4 {
        margin-left: -1.5rem !important
    }

    .ms-sm-n5 {
        margin-left: -3rem !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .p-sm-3 {
        padding: 1rem !important
    }

    .p-sm-4 {
        padding: 1.5rem !important
    }

    .p-sm-5 {
        padding: 3rem !important
    }

    .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-sm-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-sm-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-sm-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-sm-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-sm-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-sm-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-sm-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-sm-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-sm-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-sm-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-sm-0 {
        padding-top: 0 !important
    }

    .pt-sm-1 {
        padding-top: .25rem !important
    }

    .pt-sm-2 {
        padding-top: .5rem !important
    }

    .pt-sm-3 {
        padding-top: 1rem !important
    }

    .pt-sm-4 {
        padding-top: 1.5rem !important
    }

    .pt-sm-5 {
        padding-top: 3rem !important
    }

    .pe-sm-0 {
        padding-right: 0 !important
    }

    .pe-sm-1 {
        padding-right: .25rem !important
    }

    .pe-sm-2 {
        padding-right: .5rem !important
    }

    .pe-sm-3 {
        padding-right: 1rem !important
    }

    .pe-sm-4 {
        padding-right: 1.5rem !important
    }

    .pe-sm-5 {
        padding-right: 3rem !important
    }

    .pb-sm-0 {
        padding-bottom: 0 !important
    }

    .pb-sm-1 {
        padding-bottom: .25rem !important
    }

    .pb-sm-2 {
        padding-bottom: .5rem !important
    }

    .pb-sm-3 {
        padding-bottom: 1rem !important
    }

    .pb-sm-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-sm-5 {
        padding-bottom: 3rem !important
    }

    .ps-sm-0 {
        padding-left: 0 !important
    }

    .ps-sm-1 {
        padding-left: .25rem !important
    }

    .ps-sm-2 {
        padding-left: .5rem !important
    }

    .ps-sm-3 {
        padding-left: 1rem !important
    }

    .ps-sm-4 {
        padding-left: 1.5rem !important
    }

    .ps-sm-5 {
        padding-left: 3rem !important
    }

    .gap-sm-0 {
        gap: 0 !important
    }

    .gap-sm-1 {
        gap: .25rem !important
    }

    .gap-sm-2 {
        gap: .5rem !important
    }

    .gap-sm-3 {
        gap: 1rem !important
    }

    .gap-sm-4 {
        gap: 1.5rem !important
    }

    .gap-sm-5 {
        gap: 3rem !important
    }

    .row-gap-sm-0 {
        row-gap: 0 !important
    }

    .row-gap-sm-1 {
        row-gap: .25rem !important
    }

    .row-gap-sm-2 {
        row-gap: .5rem !important
    }

    .row-gap-sm-3 {
        row-gap: 1rem !important
    }

    .row-gap-sm-4 {
        row-gap: 1.5rem !important
    }

    .row-gap-sm-5 {
        row-gap: 3rem !important
    }

    .column-gap-sm-0 {
        -moz-column-gap: 0 !important;
        column-gap: 0 !important
    }

    .column-gap-sm-1 {
        -moz-column-gap: .25rem !important;
        column-gap: .25rem !important
    }

    .column-gap-sm-2 {
        -moz-column-gap: .5rem !important;
        column-gap: .5rem !important
    }

    .column-gap-sm-3 {
        -moz-column-gap: 1rem !important;
        column-gap: 1rem !important
    }

    .column-gap-sm-4 {
        -moz-column-gap: 1.5rem !important;
        column-gap: 1.5rem !important
    }

    .column-gap-sm-5 {
        -moz-column-gap: 3rem !important;
        column-gap: 3rem !important
    }

    .text-sm-start {
        text-align: left !important
    }

    .text-sm-end {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media (min-width:768px) {
    .float-md-start {
        float: left !important
    }

    .float-md-end {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }

    .object-fit-md-contain {
        -o-object-fit: contain !important;
        object-fit: contain !important
    }

    .object-fit-md-cover {
        -o-object-fit: cover !important;
        object-fit: cover !important
    }

    .object-fit-md-fill {
        -o-object-fit: fill !important;
        object-fit: fill !important
    }

    .object-fit-md-scale {
        -o-object-fit: scale-down !important;
        object-fit: scale-down !important
    }

    .object-fit-md-none {
        -o-object-fit: none !important;
        object-fit: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-grid {
        display: grid !important
    }

    .d-md-inline-grid {
        display: inline-grid !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }

    .d-md-none {
        display: none !important
    }

    .flex-md-fill {
        flex: 1 1 auto !important
    }

    .flex-md-row {
        flex-direction: row !important
    }

    .flex-md-column {
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-md-wrap {
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-md-start {
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        justify-content: center !important
    }

    .justify-content-md-between {
        justify-content: space-between !important
    }

    .justify-content-md-around {
        justify-content: space-around !important
    }

    .justify-content-md-evenly {
        justify-content: space-evenly !important
    }

    .align-items-md-start {
        align-items: flex-start !important
    }

    .align-items-md-end {
        align-items: flex-end !important
    }

    .align-items-md-center {
        align-items: center !important
    }

    .align-items-md-baseline {
        align-items: baseline !important
    }

    .align-items-md-stretch {
        align-items: stretch !important
    }

    .align-content-md-start {
        align-content: flex-start !important
    }

    .align-content-md-end {
        align-content: flex-end !important
    }

    .align-content-md-center {
        align-content: center !important
    }

    .align-content-md-between {
        align-content: space-between !important
    }

    .align-content-md-around {
        align-content: space-around !important
    }

    .align-content-md-stretch {
        align-content: stretch !important
    }

    .align-self-md-auto {
        align-self: auto !important
    }

    .align-self-md-start {
        align-self: flex-start !important
    }

    .align-self-md-end {
        align-self: flex-end !important
    }

    .align-self-md-center {
        align-self: center !important
    }

    .align-self-md-baseline {
        align-self: baseline !important
    }

    .align-self-md-stretch {
        align-self: stretch !important
    }

    .order-md-first {
        order: -1 !important
    }

    .order-md-0 {
        order: 0 !important
    }

    .order-md-1 {
        order: 1 !important
    }

    .order-md-2 {
        order: 2 !important
    }

    .order-md-3 {
        order: 3 !important
    }

    .order-md-4 {
        order: 4 !important
    }

    .order-md-5 {
        order: 5 !important
    }

    .order-md-last {
        order: 6 !important
    }

    .m-md-0 {
        margin: 0 !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .m-md-3 {
        margin: 1rem !important
    }

    .m-md-4 {
        margin: 1.5rem !important
    }

    .m-md-5 {
        margin: 3rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mx-md-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-md-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-md-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-md-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-md-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-md-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-md-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-md-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-md-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-md-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-md-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-md-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-md-0 {
        margin-top: 0 !important
    }

    .mt-md-1 {
        margin-top: .25rem !important
    }

    .mt-md-2 {
        margin-top: .5rem !important
    }

    .mt-md-3 {
        margin-top: 1rem !important
    }

    .mt-md-4 {
        margin-top: 1.5rem !important
    }

    .mt-md-5 {
        margin-top: 3rem !important
    }

    .mt-md-auto {
        margin-top: auto !important
    }

    .me-md-0 {
        margin-right: 0 !important
    }

    .me-md-1 {
        margin-right: .25rem !important
    }

    .me-md-2 {
        margin-right: .5rem !important
    }

    .me-md-3 {
        margin-right: 1rem !important
    }

    .me-md-4 {
        margin-right: 1.5rem !important
    }

    .me-md-5 {
        margin-right: 3rem !important
    }

    .me-md-auto {
        margin-right: auto !important
    }

    .mb-md-0 {
        margin-bottom: 0 !important
    }

    .mb-md-1 {
        margin-bottom: .25rem !important
    }

    .mb-md-2 {
        margin-bottom: .5rem !important
    }

    .mb-md-3 {
        margin-bottom: 1rem !important
    }

    .mb-md-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-md-5 {
        margin-bottom: 3rem !important
    }

    .mb-md-auto {
        margin-bottom: auto !important
    }

    .ms-md-0 {
        margin-left: 0 !important
    }

    .ms-md-1 {
        margin-left: .25rem !important
    }

    .ms-md-2 {
        margin-left: .5rem !important
    }

    .ms-md-3 {
        margin-left: 1rem !important
    }

    .ms-md-4 {
        margin-left: 1.5rem !important
    }

    .ms-md-5 {
        margin-left: 3rem !important
    }

    .ms-md-auto {
        margin-left: auto !important
    }

    .m-md-n1 {
        margin: -.25rem !important
    }

    .m-md-n2 {
        margin: -.5rem !important
    }

    .m-md-n3 {
        margin: -1rem !important
    }

    .m-md-n4 {
        margin: -1.5rem !important
    }

    .m-md-n5 {
        margin: -3rem !important
    }

    .mx-md-n1 {
        margin-right: -.25rem !important;
        margin-left: -.25rem !important
    }

    .mx-md-n2 {
        margin-right: -.5rem !important;
        margin-left: -.5rem !important
    }

    .mx-md-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-md-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-md-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .my-md-n1 {
        margin-top: -.25rem !important;
        margin-bottom: -.25rem !important
    }

    .my-md-n2 {
        margin-top: -.5rem !important;
        margin-bottom: -.5rem !important
    }

    .my-md-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-md-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-md-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .mt-md-n1 {
        margin-top: -.25rem !important
    }

    .mt-md-n2 {
        margin-top: -.5rem !important
    }

    .mt-md-n3 {
        margin-top: -1rem !important
    }

    .mt-md-n4 {
        margin-top: -1.5rem !important
    }

    .mt-md-n5 {
        margin-top: -3rem !important
    }

    .me-md-n1 {
        margin-right: -.25rem !important
    }

    .me-md-n2 {
        margin-right: -.5rem !important
    }

    .me-md-n3 {
        margin-right: -1rem !important
    }

    .me-md-n4 {
        margin-right: -1.5rem !important
    }

    .me-md-n5 {
        margin-right: -3rem !important
    }

    .mb-md-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-md-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-md-n3 {
        margin-bottom: -1rem !important
    }

    .mb-md-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-md-n5 {
        margin-bottom: -3rem !important
    }

    .ms-md-n1 {
        margin-left: -.25rem !important
    }

    .ms-md-n2 {
        margin-left: -.5rem !important
    }

    .ms-md-n3 {
        margin-left: -1rem !important
    }

    .ms-md-n4 {
        margin-left: -1.5rem !important
    }

    .ms-md-n5 {
        margin-left: -3rem !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .p-md-3 {
        padding: 1rem !important
    }

    .p-md-4 {
        padding: 1.5rem !important
    }

    .p-md-5 {
        padding: 3rem !important
    }

    .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-md-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-md-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-md-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-md-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-md-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-md-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-md-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-md-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-md-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-md-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-md-0 {
        padding-top: 0 !important
    }

    .pt-md-1 {
        padding-top: .25rem !important
    }

    .pt-md-2 {
        padding-top: .5rem !important
    }

    .pt-md-3 {
        padding-top: 1rem !important
    }

    .pt-md-4 {
        padding-top: 1.5rem !important
    }

    .pt-md-5 {
        padding-top: 3rem !important
    }

    .pe-md-0 {
        padding-right: 0 !important
    }

    .pe-md-1 {
        padding-right: .25rem !important
    }

    .pe-md-2 {
        padding-right: .5rem !important
    }

    .pe-md-3 {
        padding-right: 1rem !important
    }

    .pe-md-4 {
        padding-right: 1.5rem !important
    }

    .pe-md-5 {
        padding-right: 3rem !important
    }

    .pb-md-0 {
        padding-bottom: 0 !important
    }

    .pb-md-1 {
        padding-bottom: .25rem !important
    }

    .pb-md-2 {
        padding-bottom: .5rem !important
    }

    .pb-md-3 {
        padding-bottom: 1rem !important
    }

    .pb-md-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-md-5 {
        padding-bottom: 3rem !important
    }

    .ps-md-0 {
        padding-left: 0 !important
    }

    .ps-md-1 {
        padding-left: .25rem !important
    }

    .ps-md-2 {
        padding-left: .5rem !important
    }

    .ps-md-3 {
        padding-left: 1rem !important
    }

    .ps-md-4 {
        padding-left: 1.5rem !important
    }

    .ps-md-5 {
        padding-left: 3rem !important
    }

    .gap-md-0 {
        gap: 0 !important
    }

    .gap-md-1 {
        gap: .25rem !important
    }

    .gap-md-2 {
        gap: .5rem !important
    }

    .gap-md-3 {
        gap: 1rem !important
    }

    .gap-md-4 {
        gap: 1.5rem !important
    }

    .gap-md-5 {
        gap: 3rem !important
    }

    .row-gap-md-0 {
        row-gap: 0 !important
    }

    .row-gap-md-1 {
        row-gap: .25rem !important
    }

    .row-gap-md-2 {
        row-gap: .5rem !important
    }

    .row-gap-md-3 {
        row-gap: 1rem !important
    }

    .row-gap-md-4 {
        row-gap: 1.5rem !important
    }

    .row-gap-md-5 {
        row-gap: 3rem !important
    }

    .column-gap-md-0 {
        -moz-column-gap: 0 !important;
        column-gap: 0 !important
    }

    .column-gap-md-1 {
        -moz-column-gap: .25rem !important;
        column-gap: .25rem !important
    }

    .column-gap-md-2 {
        -moz-column-gap: .5rem !important;
        column-gap: .5rem !important
    }

    .column-gap-md-3 {
        -moz-column-gap: 1rem !important;
        column-gap: 1rem !important
    }

    .column-gap-md-4 {
        -moz-column-gap: 1.5rem !important;
        column-gap: 1.5rem !important
    }

    .column-gap-md-5 {
        -moz-column-gap: 3rem !important;
        column-gap: 3rem !important
    }

    .text-md-start {
        text-align: left !important
    }

    .text-md-end {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media (min-width:992px) {
    .float-lg-start {
        float: left !important
    }

    .float-lg-end {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }

    .object-fit-lg-contain {
        -o-object-fit: contain !important;
        object-fit: contain !important
    }

    .object-fit-lg-cover {
        -o-object-fit: cover !important;
        object-fit: cover !important
    }

    .object-fit-lg-fill {
        -o-object-fit: fill !important;
        object-fit: fill !important
    }

    .object-fit-lg-scale {
        -o-object-fit: scale-down !important;
        object-fit: scale-down !important
    }

    .object-fit-lg-none {
        -o-object-fit: none !important;
        object-fit: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-grid {
        display: grid !important
    }

    .d-lg-inline-grid {
        display: inline-grid !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }

    .d-lg-none {
        display: none !important
    }

    .flex-lg-fill {
        flex: 1 1 auto !important
    }

    .flex-lg-row {
        flex-direction: row !important
    }

    .flex-lg-column {
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-lg-start {
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        justify-content: center !important
    }

    .justify-content-lg-between {
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        justify-content: space-around !important
    }

    .justify-content-lg-evenly {
        justify-content: space-evenly !important
    }

    .align-items-lg-start {
        align-items: flex-start !important
    }

    .align-items-lg-end {
        align-items: flex-end !important
    }

    .align-items-lg-center {
        align-items: center !important
    }

    .align-items-lg-baseline {
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        align-items: stretch !important
    }

    .align-content-lg-start {
        align-content: flex-start !important
    }

    .align-content-lg-end {
        align-content: flex-end !important
    }

    .align-content-lg-center {
        align-content: center !important
    }

    .align-content-lg-between {
        align-content: space-between !important
    }

    .align-content-lg-around {
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        align-content: stretch !important
    }

    .align-self-lg-auto {
        align-self: auto !important
    }

    .align-self-lg-start {
        align-self: flex-start !important
    }

    .align-self-lg-end {
        align-self: flex-end !important
    }

    .align-self-lg-center {
        align-self: center !important
    }

    .align-self-lg-baseline {
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        align-self: stretch !important
    }

    .order-lg-first {
        order: -1 !important
    }

    .order-lg-0 {
        order: 0 !important
    }

    .order-lg-1 {
        order: 1 !important
    }

    .order-lg-2 {
        order: 2 !important
    }

    .order-lg-3 {
        order: 3 !important
    }

    .order-lg-4 {
        order: 4 !important
    }

    .order-lg-5 {
        order: 5 !important
    }

    .order-lg-last {
        order: 6 !important
    }

    .m-lg-0 {
        margin: 0 !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .m-lg-3 {
        margin: 1rem !important
    }

    .m-lg-4 {
        margin: 1.5rem !important
    }

    .m-lg-5 {
        margin: 3rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-lg-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-lg-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-lg-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-lg-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-lg-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-lg-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-lg-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-lg-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-lg-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-lg-0 {
        margin-top: 0 !important
    }

    .mt-lg-1 {
        margin-top: .25rem !important
    }

    .mt-lg-2 {
        margin-top: .5rem !important
    }

    .mt-lg-3 {
        margin-top: 1rem !important
    }

    .mt-lg-4 {
        margin-top: 1.5rem !important
    }

    .mt-lg-5 {
        margin-top: 3rem !important
    }

    .mt-lg-auto {
        margin-top: auto !important
    }

    .me-lg-0 {
        margin-right: 0 !important
    }

    .me-lg-1 {
        margin-right: .25rem !important
    }

    .me-lg-2 {
        margin-right: .5rem !important
    }

    .me-lg-3 {
        margin-right: 1rem !important
    }

    .me-lg-4 {
        margin-right: 1.5rem !important
    }

    .me-lg-5 {
        margin-right: 3rem !important
    }

    .me-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-0 {
        margin-bottom: 0 !important
    }

    .mb-lg-1 {
        margin-bottom: .25rem !important
    }

    .mb-lg-2 {
        margin-bottom: .5rem !important
    }

    .mb-lg-3 {
        margin-bottom: 1rem !important
    }

    .mb-lg-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-lg-5 {
        margin-bottom: 3rem !important
    }

    .mb-lg-auto {
        margin-bottom: auto !important
    }

    .ms-lg-0 {
        margin-left: 0 !important
    }

    .ms-lg-1 {
        margin-left: .25rem !important
    }

    .ms-lg-2 {
        margin-left: .5rem !important
    }

    .ms-lg-3 {
        margin-left: 1rem !important
    }

    .ms-lg-4 {
        margin-left: 1.5rem !important
    }

    .ms-lg-5 {
        margin-left: 3rem !important
    }

    .ms-lg-auto {
        margin-left: auto !important
    }

    .m-lg-n1 {
        margin: -.25rem !important
    }

    .m-lg-n2 {
        margin: -.5rem !important
    }

    .m-lg-n3 {
        margin: -1rem !important
    }

    .m-lg-n4 {
        margin: -1.5rem !important
    }

    .m-lg-n5 {
        margin: -3rem !important
    }

    .mx-lg-n1 {
        margin-right: -.25rem !important;
        margin-left: -.25rem !important
    }

    .mx-lg-n2 {
        margin-right: -.5rem !important;
        margin-left: -.5rem !important
    }

    .mx-lg-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-lg-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-lg-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .my-lg-n1 {
        margin-top: -.25rem !important;
        margin-bottom: -.25rem !important
    }

    .my-lg-n2 {
        margin-top: -.5rem !important;
        margin-bottom: -.5rem !important
    }

    .my-lg-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-lg-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-lg-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .mt-lg-n1 {
        margin-top: -.25rem !important
    }

    .mt-lg-n2 {
        margin-top: -.5rem !important
    }

    .mt-lg-n3 {
        margin-top: -1rem !important
    }

    .mt-lg-n4 {
        margin-top: -1.5rem !important
    }

    .mt-lg-n5 {
        margin-top: -3rem !important
    }

    .me-lg-n1 {
        margin-right: -.25rem !important
    }

    .me-lg-n2 {
        margin-right: -.5rem !important
    }

    .me-lg-n3 {
        margin-right: -1rem !important
    }

    .me-lg-n4 {
        margin-right: -1.5rem !important
    }

    .me-lg-n5 {
        margin-right: -3rem !important
    }

    .mb-lg-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-lg-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-lg-n3 {
        margin-bottom: -1rem !important
    }

    .mb-lg-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-lg-n5 {
        margin-bottom: -3rem !important
    }

    .ms-lg-n1 {
        margin-left: -.25rem !important
    }

    .ms-lg-n2 {
        margin-left: -.5rem !important
    }

    .ms-lg-n3 {
        margin-left: -1rem !important
    }

    .ms-lg-n4 {
        margin-left: -1.5rem !important
    }

    .ms-lg-n5 {
        margin-left: -3rem !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .p-lg-3 {
        padding: 1rem !important
    }

    .p-lg-4 {
        padding: 1.5rem !important
    }

    .p-lg-5 {
        padding: 3rem !important
    }

    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-lg-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-lg-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-lg-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-lg-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-lg-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-lg-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-lg-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-lg-0 {
        padding-top: 0 !important
    }

    .pt-lg-1 {
        padding-top: .25rem !important
    }

    .pt-lg-2 {
        padding-top: .5rem !important
    }

    .pt-lg-3 {
        padding-top: 1rem !important
    }

    .pt-lg-4 {
        padding-top: 1.5rem !important
    }

    .pt-lg-5 {
        padding-top: 3rem !important
    }

    .pe-lg-0 {
        padding-right: 0 !important
    }

    .pe-lg-1 {
        padding-right: .25rem !important
    }

    .pe-lg-2 {
        padding-right: .5rem !important
    }

    .pe-lg-3 {
        padding-right: 1rem !important
    }

    .pe-lg-4 {
        padding-right: 1.5rem !important
    }

    .pe-lg-5 {
        padding-right: 3rem !important
    }

    .pb-lg-0 {
        padding-bottom: 0 !important
    }

    .pb-lg-1 {
        padding-bottom: .25rem !important
    }

    .pb-lg-2 {
        padding-bottom: .5rem !important
    }

    .pb-lg-3 {
        padding-bottom: 1rem !important
    }

    .pb-lg-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-lg-5 {
        padding-bottom: 3rem !important
    }

    .ps-lg-0 {
        padding-left: 0 !important
    }

    .ps-lg-1 {
        padding-left: .25rem !important
    }

    .ps-lg-2 {
        padding-left: .5rem !important
    }

    .ps-lg-3 {
        padding-left: 1rem !important
    }

    .ps-lg-4 {
        padding-left: 1.5rem !important
    }

    .ps-lg-5 {
        padding-left: 3rem !important
    }

    .gap-lg-0 {
        gap: 0 !important
    }

    .gap-lg-1 {
        gap: .25rem !important
    }

    .gap-lg-2 {
        gap: .5rem !important
    }

    .gap-lg-3 {
        gap: 1rem !important
    }

    .gap-lg-4 {
        gap: 1.5rem !important
    }

    .gap-lg-5 {
        gap: 3rem !important
    }

    .row-gap-lg-0 {
        row-gap: 0 !important
    }

    .row-gap-lg-1 {
        row-gap: .25rem !important
    }

    .row-gap-lg-2 {
        row-gap: .5rem !important
    }

    .row-gap-lg-3 {
        row-gap: 1rem !important
    }

    .row-gap-lg-4 {
        row-gap: 1.5rem !important
    }

    .row-gap-lg-5 {
        row-gap: 3rem !important
    }

    .column-gap-lg-0 {
        -moz-column-gap: 0 !important;
        column-gap: 0 !important
    }

    .column-gap-lg-1 {
        -moz-column-gap: .25rem !important;
        column-gap: .25rem !important
    }

    .column-gap-lg-2 {
        -moz-column-gap: .5rem !important;
        column-gap: .5rem !important
    }

    .column-gap-lg-3 {
        -moz-column-gap: 1rem !important;
        column-gap: 1rem !important
    }

    .column-gap-lg-4 {
        -moz-column-gap: 1.5rem !important;
        column-gap: 1.5rem !important
    }

    .column-gap-lg-5 {
        -moz-column-gap: 3rem !important;
        column-gap: 3rem !important
    }

    .text-lg-start {
        text-align: left !important
    }

    .text-lg-end {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

@media (min-width:1200px) {
    .float-xl-start {
        float: left !important
    }

    .float-xl-end {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }

    .object-fit-xl-contain {
        -o-object-fit: contain !important;
        object-fit: contain !important
    }

    .object-fit-xl-cover {
        -o-object-fit: cover !important;
        object-fit: cover !important
    }

    .object-fit-xl-fill {
        -o-object-fit: fill !important;
        object-fit: fill !important
    }

    .object-fit-xl-scale {
        -o-object-fit: scale-down !important;
        object-fit: scale-down !important
    }

    .object-fit-xl-none {
        -o-object-fit: none !important;
        object-fit: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-grid {
        display: grid !important
    }

    .d-xl-inline-grid {
        display: inline-grid !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: flex !important
    }

    .d-xl-inline-flex {
        display: inline-flex !important
    }

    .d-xl-none {
        display: none !important
    }

    .flex-xl-fill {
        flex: 1 1 auto !important
    }

    .flex-xl-row {
        flex-direction: row !important
    }

    .flex-xl-column {
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-xl-start {
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        justify-content: center !important
    }

    .justify-content-xl-between {
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        justify-content: space-around !important
    }

    .justify-content-xl-evenly {
        justify-content: space-evenly !important
    }

    .align-items-xl-start {
        align-items: flex-start !important
    }

    .align-items-xl-end {
        align-items: flex-end !important
    }

    .align-items-xl-center {
        align-items: center !important
    }

    .align-items-xl-baseline {
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        align-items: stretch !important
    }

    .align-content-xl-start {
        align-content: flex-start !important
    }

    .align-content-xl-end {
        align-content: flex-end !important
    }

    .align-content-xl-center {
        align-content: center !important
    }

    .align-content-xl-between {
        align-content: space-between !important
    }

    .align-content-xl-around {
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        align-content: stretch !important
    }

    .align-self-xl-auto {
        align-self: auto !important
    }

    .align-self-xl-start {
        align-self: flex-start !important
    }

    .align-self-xl-end {
        align-self: flex-end !important
    }

    .align-self-xl-center {
        align-self: center !important
    }

    .align-self-xl-baseline {
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        align-self: stretch !important
    }

    .order-xl-first {
        order: -1 !important
    }

    .order-xl-0 {
        order: 0 !important
    }

    .order-xl-1 {
        order: 1 !important
    }

    .order-xl-2 {
        order: 2 !important
    }

    .order-xl-3 {
        order: 3 !important
    }

    .order-xl-4 {
        order: 4 !important
    }

    .order-xl-5 {
        order: 5 !important
    }

    .order-xl-last {
        order: 6 !important
    }

    .m-xl-0 {
        margin: 0 !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .m-xl-3 {
        margin: 1rem !important
    }

    .m-xl-4 {
        margin: 1.5rem !important
    }

    .m-xl-5 {
        margin: 3rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mx-xl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-xl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-xl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-xl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-xl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-xl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-xl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-xl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-xl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-xl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-xl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-xl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-xl-0 {
        margin-top: 0 !important
    }

    .mt-xl-1 {
        margin-top: .25rem !important
    }

    .mt-xl-2 {
        margin-top: .5rem !important
    }

    .mt-xl-3 {
        margin-top: 1rem !important
    }

    .mt-xl-4 {
        margin-top: 1.5rem !important
    }

    .mt-xl-5 {
        margin-top: 3rem !important
    }

    .mt-xl-auto {
        margin-top: auto !important
    }

    .me-xl-0 {
        margin-right: 0 !important
    }

    .me-xl-1 {
        margin-right: .25rem !important
    }

    .me-xl-2 {
        margin-right: .5rem !important
    }

    .me-xl-3 {
        margin-right: 1rem !important
    }

    .me-xl-4 {
        margin-right: 1.5rem !important
    }

    .me-xl-5 {
        margin-right: 3rem !important
    }

    .me-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-0 {
        margin-bottom: 0 !important
    }

    .mb-xl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xl-3 {
        margin-bottom: 1rem !important
    }

    .mb-xl-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-xl-5 {
        margin-bottom: 3rem !important
    }

    .mb-xl-auto {
        margin-bottom: auto !important
    }

    .ms-xl-0 {
        margin-left: 0 !important
    }

    .ms-xl-1 {
        margin-left: .25rem !important
    }

    .ms-xl-2 {
        margin-left: .5rem !important
    }

    .ms-xl-3 {
        margin-left: 1rem !important
    }

    .ms-xl-4 {
        margin-left: 1.5rem !important
    }

    .ms-xl-5 {
        margin-left: 3rem !important
    }

    .ms-xl-auto {
        margin-left: auto !important
    }

    .m-xl-n1 {
        margin: -.25rem !important
    }

    .m-xl-n2 {
        margin: -.5rem !important
    }

    .m-xl-n3 {
        margin: -1rem !important
    }

    .m-xl-n4 {
        margin: -1.5rem !important
    }

    .m-xl-n5 {
        margin: -3rem !important
    }

    .mx-xl-n1 {
        margin-right: -.25rem !important;
        margin-left: -.25rem !important
    }

    .mx-xl-n2 {
        margin-right: -.5rem !important;
        margin-left: -.5rem !important
    }

    .mx-xl-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-xl-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-xl-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .my-xl-n1 {
        margin-top: -.25rem !important;
        margin-bottom: -.25rem !important
    }

    .my-xl-n2 {
        margin-top: -.5rem !important;
        margin-bottom: -.5rem !important
    }

    .my-xl-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-xl-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-xl-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .mt-xl-n1 {
        margin-top: -.25rem !important
    }

    .mt-xl-n2 {
        margin-top: -.5rem !important
    }

    .mt-xl-n3 {
        margin-top: -1rem !important
    }

    .mt-xl-n4 {
        margin-top: -1.5rem !important
    }

    .mt-xl-n5 {
        margin-top: -3rem !important
    }

    .me-xl-n1 {
        margin-right: -.25rem !important
    }

    .me-xl-n2 {
        margin-right: -.5rem !important
    }

    .me-xl-n3 {
        margin-right: -1rem !important
    }

    .me-xl-n4 {
        margin-right: -1.5rem !important
    }

    .me-xl-n5 {
        margin-right: -3rem !important
    }

    .mb-xl-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-xl-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-xl-n3 {
        margin-bottom: -1rem !important
    }

    .mb-xl-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-xl-n5 {
        margin-bottom: -3rem !important
    }

    .ms-xl-n1 {
        margin-left: -.25rem !important
    }

    .ms-xl-n2 {
        margin-left: -.5rem !important
    }

    .ms-xl-n3 {
        margin-left: -1rem !important
    }

    .ms-xl-n4 {
        margin-left: -1.5rem !important
    }

    .ms-xl-n5 {
        margin-left: -3rem !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .p-xl-3 {
        padding: 1rem !important
    }

    .p-xl-4 {
        padding: 1.5rem !important
    }

    .p-xl-5 {
        padding: 3rem !important
    }

    .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-xl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-xl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-xl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-xl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-xl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-xl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-xl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-xl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-xl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-xl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-xl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-xl-0 {
        padding-top: 0 !important
    }

    .pt-xl-1 {
        padding-top: .25rem !important
    }

    .pt-xl-2 {
        padding-top: .5rem !important
    }

    .pt-xl-3 {
        padding-top: 1rem !important
    }

    .pt-xl-4 {
        padding-top: 1.5rem !important
    }

    .pt-xl-5 {
        padding-top: 3rem !important
    }

    .pe-xl-0 {
        padding-right: 0 !important
    }

    .pe-xl-1 {
        padding-right: .25rem !important
    }

    .pe-xl-2 {
        padding-right: .5rem !important
    }

    .pe-xl-3 {
        padding-right: 1rem !important
    }

    .pe-xl-4 {
        padding-right: 1.5rem !important
    }

    .pe-xl-5 {
        padding-right: 3rem !important
    }

    .pb-xl-0 {
        padding-bottom: 0 !important
    }

    .pb-xl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xl-3 {
        padding-bottom: 1rem !important
    }

    .pb-xl-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-xl-5 {
        padding-bottom: 3rem !important
    }

    .ps-xl-0 {
        padding-left: 0 !important
    }

    .ps-xl-1 {
        padding-left: .25rem !important
    }

    .ps-xl-2 {
        padding-left: .5rem !important
    }

    .ps-xl-3 {
        padding-left: 1rem !important
    }

    .ps-xl-4 {
        padding-left: 1.5rem !important
    }

    .ps-xl-5 {
        padding-left: 3rem !important
    }

    .gap-xl-0 {
        gap: 0 !important
    }

    .gap-xl-1 {
        gap: .25rem !important
    }

    .gap-xl-2 {
        gap: .5rem !important
    }

    .gap-xl-3 {
        gap: 1rem !important
    }

    .gap-xl-4 {
        gap: 1.5rem !important
    }

    .gap-xl-5 {
        gap: 3rem !important
    }

    .row-gap-xl-0 {
        row-gap: 0 !important
    }

    .row-gap-xl-1 {
        row-gap: .25rem !important
    }

    .row-gap-xl-2 {
        row-gap: .5rem !important
    }

    .row-gap-xl-3 {
        row-gap: 1rem !important
    }

    .row-gap-xl-4 {
        row-gap: 1.5rem !important
    }

    .row-gap-xl-5 {
        row-gap: 3rem !important
    }

    .column-gap-xl-0 {
        -moz-column-gap: 0 !important;
        column-gap: 0 !important
    }

    .column-gap-xl-1 {
        -moz-column-gap: .25rem !important;
        column-gap: .25rem !important
    }

    .column-gap-xl-2 {
        -moz-column-gap: .5rem !important;
        column-gap: .5rem !important
    }

    .column-gap-xl-3 {
        -moz-column-gap: 1rem !important;
        column-gap: 1rem !important
    }

    .column-gap-xl-4 {
        -moz-column-gap: 1.5rem !important;
        column-gap: 1.5rem !important
    }

    .column-gap-xl-5 {
        -moz-column-gap: 3rem !important;
        column-gap: 3rem !important
    }

    .text-xl-start {
        text-align: left !important
    }

    .text-xl-end {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }

    .fs-1 {
        font-size: 2.375rem !important
    }

    .fs-2 {
        font-size: 1.875rem !important
    }

    .fs-3 {
        font-size: 1.5rem !important
    }
}

@media (min-width:1400px) {
    .float-xxl-start {
        float: left !important
    }

    .float-xxl-end {
        float: right !important
    }

    .float-xxl-none {
        float: none !important
    }

    .object-fit-xxl-contain {
        -o-object-fit: contain !important;
        object-fit: contain !important
    }

    .object-fit-xxl-cover {
        -o-object-fit: cover !important;
        object-fit: cover !important
    }

    .object-fit-xxl-fill {
        -o-object-fit: fill !important;
        object-fit: fill !important
    }

    .object-fit-xxl-scale {
        -o-object-fit: scale-down !important;
        object-fit: scale-down !important
    }

    .object-fit-xxl-none {
        -o-object-fit: none !important;
        object-fit: none !important
    }

    .d-xxl-inline {
        display: inline !important
    }

    .d-xxl-inline-block {
        display: inline-block !important
    }

    .d-xxl-block {
        display: block !important
    }

    .d-xxl-grid {
        display: grid !important
    }

    .d-xxl-inline-grid {
        display: inline-grid !important
    }

    .d-xxl-table {
        display: table !important
    }

    .d-xxl-table-row {
        display: table-row !important
    }

    .d-xxl-table-cell {
        display: table-cell !important
    }

    .d-xxl-flex {
        display: flex !important
    }

    .d-xxl-inline-flex {
        display: inline-flex !important
    }

    .d-xxl-none {
        display: none !important
    }

    .flex-xxl-fill {
        flex: 1 1 auto !important
    }

    .flex-xxl-row {
        flex-direction: row !important
    }

    .flex-xxl-column {
        flex-direction: column !important
    }

    .flex-xxl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xxl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xxl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xxl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xxl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xxl-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-xxl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xxl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-xxl-start {
        justify-content: flex-start !important
    }

    .justify-content-xxl-end {
        justify-content: flex-end !important
    }

    .justify-content-xxl-center {
        justify-content: center !important
    }

    .justify-content-xxl-between {
        justify-content: space-between !important
    }

    .justify-content-xxl-around {
        justify-content: space-around !important
    }

    .justify-content-xxl-evenly {
        justify-content: space-evenly !important
    }

    .align-items-xxl-start {
        align-items: flex-start !important
    }

    .align-items-xxl-end {
        align-items: flex-end !important
    }

    .align-items-xxl-center {
        align-items: center !important
    }

    .align-items-xxl-baseline {
        align-items: baseline !important
    }

    .align-items-xxl-stretch {
        align-items: stretch !important
    }

    .align-content-xxl-start {
        align-content: flex-start !important
    }

    .align-content-xxl-end {
        align-content: flex-end !important
    }

    .align-content-xxl-center {
        align-content: center !important
    }

    .align-content-xxl-between {
        align-content: space-between !important
    }

    .align-content-xxl-around {
        align-content: space-around !important
    }

    .align-content-xxl-stretch {
        align-content: stretch !important
    }

    .align-self-xxl-auto {
        align-self: auto !important
    }

    .align-self-xxl-start {
        align-self: flex-start !important
    }

    .align-self-xxl-end {
        align-self: flex-end !important
    }

    .align-self-xxl-center {
        align-self: center !important
    }

    .align-self-xxl-baseline {
        align-self: baseline !important
    }

    .align-self-xxl-stretch {
        align-self: stretch !important
    }

    .order-xxl-first {
        order: -1 !important
    }

    .order-xxl-0 {
        order: 0 !important
    }

    .order-xxl-1 {
        order: 1 !important
    }

    .order-xxl-2 {
        order: 2 !important
    }

    .order-xxl-3 {
        order: 3 !important
    }

    .order-xxl-4 {
        order: 4 !important
    }

    .order-xxl-5 {
        order: 5 !important
    }

    .order-xxl-last {
        order: 6 !important
    }

    .m-xxl-0 {
        margin: 0 !important
    }

    .m-xxl-1 {
        margin: .25rem !important
    }

    .m-xxl-2 {
        margin: .5rem !important
    }

    .m-xxl-3 {
        margin: 1rem !important
    }

    .m-xxl-4 {
        margin: 1.5rem !important
    }

    .m-xxl-5 {
        margin: 3rem !important
    }

    .m-xxl-auto {
        margin: auto !important
    }

    .mx-xxl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-xxl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-xxl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-xxl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-xxl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-xxl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-xxl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xxl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-xxl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-xxl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-xxl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-xxl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-xxl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-xxl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-xxl-0 {
        margin-top: 0 !important
    }

    .mt-xxl-1 {
        margin-top: .25rem !important
    }

    .mt-xxl-2 {
        margin-top: .5rem !important
    }

    .mt-xxl-3 {
        margin-top: 1rem !important
    }

    .mt-xxl-4 {
        margin-top: 1.5rem !important
    }

    .mt-xxl-5 {
        margin-top: 3rem !important
    }

    .mt-xxl-auto {
        margin-top: auto !important
    }

    .me-xxl-0 {
        margin-right: 0 !important
    }

    .me-xxl-1 {
        margin-right: .25rem !important
    }

    .me-xxl-2 {
        margin-right: .5rem !important
    }

    .me-xxl-3 {
        margin-right: 1rem !important
    }

    .me-xxl-4 {
        margin-right: 1.5rem !important
    }

    .me-xxl-5 {
        margin-right: 3rem !important
    }

    .me-xxl-auto {
        margin-right: auto !important
    }

    .mb-xxl-0 {
        margin-bottom: 0 !important
    }

    .mb-xxl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xxl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xxl-3 {
        margin-bottom: 1rem !important
    }

    .mb-xxl-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-xxl-5 {
        margin-bottom: 3rem !important
    }

    .mb-xxl-auto {
        margin-bottom: auto !important
    }

    .ms-xxl-0 {
        margin-left: 0 !important
    }

    .ms-xxl-1 {
        margin-left: .25rem !important
    }

    .ms-xxl-2 {
        margin-left: .5rem !important
    }

    .ms-xxl-3 {
        margin-left: 1rem !important
    }

    .ms-xxl-4 {
        margin-left: 1.5rem !important
    }

    .ms-xxl-5 {
        margin-left: 3rem !important
    }

    .ms-xxl-auto {
        margin-left: auto !important
    }

    .m-xxl-n1 {
        margin: -.25rem !important
    }

    .m-xxl-n2 {
        margin: -.5rem !important
    }

    .m-xxl-n3 {
        margin: -1rem !important
    }

    .m-xxl-n4 {
        margin: -1.5rem !important
    }

    .m-xxl-n5 {
        margin: -3rem !important
    }

    .mx-xxl-n1 {
        margin-right: -.25rem !important;
        margin-left: -.25rem !important
    }

    .mx-xxl-n2 {
        margin-right: -.5rem !important;
        margin-left: -.5rem !important
    }

    .mx-xxl-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-xxl-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-xxl-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .my-xxl-n1 {
        margin-top: -.25rem !important;
        margin-bottom: -.25rem !important
    }

    .my-xxl-n2 {
        margin-top: -.5rem !important;
        margin-bottom: -.5rem !important
    }

    .my-xxl-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-xxl-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-xxl-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .mt-xxl-n1 {
        margin-top: -.25rem !important
    }

    .mt-xxl-n2 {
        margin-top: -.5rem !important
    }

    .mt-xxl-n3 {
        margin-top: -1rem !important
    }

    .mt-xxl-n4 {
        margin-top: -1.5rem !important
    }

    .mt-xxl-n5 {
        margin-top: -3rem !important
    }

    .me-xxl-n1 {
        margin-right: -.25rem !important
    }

    .me-xxl-n2 {
        margin-right: -.5rem !important
    }

    .me-xxl-n3 {
        margin-right: -1rem !important
    }

    .me-xxl-n4 {
        margin-right: -1.5rem !important
    }

    .me-xxl-n5 {
        margin-right: -3rem !important
    }

    .mb-xxl-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-xxl-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-xxl-n3 {
        margin-bottom: -1rem !important
    }

    .mb-xxl-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-xxl-n5 {
        margin-bottom: -3rem !important
    }

    .ms-xxl-n1 {
        margin-left: -.25rem !important
    }

    .ms-xxl-n2 {
        margin-left: -.5rem !important
    }

    .ms-xxl-n3 {
        margin-left: -1rem !important
    }

    .ms-xxl-n4 {
        margin-left: -1.5rem !important
    }

    .ms-xxl-n5 {
        margin-left: -3rem !important
    }

    .p-xxl-0 {
        padding: 0 !important
    }

    .p-xxl-1 {
        padding: .25rem !important
    }

    .p-xxl-2 {
        padding: .5rem !important
    }

    .p-xxl-3 {
        padding: 1rem !important
    }

    .p-xxl-4 {
        padding: 1.5rem !important
    }

    .p-xxl-5 {
        padding: 3rem !important
    }

    .px-xxl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-xxl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-xxl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-xxl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-xxl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-xxl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-xxl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-xxl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-xxl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-xxl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-xxl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-xxl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-xxl-0 {
        padding-top: 0 !important
    }

    .pt-xxl-1 {
        padding-top: .25rem !important
    }

    .pt-xxl-2 {
        padding-top: .5rem !important
    }

    .pt-xxl-3 {
        padding-top: 1rem !important
    }

    .pt-xxl-4 {
        padding-top: 1.5rem !important
    }

    .pt-xxl-5 {
        padding-top: 3rem !important
    }

    .pe-xxl-0 {
        padding-right: 0 !important
    }

    .pe-xxl-1 {
        padding-right: .25rem !important
    }

    .pe-xxl-2 {
        padding-right: .5rem !important
    }

    .pe-xxl-3 {
        padding-right: 1rem !important
    }

    .pe-xxl-4 {
        padding-right: 1.5rem !important
    }

    .pe-xxl-5 {
        padding-right: 3rem !important
    }

    .pb-xxl-0 {
        padding-bottom: 0 !important
    }

    .pb-xxl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xxl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xxl-3 {
        padding-bottom: 1rem !important
    }

    .pb-xxl-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-xxl-5 {
        padding-bottom: 3rem !important
    }

    .ps-xxl-0 {
        padding-left: 0 !important
    }

    .ps-xxl-1 {
        padding-left: .25rem !important
    }

    .ps-xxl-2 {
        padding-left: .5rem !important
    }

    .ps-xxl-3 {
        padding-left: 1rem !important
    }

    .ps-xxl-4 {
        padding-left: 1.5rem !important
    }

    .ps-xxl-5 {
        padding-left: 3rem !important
    }

    .gap-xxl-0 {
        gap: 0 !important
    }

    .gap-xxl-1 {
        gap: .25rem !important
    }

    .gap-xxl-2 {
        gap: .5rem !important
    }

    .gap-xxl-3 {
        gap: 1rem !important
    }

    .gap-xxl-4 {
        gap: 1.5rem !important
    }

    .gap-xxl-5 {
        gap: 3rem !important
    }

    .row-gap-xxl-0 {
        row-gap: 0 !important
    }

    .row-gap-xxl-1 {
        row-gap: .25rem !important
    }

    .row-gap-xxl-2 {
        row-gap: .5rem !important
    }

    .row-gap-xxl-3 {
        row-gap: 1rem !important
    }

    .row-gap-xxl-4 {
        row-gap: 1.5rem !important
    }

    .row-gap-xxl-5 {
        row-gap: 3rem !important
    }

    .column-gap-xxl-0 {
        -moz-column-gap: 0 !important;
        column-gap: 0 !important
    }

    .column-gap-xxl-1 {
        -moz-column-gap: .25rem !important;
        column-gap: .25rem !important
    }

    .column-gap-xxl-2 {
        -moz-column-gap: .5rem !important;
        column-gap: .5rem !important
    }

    .column-gap-xxl-3 {
        -moz-column-gap: 1rem !important;
        column-gap: 1rem !important
    }

    .column-gap-xxl-4 {
        -moz-column-gap: 1.5rem !important;
        column-gap: 1.5rem !important
    }

    .column-gap-xxl-5 {
        -moz-column-gap: 3rem !important;
        column-gap: 3rem !important
    }

    .text-xxl-start {
        text-align: left !important
    }

    .text-xxl-end {
        text-align: right !important
    }

    .text-xxl-center {
        text-align: center !important
    }
}

@media print {
    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-grid {
        display: grid !important
    }

    .d-print-inline-grid {
        display: inline-grid !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: flex !important
    }

    .d-print-inline-flex {
        display: inline-flex !important
    }

    .d-print-none {
        display: none !important
    }
}

.p-l-0,
.pc-sidebar ul {
    padding-left: 0
}

.editor-statusbar,
.p-b-0 {
    padding-bottom: 0
}

.p-0 {
    padding: 0
}

.p-t-0 {
    padding-top: 0
}

#api-scroller_wrapper .dataTables_paginate,
#basic-scroller_wrapper .dataTables_paginate,
#state-scroller_wrapper .dataTables_paginate,
.p-t-5 {
    padding-top: 5px
}

.p-r-0 {
    padding-right: 0
}

.m-0 {
    margin: 0
}

.m-t-0 {
    margin-top: 0
}

.m-b-0 {
    margin-bottom: 0
}

.m-l-0 {
    margin-left: 0
}

.m-r-0 {
    margin-right: 0
}

.p-5 {
    padding: 5px
}

.p-b-5 {
    padding-bottom: 5px
}

.p-l-5 {
    padding-left: 5px
}

.p-r-5 {
    padding-right: 5px
}

.m-5 {
    margin: 5px
}

.m-t-5 {
    margin-top: 5px
}

.card .card-header .h5+.small,
.card .card-header .h5+p,
.card .card-header .h5+small,
.card .card-header h5+.small,
.card .card-header h5+p,
.card .card-header h5+small,
.m-t-10,
div.dt-container div.dt-info~div.dt-paging {
    margin-top: 10px
}

.m-b-5 {
    margin-bottom: 5px
}

.m-l-5 {
    margin-left: 5px
}

.m-r-5 {
    margin-right: 5px
}

.p-10 {
    padding: 10px
}

.p-t-10 {
    padding-top: 10px
}

.p-b-10 {
    padding-bottom: 10px
}

.p-l-10 {
    padding-left: 10px
}

.p-r-10 {
    padding-right: 10px
}

.m-10 {
    margin: 10px
}

.m-b-10 {
    margin-bottom: 10px
}

.m-l-10 {
    margin-left: 10px
}

.m-r-10 {
    margin-right: 10px
}

.p-15 {
    padding: 15px
}

.p-t-15 {
    padding-top: 15px
}

.p-b-15 {
    padding-bottom: 15px
}

.p-l-15 {
    padding-left: 15px
}

.p-r-15 {
    padding-right: 15px
}

.m-15 {
    margin: 15px
}

.m-t-15 {
    margin-top: 15px
}

.m-b-15 {
    margin-bottom: 15px
}

.m-l-15 {
    margin-left: 15px
}

.m-r-15 {
    margin-right: 15px
}

.p-20 {
    padding: 20px
}

.p-t-20 {
    padding-top: 20px
}

.p-b-20 {
    padding-bottom: 20px
}

.p-l-20 {
    padding-left: 20px
}

.p-r-20 {
    padding-right: 20px
}

.m-20 {
    margin: 20px
}

.m-t-20 {
    margin-top: 20px
}

.m-b-20 {
    margin-bottom: 20px
}

.m-l-20 {
    margin-left: 20px
}

.m-r-20 {
    margin-right: 20px
}

.p-25 {
    padding: 25px
}

.p-t-25 {
    padding-top: 25px
}

.p-b-25 {
    padding-bottom: 25px
}

.p-l-25 {
    padding-left: 25px
}

.p-r-25 {
    padding-right: 25px
}

.m-25 {
    margin: 25px
}

.m-t-25 {
    margin-top: 25px
}

.m-b-25 {
    margin-bottom: 25px
}

.m-l-25 {
    margin-left: 25px
}

.m-r-25 {
    margin-right: 25px
}

.p-30 {
    padding: 30px
}

.p-t-30 {
    padding-top: 30px
}

.p-b-30 {
    padding-bottom: 30px
}

.p-l-30 {
    padding-left: 30px
}

.p-r-30 {
    padding-right: 30px
}

.m-30 {
    margin: 30px
}

.m-t-30 {
    margin-top: 30px
}

.m-b-30 {
    margin-bottom: 30px
}

.m-l-30 {
    margin-left: 30px
}

.m-r-30 {
    margin-right: 30px
}

.p-35 {
    padding: 35px
}

.p-t-35 {
    padding-top: 35px
}

.p-b-35 {
    padding-bottom: 35px
}

.p-l-35 {
    padding-left: 35px
}

.p-r-35 {
    padding-right: 35px
}

.m-35 {
    margin: 35px
}

.m-t-35 {
    margin-top: 35px
}

.m-b-35 {
    margin-bottom: 35px
}

.m-l-35 {
    margin-left: 35px
}

.m-r-35 {
    margin-right: 35px
}

.p-40 {
    padding: 40px
}

.p-t-40 {
    padding-top: 40px
}

.p-b-40 {
    padding-bottom: 40px
}

.p-l-40 {
    padding-left: 40px
}

.p-r-40 {
    padding-right: 40px
}

.m-40 {
    margin: 40px
}

.m-t-40 {
    margin-top: 40px
}

.m-b-40 {
    margin-bottom: 40px
}

.m-l-40 {
    margin-left: 40px
}

.m-r-40 {
    margin-right: 40px
}

.p-45 {
    padding: 45px
}

.p-t-45 {
    padding-top: 45px
}

.p-b-45 {
    padding-bottom: 45px
}

.p-l-45 {
    padding-left: 45px
}

.p-r-45 {
    padding-right: 45px
}

.m-45 {
    margin: 45px
}

.m-t-45 {
    margin-top: 45px
}

.m-b-45 {
    margin-bottom: 45px
}

.m-l-45 {
    margin-left: 45px
}

.m-r-45 {
    margin-right: 45px
}

.p-50 {
    padding: 50px
}

.p-t-50 {
    padding-top: 50px
}

.p-b-50 {
    padding-bottom: 50px
}

.p-l-50 {
    padding-left: 50px
}

.p-r-50 {
    padding-right: 50px
}

.m-50 {
    margin: 50px
}

.m-t-50 {
    margin-top: 50px
}

.bd-example-row .row+.row,
.highlight {
    margin-top: 1rem
}

.m-b-50 {
    margin-bottom: 50px
}

.m-l-50 {
    margin-left: 50px
}

.m-r-50 {
    margin-right: 50px
}

.f-6 {
    font-size: 6px
}

.f-8 {
    font-size: 8px
}

.f-10 {
    font-size: 10px
}

.f-12 {
    font-size: 12px
}

.f-14 {
    font-size: 14px
}

.choices,
.f-16 {
    font-size: 16px
}

.f-18 {
    font-size: 18px
}

.f-20 {
    font-size: 20px
}

.f-22 {
    font-size: 22px
}

.f-24 {
    font-size: 24px
}

.f-26,
.introjs-tooltip .theme-layout .introjs-button i,
.theme-color>a i,
.theme-layout .btn i,
.theme-layout .introjs-tooltip .introjs-button i {
    font-size: 26px
}

.f-28 {
    font-size: 28px
}

.f-30 {
    font-size: 30px
}

.f-32 {
    font-size: 32px
}

.f-34 {
    font-size: 34px
}

.f-36 {
    font-size: 36px
}

.f-38 {
    font-size: 38px
}

.f-40 {
    font-size: 40px
}

.f-42 {
    font-size: 42px
}

.f-44 {
    font-size: 44px
}

.f-46 {
    font-size: 46px
}

.f-48 {
    font-size: 48px
}

.f-50 {
    font-size: 50px
}

.f-52 {
    font-size: 52px
}

.f-54 {
    font-size: 54px
}

.f-56 {
    font-size: 56px
}

.f-58 {
    font-size: 58px
}

.f-60 {
    font-size: 60px
}

.f-62 {
    font-size: 62px
}

.f-64 {
    font-size: 64px
}

.f-66 {
    font-size: 66px
}

.f-68 {
    font-size: 68px
}

.f-70 {
    font-size: 70px
}

.f-72 {
    font-size: 72px
}

.f-74 {
    font-size: 74px
}

.f-76 {
    font-size: 76px
}

.f-78 {
    font-size: 78px
}

.f-80 {
    font-size: 80px
}

.f-w-100 {
    font-weight: 100
}

.f-w-200 {
    font-weight: 200
}

.f-w-300 {
    font-weight: 300
}

.f-w-400 {
    font-weight: 400
}

.f-w-500 {
    font-weight: 500
}

.f-w-600 {
    font-weight: 600
}

.f-w-700 {
    font-weight: 700
}

.f-w-800 {
    font-weight: 800
}

.f-w-900 {
    font-weight: 900
}

.wid-10 {
    width: 10px
}

.hei-10 {
    height: 10px
}

.wid-15 {
    width: 15px
}

.hei-15 {
    height: 15px
}

.wid-20 {
    width: 20px
}

.hei-20 {
    height: 20px
}

.wid-25 {
    width: 25px
}

.hei-25 {
    height: 25px
}

.wid-30 {
    width: 30px
}

.hei-30 {
    height: 30px
}

.wid-35 {
    width: 35px
}

.hei-35 {
    height: 35px
}

.wid-40 {
    width: 40px
}

.hei-40 {
    height: 40px
}

.wid-45 {
    width: 45px
}

.hei-45 {
    height: 45px
}

.wid-50 {
    width: 50px
}

.hei-50 {
    height: 50px
}

.wid-55 {
    width: 55px
}

.hei-55 {
    height: 55px
}

.wid-60 {
    width: 60px
}

.hei-60 {
    height: 60px
}

.wid-65 {
    width: 65px
}

.hei-65 {
    height: 65px
}

.wid-70 {
    width: 70px
}

.hei-70 {
    height: 70px
}

.wid-75 {
    width: 75px
}

.hei-75 {
    height: 75px
}

.wid-80 {
    width: 80px
}

.hei-80 {
    height: 80px
}

.wid-85 {
    width: 85px
}

.hei-85 {
    height: 85px
}

.wid-90 {
    width: 90px
}

.hei-90 {
    height: 90px
}

.wid-95 {
    width: 95px
}

.hei-95 {
    height: 95px
}

.wid-100 {
    width: 100px
}

.hei-100 {
    height: 100px
}

.wid-105 {
    width: 105px
}

.hei-105 {
    height: 105px
}

.wid-110 {
    width: 110px
}

.hei-110 {
    height: 110px
}

.wid-115 {
    width: 115px
}

.hei-115 {
    height: 115px
}

.wid-120 {
    width: 120px
}

.hei-120 {
    height: 120px
}

.wid-125 {
    width: 125px
}

.hei-125 {
    height: 125px
}

.wid-130 {
    width: 130px
}

.hei-130 {
    height: 130px
}

.wid-135 {
    width: 135px
}

.hei-135 {
    height: 135px
}

.wid-140 {
    width: 140px
}

.hei-140 {
    height: 140px
}

.wid-145 {
    width: 145px
}

.hei-145 {
    height: 145px
}

.wid-150 {
    width: 150px
}

.hei-150 {
    height: 150px
}

.b-wid-1 {
    border-width: 1px
}

.b-wid-2 {
    border-width: 2px
}

.b-wid-3 {
    border-width: 3px
}

.b-wid-4 {
    border-width: 4px
}

.b-wid-5 {
    border-width: 5px
}

.b-wid-6 {
    border-width: 6px
}

.b-wid-7 {
    border-width: 7px
}

.b-wid-8 {
    border-width: 8px
}

.text-header {
    color: var(--bs-heading-color)
}

.badge.bg-light-theme,
.bg-light-theme {
    color: #f36;
    background: #ffebf0
}

.bg-body {
    background: var(--bs-body-bg)
}

.icon-svg-theme {
    fill: #ffebf0;
    stroke: #f36
}

.material-icons-two-tone.text-theme {
    background-color: #f36
}

.text-hover-theme:hover {
    color: #f36 !important
}

.bg-light-primary {
    background: #edf2ff;
    color: #4680ff
}

.icon-svg-primary {
    fill: #edf2ff;
    stroke: #4680ff
}

.material-icons-two-tone.text-primary {
    background-color: #4680ff
}

.text-hover-primary:hover {
    color: #4680ff !important
}

.bg-light-secondary {
    background: #eff0f2;
    color: #5b6b79
}

.icon-svg-secondary {
    fill: #eff0f2;
    stroke: #5b6b79
}

.material-icons-two-tone.text-secondary {
    background-color: #5b6b79
}

.text-hover-secondary:hover {
    color: #5b6b79 !important
}

.bg-light-success {
    background: #eaf6f2;
    color: #2ca87f
}

.icon-svg-success {
    fill: #eaf6f2;
    stroke: #2ca87f
}

.material-icons-two-tone.text-success {
    background-color: #2ca87f
}

.text-hover-success:hover {
    color: #2ca87f !important
}

.bg-light-info {
    background: #ecfafb;
    color: #3ec9d6
}

.icon-svg-info {
    fill: #ecfafb;
    stroke: #3ec9d6
}

.material-icons-two-tone.text-info {
    background-color: #3ec9d6
}

.text-hover-info:hover {
    color: #3ec9d6 !important
}

.bg-light-warning {
    background: #fcf3e6;
    color: #e58a00
}

.icon-svg-warning {
    fill: #fcf3e6;
    stroke: #e58a00
}

.material-icons-two-tone.text-warning {
    background-color: #e58a00
}

.text-hover-warning:hover {
    color: #e58a00 !important
}

.bg-light-danger {
    background: #fce9e9;
    color: #dc2626
}

.icon-svg-danger {
    fill: #fce9e9;
    stroke: #dc2626
}

.material-icons-two-tone.text-danger {
    background-color: #dc2626
}

.text-hover-danger:hover {
    color: #dc2626 !important
}

.bg-light-light {
    background: #fefeff;
    color: #f8f9fa
}

.icon-svg-light {
    fill: #fefeff;
    stroke: #f8f9fa
}

.material-icons-two-tone.text-light {
    background-color: #f8f9fa
}

.text-hover-light:hover {
    color: #f8f9fa !important
}

.bg-light-dark {
    background: #e9e9ea;
    color: #212529
}

.icon-svg-dark {
    fill: #e9e9ea;
    stroke: #212529
}

.material-icons-two-tone.text-dark {
    background-color: #212529
}

.text-hover-dark:hover {
    color: #212529 !important
}

.b-theme {
    border: 1px solid #f36
}

.border-bottom-theme td {
    border-bottom: 1px solid #f36
}

.border-bottom-theme th {
    border-bottom: 1px solid #f36 !important
}

.fill-theme {
    fill: #f36
}

.b-primary {
    border: 1px solid #4680ff
}

.border-bottom-primary td {
    border-bottom: 1px solid #4680ff
}

.border-bottom-primary th {
    border-bottom: 1px solid #4680ff !important
}

.fill-primary {
    fill: #4680ff
}

.b-secondary {
    border: 1px solid #5b6b79
}

.border-bottom-secondary td {
    border-bottom: 1px solid #5b6b79
}

.border-bottom-secondary th {
    border-bottom: 1px solid #5b6b79 !important
}

.fill-secondary {
    fill: #5b6b79
}

.b-success {
    border: 1px solid #2ca87f
}

.border-bottom-success td {
    border-bottom: 1px solid #2ca87f
}

.border-bottom-success th {
    border-bottom: 1px solid #2ca87f !important
}

.fill-success {
    fill: #2ca87f
}

.b-info {
    border: 1px solid #3ec9d6
}

.border-bottom-info td {
    border-bottom: 1px solid #3ec9d6
}

.border-bottom-info th {
    border-bottom: 1px solid #3ec9d6 !important
}

.fill-info {
    fill: #3ec9d6
}

.b-warning {
    border: 1px solid #e58a00
}

.border-bottom-warning td {
    border-bottom: 1px solid #e58a00
}

.border-bottom-warning th {
    border-bottom: 1px solid #e58a00 !important
}

.fill-warning {
    fill: #e58a00
}

.b-danger {
    border: 1px solid #dc2626
}

.border-bottom-danger td {
    border-bottom: 1px solid #dc2626
}

.border-bottom-danger th {
    border-bottom: 1px solid #dc2626 !important
}

.fill-danger {
    fill: #dc2626
}

.b-light {
    border: 1px solid #f8f9fa
}

.border-bottom-light td {
    border-bottom: 1px solid #f8f9fa
}

.border-bottom-light th {
    border-bottom: 1px solid #f8f9fa !important
}

.fill-light {
    fill: #f8f9fa
}

.b-dark {
    border: 1px solid #212529
}

.border-bottom-dark td {
    border-bottom: 1px solid #212529
}

.border-bottom-dark th {
    border-bottom: 1px solid #212529 !important
}

.fill-dark {
    fill: #212529
}

.bg-light-facebook {
    background: #ecf0f7;
    color: #4267b2
}

.bg-facebook {
    background: #4267b2
}

.text-facebook {
    color: #4267b2
}

.bg-light-twitter {
    background: #ecf9ff;
    color: #42c0fb
}

.bg-twitter {
    background: #42c0fb
}

.text-twitter {
    color: #42c0fb
}

.bg-light-dribbble {
    background: #fdedf3;
    color: #ec4a89
}

.bg-dribbble {
    background: #ec4a89
}

.text-dribbble {
    color: #ec4a89
}

.bg-light-pinterest {
    background: #f9e9ea;
    color: #bf2131
}

.bg-pinterest {
    background: #bf2131
}

.text-pinterest {
    color: #bf2131
}

.bg-light-youtube {
    background: #fceae8;
    color: #e0291d
}

.bg-youtube {
    background: #e0291d
}

.text-youtube {
    color: #e0291d
}

.bg-light-googleplus {
    background: #f9ecea;
    color: #c73e2e
}

.bg-googleplus {
    background: #c73e2e
}

.text-googleplus {
    color: #c73e2e
}

.bg-light-instagram {
    background: #f7f2ef;
    color: #aa7c62
}

.bg-instagram {
    background: #aa7c62
}

.text-instagram {
    color: #aa7c62
}

.bg-light-viber {
    background: #f2eef5;
    color: #7b519d
}

.bg-viber {
    background: #7b519d
}

.text-viber {
    color: #7b519d
}

.bg-light-behance {
    background: #e6eeff;
    color: #0057ff
}

.bg-behance {
    background: #0057ff
}

.text-behance {
    color: #0057ff
}

.bg-light-dropbox {
    background: #ebf2ff;
    color: #3380ff
}

.bg-dropbox {
    background: #3380ff
}

.text-dropbox {
    color: #3380ff
}

.bg-light-linkedin {
    background: #e6f1f8;
    color: #0077b5
}

.bg-linkedin {
    background: #0077b5
}

.text-linkedin {
    color: #0077b5
}

.bg-light-amazon {
    background: #e6e6e6;
    color: #000
}

.bg-amazon {
    background: #000
}

.text-amazon {
    color: #000
}

.font-hairline {
    font-weight: 100 !important
}

.font-thin {
    font-weight: 200 !important
}

.font-light {
    font-weight: 300 !important
}

.font-normal {
    font-weight: 400 !important
}

.font-medium {
    font-weight: 500 !important
}

.font-semibold {
    font-weight: 600 !important
}

.font-bold {
    font-weight: 700 !important
}

.font-extrabold {
    font-weight: 800 !important
}

.font-black {
    font-weight: 900 !important
}

.text-xs {
    font-size: .625rem !important
}

.text-sm {
    font-size: .75rem !important
}

.text-md {
    font-size: .8125rem !important
}

.text-base {
    font-size: .875rem !important
}

.text-lg {
    font-size: 1rem !important
}

.text-xl {
    font-size: 1.125rem !important
}

.text-2xl {
    font-size: 1.25rem !important
}

.text-3xl {
    font-size: 1.5rem !important
}

.text-4xl {
    font-size: 2rem !important
}

.text-5xl {
    font-size: 2.25rem !important
}

.text-6xl {
    font-size: 2.5rem !important
}

.text-7xl {
    font-size: 3rem !important
}

.text-8xl {
    font-size: 4rem !important
}

.text-9xl {
    font-size: 6rem !important
}

.text-10xl {
    font-size: 8rem !important
}

.lowercase {
    text-transform: lowercase !important
}

.capitalize {
    text-transform: capitalize !important
}

.normal-case {
    text-transform: none !important
}

.bg-blue-100 {
    background: #c8d9ff
}

.text-blue-100 {
    color: #c8d9ff
}

.bg-blue-200 {
    background: #a3c0ff
}

.text-blue-200 {
    color: #a3c0ff
}

.bg-blue-300 {
    background: #7ea6ff
}

.text-blue-300 {
    color: #7ea6ff
}

.bg-blue-400 {
    background: #6293ff
}

.text-blue-400 {
    color: #6293ff
}

.bg-blue-500 {
    background: #4680ff
}

.text-blue-500 {
    color: #4680ff
}

.bg-blue-600 {
    background: #3f78ff
}

.text-blue-600 {
    color: #3f78ff
}

.bg-blue-700 {
    background: #376dff
}

.text-blue-700 {
    color: #376dff
}

.bg-blue-800 {
    background: #2f63ff
}

.text-blue-800 {
    color: #2f63ff
}

.bg-blue-900 {
    background: #2050ff
}

.text-blue-900 {
    color: #2050ff
}

.bg-indigo-100 {
    background: #d1b7fb
}

.text-indigo-100 {
    color: #d1b7fb
}

.bg-indigo-200 {
    background: #b388f9
}

.text-indigo-200 {
    color: #b388f9
}

.bg-indigo-300 {
    background: #9458f6
}

.text-indigo-300 {
    color: #9458f6
}

.bg-indigo-400 {
    background: #7d34f4
}

.text-indigo-400 {
    color: #7d34f4
}

.bg-indigo-500 {
    background: #6610f2
}

.text-indigo-500 {
    color: #6610f2
}

.bg-indigo-600 {
    background: #5e0ef0
}

.text-indigo-600 {
    color: #5e0ef0
}

.bg-indigo-700 {
    background: #530cee
}

.text-indigo-700 {
    color: #530cee
}

.bg-indigo-800 {
    background: #4909ec
}

.text-indigo-800 {
    color: #4909ec
}

.bg-indigo-900 {
    background: #3805e8
}

.text-indigo-900 {
    color: #3805e8
}

.bg-purple-100 {
    background: #d1c4e9
}

.text-purple-100 {
    color: #d1c4e9
}

.bg-purple-200 {
    background: #b39ddb
}

.text-purple-200 {
    color: #b39ddb
}

.bg-purple-300 {
    background: #9575cd
}

.text-purple-300 {
    color: #9575cd
}

.bg-purple-400 {
    background: #7e58c2
}

.text-purple-400 {
    color: #7e58c2
}

.bg-purple-500 {
    background: #673ab7
}

.text-purple-500 {
    color: #673ab7
}

.bg-purple-600 {
    background: #5f34b0
}

.text-purple-600 {
    color: #5f34b0
}

.bg-purple-700 {
    background: #542ca7
}

.text-purple-700 {
    color: #542ca7
}

.bg-purple-800 {
    background: #4a259f
}

.text-purple-800 {
    color: #4a259f
}

.bg-purple-900 {
    background: #391890
}

.text-purple-900 {
    color: #391890
}

.bg-pink-100 {
    background: #f8c5dd
}

.text-pink-100 {
    color: #f8c5dd
}

.bg-pink-200 {
    background: #f49fc6
}

.text-pink-200 {
    color: #f49fc6
}

.bg-pink-300 {
    background: #ef78af
}

.text-pink-300 {
    color: #ef78af
}

.bg-pink-400 {
    background: #eb5b9d
}

.text-pink-400 {
    color: #eb5b9d
}

.bg-pink-500 {
    background: #e83e8c
}

.text-pink-500 {
    color: #e83e8c
}

.bg-pink-600 {
    background: #e53884
}

.text-pink-600 {
    color: #e53884
}

.bg-pink-700 {
    background: #e23079
}

.text-pink-700 {
    color: #e23079
}

.bg-pink-800 {
    background: #de286f
}

.text-pink-800 {
    color: #de286f
}

.bg-pink-900 {
    background: #d81b5c
}

.text-pink-900 {
    color: #d81b5c
}

.bg-red-100 {
    background: #f5bebe
}

.text-red-100 {
    color: #f5bebe
}

.bg-red-200 {
    background: #ee9393
}

.text-red-200 {
    color: #ee9393
}

.bg-red-300 {
    background: #e76767
}

.text-red-300 {
    color: #e76767
}

.bg-red-400 {
    background: #e14747
}

.text-red-400 {
    color: #e14747
}

.bg-red-500 {
    background: #dc2626
}

.text-red-500 {
    color: #dc2626
}

.bg-red-600 {
    background: #d82222
}

.text-red-600 {
    color: #d82222
}

.bg-red-700 {
    background: #d31c1c
}

.text-red-700 {
    color: #d31c1c
}

.bg-red-800 {
    background: #ce1717
}

.text-red-800 {
    color: #ce1717
}

.bg-red-900 {
    background: #c50d0d
}

.text-red-900 {
    color: #c50d0d
}

.bg-orange-100 {
    background: #fed8b9
}

.text-orange-100 {
    color: #fed8b9
}

.bg-orange-200 {
    background: #febf8a
}

.text-orange-200 {
    color: #febf8a
}

.bg-orange-300 {
    background: #fea55b
}

.text-orange-300 {
    color: #fea55b
}

.bg-orange-400 {
    background: #fd9137
}

.text-orange-400 {
    color: #fd9137
}

.bg-orange-500 {
    background: #fd7e14
}

.text-orange-500 {
    color: #fd7e14
}

.bg-orange-600 {
    background: #fd7612
}

.text-orange-600 {
    color: #fd7612
}

.bg-orange-700 {
    background: #fc6b0e
}

.text-orange-700 {
    color: #fc6b0e
}

.bg-orange-800 {
    background: #fc610b
}

.text-orange-800 {
    color: #fc610b
}

.bg-orange-900 {
    background: #fc4e06
}

.text-orange-900 {
    color: #fc4e06
}

.bg-yellow-100 {
    background: #f7dcb3
}

.text-yellow-100 {
    color: #f7dcb3
}

.bg-yellow-200 {
    background: #f2c580
}

.text-yellow-200 {
    color: #f2c580
}

.bg-yellow-300 {
    background: #edad4d
}

.text-yellow-300 {
    color: #edad4d
}

.bg-yellow-400 {
    background: #e99c26
}

.text-yellow-400 {
    color: #e99c26
}

.bg-yellow-500 {
    background: #e58a00
}

.text-yellow-500 {
    color: #e58a00
}

.bg-yellow-600 {
    background: #e28200
}

.text-yellow-600 {
    color: #e28200
}

.bg-yellow-700 {
    background: #de7700
}

.text-yellow-700 {
    color: #de7700
}

.bg-yellow-800 {
    background: #da6d00
}

.text-yellow-800 {
    color: #da6d00
}

.bg-yellow-900 {
    background: #d35a00
}

.text-yellow-900 {
    color: #d35a00
}

.bg-green-100 {
    background: #c0e5d9
}

.text-green-100 {
    color: #c0e5d9
}

.bg-green-200 {
    background: #96d4bf
}

.text-green-200 {
    color: #96d4bf
}

.bg-green-300 {
    background: #6bc2a5
}

.text-green-300 {
    color: #6bc2a5
}

.bg-green-400 {
    background: #4cb592
}

.text-green-400 {
    color: #4cb592
}

.bg-green-500 {
    background: #2ca87f
}

.text-green-500 {
    color: #2ca87f
}

.bg-green-600 {
    background: #27a077
}

.text-green-600 {
    color: #27a077
}

.bg-green-700 {
    background: #21976c
}

.text-green-700 {
    color: #21976c
}

.bg-green-800 {
    background: #1b8d62
}

.text-green-800 {
    color: #1b8d62
}

.bg-green-900 {
    background: #107d4f
}

.text-green-900 {
    color: #107d4f
}

.bg-teal-100 {
    background: #b3d9d9
}

.text-teal-100 {
    color: #b3d9d9
}

.bg-teal-200 {
    background: #80c0c0
}

.text-teal-200 {
    color: #80c0c0
}

.bg-teal-300 {
    background: #4da6a6
}

.text-teal-300 {
    color: #4da6a6
}

.bg-teal-400 {
    background: #269393
}

.text-teal-400 {
    color: #269393
}

.bg-teal-500 {
    background: teal
}

.text-teal-500 {
    color: teal
}

.bg-teal-600 {
    background: #007878
}

.text-teal-600 {
    color: #007878
}

.bg-teal-700 {
    background: #006d6d
}

.text-teal-700 {
    color: #006d6d
}

.bg-teal-800 {
    background: #006363
}

.text-teal-800 {
    color: #006363
}

.bg-teal-900 {
    background: #005050
}

.text-teal-900 {
    color: #005050
}

.bg-cyan-100 {
    background: #c5eff3
}

.text-cyan-100 {
    color: #c5eff3
}

.bg-cyan-200 {
    background: #9fe4eb
}

.text-cyan-200 {
    color: #9fe4eb
}

.bg-cyan-300 {
    background: #78d9e2
}

.text-cyan-300 {
    color: #78d9e2
}

.bg-cyan-400 {
    background: #5bd1dc
}

.text-cyan-400 {
    color: #5bd1dc
}

.bg-cyan-500 {
    background: #3ec9d6
}

.text-cyan-500 {
    color: #3ec9d6
}

.bg-cyan-600 {
    background: #38c3d1
}

.text-cyan-600 {
    color: #38c3d1
}

.bg-cyan-700 {
    background: #30bccc
}

.text-cyan-700 {
    color: #30bccc
}

.bg-cyan-800 {
    background: #28b5c6
}

.text-cyan-800 {
    color: #28b5c6
}

.bg-cyan-900 {
    background: #1ba9bc
}

.text-cyan-900 {
    color: #1ba9bc
}

.bg-gray-100 {
    background: #f8f9fa
}

.text-gray-100 {
    color: #f8f9fa
}

.bg-gray-200 {
    background: #f3f5f7
}

.text-gray-200 {
    color: #f3f5f7
}

.bg-gray-300 {
    background: #dbe0e5
}

.text-gray-300 {
    color: #dbe0e5
}

.bg-gray-400 {
    background: #bec8d0
}

.text-gray-400 {
    color: #bec8d0
}

.bg-gray-500 {
    background: #8996a4
}

.text-gray-500 {
    color: #8996a4
}

.bg-gray-600 {
    background: #5b6b79
}

.text-gray-600 {
    color: #5b6b79
}

.bg-gray-700 {
    background: #3e4853
}

.text-gray-700 {
    color: #3e4853
}

.bg-gray-800 {
    background: #1d2630
}

.text-gray-800 {
    color: #1d2630
}

.bg-gray-900 {
    background: #131920
}

.text-gray-900 {
    color: #131920
}

:focus {
    outline: 0
}

a:hover {
    --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
    outline: 0;
    text-decoration: none
}

a:not([href]) {
    color: inherit
}

p {
    font-size: 14px;
   
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #000
}

b,
strong {
    font-weight: 600
}

.breadcrumb-default-icon .breadcrumb-item+.breadcrumb-item::before {
    position: relative;
    top: 2px
}

.btn-page .btn,
.btn-page .introjs-tooltip .introjs-button,
.introjs-tooltip .btn-page .introjs-button {
    margin-right: 5px;
    margin-bottom: 5px
}

.btn-page .btn-group .btn,
.btn-page .btn-group .introjs-tooltip .introjs-button,
.introjs-tooltip .btn-page .btn-group .introjs-button {
    margin-right: 0;
    margin-bottom: 0
}

.btn-page .btn-group .btn:last-child,
.btn-page .btn-group .introjs-tooltip .introjs-button:last-child,
.introjs-tooltip .btn-page .btn-group .introjs-button:last-child {
    border-left: none
}

.btn-page .btn-group label:first-of-type {
    border-right: none
}

.material-icons-two-tone {
    background-color: var(--bs-body-color);
    -webkit-text-fill-color: transparent;
    vertical-align: text-bottom;
    -webkit-background-clip: text
}

.material-icons-two-tone.text-white {
    background-color: #fff
}

.img-radius {
    border-radius: 50%
}

.pc-icon:not([class*=wid-]) {
    width: 22px
}

.pc-icon:not([class*=hei-]) {
    height: 22px
}

.peity-full-width .peity {
    width: 100%;
    height: 125px
}

.hljs {
    display: block;
    overflow-x: auto;
    padding: .5em;
    color: #abb2bf;
    background: #282c34
}

.hljs-comment,
.hljs-quote {
    color: #5c6370;
    font-style: italic
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword {
    color: #c678dd
}

.hljs-deletion,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-subst {
    color: #e06c75
}

.hljs-literal {
    color: #56b6c2
}

.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
    color: #98c379
}

.hljs-built_in,
.hljs-class .hljs-title {
    color: #e6c07b
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
    color: #d19a66
}

.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
    color: #61aeee
}

.hljs-emphasis {
    font-style: italic
}

.hljs-strong {
    font-weight: 700
}

.hljs-link {
    text-decoration: underline
}

.bd-example-row {
    background: #f8f9fa;
    padding: 15px;
    margin-bottom: 15px
}

.bd-example-tabs .nav-tabs,
.form-v1 .form-label-group,
.highlight {
    margin-bottom: 1rem
}

.bd-example-row .row>.col,
.bd-example-row .row>[class^=col-] {
    padding-top: .75rem;
    padding-bottom: .75rem;
    background-color: rgba(86, 61, 124, .15);
    border: 1px solid rgba(86, 61, 124, .2)
}

.bd-example-row .flex-items-bottom,
.bd-example-row .flex-items-middle,
.bd-example-row .flex-items-top {
    min-height: 6rem;
    background-color: rgba(255, 0, 0, .1)
}

.bd-example-row-flex-cols .row {
    min-height: 10rem;
    background-color: rgba(255, 0, 0, .1)
}

.bd-example {
    position: relative;
    padding: 1rem;
    margin: 1rem;
    border: solid #ddd;
    border-width: .2rem 0 0
}

@media only screen and (max-height:575px) {
    .bd-example {
        padding: 1.5rem;
        margin-right: 0;
        margin-left: 0;
        border-width: .2rem
    }
}

.modal {
    z-index: 1081
}

.modal .popover,
.modal .tooltip {
    z-index: 1073
}

.modal-backdrop {
    z-index: 1071
}

.bd-example-modal {
    background-color: var(--bs-body-bg)
}

.bd-example-modal .modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block
}

.bd-example-modal .modal-dialog {
    left: auto;
    margin-right: auto;
    margin-left: auto;
    padding: 0 20px
}

.bd-example-modal .offcanvas {
    position: static;
    display: block;
    height: 200px;
    visibility: visible;
    transform: translate(0)
}

.modal .bd-example-row {
    padding: 15px 25px
}

.highlight {
    padding: 1rem;
    background-color: #f3f2fb;
    -ms-overflow-style: -ms-autohiding-scrollbar
}

.bd-content .highlight {
    margin-right: 15px;
    margin-left: 15px
}

@media only screen and (max-height:575px) {
    .highlight {
        padding: 1.5rem
    }

    .bd-content .highlight {
        margin-right: 0;
        margin-left: 0
    }
}

.highlight pre {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    background-color: transparent;
    border: 0
}

.highlight pre code {
    font-size: inherit;
    color: #333
}

svg.feather:not([class*=wid-]) {
    width: 20px
}

.bar,
.page-loader,
pre[class*=language-]>code {
    width: 100%
}

svg.feather:not([class*=hei-]) {
    height: 20px
}

@media (min-width:1600px) {
    .container {
        max-width: 1540px
    }
}

pre[class*=language-]:after,
pre[class*=language-]:before {
    display: none
}

:not(pre)>code[class*=language-],
pre[class*=language-] {
    background: 0 0;
    display: flex;
    margin: 0
}

.blockquote {
    padding: .5rem 1rem
}

.fixed-button {
    position: fixed;
    bottom: -50px;
    right: 30px;
    box-shadow: 0 15px 50px rgba(0, 0, 0, .35);
    opacity: 0;
    z-index: 9;
    transition: all .5s ease
}

.fixed-button.active {
    bottom: 50px;
    opacity: 1
}

.color-card .card-body {
    margin: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    background: rgba(107, 117, 125, .08);
    border-radius: 8px
}

.color-block {
    border-radius: 8px;
    margin: 4px 0
}

.blockquote-footer,
.row>div .color-block:first-child {
    margin-top: 0
}

.pc-sidebar .pc-caption label,
.pc-sidebar ul,
.row>div .color-block:last-child {
    margin-bottom: 0
}

.color-block.bg-blue-100 {
    color: #212529
}

.color-block.bg-blue-200,
.color-block.bg-blue-300,
.color-block.bg-blue-400,
.color-block.bg-blue-500,
.color-block.bg-blue-600,
.color-block.bg-blue-700,
.color-block.bg-blue-800,
.color-block.bg-blue-900,
.color-block.bg-indigo-100,
.color-block.bg-indigo-200,
.color-block.bg-indigo-300,
.color-block.bg-indigo-400,
.color-block.bg-indigo-500,
.color-block.bg-indigo-600,
.color-block.bg-indigo-700,
.color-block.bg-indigo-800,
.color-block.bg-indigo-900,
.color-block.bg-purple-100,
.color-block.bg-purple-200,
.color-block.bg-purple-300,
.color-block.bg-purple-400,
.color-block.bg-purple-500,
.color-block.bg-purple-600,
.color-block.bg-purple-700,
.color-block.bg-purple-800,
.color-block.bg-purple-900 {
    color: #fff
}

.color-block.text-blue-100 {
    background-color: #212529
}

.color-block.text-blue-200,
.color-block.text-blue-300,
.color-block.text-blue-400,
.color-block.text-blue-500,
.color-block.text-blue-600,
.color-block.text-blue-700,
.color-block.text-blue-800,
.color-block.text-blue-900,
.color-block.text-indigo-100,
.color-block.text-indigo-200,
.color-block.text-indigo-300,
.color-block.text-indigo-400,
.color-block.text-indigo-500,
.color-block.text-indigo-600,
.color-block.text-indigo-700,
.color-block.text-indigo-800,
.color-block.text-indigo-900,
.color-block.text-purple-100,
.color-block.text-purple-200,
.color-block.text-purple-300,
.color-block.text-purple-400,
.color-block.text-purple-500,
.color-block.text-purple-600,
.color-block.text-purple-700,
.color-block.text-purple-800,
.color-block.text-purple-900 {
    background-color: #fff
}

.color-block.bg-pink-100 {
    color: #212529
}

.color-block.bg-pink-200,
.color-block.bg-pink-300,
.color-block.bg-pink-400,
.color-block.bg-pink-500,
.color-block.bg-pink-600,
.color-block.bg-pink-700,
.color-block.bg-pink-800,
.color-block.bg-pink-900,
.color-block.bg-red-100,
.color-block.bg-red-200,
.color-block.bg-red-300,
.color-block.bg-red-400,
.color-block.bg-red-500,
.color-block.bg-red-600,
.color-block.bg-red-700,
.color-block.bg-red-800,
.color-block.bg-red-900 {
    color: #fff
}

.color-block.text-pink-100 {
    background-color: #212529
}

.color-block.text-pink-200,
.color-block.text-pink-300,
.color-block.text-pink-400,
.color-block.text-pink-500,
.color-block.text-pink-600,
.color-block.text-pink-700,
.color-block.text-pink-800,
.color-block.text-pink-900,
.color-block.text-red-100,
.color-block.text-red-200,
.color-block.text-red-300,
.color-block.text-red-400,
.color-block.text-red-500,
.color-block.text-red-600,
.color-block.text-red-700,
.color-block.text-red-800,
.color-block.text-red-900 {
    background-color: #fff
}

.color-block.bg-orange-100 {
    color: #212529
}

.color-block.bg-orange-200,
.color-block.bg-orange-300,
.color-block.bg-orange-400,
.color-block.bg-orange-500,
.color-block.bg-orange-600,
.color-block.bg-orange-700,
.color-block.bg-orange-800,
.color-block.bg-orange-900 {
    color: #fff
}

.color-block.text-orange-100 {
    background-color: #212529
}

.color-block.text-orange-200,
.color-block.text-orange-300,
.color-block.text-orange-400,
.color-block.text-orange-500,
.color-block.text-orange-600,
.color-block.text-orange-700,
.color-block.text-orange-800,
.color-block.text-orange-900 {
    background-color: #fff
}

.color-block.bg-yellow-100 {
    color: #212529
}

.color-block.bg-yellow-200,
.color-block.bg-yellow-300,
.color-block.bg-yellow-400,
.color-block.bg-yellow-500,
.color-block.bg-yellow-600,
.color-block.bg-yellow-700,
.color-block.bg-yellow-800,
.color-block.bg-yellow-900 {
    color: #fff
}

.color-block.text-yellow-100 {
    background-color: #212529
}

.color-block.text-yellow-200,
.color-block.text-yellow-300,
.color-block.text-yellow-400,
.color-block.text-yellow-500,
.color-block.text-yellow-600,
.color-block.text-yellow-700,
.color-block.text-yellow-800,
.color-block.text-yellow-900 {
    background-color: #fff
}

.color-block.bg-green-100 {
    color: #212529
}

.color-block.bg-green-200,
.color-block.bg-green-300,
.color-block.bg-green-400,
.color-block.bg-green-500,
.color-block.bg-green-600,
.color-block.bg-green-700,
.color-block.bg-green-800,
.color-block.bg-green-900 {
    color: #fff
}

.color-block.text-green-100 {
    background-color: #212529
}

.color-block.text-green-200,
.color-block.text-green-300,
.color-block.text-green-400,
.color-block.text-green-500,
.color-block.text-green-600,
.color-block.text-green-700,
.color-block.text-green-800,
.color-block.text-green-900 {
    background-color: #fff
}

.color-block.bg-teal-100 {
    color: #212529
}

.color-block.bg-teal-200,
.color-block.bg-teal-300,
.color-block.bg-teal-400,
.color-block.bg-teal-500,
.color-block.bg-teal-600,
.color-block.bg-teal-700,
.color-block.bg-teal-800,
.color-block.bg-teal-900 {
    color: #fff
}

.color-block.text-teal-100 {
    background-color: #212529
}

.color-block.text-teal-200,
.color-block.text-teal-300,
.color-block.text-teal-400,
.color-block.text-teal-500,
.color-block.text-teal-600,
.color-block.text-teal-700,
.color-block.text-teal-800,
.color-block.text-teal-900 {
    background-color: #fff
}

.color-block.bg-cyan-100,
.color-block.bg-cyan-200 {
    color: #212529
}

.color-block.text-cyan-100,
.color-block.text-cyan-200 {
    background-color: #212529
}

.color-block.bg-cyan-300,
.color-block.bg-cyan-400,
.color-block.bg-cyan-500,
.color-block.bg-cyan-600,
.color-block.bg-cyan-700,
.color-block.bg-cyan-800,
.color-block.bg-cyan-900 {
    color: #fff
}

.color-block.text-cyan-300,
.color-block.text-cyan-400,
.color-block.text-cyan-500,
.color-block.text-cyan-600,
.color-block.text-cyan-700,
.color-block.text-cyan-800,
.color-block.text-cyan-900 {
    background-color: #fff
}

.color-block.bg-gray-100,
.color-block.bg-gray-200,
.color-block.bg-gray-300 {
    color: #212529
}

.color-block.text-gray-100,
.color-block.text-gray-200,
.color-block.text-gray-300 {
    background-color: #212529
}

.color-block.bg-gray-400,
.color-block.bg-gray-500,
.color-block.bg-gray-600,
.color-block.bg-gray-700,
.color-block.bg-gray-800,
.color-block.bg-gray-900,
.pc-sidebar .pc-badge {
    color: #fff
}

.color-block.text-gray-400,
.color-block.text-gray-500,
.color-block.text-gray-600,
.color-block.text-gray-700,
.color-block.text-gray-800,
.color-block.text-gray-900 {
    background-color: #fff
}

.card .toast-container {
    position: relative
}

.toast-container {
    z-index: 1022
}

.carousel-indicators li::marker {
    font-size: 0
}

.pagination .page-item .page-link {
    border-radius: 0
}

.pagination .page-item:first-child .page-link {
    border-radius: var(--bs-pagination-border-radius) 0 0 var(--bs-pagination-border-radius)
}

.pagination .page-item:last-child .page-link {
    border-radius: 0 var(--bs-pagination-border-radius) var(--bs-pagination-border-radius) 0
}

.customer-body {
    height: calc(100% - 60px)
}

.offcanvas-bottom,
.offcanvas-top {
    min-height: 240px
}

.page-loader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000000;
    pointer-events: none;
    animation: loading 2s ease-in-out;
    animation-iteration-count: infinite;
    opacity: 1;
    transition: width 1.35s ease-in-out, opacity 350ms linear, left 50ms ease-in-out
}

.bar {
    background-color: var(--bs-primary);
    height: 5px
}

@keyframes loading {
    0% {
        width: 0;
        left: 0
    }

    50% {
        width: 100%;
        left: 0
    }

    100% {
        width: 100%;
        left: 100%
    }
}

.pc-sidebar {
    background: var(--pc-sidebar-background);
    width: 280px;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1026;
    overflow: hidden;
    box-shadow: var(--pc-sidebar-shadow);
    border-right: var(--pc-sidebar-border)
}

.pc-sidebar .navbar-wrapper {
    width: 280px;
    background: inherit
}

.pc-sidebar ul {
    list-style: none
}

.pc-sidebar .pc-caption {
    color: var(--pc-sidebar-caption-color);
    display: block;
    padding: 24px 23px 8px !important;
    text-transform: uppercase;
    position: relative;
    line-height: 1.66;
    font-size: 11px;
    font-weight: 600
}

.pc-sidebar .pc-caption:first-child {
    padding-top: 10px !important
}

.pc-sidebar .pc-caption i,
.pc-sidebar .pc-caption svg,
.pc-sidebar .pc-caption:first-child::after {
    display: none
}

.pc-sidebar .pc-caption span:not(.badge) {
    display: block;
    color: #67758a;
    font-size: 14px;
    font-weight: 500
}

.pc-sidebar .pc-micon {
    margin-right: 15px;
    height: 24px;
    width: 24px;
    display: inline-block;
    vertical-align: middle;
    text-align: center
}

.pc-sidebar .pc-micon i {
    font-size: 18px;
    vertical-align: super
}

.pc-sidebar .pc-micon i.material-icons-two-tone {
    font-size: 22px;
    display: inline-block
}

.pc-sidebar .pc-micon>svg {
    width: 22px;
    height: 22px;
    display: inline-block
}

.pc-sidebar .pc-link {
    display: block;
    padding: 14px 20px;
    color: var(--pc-sidebar-color);
    position: relative;
    font-size: 14px;
    font-weight: 400
}



.pc-sidebar .pc-link.active,
.pc-sidebar .pc-link:focus,
.pc-sidebar .pc-link:hover {
    text-decoration: none
}

.pc-sidebar .pc-navbar>.pc-item {
    margin: 0 10px
}

.pc-sidebar .pc-navbar>.pc-item:hover {
    background-color: #edf2ff;
    border-radius: 8px;
}

.pc-sidebar .pc-navbar>.pc-item.active>.pc-link {
    background: var(--bs-primary);
    color: #fff;
    border-radius: 6px;
}



.pc-sidebar .pc-navbar>.pc-item .pc-submenu .pc-item>.pc-link:after {
    content: "";
    border-radius: 50%;
    position: absolute;
    top: 20px;
    width: 5px;
    height: 5px;
    /* background: var(--pc-sidebar-color); */
    opacity: .8;
    transition: all 80ms cubic-bezier(.37, .24, .53, .99)
}

.pc-sidebar .pc-navbar>.pc-item .pc-submenu .pc-item.active>.pc-link,
.pc-sidebar .pc-navbar>.pc-item .pc-submenu .pc-item.pc-trigger>.pc-link {
    font-weight: 500
}

.pc-sidebar .pc-navbar>.pc-item .pc-submenu .pc-item.active>.pc-link:after,
.pc-sidebar .pc-navbar>.pc-item .pc-submenu .pc-item.pc-trigger>.pc-link:after,
.pc-sidebar .pc-navbar>.pc-item .pc-submenu .pc-item:focus>.pc-link:after,
.pc-sidebar .pc-navbar>.pc-item .pc-submenu .pc-item:hover>.pc-link:after {
    opacity: 1;
    transform: scale(1.2);
    background: var(--pc-sidebar-active-color)
}

.pc-sidebar .pc-navbar>.pc-item .pc-submenu:after {
    left: 30px
}

.pc-sidebar .pc-navbar>.pc-item .pc-submenu .pc-link {
    padding: 12px 30px 12px 60px
}

.pc-sidebar .pc-navbar>.pc-item .pc-submenu .pc-link:after {
    left: 28px
}

.pc-sidebar .pc-navbar>.pc-item .pc-submenu .pc-submenu:after {
    left: 46px
}

.pc-sidebar .pc-navbar>.pc-item .pc-submenu .pc-submenu .pc-link {
    padding: 12px 30px 12px 80px
}

.pc-sidebar .pc-navbar>.pc-item .pc-submenu .pc-submenu .pc-link:after {
    left: 62px
}

.pc-sidebar .pc-navbar>.pc-item .pc-submenu .pc-submenu .pc-submenu:after {
    left: 63px
}

.pc-sidebar .pc-navbar>.pc-item .pc-submenu .pc-submenu .pc-submenu .pc-link {
    padding: 12px 30px 12px 95px
}

.pc-sidebar .pc-navbar>.pc-item .pc-submenu .pc-submenu .pc-submenu .pc-link:after {
    left: 79px
}

.pc-sidebar .pc-item.disabled a {
    cursor: not-allowed;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.pc-sidebar .pc-arrow {
    float: right;
    display: inline-block;
    transition: all .2s ease-in-out;
    position: relative
}

.pc-sidebar .pc-arrow::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.pc-sidebar .pc-arrow>svg {
    width: 14px;
    height: 14px
}

.pc-sidebar .pc-badge {
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 10px;
    line-height: 1;
    float: right;
    background: var(--bs-primary)
}

.pc-sidebar .m-header {
    height: 74px;
    display: flex;
    align-items: center;
    padding: 16px 24px
}

.pc-sidebar .m-header .logo-sm {
    display: none
}

.pc-sidebar .navbar-content {
    position: relative;
    height: calc(100vh - 74px);
    padding: 10px 0
}

.pc-sidebar .card {
    box-shadow: var(--bs-card-box-shadow);
    margin: 15px
}

.pc-sidebar .card.pc-user-card {
    margin: 0 15px 15px;
    box-shadow: none;
    background: var(--pc-sidebar-user-background)
}

.pc-sidebar .card.pc-user-card .card-body {
    padding: 20px
}

.pc-sidebar .card.pc-user-card .pc-user-links a {
    display: flex;
    align-items: center;
    color: var(--pc-sidebar-color);
    padding: 8px 0
}

.pc-sidebar .card.pc-user-card .pc-user-links a i {
    font-size: 18px;
    margin-right: 10px
}

.pc-sidebar .card.pc-user-card .pc-user-links a:focus,
.pc-sidebar .card.pc-user-card .pc-user-links a:hover {
    color: var(--pc-sidebar-active-color)
}

.pc-sidebar .pc-hasmenu:not(.pc-trigger)>.pc-submenu {
    display: none
}

.pc-sidebar .pc-hasmenu.pc-trigger>.pc-submenu {
    display: block
}

.pc-sidebar .pc-hasmenu.pc-trigger>.pc-link>.pc-arrow {
    transform: rotate(90deg)
}


@media (min-width:1025px) {
    .navbar-overlay .pc-sidebar.pc-over-menu-active .navbar-wrapper {
        position: relative;
        z-index: 5
    }

    .navbar-overlay .pc-sidebar:not(.pc-over-menu-active) {
        left: -280px;
        box-shadow: none
    }

    .pc-sidebar {
        transition: width .2s ease
    }

    .pc-sidebar~.pc-header {
        transition: left .2s ease
    }

    .pc-sidebar~.pc-container,
    .pc-sidebar~.pc-footer {
        transition: margin-left .2s ease
    }

    .pc-sidebar.pc-sidebar-hide {
        width: 0;
        --pc-sidebar-border: none
    }

    .pc-sidebar.pc-sidebar-hide~.pc-header {
        left: 0
    }

    .pc-sidebar.pc-sidebar-hide~.pc-container,
    .pc-sidebar.pc-sidebar-hide~.pc-footer {
        margin-left: 0
    }

    .pc-header .pc-h-item.pc-sidebar-popup {
        display: none
    }
}

@media (max-width:1024px) {
    .pc-header .pc-h-item.pc-sidebar-collapse {
        display: none
    }

    .pc-sidebar {
        left: -280px;
        box-shadow: none;
        background: var(--bs-body-bg);
        top: 0;
        transition: all .2s ease-in-out
    }

    .pc-sidebar.mob-sidebar-active {
        left: 0
    }

    .pc-sidebar.mob-sidebar-active .navbar-wrapper {
        position: relative;
        z-index: 5;
        background: inherit
    }
}


.hamburger-inner::after,
.hamburger-inner::before,
.pc-header .pc-head-link::before {
    content: ""
}

.pc-header {
    background: var(--pc-header-background);
    color: var(--pc-header-color);
    min-height: 74px;
    box-shadow: var(--pc-header-shadow);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    position: fixed;
    left: 280px;
    right: 0;
    z-index: 99;
    display: flex
}

.pc-header ul {
    margin-bottom: 0;
    display: inline-flex
}

.pc-header .m-header {
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 280px;
    padding: 16px 10px 16px 24px
}

.pc-header .header-wrapper {
    display: flex;
    padding: 0 25px;
    flex-grow: 1
}

@media (max-width:575.98px) {
    .pc-header .header-wrapper {
        padding: 0 15px
    }
}

.pc-header .form-search i {
    top: 5px
}

.pc-header .form-search i svg {
    width: 16px;
    height: 16px
}

.pc-header .form-search .custom-select,
.pc-header .form-search .dataTable-input,
.pc-header .form-search .dataTable-selector,
.pc-header .form-search .datatable-input,
.pc-header .form-search .form-control {
    width: 198px;
    padding: .65rem .75rem .65rem 40px
}

.pc-header .header-search {
    position: relative
}

.pc-header .header-search .custom-select,
.pc-header .header-search .dataTable-input,
.pc-header .header-search .dataTable-selector,
.pc-header .header-search .datatable-input,
.pc-header .header-search .form-control {
    border-radius: 8px;
    padding: .344rem 1.8rem;
    width: 200px;
    max-width: 100%
}

@media (max-width:1024px) {

    .pc-header .header-search .custom-select,
    .pc-header .header-search .dataTable-input,
    .pc-header .header-search .dataTable-selector,
    .pc-header .header-search .datatable-input,
    .pc-header .header-search .form-control {
        width: 100%
    }
}

.pc-header .header-search .icon-search {
    position: absolute;
    top: 11px;
    left: 11px;
    width: 12px;
    height: 12px
}

.pc-header .header-search .btn-search {
    position: absolute;
    top: 7px;
    right: 9px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: 8px
}

.pc-header .pc-h-item {
    min-height: 74px;
    display: flex;
    align-items: center;
    position: relative
}

.pc-header .pc-head-link {
    margin: 0 4px;
    position: relative;
    font-weight: 500;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 8px;
    color: var(--pc-header-color);
    overflow: hidden;
    cursor: pointer;
}

/* .pc-header .pc-head-link>i,
.pc-header .pc-head-link>svg {
    position: relative;
    z-index: 5;
    color: var(--pc-header-color);
    transition: all 80ms cubic-bezier(.37, .24, .53, .99)
} */

.pc-header .pc-head-link>svg {
    font-size: 16px
}


.pc-header .pc-head-link.active,
.pc-header .pc-head-link:active,
.pc-header .pc-head-link:focus,
.pc-header .pc-head-link:hover {
    text-decoration: none;
    color: var(--pc-header-color)
}

.pc-header .pc-head-link.active>i,
.pc-header .pc-head-link.active>svg,
.pc-header .pc-head-link:active>i,
.pc-header .pc-head-link:active>svg,
.pc-header .pc-head-link:focus>i,
.pc-header .pc-head-link:focus>svg,
.pc-header .pc-head-link:hover>i,
.pc-header .pc-head-link:hover>svg {
    color: var(--pc-header-color);
    transform: scale(1.08)
}

.pc-header .pc-head-link.active::before,
.pc-header .pc-head-link:active::before,
.pc-header .pc-head-link:focus::before,
.pc-header .pc-head-link:hover::before {
    border-radius: 0;
    transform: scale(1)
}

.pc-header .pc-head-link.active .hamburger .hamburger-inner,
.pc-header .pc-head-link.active .hamburger .hamburger-inner::after,
.pc-header .pc-head-link.active .hamburger .hamburger-inner::before,
.pc-header .pc-head-link.active i.material-icons-two-tone,
.pc-header .pc-head-link:active .hamburger .hamburger-inner,
.pc-header .pc-head-link:active .hamburger .hamburger-inner::after,
.pc-header .pc-head-link:active .hamburger .hamburger-inner::before,
.pc-header .pc-head-link:active i.material-icons-two-tone,
.pc-header .pc-head-link:focus .hamburger .hamburger-inner,
.pc-header .pc-head-link:focus .hamburger .hamburger-inner::after,
.pc-header .pc-head-link:focus .hamburger .hamburger-inner::before,
.pc-header .pc-head-link:focus i.material-icons-two-tone,
.pc-header .pc-head-link:hover .hamburger .hamburger-inner,
.pc-header .pc-head-link:hover .hamburger .hamburger-inner::after,
.pc-header .pc-head-link:hover .hamburger .hamburger-inner::before,
.pc-header .pc-head-link:hover i.material-icons-two-tone {
    background-color: #5b6b79
}

.pc-header .pc-head-link .pc-h-badge {
    position: absolute;
    top: 2px;
    right: 2px;
    border-radius: 50%;
    font-size: 9px;
    z-index: 9
}

.pc-header .pc-head-link .pc-h-badge.dots {
    width: 9px;
    height: 9px;
    top: 7px;
    right: 16px;
    padding: 0
}

.pc-header .pc-head-link .user-desc,
.pc-header .pc-head-link .user-name {
    display: block;
    line-height: 1
}

.pc-header .pc-head-link .user-name {
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 600
}

.pc-header .pc-head-link .user-desc {
    font-size: 12px;
    font-weight: 400;
    color: var(--pc-header-color)
}

.pc-header .pc-h-dropdown {
    transform: none !important;
    top: 100% !important
}

.pc-header .pc-h-dropdown .ti-circle {
    font-size: 7px;
    vertical-align: middle
}

.pc-header .pc-h-dropdown.dropdown-menu-end {
    right: 0 !important;
    left: auto !important
}

.pc-header .pc-level-menu {
    position: relative
}

.pc-header .pc-level-menu:hover>.dropdown-menu {
    display: block;
    left: 100%;
    top: -18px !important
}

.pc-header .drp-search {
    min-width: 20rem
}

.pc-header #vertical-nav-toggle {
    margin-left: -15px
}

.pc-header .pc-cart-menu .drp-cart {
    min-width: 20rem;
    padding: 0
}

.pc-header .pc-cart-menu .drp-cart .cart-head {
    border-radius: 2px 2px 0 0;
    padding: 20px;
    background: rgba(237, 242, 255, .2);
    border-bottom: 1px solid #e7eaee
}

.pc-header .pc-cart-menu .drp-cart .cart-item {
    display: flex;
    padding: 10px 20px
}

.pc-header .pc-cart-menu .drp-cart .cart-item+.cart-item {
    border-top: 1px dashed #e7eaee
}

.pc-header .pc-cart-menu .drp-cart .cart-item img {
    width: 60px
}

.pc-header .pc-cart-menu .drp-cart .cart-item .cart-desc {
    flex: 1
}

.pc-header .pc-cart-menu .drp-cart .cart-item .p-star {
    fill: currentColor
}

.pc-header .pc-cart-menu .drp-cart .cart-item.table-responsive {
    border-top: 1px solid #e7eaee;
    background: rgba(237, 242, 255, .2)
}

.pc-header .pc-cart-menu .drp-cart .cart-item svg {
    width: 14px;
    height: 14px
}

.pc-header .user-avtar {
    width: 40px;
    border-radius: 50%
}

.pc-header .header-user-profile .pc-head-link {
    width: auto;
    padding: 7px;
    background: 0 0
}

.pc-header .header-user-profile .pc-head-link:focus .user-avtar,
.pc-header .header-user-profile .pc-head-link:hover .user-avtar {
    transform: scale(1.03)
}

.pc-header .header-user-profile .pc-head-link:focus::before,
.pc-header .header-user-profile .pc-head-link:hover::before {
    display: none
}

.pc-header .header-user-profile .pc-head-link>span>i {
    font-size: 22px;
    margin-right: 8px
}

.pc-header .header-user-profile .pc-head-link .user-avtar {
    width: 40px;
    transition: all 80ms cubic-bezier(.37, .24, .53, .99)
}

@media (max-width:575.98px) {
    .pc-header .header-user-profile .pc-head-link {
        width: 40px
    }

    .pc-header .header-user-profile .pc-head-link .user-avtar {
        margin-right: 0
    }

    .pc-header .header-user-profile .pc-head-link>span,
    .pc-header .header-user-profile .pc-head-link>span>i {
        display: none
    }
}

.pc-header .dropdown-user-profile {
    min-width: 352px;
    max-width: 100%
}

.pc-header .dropdown-user-profile .dropdown-body,
.pc-header .dropdown-user-profile .dropdown-header {
    padding: 16px 20px
}

.pc-header .dropdown-user-profile .dropdown-item {
    padding: 10px 15px;
    display: flex;
    border-radius: var(--bs-border-radius);
    align-items: center;
    justify-content: space-between;
    border: var(--bs-border-width) var(--bs-border-style) transparent
}

.pc-header .dropdown-user-profile .dropdown-item:focus,
.pc-header .dropdown-user-profile .dropdown-item:hover {
    color: var(--bs-dropdown-link-color);
    background: var(--pc-active-background);
    border-color: var(--bs-border-color)
}

.pc-header .dropdown-user-profile .dropdown-item .user-group .avtar,
.pc-header .dropdown-user-profile .dropdown-item .user-group img {
    width: 30px;
    height: 30px;
    font-size: 14px
}

@media (max-width:575.98px) {
    .pc-header .dropdown-user-profile {
        min-width: calc(100% - 30px)
    }
}

.pc-header .dropdown-notification {
    min-width: 450px;
    max-width: 100%
}

.pc-header .dropdown-notification .dropdown-body,
.pc-header .dropdown-notification .dropdown-header {
    padding: 16px 20px
}

.pc-header .dropdown-notification .list-group-item-action:active,
.pc-header .dropdown-notification .list-group-item-action:focus,
.pc-header .dropdown-notification .list-group-item-action:hover {
    background: #edf2ff
}

.pc-header .dropdown-notification .list-group-item-action .h5,
.pc-header .dropdown-notification .list-group-item-action .user-avtar,
.pc-header .dropdown-notification .list-group-item-action h5 {
    cursor: pointer
}

.pc-header .dropdown-notification .badge {
    font-size: .8125rem;
    padding: .43em 1em
}

.pc-header .dropdown-notification .user-avtar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 20px
}

.pc-header .dropdown-notification .notification-file {
    display: flex;
    align-items: center
}

.pc-header .dropdown-notification .notification-file i {
    font-size: 20px;
    margin-right: 16px
}

.pc-header .dropdown-notification .card {
    cursor: pointer
}

.pc-header .dropdown-notification .card:hover {
    background: var(--pc-active-background)
}

.pc-announcement-offcanvas {
    --bs-offcanvas-width: 474px
}

.pc-announcement-offcanvas .offcanvas-body::-webkit-scrollbar {
    width: 6px;
    opacity: 0
}

.pc-announcement-offcanvas .offcanvas-body::-webkit-scrollbar:hover {
    opacity: 1
}

.pc-announcement-offcanvas .offcanvas-body::-webkit-scrollbar-track {
    background: 0 0
}

.pc-announcement-offcanvas .offcanvas-body::-webkit-scrollbar-thumb {
    background: #e9ecef
}

.pc-announcement-offcanvas .offcanvas-body::-webkit-scrollbar-thumb:hover {
    background: #aeb9c5
}

.drp-upgrade-card {
    background-size: cover
}

.drp-upgrade-card .user-group .avtar,
.drp-upgrade-card .user-group img {
    font-size: 14px
}

.user-group {
    display: inline-flex;
    align-items: center
}

.user-group .avtar,
.user-group img {
    cursor: pointer;
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 2;
    transition: all .1s ease-in-out;
    border: 2px solid #fff
}

.user-group .avtar+.avtar,
.user-group .avtar+img,
.user-group img+.avtar,
.user-group img+img {
    margin-left: -14px
}

.user-group .avtar:hover,
.user-group img:hover {
    z-index: 5
}

.notification-modal.modal.fade .modal-dialog {
    transform: translate(100%, 0)
}

.notification-modal.modal.show .modal-dialog {
    transform: none
}

.notification-modal {
    padding-right: 0 !important
}

.notification-modal .modal-dialog {
    margin: 0 0 0 auto
}

.notification-modal .introjs-tooltip,
.notification-modal .modal-content {
    min-height: 100vh;
    border: none;
    border-radius: 0;
    box-shadow: -1px 0 9px -3px rgba(0, 0, 0, .6)
}

@media (max-width:1024px) {
    .pc-header {
        top: 0;
        left: 0;
        transition: all .15s ease-in-out
    }

    .pc-header .m-header,
    .pc-header .pc-head-link .user-desc,
    .pc-header .pc-head-link .user-name {
        display: none
    }

    .pc-header .pcm-logo {
        margin-left: 30px
    }

    .pc-header .pcm-logo img {
        max-width: 160px
    }

    .pc-header.mob-header-active {
        top: 74px
    }

    .pc-header.mob-header-active .header-wrapper {
        background: var(pc-header-background);
        position: relative;
        z-index: 5
    }

    .pc-header .pc-md-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1;
        background: rgba(0, 0, 0, .2)
    }

    .pc-header .pc-level-menu .dropdown-menu {
        left: 0;
        display: block;
        padding-left: 30px
    }

    .pc-header .pc-mob-drp.mob-drp-active .pc-h-item {
        display: block;
        min-height: auto;
        position: relative
    }

    .pc-header .pc-mob-drp.mob-drp-active .pc-h-item .pc-head-link {
        display: block;
        margin: 5px 10px !important
    }

    .pc-header .pc-mob-drp.mob-drp-active .pc-h-item .dropdown-menu {
        position: relative !important;
        width: 100%;
        float: none;
        box-shadow: none
    }

    .pc-header .pc-mob-drp.mob-drp-active ul {
        display: block
    }

    .minimenu .pc-header {
        left: 0
    }
}

@media (max-width:575.98px) {
    .pc-header .dropdown-notification {
        min-width: calc(100% - 30px)
    }

    .pc-header .pc-head-link {
        padding: .65rem;
        margin: 0 5px
    }

    .pc-header .pc-h-item {
        position: static
    }

    .pc-header .pc-h-item .pc-h-dropdown {
        left: 15px !important;
        right: 15px !important
    }
}

.pc-footer {
    position: relative;
    z-index: 995;
    margin-left: 280px;
    margin-top: 74px;
    padding: 15px 0
}

.pc-footer a {
    color: var(--bs-body-color)
}

.pc-footer a:hover {
    color: var(--bs-primary)
}

.pc-footer .footer-wrapper {
    padding-left: 40px;
    padding-right: 40px
}

@media (max-width:1024px) {
    .pc-footer .footer-wrapper {
        padding-left: 30px;
        padding-right: 30px
    }

    .pc-footer {
        margin-left: 0
    }
}

.pc-footer .footer-link .list-inline-item:not(:last-child) {
    margin-right: .9rem
}

@media (max-width:575.98px) {
    .pc-footer .footer-wrapper {
        padding-left: 15px;
        padding-right: 15px
    }

    .pc-footer {
        margin-top: 50px
    }
}

.pc-container {
    position: relative;
    top: 74px;
    margin-left: 280px;
    min-height: calc(100vh - 135px)
}

.pc-container .pc-content {
    padding: 20px;
}

@media (max-width:1199.98px) {
    .pc-container .pc-content.container {
        max-width: 100%
    }
}

.pc-container .page-header+.row {
    padding-top: 24px
}

.pc-container .page-header+.pc-content {
    padding-top: 85px
}

.page-header {
    display: flex;
    align-items: center;
    top: 74px;
    left: 280px;
    right: 0;
    z-index: 1023;
    min-height: 55px;
    padding: 13px 0;
    background: 0 0;
    border-radius: 8px
}

.page-header .page-block {
    width: 100%
}

.page-header .page-header-title {
    display: inline-block
}

.analytics-tab .nav-item .nav-link,
.overview-product-legends p {
    display: inline-flex;
    align-items: center;
    position: relative
}

.page-header .h5,
.page-header h5 {
    margin-bottom: 0;
    margin-right: 8px;
    padding-right: 8px;
    font-weight: 500
}

.page-header .breadcrumb {
    padding: 0;
    display: inline-flex;
    background: 0 0;
    font-size: 13px
}

.page-header .breadcrumb a {
    color: var(--bs-body-color)
}

.page-header .breadcrumb .breadcrumb-item a:hover {
    color: #4680ff
}

.page-header .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    position: relative;
    top: 2px
}

.page-header .breadcrumb .breadcrumb-item:last-child {
    opacity: .75
}

.page-header .breadcrumb svg {
    width: 14px;
    height: 14px;
    vertical-align: baseline
}

.upgrade-card .card-body {
    padding-bottom: 100px;
    z-index: 1;
    position: relative
}

.upgrade-card>.container {
    z-index: 5;
    position: relative;
    margin-top: -60px
}

.upgrade-card .up-price-table td {
    border-color: #f8f9fa
}

.upgrade-card .up-price-table th {
    border: none
}

.upgrade-card .up-price-table th+th {
    border-radius: 5px 5px 0 0;
    padding: 20px 0
}

.upgrade-card .up-price-table tbody tr:first-child td {
    border-top: none
}

.upgrade-card .up-price-table td,
.upgrade-card .up-price-table th {
    text-align: right
}

#cke5-inline-demo header.ck-content,
.dropzone,
.upgrade-card .up-price-table td+td,
.upgrade-card .up-price-table td+th,
.upgrade-card .up-price-table th+td,
.upgrade-card .up-price-table th+th {
    text-align: center
}

.upgrade-card .up-price-table td+td,
.upgrade-card .up-price-table th+td {
    border-left: 1px solid #f8f9fa
}

.hamburger {
    display: inline-block;
    cursor: pointer;
    transition: filter .15s linear;
    width: 20px;
    height: 20px
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::after,
.hamburger.is-active .hamburger-inner::before {
    background-color: #000
}

.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
    width: 18px;
    background-color: var(--pc-header-color);
    border-radius: 4px;
    position: absolute;
    transition: transform .15s ease;
    display: block;
    height: 2px
}

.hamburger-inner {
    top: 50%;
    margin-top: -2px
}

.navbar-content,
[data-simplebar] {
    position: relative
}

.hamburger-inner::before {
    top: -6px
}

.hamburger-inner::after {
    bottom: -6px
}

.hamburger--arrowturn.is-active .hamburger-inner {
    transform: rotate(-180deg)
}

.hamburger--arrowturn.is-active .hamburger-inner::before {
    transform: translate3d(4px, 2px, 0) rotate(45deg) scale(.7, 1)
}

.hamburger--arrowturn.is-active .hamburger-inner::after {
    transform: translate3d(4px, -2px, 0) rotate(-45deg) scale(.7, 1)
}

.pc-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 101;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, .15)
}

@media (max-width:1024px) {
    .pc-container {
        margin-left: 0;
        margin-right: 0
    }

    .pc-container .page-header+.pc-content,
    .pc-container .page-header+.row,
    .pc-container .pc-content {
        padding-top: 20px;
        padding-left: 15px;
        padding-right: 15px
    }

    .page-header {
        position: relative;
        left: 0;
        top: 0;
        margin-left: 10px;
        margin-right: 10px
    }
}

@media (max-width:575.98px) {
    .col-form-label {
        padding-top: 0;
        padding-bottom: 5px
    }

    .pc-container .pc-content {
        padding: 15px
    }

    .pc-container .container,
    .pc-container .page-header+.pc-content,
    .pc-container .page-header+.row {
        padding-left: 0;
        padding-right: 0
    }

    .page-header .h5,
    .page-header h5 {
        padding-bottom: 5px;
        margin-bottom: 0;
        border-right: none;
        font-size: 14px
    }

    .page-header .breadcrumb {
        display: flex;
        margin-bottom: 0;
        font-size: 11px
    }

    .page-header .text-md-right {
        margin-top: 5px
    }
}

[data-simplebar] {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start
}

.simplebar-wrapper {
    overflow: auto;
    width: inherit;
    /* height: inherit; */
    max-width: inherit;
    max-height: inherit
}

.simplebar-mask {
    direction: inherit;
    position: absolute;
    overflow: hidden;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    height: auto !important;
    z-index: 0
}

.simplebar-offset {
    direction: inherit !important;
    box-sizing: inherit !important;
    resize: none !important;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch
}

.simplebar-content-wrapper {
    direction: inherit;
    box-sizing: border-box !important;
    position: relative;
    display: block;
    height: 100%;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none
}

.simplebar-content-wrapper::-webkit-scrollbar {
    width: 0;
    height: 0
}

.simplebar-hide-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0
}

.simplebar-content:after,
.simplebar-content:before {
    content: " ";
    display: table
}

.simplebar-placeholder {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    pointer-events: none
}

.simplebar-height-auto-observer-wrapper {
    box-sizing: inherit !important;
    height: 100%;
    width: 100%;
    max-width: 1px;
    position: relative;
    float: left;
    max-height: 1px;
    overflow: hidden;
    z-index: -1;
    padding: 0;
    margin: 0;
    pointer-events: none;
    flex-grow: inherit;
    flex-shrink: 0;
    flex-basis: 0
}

.simplebar-height-auto-observer {
    box-sizing: inherit;
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 1000%;
    width: 1000%;
    min-height: 1px;
    min-width: 1px;
    overflow: hidden;
    pointer-events: none;
    z-index: -1
}


.simplebar-track {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden
}

[data-simplebar].simplebar-dragging .simplebar-content {
    pointer-events: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

[data-simplebar].simplebar-dragging .simplebar-track {
    pointer-events: all
}

.simplebar-scrollbar {
    position: absolute;
    left: 0;
    right: 0;
    min-height: 10px
}

.simplebar-scrollbar:before {
    position: absolute;
    content: "";
    background: #aeb9c5;
    border-radius: 7px;
    left: 2px;
    right: 2px;
    opacity: 0;
    transition: opacity .2s linear
}

.simplebar-scrollbar.simplebar-visible:before {
    opacity: .5;
    transition: opacity 0s linear
}

.simplebar-track.simplebar-vertical {
    top: 0;
    width: 11px
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    top: 2px;
    bottom: 2px
}

.simplebar-track.simplebar-horizontal {
    left: 0;
    height: 11px
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    right: auto;
    left: 0;
    top: 2px;
    height: 7px;
    min-height: 0;
    min-width: 10px;
    width: auto
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
    height: 100%;
    left: 2px;
    right: 2px
}

[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
    right: auto;
    left: 0
}

.hs-dummy-scrollbar-size {
    direction: rtl;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    height: 500px;
    width: 500px;
    overflow-y: hidden;
    overflow-x: scroll
}

.simplebar-hide-scrollbar {
    position: fixed;
    left: 0;
    visibility: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none
}

@media (min-width:768px) {

    .footer-wrapper.container,
    .pc-content.container {
        max-width: 540px
    }

    
    
}

@media (min-width:992px) {

    .footer-wrapper.container,
    .pc-content.container {
        max-width: 720px
    }
}

@media (min-width:1200px) {

    .footer-wrapper.container,
    .pc-content.container {
        max-width: 960px
    }
}

@media (min-width:1400px) {

    .footer-wrapper.container,
    .pc-content.container {
        max-width: 1140px
    }
}



.available-balance-card::after,
.dropbox-card::after {
    top: 0;
    left: 0;
    right: 0;
    content: "";
    bottom: 0
}



.dropbox-card {
    overflow: hidden;
    position: relative
}

.dropbox-card::after {
    background-image: url('../images/img-ipd-bg.svg');
    position: absolute;
    z-index: 1;
    opacity: .5;
    background-position: bottom right;
    background-size: 100%;
    background-repeat: no-repeat
}

.dropbox-card>* {
    position: relative;
    z-index: 5
}

.dropbox-card .avtar {
    background: rgba(255, 255, 255, .3);
    color: #fff
}

.available-balance-card {
    overflow: hidden;
    position: relative
}

.available-balance-card::after {
    background: linear-gradient(245deg, transparent 25.46%, rgba(0, 0, 0, .2) 68.77%, rgba(0, 0, 0, .3) 81.72%);
    position: absolute;
    z-index: 1;
    opacity: .6
}

.available-balance-card>* {
    position: relative;
    z-index: 5
}

.available-balance-card .avtar {
    background: rgba(0, 0, 0, .1);
    color: #fff
}

.analytics-tab .nav-item {
    margin-bottom: 0
}

.analytics-tab .nav-item .nav-link {
    padding: 1rem .8rem;
    margin: 0 .2rem;
    font-weight: 500;
    color: var(--bs-body-color)
}

.analytics-tab .nav-item .nav-link:after {
    content: "";
    background: var(--bs-primary);
    position: absolute;
    transition: all .3s ease-in-out;
    left: 50%;
    right: 50%;
    bottom: -1px;
    height: 2px;
    border-radius: 2px 2px 0 0
}

.analytics-tab .nav-item.show .nav-link,
.analytics-tab .nav-link.active,
.analytics-tab .nav-link:hover {
    border-color: transparent;
    color: var(--bs-primary);
    background: 0 0
}

.analytics-tab .nav-item.show .nav-link:after,
.analytics-tab .nav-link.active:after {
    left: 0;
    right: 0
}

.overview-product-legends {
    border: 1px solid var(--bs-border-color);
    padding: 16px;
    border-radius: var(--bs-border-radius)
}

.overview-product-legends p::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background: currentColor;
    margin-left: -10px
}

.overview-product-legends p span {
    color: var(--bs-body-color)
}

.able-user-group .avtar {
    width: 30px;
    height: 30px
}

.table-body.card-body,
.table-card .card-body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0
}

.table-body.card-body .table>thead>tr>th,
.table-card .card-body .table>thead>tr>th {
    border-top: 0
}

.table-body.card-body .table tr td:first-child,
.table-body.card-body .table tr th:first-child,
.table-card .card-body .table tr td:first-child,
.table-card .card-body .table tr th:first-child {
    padding-left: 25px
}

.table-body.card-body .table tr td:last-child,
.table-body.card-body .table tr th:last-child,
.table-card .card-body .table tr td:last-child,
.table-card .card-body .table tr th:last-child {
    padding-right: 25px
}

@media (max-width:575.98px) {

    .table-body.card-body .table tr td:first-child,
    .table-body.card-body .table tr th:first-child,
    .table-card .card-body .table tr td:first-child,
    .table-card .card-body .table tr th:first-child {
        padding-left: 20px
    }

    .table-body.card-body .table tr td:last-child,
    .table-body.card-body .table tr th:last-child,
    .table-card .card-body .table tr td:last-child,
    .table-card .card-body .table tr th:last-child {
        padding-right: 20px
    }
}

.table-body.card-body .table.without-header tr:first-child td,
.table-card .card-body .table.without-header tr:first-child td {
    border-top: none
}

.social-widget-card {
    color: #fff
}

.social-widget-card i {
    position: absolute;
    right: 14px;
    top: 24px;
    font-size: 48px;
    opacity: .4;
    transition: all ease-in .3s
}

.social-widget-card:hover i {
    opacity: 1;
    transform: scale(1.1)
}

.task-card .task-list {
    position: relative
}

.task-card .task-list:after,
.task-card .task-list:before {
    position: absolute;
    background: #e7eaee;
    content: ""
}

.task-card .task-list:after {
    height: 100%;
    width: 2px;
    top: 0;
    left: 10px;
    z-index: 1
}

.task-card .task-list:before {
    height: 15px;
    width: 15px;
    bottom: -14px;
    left: 3px;
    z-index: 2;
    border-radius: 50%
}

.task-card .task-list li {
    margin-bottom: 30px;
    padding-left: 30px;
    position: relative
}

.task-card .task-list li .task-icon {
    position: absolute;
    left: 3px;
    top: 1px;
    border-radius: 50%;
    padding: 2px;
    color: #fff;
    min-width: 15px;
    min-height: 15px;
    z-index: 2
}

.new-cust-card img {
    width: 40px
}

.new-cust-card .h6,
.new-cust-card h6 {
    margin-bottom: 0
}

.new-cust-card .align-middle {
    position: relative
}

.new-cust-card .align-middle .status {
    position: absolute;
    right: 0;
    top: 19px;
    font-size: 13px
}

.new-cust-card .align-middle .status.active {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #2ca87f
}

.table-card .row-table {
    display: flex;
    align-items: center;
    table-layout: fixed;
    height: 100%;
    width: 100%;
    margin: 0
}

.table-card .row-table svg {
    margin: 0 20px
}

.table-card .row-table>[class*=col-] {
    display: table-cell;
    float: none;
    table-layout: fixed;
    vertical-align: middle
}

.table-card .row-table>[class*=col-] .row {
    display: flex;
    align-items: center
}

.welcome-banner {
    overflow: hidden;
    position: relative
}

.welcome-banner::after {
    content: "";
    /* background-image: url(../images/widget/img-dropbox-bg.svg); */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: .5;
    background-position: bottom right;
    background-size: 100%;
    background-repeat: no-repeat
}

.welcome-banner>* {
    position: relative;
    z-index: 5
}

.welcome-banner .img-welcome-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    z-index: 2;
    padding-right: 64px
}

@media (max-width:768px) {
    .welcome-banner .img-welcome-banner {
        padding-right: 24px
    }
}

.welcome-banner .img-welcome-banner img {
    width: 200px
}

.avtar {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    width: 48px;
    height: 48px
}

.avtar.avtar-xxs {
    width: 16px;
    height: 16px;
    font-size: 14px;
    border-radius: 12px
}

.avtar.avtar-xs {
    width: 32px;
    height: 32px;
    font-size: 12px;
    border-radius: 12px
}

.avtar.avtar-s {
    width: 40px;
    height: 40px;
    font-size: 14px;
    border-radius: 12px
}

.avtar.avtar-l {
    width: 64px;
    height: 64px;
    font-size: 20px;
    border-radius: 12px
}

.avtar.avtar-xl {
    width: 80px;
    height: 80px;
    font-size: 24px;
    border-radius: 12px
}

.avtar.avtar-square {
    border-radius: 4px;
    border: 2px solid
}

.avtar.avtar-icon {
    border: 2px solid #e7eaee
}

.avtar.avtar-icon>img {
    width: 60%
}

.badge.dot {
    width: 6px;
    height: 6px;
    display: block;
    padding: 0
}

.badge.bg-light-primary {
    background: #edf2ff;
    color: #4680ff
}

.badge.bg-light-secondary {
    background: #eff0f2;
    color: #5b6b79
}

.badge.bg-light-success {
    background: #eaf6f2;
    color: #2ca87f
}

.badge.bg-light-info {
    background: #ecfafb;
    color: #3ec9d6
}

.badge.bg-light-warning {
    background: #fcf3e6;
    color: #e58a00
}

.badge.bg-light-danger {
    background: #fce9e9;
    color: #dc2626
}

.badge.bg-light-light {
    background: #fefeff;
    color: #f8f9fa
}

.badge.bg-light-dark {
    background: #e9e9ea;
    color: #212529
}

.btn,
.introjs-tooltip .introjs-button {
    font-size: 14px
}

.btn i,
.introjs-tooltip .introjs-button i {
    font-size: 18px
}

.btn i.ph-duotone,
.introjs-tooltip .introjs-button i.ph-duotone {
    vertical-align: text-bottom
}

.btn svg,
.introjs-tooltip .introjs-button svg {
    width: 18px;
    height: 18px
}

.btn[class*=btn-light-],
.btn[class*=btn-link-],
.btn[class*=btn-outline-]:not(:hover),
.introjs-tooltip [class*=btn-light-].introjs-button,
.introjs-tooltip [class*=btn-link-].introjs-button,
.introjs-tooltip [class*=btn-outline-].introjs-button:not(:hover) {
    box-shadow: none
}

.btn.btn-shadow,
.introjs-tooltip .btn-shadow.introjs-button {
    box-shadow: 0 6px 7px -1px rgba(80, 86, 175, .3)
}

.btn-group-sm>.btn i,
.btn.btn-sm i,
.introjs-tooltip .btn-group-sm>.introjs-button i,
.introjs-tooltip .btn-sm.introjs-button i {
    font-size: 14px
}

.btn.btn-icon,
.introjs-tooltip .btn-icon.introjs-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    padding: 0;
    width: 40px;
    height: 40px
}

.btn.btn-icon.avtar-xs,
.introjs-tooltip .btn-icon.avtar-xs.introjs-button {
    width: 20px;
    height: 20px;
    font-size: 10px
}

.btn.btn-icon.avtar-s,
.introjs-tooltip .btn-icon.avtar-s.introjs-button {
    width: 30px;
    height: 30px;
    font-size: 12px
}

.btn.btn-icon.avtar-l,
.introjs-tooltip .btn-icon.avtar-l.introjs-button {
    width: 50px;
    height: 50px;
    font-size: 18px
}

.btn.btn-icon.avtar-xl,
.introjs-tooltip .btn-icon.avtar-xl.introjs-button {
    width: 60px;
    height: 60px;
    font-size: 20px;
    border-radius: 30px
}

.btn-light-theme {
    background: #ffebf0;
    color: #f36;
    border-color: #ffebf0
}

.btn-light-theme .material-icons-two-tone {
    background-color: #f36
}

.btn-light-theme:hover {
    background: #f36;
    color: #fff;
    border-color: #f36
}

.btn-light-theme:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-theme.focus,
.btn-light-theme:focus {
    background: #f36;
    color: #fff;
    border-color: #f36
}

.btn-light-theme.focus .material-icons-two-tone,
.btn-light-theme:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-theme:not(:disabled):not(.disabled).active,
.btn-light-theme:not(:disabled):not(.disabled):active,
.show>.btn-light-theme.dropdown-toggle {
    background: #f36;
    color: #fff;
    border-color: #f36
}

.btn-light-theme:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-theme:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-theme.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-check:active+.btn-light-theme,
.btn-check:checked+.btn-light-theme {
    background: #f36;
    color: #fff;
    border-color: #f36
}

.btn-check:active+.btn-light-theme .material-icons-two-tone,
.btn-check:checked+.btn-light-theme .material-icons-two-tone {
    background-color: #fff
}

.btn-link-theme {
    background: 0 0;
    color: #f36;
    border-color: transparent
}

.btn-link-theme .material-icons-two-tone {
    background-color: #f36
}

.btn-check:active+.btn-link-theme,
.btn-check:checked+.btn-link-theme,
.btn-link-theme.focus,
.btn-link-theme:focus,
.btn-link-theme:hover,
.btn-link-theme:not(:disabled):not(.disabled).active,
.btn-link-theme:not(:disabled):not(.disabled):active,
.show>.btn-link-theme.dropdown-toggle {
    background: #ffebf0;
    color: #f36;
    border-color: #ffebf0
}

.btn-link-hover-theme {
    background: 0 0;
    color: var(--bs-secondary);
    border-color: transparent
}

.btn-link-hover-theme .material-icons-two-tone {
    background-color: var(--bs-secondary)
}

.btn-check:active+.btn-link-hover-theme,
.btn-check:checked+.btn-link-hover-theme,
.btn-link-hover-theme.focus,
.btn-link-hover-theme:focus,
.btn-link-hover-theme:hover,
.btn-link-hover-theme:not(:disabled):not(.disabled).active,
.btn-link-hover-theme:not(:disabled):not(.disabled):active,
.show>.btn-link-hover-theme.dropdown-toggle {
    background: #ffebf0;
    color: #f36;
    border-color: #ffebf0
}

.btn-light-primary {
    background: #edf2ff;
    color: #4680ff;
    border-color: #edf2ff
}

.btn-light-primary .material-icons-two-tone {
    background-color: #4680ff
}

.btn-light-primary:hover {
    background: #4680ff;
    color: #fff;
    border-color: #4680ff
}

.btn-light-primary:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-primary.focus,
.btn-light-primary:focus {
    background: #4680ff;
    color: #fff;
    border-color: #4680ff
}

.btn-light-primary.focus .material-icons-two-tone,
.btn-light-primary:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-primary:not(:disabled):not(.disabled).active,
.btn-light-primary:not(:disabled):not(.disabled):active,
.show>.btn-light-primary.dropdown-toggle {
    background: #4680ff;
    color: #fff;
    border-color: #4680ff
}

.btn-light-primary:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-primary:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-primary.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-check:active+.btn-light-primary,
.btn-check:checked+.btn-light-primary {
    background: #4680ff;
    color: #fff;
    border-color: #4680ff
}

.btn-check:active+.btn-light-primary .material-icons-two-tone,
.btn-check:checked+.btn-light-primary .material-icons-two-tone {
    background-color: #fff
}

.btn-link-primary {
    background: 0 0;
    color: #4680ff;
    border-color: transparent
}

.btn-link-primary .material-icons-two-tone {
    background-color: #4680ff
}

.btn-check:active+.btn-link-primary,
.btn-check:checked+.btn-link-primary,
.btn-link-primary.focus,
.btn-link-primary:focus,
.btn-link-primary:hover,
.btn-link-primary:not(:disabled):not(.disabled).active,
.btn-link-primary:not(:disabled):not(.disabled):active,
.show>.btn-link-primary.dropdown-toggle {
    background: #edf2ff;
    color: #4680ff;
    border-color: #edf2ff
}

.btn-link-hover-primary {
    background: 0 0;
    color: var(--bs-secondary);
    border-color: transparent
}

.btn-link-hover-primary .material-icons-two-tone {
    background-color: var(--bs-secondary)
}

.btn-check:active+.btn-link-hover-primary,
.btn-check:checked+.btn-link-hover-primary,
.btn-link-hover-primary.focus,
.btn-link-hover-primary:focus,
.btn-link-hover-primary:hover,
.btn-link-hover-primary:not(:disabled):not(.disabled).active,
.btn-link-hover-primary:not(:disabled):not(.disabled):active,
.show>.btn-link-hover-primary.dropdown-toggle {
    background: #edf2ff;
    color: #4680ff;
    border-color: #edf2ff
}

.btn-light-secondary {
    background: #eff0f2;
    color: #5b6b79;
    border-color: #eff0f2
}

.btn-light-secondary .material-icons-two-tone {
    background-color: #5b6b79
}

.btn-light-secondary:hover {
    background: #5b6b79;
    color: #fff;
    border-color: #5b6b79
}

.btn-light-secondary:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-secondary.focus,
.btn-light-secondary:focus {
    background: #5b6b79;
    color: #fff;
    border-color: #5b6b79
}

.btn-light-secondary.focus .material-icons-two-tone,
.btn-light-secondary:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-secondary:not(:disabled):not(.disabled).active,
.btn-light-secondary:not(:disabled):not(.disabled):active,
.show>.btn-light-secondary.dropdown-toggle {
    background: #5b6b79;
    color: #fff;
    border-color: #5b6b79
}

.btn-light-secondary:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-secondary:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-secondary.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-check:active+.btn-light-secondary,
.btn-check:checked+.btn-light-secondary {
    background: #5b6b79;
    color: #fff;
    border-color: #5b6b79
}

.btn-check:active+.btn-light-secondary .material-icons-two-tone,
.btn-check:checked+.btn-light-secondary .material-icons-two-tone {
    background-color: #fff
}

.btn-link-secondary {
    background: 0 0;
    color: #5b6b79;
    border-color: transparent
}

.btn-link-secondary .material-icons-two-tone {
    background-color: #5b6b79
}

.btn-check:active+.btn-link-secondary,
.btn-check:checked+.btn-link-secondary,
.btn-link-secondary.focus,
.btn-link-secondary:focus,
.btn-link-secondary:hover,
.btn-link-secondary:not(:disabled):not(.disabled).active,
.btn-link-secondary:not(:disabled):not(.disabled):active,
.show>.btn-link-secondary.dropdown-toggle {
    background: #eff0f2;
    color: #5b6b79;
    border-color: #eff0f2
}

.btn-link-hover-secondary {
    background: 0 0;
    color: var(--bs-secondary);
    border-color: transparent
}

.btn-link-hover-secondary .material-icons-two-tone {
    background-color: var(--bs-secondary)
}

.btn-check:active+.btn-link-hover-secondary,
.btn-check:checked+.btn-link-hover-secondary,
.btn-link-hover-secondary.focus,
.btn-link-hover-secondary:focus,
.btn-link-hover-secondary:hover,
.btn-link-hover-secondary:not(:disabled):not(.disabled).active,
.btn-link-hover-secondary:not(:disabled):not(.disabled):active,
.show>.btn-link-hover-secondary.dropdown-toggle {
    background: #eff0f2;
    color: #5b6b79;
    border-color: #eff0f2
}

.btn-light-success {
    background: #eaf6f2;
    color: #2ca87f;
    border-color: #eaf6f2
}

.btn-light-success .material-icons-two-tone {
    background-color: #2ca87f
}

.btn-light-success:hover {
    background: #2ca87f;
    color: #fff;
    border-color: #2ca87f
}

.btn-light-success:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-success.focus,
.btn-light-success:focus {
    background: #2ca87f;
    color: #fff;
    border-color: #2ca87f
}

.btn-light-success.focus .material-icons-two-tone,
.btn-light-success:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-success:not(:disabled):not(.disabled).active,
.btn-light-success:not(:disabled):not(.disabled):active,
.show>.btn-light-success.dropdown-toggle {
    background: #2ca87f;
    color: #fff;
    border-color: #2ca87f
}

.btn-light-success:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-success:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-success.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-check:active+.btn-light-success,
.btn-check:checked+.btn-light-success {
    background: #2ca87f;
    color: #fff;
    border-color: #2ca87f
}

.btn-check:active+.btn-light-success .material-icons-two-tone,
.btn-check:checked+.btn-light-success .material-icons-two-tone {
    background-color: #fff
}

.btn-link-success {
    background: 0 0;
    color: #2ca87f;
    border-color: transparent
}

.btn-link-success .material-icons-two-tone {
    background-color: #2ca87f
}

.btn-check:active+.btn-link-success,
.btn-check:checked+.btn-link-success,
.btn-link-success.focus,
.btn-link-success:focus,
.btn-link-success:hover,
.btn-link-success:not(:disabled):not(.disabled).active,
.btn-link-success:not(:disabled):not(.disabled):active,
.show>.btn-link-success.dropdown-toggle {
    background: #eaf6f2;
    color: #2ca87f;
    border-color: #eaf6f2
}

.btn-link-hover-success {
    background: 0 0;
    color: var(--bs-secondary);
    border-color: transparent
}

.btn-link-hover-success .material-icons-two-tone {
    background-color: var(--bs-secondary)
}

.btn-check:active+.btn-link-hover-success,
.btn-check:checked+.btn-link-hover-success,
.btn-link-hover-success.focus,
.btn-link-hover-success:focus,
.btn-link-hover-success:hover,
.btn-link-hover-success:not(:disabled):not(.disabled).active,
.btn-link-hover-success:not(:disabled):not(.disabled):active,
.show>.btn-link-hover-success.dropdown-toggle {
    background: #eaf6f2;
    color: #2ca87f;
    border-color: #eaf6f2
}

.btn-light-info {
    background: #ecfafb;
    color: #3ec9d6;
    border-color: #ecfafb
}

.btn-light-info .material-icons-two-tone {
    background-color: #3ec9d6
}

.btn-light-info:hover {
    background: #3ec9d6;
    color: #fff;
    border-color: #3ec9d6
}

.btn-light-info:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-info.focus,
.btn-light-info:focus {
    background: #3ec9d6;
    color: #fff;
    border-color: #3ec9d6
}

.btn-light-info.focus .material-icons-two-tone,
.btn-light-info:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-info:not(:disabled):not(.disabled).active,
.btn-light-info:not(:disabled):not(.disabled):active,
.show>.btn-light-info.dropdown-toggle {
    background: #3ec9d6;
    color: #fff;
    border-color: #3ec9d6
}

.btn-light-info:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-info:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-info.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-check:active+.btn-light-info,
.btn-check:checked+.btn-light-info {
    background: #3ec9d6;
    color: #fff;
    border-color: #3ec9d6
}

.btn-check:active+.btn-light-info .material-icons-two-tone,
.btn-check:checked+.btn-light-info .material-icons-two-tone {
    background-color: #fff
}

.btn-link-info {
    background: 0 0;
    color: #3ec9d6;
    border-color: transparent
}

.btn-link-info .material-icons-two-tone {
    background-color: #3ec9d6
}

.btn-check:active+.btn-link-info,
.btn-check:checked+.btn-link-info,
.btn-link-info.focus,
.btn-link-info:focus,
.btn-link-info:hover,
.btn-link-info:not(:disabled):not(.disabled).active,
.btn-link-info:not(:disabled):not(.disabled):active,
.show>.btn-link-info.dropdown-toggle {
    background: #ecfafb;
    color: #3ec9d6;
    border-color: #ecfafb
}

.btn-link-hover-info {
    background: 0 0;
    color: var(--bs-secondary);
    border-color: transparent
}

.btn-link-hover-info .material-icons-two-tone {
    background-color: var(--bs-secondary)
}

.btn-check:active+.btn-link-hover-info,
.btn-check:checked+.btn-link-hover-info,
.btn-link-hover-info.focus,
.btn-link-hover-info:focus,
.btn-link-hover-info:hover,
.btn-link-hover-info:not(:disabled):not(.disabled).active,
.btn-link-hover-info:not(:disabled):not(.disabled):active,
.show>.btn-link-hover-info.dropdown-toggle {
    background: #ecfafb;
    color: #3ec9d6;
    border-color: #ecfafb
}

.btn-light-warning {
    background: #fcf3e6;
    color: #e58a00;
    border-color: #fcf3e6
}

.btn-light-warning .material-icons-two-tone {
    background-color: #e58a00
}

.btn-light-warning:hover {
    background: #e58a00;
    color: #fff;
    border-color: #e58a00
}

.btn-light-warning:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-warning.focus,
.btn-light-warning:focus {
    background: #e58a00;
    color: #fff;
    border-color: #e58a00
}

.btn-light-warning.focus .material-icons-two-tone,
.btn-light-warning:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-warning:not(:disabled):not(.disabled).active,
.btn-light-warning:not(:disabled):not(.disabled):active,
.show>.btn-light-warning.dropdown-toggle {
    background: #e58a00;
    color: #fff;
    border-color: #e58a00
}

.btn-light-warning:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-warning:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-warning.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-check:active+.btn-light-warning,
.btn-check:checked+.btn-light-warning {
    background: #e58a00;
    color: #fff;
    border-color: #e58a00
}

.btn-check:active+.btn-light-warning .material-icons-two-tone,
.btn-check:checked+.btn-light-warning .material-icons-two-tone {
    background-color: #fff
}

.btn-link-warning {
    background: 0 0;
    color: #e58a00;
    border-color: transparent
}

.btn-link-warning .material-icons-two-tone {
    background-color: #e58a00
}

.btn-check:active+.btn-link-warning,
.btn-check:checked+.btn-link-warning,
.btn-link-warning.focus,
.btn-link-warning:focus,
.btn-link-warning:hover,
.btn-link-warning:not(:disabled):not(.disabled).active,
.btn-link-warning:not(:disabled):not(.disabled):active,
.show>.btn-link-warning.dropdown-toggle {
    background: #fcf3e6;
    color: #e58a00;
    border-color: #fcf3e6
}

.btn-link-hover-warning {
    background: 0 0;
    color: var(--bs-secondary);
    border-color: transparent
}

.btn-link-hover-warning .material-icons-two-tone {
    background-color: var(--bs-secondary)
}

.btn-check:active+.btn-link-hover-warning,
.btn-check:checked+.btn-link-hover-warning,
.btn-link-hover-warning.focus,
.btn-link-hover-warning:focus,
.btn-link-hover-warning:hover,
.btn-link-hover-warning:not(:disabled):not(.disabled).active,
.btn-link-hover-warning:not(:disabled):not(.disabled):active,
.show>.btn-link-hover-warning.dropdown-toggle {
    background: #fcf3e6;
    color: #e58a00;
    border-color: #fcf3e6
}

.btn-light-danger {
    background: #fce9e9;
    color: #dc2626;
    border-color: #fce9e9
}

.btn-light-danger .material-icons-two-tone {
    background-color: #dc2626
}

.btn-light-danger:hover {
    background: #dc2626;
    color: #fff;
    border-color: #dc2626
}

.btn-light-danger:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-danger.focus,
.btn-light-danger:focus {
    background: #dc2626;
    color: #fff;
    border-color: #dc2626
}

.btn-light-danger.focus .material-icons-two-tone,
.btn-light-danger:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-danger:not(:disabled):not(.disabled).active,
.btn-light-danger:not(:disabled):not(.disabled):active,
.show>.btn-light-danger.dropdown-toggle {
    background: #dc2626;
    color: #fff;
    border-color: #dc2626
}

.btn-light-danger:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-danger:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-danger.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-check:active+.btn-light-danger,
.btn-check:checked+.btn-light-danger {
    background: #dc2626;
    color: #fff;
    border-color: #dc2626
}

.btn-check:active+.btn-light-danger .material-icons-two-tone,
.btn-check:checked+.btn-light-danger .material-icons-two-tone {
    background-color: #fff
}

.btn-link-danger {
    background: 0 0;
    color: #dc2626;
    border-color: transparent
}

.btn-link-danger .material-icons-two-tone {
    background-color: #dc2626
}

.btn-check:active+.btn-link-danger,
.btn-check:checked+.btn-link-danger,
.btn-link-danger.focus,
.btn-link-danger:focus,
.btn-link-danger:hover,
.btn-link-danger:not(:disabled):not(.disabled).active,
.btn-link-danger:not(:disabled):not(.disabled):active,
.show>.btn-link-danger.dropdown-toggle {
    background: #fce9e9;
    color: #dc2626;
    border-color: #fce9e9
}

.btn-link-hover-danger {
    background: 0 0;
    color: var(--bs-secondary);
    border-color: transparent
}

.btn-link-hover-danger .material-icons-two-tone {
    background-color: var(--bs-secondary)
}

.btn-check:active+.btn-link-hover-danger,
.btn-check:checked+.btn-link-hover-danger,
.btn-link-hover-danger.focus,
.btn-link-hover-danger:focus,
.btn-link-hover-danger:hover,
.btn-link-hover-danger:not(:disabled):not(.disabled).active,
.btn-link-hover-danger:not(:disabled):not(.disabled):active,
.show>.btn-link-hover-danger.dropdown-toggle {
    background: #fce9e9;
    color: #dc2626;
    border-color: #fce9e9
}

.btn-light-light {
    background: #fefeff;
    color: #f8f9fa;
    border-color: #fefeff
}

.btn-light-light .material-icons-two-tone {
    background-color: #f8f9fa
}

.btn-light-light:hover {
    background: #f8f9fa;
    color: #fff;
    border-color: #f8f9fa
}

.btn-light-light:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-light.focus,
.btn-light-light:focus {
    background: #f8f9fa;
    color: #fff;
    border-color: #f8f9fa
}

.btn-light-light.focus .material-icons-two-tone,
.btn-light-light:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-light:not(:disabled):not(.disabled).active,
.btn-light-light:not(:disabled):not(.disabled):active,
.show>.btn-light-light.dropdown-toggle {
    background: #f8f9fa;
    color: #fff;
    border-color: #f8f9fa
}

.btn-light-light:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-light:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-light.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-check:active+.btn-light-light,
.btn-check:checked+.btn-light-light {
    background: #f8f9fa;
    color: #fff;
    border-color: #f8f9fa
}

.btn-check:active+.btn-light-light .material-icons-two-tone,
.btn-check:checked+.btn-light-light .material-icons-two-tone {
    background-color: #fff
}

.btn-link-light {
    background: 0 0;
    color: #f8f9fa;
    border-color: transparent
}

.btn-link-light .material-icons-two-tone {
    background-color: #f8f9fa
}

.btn-check:active+.btn-link-light,
.btn-check:checked+.btn-link-light,
.btn-link-light.focus,
.btn-link-light:focus,
.btn-link-light:hover,
.btn-link-light:not(:disabled):not(.disabled).active,
.btn-link-light:not(:disabled):not(.disabled):active,
.show>.btn-link-light.dropdown-toggle {
    background: #fefeff;
    color: #f8f9fa;
    border-color: #fefeff
}

.btn-link-hover-light {
    background: 0 0;
    color: var(--bs-secondary);
    border-color: transparent
}

.btn-link-hover-light .material-icons-two-tone {
    background-color: var(--bs-secondary)
}

.btn-check:active+.btn-link-hover-light,
.btn-check:checked+.btn-link-hover-light,
.btn-link-hover-light.focus,
.btn-link-hover-light:focus,
.btn-link-hover-light:hover,
.btn-link-hover-light:not(:disabled):not(.disabled).active,
.btn-link-hover-light:not(:disabled):not(.disabled):active,
.show>.btn-link-hover-light.dropdown-toggle {
    background: #fefeff;
    color: #f8f9fa;
    border-color: #fefeff
}

.btn-light-dark {
    background: #e9e9ea;
    color: #212529;
    border-color: #e9e9ea
}

.btn-light-dark .material-icons-two-tone {
    background-color: #212529
}

.btn-light-dark:hover {
    background: #212529;
    color: #fff;
    border-color: #212529
}

.btn-light-dark:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-dark.focus,
.btn-light-dark:focus {
    background: #212529;
    color: #fff;
    border-color: #212529
}

.btn-light-dark.focus .material-icons-two-tone,
.btn-light-dark:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-dark:not(:disabled):not(.disabled).active,
.btn-light-dark:not(:disabled):not(.disabled):active,
.show>.btn-light-dark.dropdown-toggle {
    background: #212529;
    color: #fff;
    border-color: #212529
}

.btn-light-dark:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-dark:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-dark.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-check:active+.btn-light-dark,
.btn-check:checked+.btn-light-dark {
    background: #212529;
    color: #fff;
    border-color: #212529
}

.btn-check:active+.btn-light-dark .material-icons-two-tone,
.btn-check:checked+.btn-light-dark .material-icons-two-tone {
    background-color: #fff
}

.btn-link-dark {
    background: 0 0;
    color: #212529;
    border-color: transparent
}

.btn-link-dark .material-icons-two-tone {
    background-color: #212529
}

.btn-check:active+.btn-link-dark,
.btn-check:checked+.btn-link-dark,
.btn-link-dark.focus,
.btn-link-dark:focus,
.btn-link-dark:hover,
.btn-link-dark:not(:disabled):not(.disabled).active,
.btn-link-dark:not(:disabled):not(.disabled):active,
.show>.btn-link-dark.dropdown-toggle {
    background: #e9e9ea;
    color: #212529;
    border-color: #e9e9ea
}

.btn-link-hover-dark {
    background: 0 0;
    color: var(--bs-secondary);
    border-color: transparent
}

.btn-link-hover-dark .material-icons-two-tone {
    background-color: var(--bs-secondary)
}

.btn-check:active+.btn-link-hover-dark,
.btn-check:checked+.btn-link-hover-dark,
.btn-link-hover-dark.focus,
.btn-link-hover-dark:focus,
.btn-link-hover-dark:hover,
.btn-link-hover-dark:not(:disabled):not(.disabled).active,
.btn-link-hover-dark:not(:disabled):not(.disabled):active,
.show>.btn-link-hover-dark.dropdown-toggle {
    background: #e9e9ea;
    color: #212529;
    border-color: #e9e9ea
}

.btn-light-facebook {
    background: #ecf0f7;
    color: #4267b2;
    border-color: #ecf0f7
}

.btn-light-facebook .material-icons-two-tone {
    background-color: #4267b2
}

.btn-light-facebook:hover {
    background: #4267b2;
    color: #fff;
    border-color: #4267b2
}

.btn-light-facebook:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-facebook.focus,
.btn-light-facebook:focus {
    background: #4267b2;
    color: #fff;
    border-color: #4267b2
}

.btn-light-facebook.focus .material-icons-two-tone,
.btn-light-facebook:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-facebook:not(:disabled):not(.disabled).active,
.btn-light-facebook:not(:disabled):not(.disabled):active,
.show>.btn-light-facebook.dropdown-toggle {
    background: #4267b2;
    color: #fff;
    border-color: #4267b2
}

.btn-light-facebook:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-facebook:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-facebook.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-light-twitter {
    background: #ecf9ff;
    color: #42c0fb;
    border-color: #ecf9ff
}

.btn-light-twitter .material-icons-two-tone {
    background-color: #42c0fb
}

.btn-light-twitter:hover {
    background: #42c0fb;
    color: #fff;
    border-color: #42c0fb
}

.btn-light-twitter:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-twitter.focus,
.btn-light-twitter:focus {
    background: #42c0fb;
    color: #fff;
    border-color: #42c0fb
}

.btn-light-twitter.focus .material-icons-two-tone,
.btn-light-twitter:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-twitter:not(:disabled):not(.disabled).active,
.btn-light-twitter:not(:disabled):not(.disabled):active,
.show>.btn-light-twitter.dropdown-toggle {
    background: #42c0fb;
    color: #fff;
    border-color: #42c0fb
}

.btn-light-twitter:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-twitter:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-twitter.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-light-dribbble {
    background: #fdedf3;
    color: #ec4a89;
    border-color: #fdedf3
}

.btn-light-dribbble .material-icons-two-tone {
    background-color: #ec4a89
}

.btn-light-dribbble:hover {
    background: #ec4a89;
    color: #fff;
    border-color: #ec4a89
}

.btn-light-dribbble:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-dribbble.focus,
.btn-light-dribbble:focus {
    background: #ec4a89;
    color: #fff;
    border-color: #ec4a89
}

.btn-light-dribbble.focus .material-icons-two-tone,
.btn-light-dribbble:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-dribbble:not(:disabled):not(.disabled).active,
.btn-light-dribbble:not(:disabled):not(.disabled):active,
.show>.btn-light-dribbble.dropdown-toggle {
    background: #ec4a89;
    color: #fff;
    border-color: #ec4a89
}

.btn-light-dribbble:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-dribbble:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-dribbble.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-light-pinterest {
    background: #f9e9ea;
    color: #bf2131;
    border-color: #f9e9ea
}

.btn-light-pinterest .material-icons-two-tone {
    background-color: #bf2131
}

.btn-light-pinterest:hover {
    background: #bf2131;
    color: #fff;
    border-color: #bf2131
}

.btn-light-pinterest:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-pinterest.focus,
.btn-light-pinterest:focus {
    background: #bf2131;
    color: #fff;
    border-color: #bf2131
}

.btn-light-pinterest.focus .material-icons-two-tone,
.btn-light-pinterest:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-pinterest:not(:disabled):not(.disabled).active,
.btn-light-pinterest:not(:disabled):not(.disabled):active,
.show>.btn-light-pinterest.dropdown-toggle {
    background: #bf2131;
    color: #fff;
    border-color: #bf2131
}

.btn-light-pinterest:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-pinterest:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-pinterest.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-light-youtube {
    background: #fceae8;
    color: #e0291d;
    border-color: #fceae8
}

.btn-light-youtube .material-icons-two-tone {
    background-color: #e0291d
}

.btn-light-youtube:hover {
    background: #e0291d;
    color: #fff;
    border-color: #e0291d
}

.btn-light-youtube:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-youtube.focus,
.btn-light-youtube:focus {
    background: #e0291d;
    color: #fff;
    border-color: #e0291d
}

.btn-light-youtube.focus .material-icons-two-tone,
.btn-light-youtube:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-youtube:not(:disabled):not(.disabled).active,
.btn-light-youtube:not(:disabled):not(.disabled):active,
.show>.btn-light-youtube.dropdown-toggle {
    background: #e0291d;
    color: #fff;
    border-color: #e0291d
}

.btn-light-youtube:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-youtube:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-youtube.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-light-googleplus {
    background: #f9ecea;
    color: #c73e2e;
    border-color: #f9ecea
}

.btn-light-googleplus .material-icons-two-tone {
    background-color: #c73e2e
}

.btn-light-googleplus:hover {
    background: #c73e2e;
    color: #fff;
    border-color: #c73e2e
}

.btn-light-googleplus:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-googleplus.focus,
.btn-light-googleplus:focus {
    background: #c73e2e;
    color: #fff;
    border-color: #c73e2e
}

.btn-light-googleplus.focus .material-icons-two-tone,
.btn-light-googleplus:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-googleplus:not(:disabled):not(.disabled).active,
.btn-light-googleplus:not(:disabled):not(.disabled):active,
.show>.btn-light-googleplus.dropdown-toggle {
    background: #c73e2e;
    color: #fff;
    border-color: #c73e2e
}

.btn-light-googleplus:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-googleplus:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-googleplus.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-light-instagram {
    background: #f7f2ef;
    color: #aa7c62;
    border-color: #f7f2ef
}

.btn-light-instagram .material-icons-two-tone {
    background-color: #aa7c62
}

.btn-light-instagram:hover {
    background: #aa7c62;
    color: #fff;
    border-color: #aa7c62
}

.btn-light-instagram:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-instagram.focus,
.btn-light-instagram:focus {
    background: #aa7c62;
    color: #fff;
    border-color: #aa7c62
}

.btn-light-instagram.focus .material-icons-two-tone,
.btn-light-instagram:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-instagram:not(:disabled):not(.disabled).active,
.btn-light-instagram:not(:disabled):not(.disabled):active,
.show>.btn-light-instagram.dropdown-toggle {
    background: #aa7c62;
    color: #fff;
    border-color: #aa7c62
}

.btn-light-instagram:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-instagram:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-instagram.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-light-viber {
    background: #f2eef5;
    color: #7b519d;
    border-color: #f2eef5
}

.btn-light-viber .material-icons-two-tone {
    background-color: #7b519d
}

.btn-light-viber:hover {
    background: #7b519d;
    color: #fff;
    border-color: #7b519d
}

.btn-light-viber:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-viber.focus,
.btn-light-viber:focus {
    background: #7b519d;
    color: #fff;
    border-color: #7b519d
}

.btn-light-viber.focus .material-icons-two-tone,
.btn-light-viber:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-viber:not(:disabled):not(.disabled).active,
.btn-light-viber:not(:disabled):not(.disabled):active,
.show>.btn-light-viber.dropdown-toggle {
    background: #7b519d;
    color: #fff;
    border-color: #7b519d
}

.btn-light-viber:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-viber:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-viber.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-light-behance {
    background: #e6eeff;
    color: #0057ff;
    border-color: #e6eeff
}

.btn-light-behance .material-icons-two-tone {
    background-color: #0057ff
}

.btn-light-behance:hover {
    background: #0057ff;
    color: #fff;
    border-color: #0057ff
}

.btn-light-behance:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-behance.focus,
.btn-light-behance:focus {
    background: #0057ff;
    color: #fff;
    border-color: #0057ff
}

.btn-light-behance.focus .material-icons-two-tone,
.btn-light-behance:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-behance:not(:disabled):not(.disabled).active,
.btn-light-behance:not(:disabled):not(.disabled):active,
.show>.btn-light-behance.dropdown-toggle {
    background: #0057ff;
    color: #fff;
    border-color: #0057ff
}

.btn-light-behance:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-behance:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-behance.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-light-dropbox {
    background: #ebf2ff;
    color: #3380ff;
    border-color: #ebf2ff
}

.btn-light-dropbox .material-icons-two-tone {
    background-color: #3380ff
}

.btn-light-dropbox:hover {
    background: #3380ff;
    color: #fff;
    border-color: #3380ff
}

.btn-light-dropbox:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-dropbox.focus,
.btn-light-dropbox:focus {
    background: #3380ff;
    color: #fff;
    border-color: #3380ff
}

.btn-light-dropbox.focus .material-icons-two-tone,
.btn-light-dropbox:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-dropbox:not(:disabled):not(.disabled).active,
.btn-light-dropbox:not(:disabled):not(.disabled):active,
.show>.btn-light-dropbox.dropdown-toggle {
    background: #3380ff;
    color: #fff;
    border-color: #3380ff
}

.btn-light-dropbox:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-dropbox:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-dropbox.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-light-linkedin {
    background: #e6f1f8;
    color: #0077b5;
    border-color: #e6f1f8
}

.btn-light-linkedin .material-icons-two-tone {
    background-color: #0077b5
}

.btn-light-linkedin:hover {
    background: #0077b5;
    color: #fff;
    border-color: #0077b5
}

.btn-light-linkedin:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-linkedin.focus,
.btn-light-linkedin:focus {
    background: #0077b5;
    color: #fff;
    border-color: #0077b5
}

.btn-light-linkedin.focus .material-icons-two-tone,
.btn-light-linkedin:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-linkedin:not(:disabled):not(.disabled).active,
.btn-light-linkedin:not(:disabled):not(.disabled):active,
.show>.btn-light-linkedin.dropdown-toggle {
    background: #0077b5;
    color: #fff;
    border-color: #0077b5
}

.btn-light-linkedin:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-linkedin:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-linkedin.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-light-amazon {
    background: #e6e6e6;
    color: #000;
    border-color: #e6e6e6
}

.btn-light-amazon .material-icons-two-tone {
    background-color: #000
}

.btn-light-amazon:hover {
    background: #000;
    color: #fff;
    border-color: #000
}

.btn-light-amazon:hover .material-icons-two-tone {
    background-color: #fff
}

.btn-light-amazon.focus,
.btn-light-amazon:focus {
    background: #000;
    color: #fff;
    border-color: #000
}

.btn-light-amazon.focus .material-icons-two-tone,
.btn-light-amazon:focus .material-icons-two-tone {
    background-color: #fff
}

.btn-light-amazon:not(:disabled):not(.disabled).active,
.btn-light-amazon:not(:disabled):not(.disabled):active,
.show>.btn-light-amazon.dropdown-toggle {
    background: #000;
    color: #fff;
    border-color: #000
}

.btn-light-amazon:not(:disabled):not(.disabled).active .material-icons-two-tone,
.btn-light-amazon:not(:disabled):not(.disabled):active .material-icons-two-tone,
.show>.btn-light-amazon.dropdown-toggle .material-icons-two-tone {
    background-color: #fff
}

.btn-pc-default:not(:hover) {
    color: var(--bs-secondary)
}

.pc-header .dropdown-menu {
    animation: .3s ease-in-out 0s normal forwards .3s fadein
}

@keyframes fadein {
    from {
        transform: translate3d(0, 8px, 0);
        opacity: 0
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

.dropdown .dropdown-item {
    display: flex;
    align-items: center
}

.card .card-header .card-header-right .btn.dropdown-toggle:after,
.card .card-header .card-header-right .introjs-tooltip .dropdown-toggle.introjs-button:after,
.dropzone-multi .dropzone-panel .dropzone-remove-all,
.dropzone-multi .dropzone-panel .dropzone-upload,
.dropzone-multi .dz-message,
.dz-started .dropzone-msg,
.introjs-tooltip .card .card-header .card-header-right .dropdown-toggle.introjs-button:after {
    display: none
}

.dropdown .dropdown-item.active,
.dropdown .dropdown-item:active,
.dropdown .dropdown-item:focus,
.dropdown .dropdown-item:hover {
    background: var(--pc-active-background);
    color: var(--bs-dropdown-link-color)
}

.dropdown .dropdown-item.active i.material-icons-two-tone,
.dropdown .dropdown-item:active i.material-icons-two-tone,
.dropdown .dropdown-item:focus i.material-icons-two-tone,
.dropdown .dropdown-item:hover i.material-icons-two-tone {
    background-color: var(--bs-dropdown-link-color)
}

.dropdown-menu {
    box-shadow: 0 4px 24px 0 rgba(62, 57, 107, .18);
    border: none;
    padding: 8px
}

.dropdown-menu .dropdown-item {
    border-radius: 8px;
    padding: 10px 15px
}

.dropdown-menu .dropdown-item i {
    font-size: 18px;
    margin-right: 10px
}

.dropdown-menu .dropdown-item i.material-icons-two-tone {
    vertical-align: bottom;
    font-size: 22px;
    background-color: var(--pc-header-color)
}

.dropdown-menu .dropdown-item svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    fill: #f2f2f2
}

.dropdown-menu .dropdown-item .float-right svg {
    width: 14px;
    height: 14px
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg)
}

.card {
    box-shadow: var(--pc-card-box-shadow);
    margin-bottom: 24px;
    transition: box-shadow .2s ease-in-out
}

.card .card-header {
    border-bottom: 1px solid var(--bs-card-border-color)
}

.card .card-header .h5,
.card .card-header h5 {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1rem
}

.card .card-header .h5+.small:last-child,
.card .card-header .h5+p:last-child,
.card .card-header .h5+small:last-child,
.card .card-header h5+.small:last-child,
.card .card-header h5+p:last-child,
.card .card-header h5+small:last-child {
    margin-bottom: 0
}

.card .card-header .card-header-right {
    right: 10px;
    top: 10px;
    float: right;
    padding: 0;
    position: absolute
}

@media only screen and (max-width:575px) {
    .card .card-header .card-header-right {
        display: none
    }
}

.card .card-header .card-header-right .dropdown-menu {
    margin-top: 0
}

.card .card-header .card-header-right .dropdown-menu li {
    cursor: pointer
}

.card .card-header .card-header-right .dropdown-menu li a {
    font-size: 14px;
    text-transform: capitalize
}

.card .card-header .card-header-right .btn.dropdown-toggle,
.card .card-header .card-header-right .introjs-tooltip .dropdown-toggle.introjs-button,
.introjs-tooltip .card .card-header .card-header-right .dropdown-toggle.introjs-button {
    color: #888;
    border: none;
    background: 0 0;
    box-shadow: none;
    padding: 0;
    width: 20px;
    height: 20px;
    right: 8px;
    top: 8px
}

.card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span,
.card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span:after,
.card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span:before,
.card .card-header .card-header-right .introjs-tooltip .dropdown-toggle.mobile-menu.introjs-button span,
.card .card-header .card-header-right .introjs-tooltip .dropdown-toggle.mobile-menu.introjs-button span:after,
.card .card-header .card-header-right .introjs-tooltip .dropdown-toggle.mobile-menu.introjs-button span:before,
.introjs-tooltip .card .card-header .card-header-right .dropdown-toggle.mobile-menu.introjs-button span,
.introjs-tooltip .card .card-header .card-header-right .dropdown-toggle.mobile-menu.introjs-button span:after,
.introjs-tooltip .card .card-header .card-header-right .dropdown-toggle.mobile-menu.introjs-button span:before {
    border-radius: 5px;
    height: 2px;
    background-color: #888
}

.card .card-header .card-header-right .btn.dropdown-toggle i,
.card .card-header .card-header-right .introjs-tooltip .dropdown-toggle.introjs-button i,
.introjs-tooltip .card .card-header .card-header-right .dropdown-toggle.introjs-button i {
    margin-right: 0
}

.card .card-header .card-header-right .btn.dropdown-toggle:focus,
.card .card-header .card-header-right .introjs-tooltip .dropdown-toggle.introjs-button:focus,
.introjs-tooltip .card .card-header .card-header-right .dropdown-toggle.introjs-button:focus {
    box-shadow: none;
    outline: 0
}

.card .card-header .card-header-right .nav-pills {
    padding: 0;
    box-shadow: none;
    background: 0 0
}

.card .card-footer {
    transition: box-shadow .2s ease-in-out
}

.card:hover .card-footer[class*=bg-] {
    box-shadow: none
}

@media (max-width:575.98px) {

    .card .card-body,
    .card .card-header {
        padding: 20px
    }

    .card {
        margin-bottom: 20px
    }

    .card .card-header .h5,
    .card .card-header h5 {
        font-size: .875rem
    }
}

.tabs-border.nav-tabs .nav-item,
.tabs-light.nav-pill .nav-item {
    margin-bottom: 0
}

.progress {
    overflow: visible
}

.progress .progress-bar {
    border-radius: 8px
}

.progress.progress-theme {
    background: #ffebf0
}

.progress.progress-theme .progress-bar {
    background: #f36
}

.progress.progress-primary {
    background: #edf2ff
}

.progress.progress-primary .progress-bar {
    background: #4680ff
}

.fc-event,
.progress.progress-secondary {
    background: #eff0f2
}

.progress.progress-secondary .progress-bar {
    background: #5b6b79
}

.progress.progress-success {
    background: #eaf6f2
}

.progress.progress-success .progress-bar {
    background: #2ca87f
}

.progress.progress-info {
    background: #ecfafb
}

.progress.progress-info .progress-bar {
    background: #3ec9d6
}

.progress.progress-warning {
    background: #fcf3e6
}

.progress.progress-warning .progress-bar {
    background: #e58a00
}

.progress.progress-danger {
    background: #fce9e9
}

.progress.progress-danger .progress-bar {
    background: #dc2626
}

.progress.progress-light {
    background: #fefeff
}

.progress.progress-light .progress-bar {
    background: #f8f9fa
}

.progress.progress-dark {
    background: #e9e9ea
}

.progress.progress-dark .progress-bar {
    background: #212529
}

.progress-stacked {
    overflow: hidden
}

.progress-stacked .progress:not(:first-child) .progress-bar {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.progress-stacked .progress:not(:last-child) .progress-bar {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.table.table-align-center td,
.table.table-align-center th {
    vertical-align: middle
}

.table thead th {
    padding: .9rem .75rem
}

.table.table-borderless td,
.table.table-borderless th {
    border: none !important
}

@media (max-width:1199.98px) {

    .affiliate-table tr td:first-child,
    .affiliate-table tr th:first-child {
        min-width: 150px
    }
}

.tabs-border.nav-tabs .nav-link {
    border: none;
    background: center bottom no-repeat, center 100%;
    background-size: 0 100%, 100% 100%;
    transition: background .3s ease-out;
    background-image: linear-gradient(to top, theme-color("primary") 2px, rgba(255, 255, 255, 0) 2px)
}

.tabs-border.nav-tabs .nav-link.active {
    background-size: 100% 100%, 100% 100%
}

.tabs-light.nav-pill+.tab-content {
    border-top: 1px solid #e7eaee
}

.tabs-light.nav-pill .nav-item .nav-link {
    color: #4680ff;
    background: #edf2ff;
    border-radius: 4px;
    transition: background .3s ease-out
}

.tabs-light.nav-pill .nav-link {
    border: none
}

.tabs-light.nav-pill .nav-link.active {
    color: #fff;
    background: #4680ff
}

.choices__list--dropdown {
    z-index: 9999 !important
}

.custom-select:hover,
.dataTable-input:hover,
.dataTable-selector:hover,
.datatable-input:hover,
.form-control:hover,
select.form-control:hover {
    background-color: #f8f9fa
}

.form-control[readonly],
[readonly].custom-select,
[readonly].dataTable-input,
[readonly].dataTable-selector,
[readonly].datatable-input,
select.form-control[readonly] {
    opacity: .6
}

.input-group-text svg {
    width: 18px;
    height: 18px
}

.form-control-color-picker {
    height: 43px;
    padding: .5rem
}

select.custom-select,
select.dataTable-input,
select.dataTable-selector,
select.datatable-input,
select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") right .75rem center/18px 25px no-repeat #fff
}

select.form-control[data-multiselectsplitter-firstselect-selector],
select.form-control[data-multiselectsplitter-secondselect-selector],
select[data-multiselectsplitter-firstselect-selector].custom-select,
select[data-multiselectsplitter-firstselect-selector].dataTable-input,
select[data-multiselectsplitter-firstselect-selector].dataTable-selector,
select[data-multiselectsplitter-firstselect-selector].datatable-input,
select[data-multiselectsplitter-secondselect-selector].custom-select,
select[data-multiselectsplitter-secondselect-selector].dataTable-input,
select[data-multiselectsplitter-secondselect-selector].dataTable-selector,
select[data-multiselectsplitter-secondselect-selector].datatable-input {
    background: 0 0
}

.form-floating>label {
    top: 1px
}

.form-floating>.custom-select:not(:-moz-placeholder-shown)~label,
.form-floating>.dataTable-input:not(:-moz-placeholder-shown)~label,
.form-floating>.dataTable-selector:not(:-moz-placeholder-shown)~label,
.form-floating>.datatable-input:not(:-moz-placeholder-shown)~label,
.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    color: #5b6b79
}

.form-floating>.custom-select:focus~label,
.form-floating>.custom-select:not(:placeholder-shown)~label,
.form-floating>.dataTable-input:focus~label,
.form-floating>.dataTable-input:not(:placeholder-shown)~label,
.form-floating>.dataTable-selector:focus~label,
.form-floating>.dataTable-selector:not(:placeholder-shown)~label,
.form-floating>.datatable-input:focus~label,
.form-floating>.datatable-input:not(:placeholder-shown)~label,
.form-floating>.datatable-selector~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    color: #5b6b79
}

.auth-option .btn-check:checked+.auth-megaoption span,
.auth-option .btn-check:checked+.auth-megaoption svg,
.form-floating>.custom-select:focus~label,
.form-floating>.dataTable-input:focus~label,
.form-floating>.dataTable-selector:focus~label,
.form-floating>.datatable-input:focus~label,
.form-floating>.form-control:focus~label {
    color: var(--bs-primary)
}

.form-floating>input {
    color: #131920
}

.bootstrap-select -select>.dropdown-toggle.btn-default,
.bootstrap-select -select>.dropdown-toggle.btn-light,
.bootstrap-select -select>.dropdown-toggle.btn-secondary,
.bootstrap-select .introjs-tooltip -select>.dropdown-toggle.introjs-button.introjs-prevbutton,
.introjs-tooltip .bootstrap-select -select>.dropdown-toggle.introjs-button.introjs-prevbutton {
    border-color: #bec8d0 !important;
    box-shadow: none;
    background: #fff !important;
    color: #131920
}

.bootstrap-select -select>.dropdown-toggle.btn-default:focus,
.bootstrap-select -select>.dropdown-toggle.btn-light:focus,
.bootstrap-select -select>.dropdown-toggle.btn-secondary:focus,
.bootstrap-select .introjs-tooltip -select>.dropdown-toggle.introjs-button.introjs-prevbutton:focus,
.introjs-tooltip .bootstrap-select -select>.dropdown-toggle.introjs-button.introjs-prevbutton:focus {
    outline: 0 !important
}

.form-search {
    position: relative
}

.form-search i {
    position: absolute;
    top: 14px;
    left: 15px;
    font-size: 20px
}

.form-search .custom-select,
.form-search .dataTable-input,
.form-search .dataTable-selector,
.form-search .datatable-input,
.form-search .form-control {
    padding-left: 50px
}

.form-check label,
.form-check label input {
    cursor: pointer
}

.form-check-input.f-12 {
    margin-left: -2.9em
}

.form-check .form-check-input.input-theme:checked {
    border-color: #f36;
    background-color: #f36
}

.form-check .form-check-input.input-light-theme:checked {
    border-color: #ffebf0;
    background-color: #ffebf0
}

.form-check .form-check-input.input-light-theme:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ff3366' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-light-theme:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ff3366'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-light-theme:focus[type=checkbox],
.form-check .form-check-input.input-light-theme:focus[type=radio],
.form-check .form-check-input.input-theme:focus[type=checkbox],
.form-check .form-check-input.input-theme:focus[type=radio] {
    box-shadow: 0 0 0 .2rem rgba(255, 51, 102, .25);
    border-color: #f36
}

.form-check.form-switch .form-check-input.input-light-theme:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ff3366'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-primary:checked {
    border-color: #4680ff;
    background-color: #4680ff
}

.form-check .form-check-input.input-light-primary:checked {
    border-color: #edf2ff;
    background-color: #edf2ff
}

.form-check .form-check-input.input-light-primary:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%234680ff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-light-primary:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%234680ff'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-light-primary:focus[type=checkbox],
.form-check .form-check-input.input-light-primary:focus[type=radio],
.form-check .form-check-input.input-primary:focus[type=checkbox],
.form-check .form-check-input.input-primary:focus[type=radio] {
    box-shadow: 0 0 0 .2rem rgba(70, 128, 255, .25);
    border-color: #4680ff
}

.form-check.form-switch .form-check-input.input-light-primary:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%234680ff'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-secondary:checked {
    border-color: #5b6b79;
    background-color: #5b6b79
}

.form-check .form-check-input.input-light-secondary:checked {
    border-color: #eff0f2;
    background-color: #eff0f2
}

.form-check .form-check-input.input-light-secondary:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%235b6b79' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-light-secondary:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%235b6b79'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-light-secondary:focus[type=checkbox],
.form-check .form-check-input.input-light-secondary:focus[type=radio],
.form-check .form-check-input.input-secondary:focus[type=checkbox],
.form-check .form-check-input.input-secondary:focus[type=radio] {
    box-shadow: 0 0 0 .2rem rgba(91, 107, 121, .25);
    border-color: #5b6b79
}

.form-check.form-switch .form-check-input.input-light-secondary:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%235b6b79'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-success:checked {
    border-color: #2ca87f;
    background-color: #2ca87f
}

.form-check .form-check-input.input-light-success:checked {
    border-color: #eaf6f2;
    background-color: #eaf6f2
}

.form-check .form-check-input.input-light-success:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%232ca87f' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-light-success:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%232ca87f'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-light-success:focus[type=checkbox],
.form-check .form-check-input.input-light-success:focus[type=radio],
.form-check .form-check-input.input-success:focus[type=checkbox],
.form-check .form-check-input.input-success:focus[type=radio] {
    box-shadow: 0 0 0 .2rem rgba(44, 168, 127, .25);
    border-color: #2ca87f
}

.form-check.form-switch .form-check-input.input-light-success:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%232ca87f'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-info:checked {
    border-color: #3ec9d6;
    background-color: #3ec9d6
}

.form-check .form-check-input.input-light-info:checked {
    border-color: #ecfafb;
    background-color: #ecfafb
}

.form-check .form-check-input.input-light-info:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%233ec9d6' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-light-info:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%233ec9d6'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-info:focus[type=checkbox],
.form-check .form-check-input.input-info:focus[type=radio],
.form-check .form-check-input.input-light-info:focus[type=checkbox],
.form-check .form-check-input.input-light-info:focus[type=radio] {
    box-shadow: 0 0 0 .2rem rgba(62, 201, 214, .25);
    border-color: #3ec9d6
}

.form-check.form-switch .form-check-input.input-light-info:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%233ec9d6'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-warning:checked {
    border-color: #e58a00;
    background-color: #e58a00
}

.form-check .form-check-input.input-light-warning:checked {
    border-color: #fcf3e6;
    background-color: #fcf3e6
}

.form-check .form-check-input.input-light-warning:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23e58a00' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-light-warning:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23e58a00'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-light-warning:focus[type=checkbox],
.form-check .form-check-input.input-light-warning:focus[type=radio],
.form-check .form-check-input.input-warning:focus[type=checkbox],
.form-check .form-check-input.input-warning:focus[type=radio] {
    box-shadow: 0 0 0 .2rem rgba(229, 138, 0, .25);
    border-color: #e58a00
}

.form-check.form-switch .form-check-input.input-light-warning:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23e58a00'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-danger:checked {
    border-color: #dc2626;
    background-color: #dc2626
}

.form-check .form-check-input.input-light-danger:checked {
    border-color: #fce9e9;
    background-color: #fce9e9
}

.form-check .form-check-input.input-light-danger:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23dc2626' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-light-danger:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23dc2626'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-danger:focus[type=checkbox],
.form-check .form-check-input.input-danger:focus[type=radio],
.form-check .form-check-input.input-light-danger:focus[type=checkbox],
.form-check .form-check-input.input-light-danger:focus[type=radio] {
    box-shadow: 0 0 0 .2rem rgba(220, 38, 38, .25);
    border-color: #dc2626
}

.form-check.form-switch .form-check-input.input-light-danger:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23dc2626'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-light:checked {
    border-color: #f8f9fa;
    background-color: #f8f9fa
}

.form-check .form-check-input.input-light-light:checked {
    border-color: #fefeff;
    background-color: #fefeff
}

.form-check .form-check-input.input-light-light:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23f8f9fa' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-light-light:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23f8f9fa'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-light-light:focus[type=checkbox],
.form-check .form-check-input.input-light-light:focus[type=radio],
.form-check .form-check-input.input-light:focus[type=checkbox],
.form-check .form-check-input.input-light:focus[type=radio] {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .25);
    border-color: #f8f9fa
}

.form-check.form-switch .form-check-input.input-light-light:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f8f9fa'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-dark:checked {
    border-color: #212529;
    background-color: #212529
}

.form-check .form-check-input.input-light-dark:checked {
    border-color: #e9e9ea;
    background-color: #e9e9ea
}

.form-check .form-check-input.input-light-dark:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23212529' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-light-dark:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23212529'/%3e%3c/svg%3e")
}

.form-check .form-check-input.input-dark:focus[type=checkbox],
.form-check .form-check-input.input-dark:focus[type=radio],
.form-check .form-check-input.input-light-dark:focus[type=checkbox],
.form-check .form-check-input.input-light-dark:focus[type=radio] {
    box-shadow: 0 0 0 .2rem rgba(33, 37, 41, .25);
    border-color: #212529
}

.form-check.form-switch .form-check-input.input-light-dark:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23212529'/%3e%3c/svg%3e")
}

.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text,
.introjs-tooltip .input-group>.input-group-append:last-child>.introjs-button:not(:last-child):not(.dropdown-toggle),
.introjs-tooltip .input-group>.input-group-append:not(:last-child)>.introjs-button,
.introjs-tooltip .input-group>.input-group-prepend>.introjs-button {
    border-right: none
}

.form-v1 .form-label-group {
    position: relative
}

.form-v1 .form-label-group>input,
.form-v1 .form-label-group>label {
    height: 3.125rem;
    padding: .75rem
}

.form-v1 .form-label-group>label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    color: #495057;
    pointer-events: none;
    cursor: text;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out
}

.form-v1 .form-label-group input::-moz-placeholder {
    color: transparent
}

.form-v1 .form-label-group input::placeholder {
    color: transparent
}

.form-v1 .form-label-group input:not(:-moz-placeholder-shown) {
    padding-top: 1.25rem;
    padding-bottom: .25rem
}

.form-v1 .form-label-group input:not(:placeholder-shown) {
    padding-top: 1.25rem;
    padding-bottom: .25rem
}

.form-v1 .form-label-group input:not(:-moz-placeholder-shown)~label {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 12px;
    color: #777
}

.form-v1 .form-label-group input:not(:placeholder-shown)~label {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 12px;
    color: #777
}

.datepicker,
.datepicker.dropdown-menu {
    padding: 10px
}

.datepicker {
    width: 275px
}

.datepicker.datepicker-orient-top {
    margin-top: 8px
}

.datepicker table {
    width: 100%
}

.datepicker td,
.datepicker th {
    font-weight: regular;
    width: 35px;
    height: 35px
}

.datepicker thead th {
    color: #262626
}

.datepicker thead th.datepicker-switch,
.datepicker thead th.next,
.datepicker thead th.prev {
    font-weight: 500;
    color: #131920
}

.datepicker thead th.datepicker-switch i,
.datepicker thead th.next i,
.datepicker thead th.prev i {
    font-size: 1.2rem;
    color: #131920
}

.datepicker thead th.datepicker-switch i:before,
.datepicker thead th.next i:before,
.datepicker thead th.prev i:before {
    line-height: 0;
    vertical-align: middle
}

.datepicker thead th.datepicker-switch:hover,
.datepicker thead th.next:hover,
.datepicker thead th.prev:hover {
    background: #f8f9fa !important
}

.datepicker thead th.dow {
    color: #131920;
    font-weight: 500
}

.datepicker tbody tr>td.day {
    color: #131920
}

.datepicker tbody tr>td.day:hover {
    background: #f8f9fa;
    color: #131920
}

.datepicker tbody tr>td.day.new,
.datepicker tbody tr>td.day.old {
    color: #131920
}

.datepicker tbody tr>td.day.active,
.datepicker tbody tr>td.day.active:hover,
.datepicker tbody tr>td.day.selected,
.datepicker tbody tr>td.day.selected:hover {
    background: #4680ff;
    color: #fff
}

.datepicker tbody tr>td.day.today {
    position: relative;
    background: #4680ff !important;
    color: #fff !important
}

.datepicker tbody tr>td span.hour:hover,
.datepicker tbody tr>td span.minute:hover,
.datepicker tbody tr>td span.month:hover,
.datepicker tbody tr>td span.year:hover,
.datepicker tbody tr>td.day.range {
    background: #f8f9fa
}

.datepicker tbody tr>td.day.today:before {
    content: "";
    display: inline-block;
    border: solid transparent;
    border-width: 0 0 7px 7px;
    border-bottom-color: #fff;
    border-top-color: #131920;
    position: absolute;
    bottom: 4px;
    right: 4px
}

.datepicker tbody tr>td span.hour,
.datepicker tbody tr>td span.minute,
.datepicker tbody tr>td span.month,
.datepicker tbody tr>td span.year {
    color: #131920
}

.datepicker tbody tr>td span.hour.active,
.datepicker tbody tr>td span.hour.active.focused:hover,
.datepicker tbody tr>td span.hour.active:hover,
.datepicker tbody tr>td span.hour.focused,
.datepicker tbody tr>td span.hour.focused:hover,
.datepicker tbody tr>td span.minute.active,
.datepicker tbody tr>td span.minute.active.focused:hover,
.datepicker tbody tr>td span.minute.active:hover,
.datepicker tbody tr>td span.minute.focused,
.datepicker tbody tr>td span.minute.focused:hover,
.datepicker tbody tr>td span.month.active,
.datepicker tbody tr>td span.month.active.focused:hover,
.datepicker tbody tr>td span.month.active:hover,
.datepicker tbody tr>td span.month.focused,
.datepicker tbody tr>td span.month.focused:hover,
.datepicker tbody tr>td span.year.active,
.datepicker tbody tr>td span.year.active.focused:hover,
.datepicker tbody tr>td span.year.active:hover,
.datepicker tbody tr>td span.year.focused,
.datepicker tbody tr>td span.year.focused:hover {
    background: #4680ff;
    color: #fff
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-timepicker-widget table td>a:hover,
.datepicker tfoot tr>th.clear:hover,
.datepicker tfoot tr>th.today:hover {
    background: #f8f9fa
}

.datepicker tfoot tr>th.clear,
.datepicker tfoot tr>th.today {
    font-weight: 500
}

.bootstrap-timepicker-widget {
    left: auto;
    width: 175px
}

.bootstrap-timepicker-widget .bootstrap-timepicker-hour,
.bootstrap-timepicker-widget .bootstrap-timepicker-meridian,
.bootstrap-timepicker-widget .bootstrap-timepicker-minute,
.bootstrap-timepicker-widget .bootstrap-timepicker-second {
    border: none;
    box-shadow: none;
    color: #262626
}

.bootstrap-timepicker-widget table td>a {
    border: 0
}

.bootstrap-timepicker-widget table td>a i {
    font-size: 1.1rem
}

.tagify__tag {
    --tag-bg: #f8f9fa;
    --tag-hover: rgba(70, 128, 255, 0.3);
    --tag-remove-bg: rgba(220, 38, 38, 0.3);
    --tag-remove-btn-bg--hover: #dc2626
}

.tagify__tag.tagify__tag--theme {
    --tag-bg: rgba(255, 51, 102, 0.2)
}

.tagify__tag.tagify__tag--theme div .tagify__tag-text {
    color: #f36
}

.tagify__tag.tagify__tag--primary {
    --tag-bg: rgba(70, 128, 255, 0.2)
}

.tagify__tag.tagify__tag--primary div .tagify__tag-text {
    color: #4680ff
}

.tagify__tag.tagify__tag--secondary {
    --tag-bg: rgba(91, 107, 121, 0.2)
}

.tagify__tag.tagify__tag--secondary div .tagify__tag-text {
    color: #5b6b79
}

.tagify__tag.tagify__tag--success {
    --tag-bg: rgba(44, 168, 127, 0.2)
}

.tagify__tag.tagify__tag--success div .tagify__tag-text {
    color: #2ca87f
}

.tagify__tag.tagify__tag--info {
    --tag-bg: rgba(62, 201, 214, 0.2)
}

.tagify__tag.tagify__tag--info div .tagify__tag-text {
    color: #3ec9d6
}

.tagify__tag.tagify__tag--warning {
    --tag-bg: rgba(229, 138, 0, 0.2)
}

.tagify__tag.tagify__tag--warning div .tagify__tag-text {
    color: #e58a00
}

.tagify__tag.tagify__tag--danger {
    --tag-bg: rgba(220, 38, 38, 0.2)
}

.tagify__tag.tagify__tag--danger div .tagify__tag-text {
    color: #dc2626
}

.tagify__tag.tagify__tag--light {
    --tag-bg: rgba(248, 249, 250, 0.2)
}

.tagify__tag.tagify__tag--light div .tagify__tag-text {
    color: #f8f9fa
}

.tagify__tag.tagify__tag--dark {
    --tag-bg: rgba(33, 37, 41, 0.2)
}

.tagify__tag.tagify__tag--dark div .tagify__tag-text {
    color: #212529
}

.bootstrap-switch {
    border-color: #e7eaee
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-theme,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-theme {
    color: #fff;
    background: #f36
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
    color: #fff;
    background: #4680ff
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-secondary,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-secondary {
    color: #fff;
    background: #5b6b79
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success {
    color: #fff;
    background: #2ca87f
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info {
    color: #fff;
    background: #3ec9d6
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning {
    color: #fff;
    background: #e58a00
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger {
    color: #fff;
    background: #dc2626
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-light,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-light {
    color: #fff;
    background: #f8f9fa
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-dark,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-dark {
    color: #fff;
    background: #212529
}

.bootstrap-select>.dropdown-toggle.bs-placeholder.btn[class*=btn-]:not(.btn-light),
.introjs-tooltip .bootstrap-select>.dropdown-toggle.bs-placeholder[class*=btn-].introjs-button:not(.btn-light) {
    color: #fff
}

.scrollable-dropdown-menu .tt-menu {
    max-height: 150px;
    overflow-y: auto
}

.tt-menu {
    padding: 10px 0;
    font-size: .875rem;
    background: #fff;
    min-width: 10rem;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px
}

.tt-menu .tt-suggestion {
    padding: 4px 20px;
    border-radius: 2px
}

.tt-menu .tt-suggestion.active,
.tt-menu .tt-suggestion:active,
.tt-menu .tt-suggestion:focus,
.tt-menu .tt-suggestion:hover {
    background: rgba(70, 128, 255, .03);
    color: #4680ff
}

.sticky-action {
    top: 74px;
    position: sticky;
    z-index: 1020;
    background: var(--bs-card-bg);
    border-radius: var(--bs-card-border-radius)
}

.dropzone {
    min-height: auto;
    padding: 20px;
    cursor: pointer;
    border: 2px dashed #e7eaee;
    border-radius: 8px
}

.dropzone .dropzone-msg-title {
    color: #131920;
    margin: 0 0 5px;
    padding: 0;
    font-weight: 500;
    font-size: 1.2rem
}

.dropzone .dropzone-msg-desc {
    color: #131920;
    font-weight: 400;
    font-size: 1rem
}

.dropzone .dz-preview .dz-image {
    border-radius: 8px
}

.dropzone.dropzone-theme {
    border-color: #f36
}

.dropzone.dropzone-primary {
    border-color: #4680ff
}

.dropzone.dropzone-secondary {
    border-color: #5b6b79
}

.dropzone.dropzone-success {
    border-color: #2ca87f
}

.dropzone.dropzone-info {
    border-color: #3ec9d6
}

.dropzone.dropzone-warning {
    border-color: #e58a00
}

.dropzone.dropzone-danger {
    border-color: #dc2626
}

.dropzone.dropzone-light {
    border-color: #f8f9fa
}

.dropzone.dropzone-dark {
    border-color: #212529
}

.dropzone-multi {
    border: 0;
    padding: 0
}

.dropzone-multi .dropzone-item {
    background: #f8f9fa;
    border-radius: 8px;
    margin: 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 14px
}

.dropzone-multi .dropzone-item .dropzone-progress {
    width: 20%
}

.dropzone-multi .dropzone-item .dropzone-progress .progress {
    height: .5rem;
    transition: all .2s ease-in-out
}

.dropzone-multi .dropzone-item .dropzone-file .dropzone-filename {
    font-size: .9rem;
    font-weight: 500;
    color: #131920;
    text-overflow: ellipsis;
    margin-right: .5rem
}

.dropzone-multi .dropzone-item .dropzone-file .dropzone-filename b {
    font-size: .9rem;
    font-weight: 500;
    color: #131920
}

.dropzone-multi .dropzone-item .dropzone-file .dropzone-error {
    margin-top: .25rem;
    font-size: .9rem;
    font-weight: 400;
    color: #dc2626;
    text-overflow: ellipsis
}

.dropzone-multi .dropzone-item .dropzone-toolbar {
    margin-left: 1rem;
    display: flex;
    flex-wrap: nowrap
}

.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start {
    height: 25px;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer
}

.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel i,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete i,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start i {
    font-size: .8rem;
    color: #262626
}

.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel:hover i,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete:hover i,
.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start:hover i {
    color: #4680ff
}

.dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start {
    transition: all .2s ease-in-out
}

.uppy-Dashboard--modal,
.uppy-Dashboard--modal .uppy-Dashboard-overlay {
    z-index: 1030
}

.uppy-Dashboard--modal .uppy-Dashboard-inner {
    z-index: 1031
}

.uppy-DragDrop--is-dragdrop-supported {
    border-color: #f8f9fa
}

.pc-uppy-thumbnail-container .pc-uppy-thumbnail img {
    width: 100px
}

.slider-selection {
    box-shadow: none
}

.pc-toggle-noUiSlider {
    height: 50px
}

.pc-toggle-noUiSlider.off .noUi-handle {
    border-color: #dc2626;
    background: #dc2626;
    box-shadow: none
}

.note-editor.card .card-header {
    padding: 0 5px 5px;
    border-bottom-color: #a9a9a9
}

#cke5-inline-demo .ck-content {
    margin-bottom: 1rem;
    padding: 2.5rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .15)
}

#cke5-inline-demo .ck-content .h3,
#cke5-inline-demo .ck-content h3 {
    margin-top: 0
}

#cke5-inline-demo header.ck-content .h2:first-of-type,
#cke5-inline-demo header.ck-content h2:first-of-type {
    margin-top: 0
}

#cke5-inline-demo header.ck-content .h2+.h3,
#cke5-inline-demo header.ck-content .h2+h3,
#cke5-inline-demo header.ck-content h2+.h3,
#cke5-inline-demo header.ck-content h2+h3 {
    margin-top: 0;
    font-weight: 600
}

#cke5-inline-demo .demo-row {
    width: 100%;
    display: flex
}

#cke5-inline-demo .demo-row .demo-row__half {
    width: 50%
}

#cke5-inline-demo .demo-row .demo-row__half:first-of-type {
    padding-right: .5rem
}

#cke5-inline-demo .demo-row .demo-row__half:last-of-type {
    padding-left: .5rem
}

#cke5-inline-demo .demo-row p {
    margin-bottom: 0
}

#cke5-inline-demo .demo-row .h3,
#cke5-inline-demo .demo-row h3 {
    margin: 0;
    font-weight: 600
}

@media (max-width:575.98px) {
    #cke5-inline-demo .ck-content {
        padding: 1rem
    }

    #cke5-inline-demo .demo-row {
        flex-direction: column
    }

    #cke5-inline-demo .demo-row .demo-row__half {
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important
    }
}

.switch-demo .custom-switch-v1 {
    margin-bottom: 4px
}

.custom-switch-v1.form-switch {
    padding-left: 2.9em
}

.custom-switch-v1.form-switch .form-check-input {
    height: 20px;
    width: 35px;
    margin-left: -2.9em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    transition: .35s cubic-bezier(.54, 1.6, .5, 1)
}

.custom-switch-v1.form-switch .form-check-input[class*=input-light-] {
    border: none
}

.custom-switch-v1.form-switch .form-check-input:focus {
    box-shadow: none;
    border-color: rgba(0, 0, 0, .25)
}

.custom-switch-v1.form-switch .form-check-input:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ffffff'/%3e%3c/svg%3e")
}

.custom-switch-v1.form-switch .form-check-input.input-light-theme:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ff3366'/%3e%3c/svg%3e")
}

.custom-switch-v1.form-switch .form-check-input.input-light-primary:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%234680ff'/%3e%3c/svg%3e")
}

.custom-switch-v1.form-switch .form-check-input.input-light-secondary:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%235b6b79'/%3e%3c/svg%3e")
}

.custom-switch-v1.form-switch .form-check-input.input-light-success:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%232ca87f'/%3e%3c/svg%3e")
}

.custom-switch-v1.form-switch .form-check-input.input-light-info:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%233ec9d6'/%3e%3c/svg%3e")
}

.custom-switch-v1.form-switch .form-check-input.input-light-warning:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23e58a00'/%3e%3c/svg%3e")
}

.custom-switch-v1.form-switch .form-check-input.input-light-danger:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23dc2626'/%3e%3c/svg%3e")
}

.custom-switch-v1.form-switch .form-check-input.input-light-light:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23f8f9fa'/%3e%3c/svg%3e")
}

.custom-switch-v1.form-switch .form-check-input.input-light-dark:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23212529'/%3e%3c/svg%3e")
}

.custom-switch-v1 .custom-control-label::before {
    transition: .2s cubic-bezier(.24, 0, .5, 1);
    height: 20px;
    width: 35px;
    border-radius: .8rem;
    top: 0;
    left: -2.55rem
}

.custom-switch-v1 .custom-control-label::after {
    top: calc(.15625rem - 2px);
    left: calc(-2.25rem - 4px);
    height: 19px;
    width: 19px;
    border-radius: .7rem;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 4px 0 0 rgba(0, 0, 0, .04), 0 4px 9px rgba(0, 0, 0, .13), 0 3px 3px rgba(0, 0, 0, .05);
    transition: .35s cubic-bezier(.54, 1.6, .5, 1)
}

.custom-switch-v1 .custom-control-input:checked~.custom-control-label::after {
    transform: translateX(.95rem)
}

.row[data-multiselectsplitter-wrapper-selector] select {
    margin-bottom: 8px
}

.choices {
    position: relative;
    margin-bottom: 5px
}

.choices:focus {
    outline: 0
}

.choices:last-child {
    margin-bottom: 0
}

.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
    background-color: #f3f5f7;
    cursor: not-allowed;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.choices.is-disabled .choices__item {
    cursor: not-allowed
}

.choices [hidden] {
    display: none !important
}

.choices[data-type*=select-one] {
    cursor: pointer
}

.choices[data-type*=select-one] .choices__inner {
    padding-bottom: 7.5px
}

.choices[data-type*=select-one] .choices__input {
    display: block;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #bec8d0;
    background-color: #fff;
    margin: 0
}

.choices[data-type*=select-one] .choices__button {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
    padding: 0;
    background-size: 8px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -10px;
    margin-right: 25px;
    height: 20px;
    width: 20px;
    border-radius: 10em;
    opacity: .5
}

.choices[data-type*=select-one] .choices__button:focus,
.choices[data-type*=select-one] .choices__button:hover {
    opacity: 1
}

.choices[data-type*=select-one] .choices__button:focus {
    box-shadow: 0 0 0 2px var(--bs-primary)
}

.choices[data-type*=select-one] .choices__item[data-value=""] .choices__button {
    display: none
}

.choices[data-type*=select-one]:after {
    content: "";
    height: 0;
    width: 0;
    border-style: solid;
    border-color: #131920 transparent transparent;
    border-width: 5px;
    position: absolute;
    right: 11.5px;
    top: 50%;
    margin-top: -2.5px;
    pointer-events: none
}

.choices[data-type*=select-one].is-open:after {
    border-color: transparent transparent #131920;
    margin-top: -7.5px
}

.choices[data-type*=select-one][dir=rtl]:after {
    left: 11.5px;
    right: auto
}

.choices[data-type*=select-one][dir=rtl] .choices__button {
    right: auto;
    left: 0;
    margin-left: 25px;
    margin-right: 0
}

.choices[data-type*=select-multiple] .choices__inner,
.choices[data-type*=text] .choices__inner {
    cursor: text
}

.choices[data-type*=select-multiple] .choices__button,
.choices[data-type*=text] .choices__button {
    position: relative;
    display: inline-block;
    margin: 0 -4px 0 8px;
    padding-left: 16px;
    border-left: 1px solid rgba(255, 255, 255, .35);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
    background-size: 8px;
    width: 8px;
    line-height: 1;
    opacity: .75;
    border-radius: 0
}

.choices[data-type*=select-multiple] .choices__button:focus,
.choices[data-type*=select-multiple] .choices__button:hover,
.choices[data-type*=text] .choices__button:focus,
.choices[data-type*=text] .choices__button:hover {
    opacity: 1
}

.choices__inner {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    background-color: #fff;
    padding: 7.5px 7.5px 3.75px;
    border: 1px solid #bec8d0;
    border-radius: 8px;
    font-size: 14px;
    min-height: 44px;
    overflow: hidden
}

.is-focused .choices__inner,
.is-open .choices__inner {
    border-width: 1px;
    border-color: #4680ff;
    box-shadow: 0 0 0 .2rem rgba(70, 128, 255, .25)
}

.is-open .choices__inner {
    border-radius: 8px 8px 0 0
}

.is-flipped.is-open .choices__inner {
    border-radius: 0 0 8px 8px
}

.choices__list {
    margin: 0;
    padding-left: 0;
    list-style: none
}

.choices__list--single {
    display: inline-block;
    padding: 4px 16px 4px 4px;
    width: 100%
}

[dir=rtl] .choices__list--single {
    padding-right: 4px;
    padding-left: 16px
}

.choices__list--single .choices__item {
    width: 100%
}

.choices__list--multiple {
    display: inline
}

.choices__list--multiple .choices__item {
    display: inline-block;
    vertical-align: middle;
    border-radius: 8px;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: var(--bs-primary);
    border: 1px solid var(--bs-primary);
    color: #fff;
    word-break: break-all;
    box-sizing: border-box
}

.choices__list--multiple .choices__item[data-deletable] {
    padding-right: 5px
}

.choices__list--multiple .choices__item.is-highlighted,
.is-disabled .choices__list--multiple .choices__item {
    background-color: rgba(var(--bs-primary-rgb), .5);
    border: 1px solid
}

.choices__list--dropdown {
    visibility: hidden;
    position: absolute;
    width: 100%;
    background-color: #fff;
    border: 1px solid #bec8d0;
    top: 100%;
    margin-top: -1px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
    word-break: break-all;
    will-change: visibility
}

.choices__list--dropdown.is-active {
    visibility: visible
}

.is-open .choices__list--dropdown {
    border-color: #92a2b0
}

.is-flipped .choices__list--dropdown {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: -1px;
    border-radius: .25rem .25rem 0 0
}

.choices__list--dropdown .choices__list {
    padding: 10px 15px;
    position: relative;
    max-height: 300px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position
}

.choices__list--dropdown .choices__item {
    position: relative;
    padding: 10px;
    font-size: 14px
}


@media (min-width:640px) {
    .choices__list--dropdown .choices__item--selectable {
        padding-right: 100px
    }

    .choices__list--dropdown .choices__item--selectable:after {
        content: attr(data-select-text);
        font-size: 12px;
        opacity: 0;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%)
    }
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
    background-color: rgba(70, 128, 255, .1)
}

.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
    opacity: .5
}

.choices__item {
    cursor: default
}

.choices__item--selectable {
    cursor: pointer
}

.choices__item--disabled {
    cursor: not-allowed;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    opacity: .5
}

.choices__heading {
    font-weight: 600;
    font-size: 12px;
    padding: 10px;
    border-bottom: 1px solid #dce1e5;
    color: gray
}

.choices__button {
    text-indent: -9999px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer
}

.choices__input,
.typeahead>ul {
    background-color: #fff;
    font-size: 14px
}

.choices__button:focus {
    outline: 0
}

.choices__input {
    display: inline-block;
    vertical-align: baseline;
    margin-bottom: 0;
    border: 0;
    color: #131920;
    border-radius: 0;
    max-width: 100%;
    padding: 4px 0 4px 2px
}

.choices__input:focus {
    outline: 0
}


.choices__placeholder {
    opacity: .5
}

.typeahead {
    position: relative
}

.typeahead>ul {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .17);
    display: none;
    float: left;
    left: 0;
    list-style: none;
    margin: 2px 0 0;
    min-width: 170px;
    padding: 5px 0;
    position: absolute;
    text-align: left;
    top: 100%;
    z-index: 1000
}

.typeahead>ul>li>a {
    clear: both;
    color: #333;
    cursor: default;
    display: block;
    font-weight: 400;
    line-height: 1.42857143;
    padding: 3px 20px;
    white-space: nowrap
}

.typeahead>ul>li.active>a,
.typeahead>ul>li.active>a:hover,
.typeahead>ul>li>a:hover {
    background-color: var(--bs-primary);
    color: #fff !important;
    text-decoration: none
}

.validate-me .error {
    border-color: #dc2626
}

.error-message {
    color: #dc2626
}

.flatpickr-input[readonly] {
    background: #fff
}

.noUi-connect {
    background: var(--bs-primary)
}

.CodeMirror-fullscreen,
.editor-toolbar.fullscreen {
    z-index: 1099
}

@media (max-width:575.98px) {
    .noUi-target {
        margin: 0 15px
    }

    .flatpickr-calendar {
        width: 270px
    }
}

.form-wizard .nav-pills .nav-link {
    color: var(--bs-body-color);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px
}

.form-wizard .nav-pills .nav-link i {
    font-size: 20px
}

.form-wizard .nav-pills .nav-link.active,
.form-wizard .nav-pills .show>.nav-link {
    color: #fff
}

.auth-main {
    position: relative
}

.auth-main .auth-wrapper {
    height: 100%;
    width: 100%;
    min-height: 100vh
}

.auth-main .auth-wrapper .saprator {
    position: relative;
    display: flex;
    align-self: center;
    justify-content: center
}

.auth-main .auth-wrapper .saprator:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--bs-border-color);
    z-index: 1
}

.auth-main .auth-wrapper .saprator span {
    font-size: .875rem;
    padding: 8px 24px;
    background: var(--bs-card-bg);
    z-index: 5;
    text-transform: capitalize;
    color: var(--pc-heading-color);
    font-weight: 500
}

.auth-main .auth-wrapper .auth-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1
}

.auth-main .auth-wrapper.v1,
.auth-main .auth-wrapper.v2 {
    display: flex;
    align-items: center
}

.auth-main .auth-wrapper .auth-form .card {
    width: 100%;
    max-width: 480px;
    box-shadow: none
}

.auth-main .auth-wrapper .auth-form img+span {
    padding-left: 10px
}

.auth-main .auth-wrapper .auth-form .h5 span,
.auth-main .auth-wrapper .auth-form h5 span {
    text-decoration: underline
}

.auth-main .auth-wrapper.v1 .auth-form {
    flex-direction: column;
    /* background: url(../images/authentication/img-auth-bg.jpg); */
    min-height: 100vh;
    padding: 24px;
    background-size: cover;
    position: relative;
    justify-content: center
}

.auth-main .auth-wrapper.v1 .auth-form .card-body {
    padding: 40px
}

@media (max-width:575.98px) {
    .auth-main .auth-wrapper.v1 .auth-form .card-body {
        padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x)
    }
    .auth-form {
        width: 100% !important;
    }
}

.auth-main .auth-wrapper.v2 {
    background: #fff
}

.auth-main .auth-wrapper.v2 .img-auth-side {
    height: 100vh
}

@media (max-width:1199.98px) {
    .auth-main .auth-wrapper.v2 .img-auth-side {
        display: none
    }
}

.auth-main .auth-wrapper.v2 .auth-form .card {
    border: none;
    box-shadow: none
}

.auth-main .auth-wrapper.v3 {
    display: flex;
    align-items: center;
    background: #fff
}

.auth-main .auth-wrapper.v3 .auth-sidecontent {
    width: 580px;
    align-self: stretch;
    background: var(--bs-primary);
    position: relative;
    display: flex;
    align-items: center
}

.auth-main .auth-wrapper.v3 .auth-sidecontent>* {
    position: relative;
    z-index: 5
}

.auth-main .auth-wrapper.v3 .auth-sidecontent::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(338deg, rgba(0, 0, 0, .3), transparent)
}

.auth-main .auth-wrapper.v3 .auth-sidecontent .carousel-indicators [data-bs-target] {
    height: 6px;
    border-radius: 4px;
    border-top: none;
    border-bottom: none
}

.auth-main .auth-wrapper.v3 .auth-sidecontent .carousel-indicators [data-bs-target]:not(.active) {
    width: 6px
}

@media (max-width:1199.98px) {
    .auth-main .auth-wrapper.v3 .auth-sidecontent {
        display: none
    }
}

.auth-main .auth-wrapper.v3 .auth-form {
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    padding: 24px
}

.auth-main .auth-wrapper.v3 .auth-form .auth-footer,
.auth-main .auth-wrapper.v3 .auth-form .auth-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.auth-main .auth-wrapper.v3 .auth-form .card {
    border: none;
    box-shadow: none
}

.auth-option .auth-megaoption {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 180px;
    border: 1px solid var(--bs-border-color);
    border-radius: var(--bs-border-radius)
}

.auth-option .auth-megaoption svg {
    width: 48px;
    height: 48px;
    margin-bottom: 10px
}

.auth-option .btn-check:checked+.auth-megaoption {
    border-color: var(--bs-primary);
    background: var(--bs-primary-light);
    box-shadow: 0 0 0 1px var(--bs-primary), 0 8px 24px rgba(27, 46, 94, .12)
}

.construction-card,
.error-card {
    background: 0 0;
    border: none;
    text-align: center
}

.code-input::-webkit-inner-spin-button,
.code-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

.code-input[type=number] {
    -moz-appearance: textfield
}

.maintenance-block {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%
}

.error-card {
    box-shadow: none;
    overflow: hidden
}

.error-card .error-image-block {
    position: relative;
    text-align: center;
    display: inline-flex
}

.construction-card-1 {
    /* background: url(../images/pages/img-cunstruct-1-bg.png) no-repeat; */
    min-height: 100vh;
    background-size: 100%
}

.construction-card-1 .construction-card-bottom {
    /* background: url(../images/pages/img-cunstruct-1-bottom.svg) bottom no-repeat; */
    background-size: contain
}

.soon-card {
    border: none;
    background: 0 0
}

.soon-card .timer-block .avtar {
    margin: 5px 0;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    border: 1px solid #e7eaee;
    padding-bottom: 25px;
    padding-top: 25px
}

.soon-card-1 {
    /* background: url(../images/authentication/img-auth-bg.jpg); */
    min-height: 100vh;
    background-size: cover;
    justify-content: center
}

.price-card .price-price span {
    display: block
}

.price-card .product-list {
    text-align: center
}

.price-card .product-list li {
    padding: 5px 0;
    border: none;
    position: relative
}

.price-card .product-list li:not(.enable) {
    opacity: .5;
    text-decoration: line-through
}

.product-check-list {
    text-align: left
}

.product-check-list li {
    padding: 8px 0 8px 25px;
    position: relative;
    opacity: .5
}

.product-check-list li::before {
    content: "\ea5e";
    font-family: tabler-icons !important;
    position: absolute;
    left: 0;
    top: 8px;
    opacity: .5
}

.product-check-list li.enable {
    opacity: 1
}

.product-check-list li.enable::before {
    opacity: 1;
    color: #2ca87f
}

.price-check {
    overflow: hidden;
    position: relative
}

.price-check .form-check-label::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.price-check .form-check-input,
.price-check .form-check-label .row {
    position: relative;
    z-index: 8
}

.price-check .form-check .form-check-input:checked+.form-check-label::after {
    background: rgba(var(--bs-primary-rgb), .1)
}

.contact-hero {
    padding: 110px 0 50px;
    background-size: cover;
    position: relative;
    justify-content: center
}

.contact-form {
    padding: 90px 0
}

.contact-footer {
    position: relative
}

.contact-footer .top-footer {
    background: rgba(255, 255, 255, .05);
    padding: 80px 0
}

.contact-footer .bottom-footer {
    padding: 10px 0
}

.contact-footer .footer-link a {
    margin: 14px 0;
    display: block;
    color: var(--bs-primary)
}

.contact-footer .footer-link a:not(:hover) {
    opacity: .6;
    color: var(--bs-body-color)
}

.contact-footer .footer-sos-link i {
    font-size: 18px
}

.i-main .i-block {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    margin: 5px;
    border: 1px solid #e7eaee;
    border-radius: var(--bs-border-radius);
    position: relative;
    cursor: pointer
}

.i-main .i-block i {
    font-size: 30px
}

.i-main .i-block label {
    margin-bottom: 0;
    display: none
}

.i-main .i-block span.ic-badge {
    position: absolute;
    bottom: 0;
    right: 0
}

.user-upload {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    display: inline-flex
}

.user-upload .img-avtar-upload {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: all .2s ease-in-out
}

.img-post .card-img,
.img-post .card-img-overlay,
.mail-option .mail-buttons,
.pct-c-btn,
.user-story-block .user-story .card-img,
.user-story-block .user-story .card-img-overlay {
    transition: all .15s ease-in-out
}

.user-upload:hover .img-avtar-upload {
    opacity: 1
}

.social-profile .img-profile-avtar {
    border-radius: 50%;
    width: 90px;
    margin-top: -20%;
    box-shadow: 0 0 0 3px var(--bs-card-bg)
}

@media (max-width:767.98px) {
    .social-profile {
        text-align: center
    }

    .social-profile .img-profile-avtar {
        margin-top: -70px
    }

    .social-profile .soc-profile-data {
        margin-top: 24px;
        margin-bottom: 24px
    }
}

.user-story-block {
    display: flex;
    align-items: flex-start
}

.user-story-block .user-story {
    min-width: 115px;
    margin-bottom: 0;
    margin-right: 8px;
    overflow: hidden;
    cursor: pointer
}

.user-story-block .user-story:hover .card-img {
    transform: scale(1.4)
}

.user-story-block .user-story:hover .card-img-overlay {
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px)
}

.user-story-block .user-story .card-img-overlay .img-user {
    box-shadow: 0 0 0 2px var(--bs-card-bg)
}

.user-story-block .user-story.add-story .card-img-overlay {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end
}

.user-story-block .user-story.add-story .card-img-overlay .btn-plus {
    background: var(--bs-card-bg);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    border: 1px dashed var(--bs-border-color)
}

.user-story-block .horizontal-scroll {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden
}

.user-story-block .horizontal-scroll::-webkit-scrollbar {
    height: 6px;
    opacity: 0
}

.user-story-block .horizontal-scroll::-webkit-scrollbar:hover {
    opacity: 1
}

.user-story-block .horizontal-scroll::-webkit-scrollbar-track {
    background: 0 0
}

.user-story-block .horizontal-scroll::-webkit-scrollbar-thumb {
    background: #e9ecef
}

.user-story-block .horizontal-scroll::-webkit-scrollbar-thumb:hover {
    background: #aeb9c5
}

.img-post {
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 0
}

.img-post .card-img-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 35px;
    opacity: 0
}

.img-post:hover .card-img {
    transform: scale(1.2)
}

.img-post:hover .card-img-overlay {
    opacity: 1;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px)
}

.post-modal-lightbox {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px)
}

.post-user-group .avtar {
    width: 25px;
    height: 25px
}

.post-user-group .avtar+.avtar {
    margin-left: -9px
}

.comment-block .comment {
    background: var(--bs-body-bg);
    border-radius: var(--bs-border-radius);
    margin-bottom: 24px
}

.comment-block .comment+.comment-content {
    margin-left: 65px
}

.comment-block .comment.sub-comment {
    position: relative;
    margin-left: 70px
}

.comment-block .comment.sub-comment:after,
.comment-block .comment.sub-comment:before {
    content: "";
    position: absolute;
    background: var(--bs-body-bg);
    border-radius: var(--bs-border-radius)
}

.comment-block .comment.sub-comment:before {
    width: 35px;
    height: 2px;
    right: 100%;
    top: 40px
}

.comment-block .comment.sub-comment:after {
    width: 2px;
    height: 133px;
    right: calc(100% + 32px);
    top: -90px
}

.comment-block .comment.sub-comment+.comment-content {
    margin-left: 140px
}

.comment-block .comment-content {
    margin-left: 57px
}

.input-comment {
    position: relative
}

.input-comment .custom-select,
.input-comment .dataTable-input,
.input-comment .dataTable-selector,
.input-comment .datatable-input,
.input-comment .form-control {
    padding: .56rem 5.5rem .56rem 3.5rem
}

.input-comment .list-inline {
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%)
}

.input-comment .list-inline.start-0 {
    padding-left: .35rem
}

.input-comment .list-inline.end-0 {
    padding-right: .35rem
}

.social-event-card .carousel-indicators {
    left: auto;
    margin-left: auto;
    margin-right: 0
}

.social-event-card .carousel-indicators [data-bs-target] {
    width: 4px;
    height: 4px;
    border-radius: 50%
}

.social-gallery-card .card-img-overlay {
    background: rgba(0, 0, 0, .1)
}

.chat-wrapper {
    display: flex
}

.chat-wrapper .chat-offcanvas.show .collapse:not(.show),
.mail-wrapper .mail-offcanvas.show .collapse:not(.show) {
    display: block
}

.chat-wrapper .chat-user_list {
    width: 300px;
    margin-right: var(--bs-gutter-x)
}

@media (max-width:1399.98px) {
    .chat-wrapper .chat-user_list {
        margin-left: 20px !important;
        margin-right: 20px !important;
        width: calc(100% - 40px)
    }
}

.chat-wrapper .chat-user_list .scroll-block {
    height: calc(100vh - 520px)
}

.chat-wrapper .chat-content {
    flex: 1 1 auto;
    min-width: 1%
}

.chat-wrapper .chat-user_info {
    width: 300px;
    margin-left: var(--bs-gutter-x)
}

@media (max-width:1399.98px) {
    .chat-wrapper .chat-user_info {
        margin-left: 0 !important;
        margin-right: 0;
        padding-inline: 20px;
        width: 100%
    }

    .chat-avtar .wid-40 {
        min-width: 35px
    }
}

.chat-wrapper .chat-user_info .scroll-block {
    height: calc(100vh - 470px)
}

.chat-wrapper .chat-user_info .chat-avtar img {
    border: 1px solid var(--bs-success);
    padding: 3px
}

.chat-wrapper .chat-user_info .chat-avtar .chat-badge {
    bottom: 8px;
    right: 8px;
    width: 8px;
    height: 8px
}

@media (max-width:575.98px) {
    .chat-wrapper .avtar.avtar-s {
        width: 35px;
        height: 35px
    }
}

.chat-wrapper .chat-offcanvas {
    --bs-offcanvas-width: 301px
}

.chat-wrapper .chat-offcanvas.show .chat-user_list {
    margin-right: 0
}

.chat-wrapper .chat-offcanvas.show .chat-user_list .scroll-block {
    height: calc(100vh - 425px)
}

.chat-wrapper .chat-offcanvas.show .chat-user_info {
    margin-left: 0;
    margin-right: 0
}

.chat-wrapper .chat-offcanvas.show .chat-user_info .scroll-block {
    height: calc(100vh - 375px)
}

.chat-badge {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    color: #fff;
    overflow: hidden
}

.chat-badge-status {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center
}

.chat-avtar {
    position: relative
}

.chat-avtar .chat-badge {
    position: absolute;
    bottom: 1px;
    right: 1px
}

.chat-message.scroll-block {
    height: calc(100vh - 390px)
}

.chat-message .msg-content {
    padding: 12px 16px;
    border-radius: var(--bs-border-radius)
}

.chat-message .message-in,
.chat-message .message-out {
    display: flex;
    margin-bottom: 24px
}

.chat-message .message-in>.d-flex,
.chat-message .message-out>.d-flex {
    max-width: 90%
}

.chat-message .message-in .chat-msg .chat-msg-option,
.chat-message .message-out .chat-msg .chat-msg-option {
    opacity: 0
}

.chat-message .message-in .chat-msg:hover .chat-msg-option,
.chat-message .message-out .chat-msg:hover .chat-msg-option {
    opacity: 1
}

.chat-message .message-out {
    justify-content: flex-end
}

.chat-message .message-out p {
    color: #fff
}

.chat-message .message-out p:last-child {
    text-align: right
}

.chat-message .message-out .chat-msg:not(:first-child) .msg-content {
    border-top-right-radius: 0
}

.chat-message .message-out .chat-msg:not(:last-child) .msg-content {
    border-bottom-right-radius: 0
}

.chat-message .message-in {
    justify-content: flex-start
}

.chat-message .message-in .chat-msg:not(:first-child) .msg-content {
    border-top-left-radius: 0
}

.chat-message .message-in .chat-msg:not(:last-child) .msg-content {
    border-bottom-left-radius: 0
}

.mail-wrapper {
    display: flex
}

.mail-wrapper .mail-menulist {
    width: 300px;
    margin-right: var(--bs-gutter-x)
}

.mail-wrapper .mail-menulist .scroll-block {
    height: calc(100vh - 270px)
}

.mail-wrapper .mail-menulist .list-group-item-action {
    padding: 14px 16px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    color: var(--bs-body-color);
    border: none;
    border-radius: var(--bs-border-radius)
}

.mail-wrapper .mail-menulist .list-group-item-action span {
    display: flex;
    align-items: center
}

.mail-wrapper .mail-menulist .list-group-item-action .avtar {
    width: 22px;
    height: 22px;
    color: var(--bs-body-color);
    background: rgba(var(--bs-black-rgb), .1)
}

.mail-wrapper .mail-menulist .list-group-item-action .material-icons-two-tone,
.mail-wrapper .mail-menulist .list-group-item-action i {
    font-size: 22px;
    margin-right: 8px;
    opacity: .6
}

.mail-wrapper .mail-menulist .list-group-item-action .material-icons-two-tone:not([class*=text-]) {
    background-color: var(--bs-body-color)
}

.mail-wrapper .mail-menulist .list-group-item-action+.list-group-item.active {
    margin-top: 0
}

.mail-wrapper .mail-content {
    flex: 1 1 auto;
    min-width: 1%
}

.mail-wrapper .mail-content .card .card {
    background: 0 0;
    box-shadow: none;
    border: none;
    margin-bottom: 0
}

.mail-wrapper .mail-content .card .card .card-body {
    padding: 0
}

.mail-wrapper .scroll-block {
    height: calc(100vh - 290px)
}

.mail-wrapper .mail-offcanvas {
    --bs-offcanvas-width: 301px
}

.mail-wrapper .mail-offcanvas.show .mail-menulist {
    margin-right: 0
}

.mail-wrapper .mail-offcanvas.show .mail-menulist .scroll-block {
    height: calc(100vh - 185px)
}

.mail-wrapper .img-user {
    width: 40px
}

.mail-wrapper .mail-table {
    border-collapse: separate;
    width: calc(100% - 10px);
    margin: 0 5px
}

.mail-wrapper .mail-table tr {
    display: flex;
    align-items: center;
    border: 1px solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    margin: 10px 0
}

.mail-wrapper .mail-table tr.unread {
    font-weight: 500
}

.mail-wrapper .mail-table tr:not(.unread) {
    background: var(--bs-body-bg)
}

.mail-wrapper .mail-table tr td {
    height: 64px;
    display: inline-flex;
    align-items: center
}

.mail-wrapper .mail-table tr td .h5,
.mail-wrapper .mail-table tr td h5 {
    font-weight: 500
}

.mail-wrapper .mail-table tr td:nth-child(1) {
    width: 130px
}

.mail-wrapper .mail-table tr td:nth-child(2) {
    width: 180px
}

.mail-wrapper .mail-table tr td:nth-child(2) .text-truncate {
    width: 105px
}

.mail-wrapper .mail-table tr td:nth-child(4) {
    width: 65px
}

.mail-wrapper .mail-table tr td:nth-child(5) {
    width: 150px
}

.mail-wrapper .mail-table tr td:nth-child(3) {
    flex: 1 1 auto;
    min-width: 0;
    overflow: hidden;
    position: relative
}

.mail-wrapper .mail-table tr td:nth-child(3) .text-truncate {
    position: absolute;
    width: calc(100% - 1.4rem);
    top: 0;
    transform: translatey(-50%);
    left: 0
}

.mail-wrapper .mail-table tr td:nth-child(2),
.mail-wrapper .mail-table tr td:nth-child(3) {
    cursor: pointer
}

.mail-wrapper.mini-mail-list .img-user {
    width: 30px
}

.mail-wrapper.mini-mail-list .mail-table td {
    height: 45px;
    padding-top: .4rem;
    padding-bottom: .4rem
}

.mail-wrapper.mini-mail-list .mail-table td .avtar {
    width: 26px;
    height: 26px;
    border-radius: 8px
}

.mail-wrapper.mini-mail-list .mail-table td .avtar i.f-18 {
    font-size: 16px
}

.mail-wrapper tr:hover .mail-option .mail-buttons {
    opacity: 1;
    transform: translateY(-50%)
}

.empty-mail .simplebar-content {
    height: 100%
}

.mail-option {
    position: relative;
    text-align: right
}

.mail-option .list-inline-item:not(:last-child) {
    margin-right: .1rem
}

.mail-option .mail-buttons {
    position: absolute;
    top: 50%;
    right: 5px;
    padding: 6px;
    opacity: 0;
    transform: translateY(-50%) scale(.8);
    transform-origin: right;
    background: var(--bs-card-bg);
    border-radius: var(--bs-border-radius);
    box-shadow: 0 8px 24px rgba(27, 46, 94, .12)
}

.pc-icon-checkbox {
    position: relative;
    padding: 0
}

.pc-icon-checkbox .pc-icon-uncheck {
    display: inline-block;
    opacity: .35
}

.pc-icon-checkbox .form-check-input:checked~.pc-icon-uncheck,
.pc-icon-checkbox .pc-icon-check {
    display: none
}

.pc-icon-checkbox .form-check-input {
    position: absolute;
    z-index: 9;
    margin-left: 0;
    left: 3px;
    top: 2px;
    opacity: 0;
    cursor: pointer;
    font-size: 20px
}

.pc-icon-checkbox .form-check-input:checked~.pc-icon-check {
    display: inline-block
}

.compose_mail_modal.show.modal-pos-down .modal-dialog {
    margin: 20px;
    align-items: flex-end;
    height: calc(100% - 40px);
    --bs-modal-width: calc(100% - 40px)
}

.compose_mail_modal.show.modal-pos-down .modal-dialog .modal-body {
    overflow: auto;
    height: calc(100vh - 205px)
}

.compose_mail_modal.show.modal-pos-down .modal-dialog .ql-container {
    height: calc(100vh - 480px) !important
}

.mail-details .indent-text {
    text-align: justify
}

.mail-details hr {
    opacity: .04
}


@media (max-width:767.98px) {

    .chat-message .message-in>.d-flex,
    .chat-message .message-out>.d-flex {
        max-width: 100%
    }

    .mail-wrapper .mail-table tr {
        flex-wrap: wrap
    }

    .mail-wrapper .mail-table tr td {
        height: auto
    }

    .mail-wrapper .mail-table tr td:nth-child(5) {
        margin-right: auto;
        flex: 1 1 auto;
        min-width: 0
    }

    .mail-wrapper .mail-table tr td:nth-child(3),
    .mail-wrapper .mail-table tr td:nth-child(5) {
        margin-left: 55px
    }

    .mail-wrapper .mail-table tr td:nth-child(4) {
        order: 6
    }

    .mail-wrapper .mail-table tr td:nth-child(3) {
        width: 100%
    }
}

.file-manger-wrapper .card {
    transition: all .2s ease-in-out
}

.file-manger-wrapper .card:hover {
    box-shadow: 0 8px 24px rgba(27, 46, 94, .08)
}

.file-card {
    position: relative
}

.file-card .form-check .form-check-label::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: var(--bs-border-radius)
}

.file-card .form-check .form-check-input:checked+.form-check-label::after {
    background: rgba(var(--bs-primary-rgb), .03);
    box-shadow: 0 8px 24px rgba(27, 46, 94, .12), inset 0 0 0 2px var(--bs-primary)
}

.file-card .user-popup {
    position: relative;
    z-index: 5
}

.file-card .user-group .avtar {
    width: 30px;
    height: 30px
}

.file-card.table {
    border-collapse: separate;
    width: calc(100% - 20px);
    margin: 0 10px;
    border-spacing: 0 10px
}

.file-card.table tr {
    background: var(--bs-card-bg);
    border: 1px solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    box-shadow: 0 8px 24px rgba(27, 46, 94, .08)
}

.file-card.table tr td:nth-child(2) {
    cursor: pointer
}

.file-card.table ul {
    margin-bottom: 0
}

.nav-files {
    border: 1px solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    padding: 10px;
    background: var(--bs-card-bg)
}

.nav-files.nav-pills .nav-link {
    padding: 0;
    color: var(--bs-body-color);
    font-size: 20px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1
}

.nav-files.nav-pills .nav-link.active,
.nav-files.nav-pills .show>.nav-link {
    color: var(--bs-body-color);
    background-color: var(--bs-card-bg);
    box-shadow: 0 8px 24px rgba(27, 46, 94, .08)
}

.fc td,
.fc th {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)
}

.fc .fc-toolbar .h2,
.fc .fc-toolbar h2 {
    font-size: 16px;
    line-height: 30px;
    text-transform: uppercase
}

@media (max-width:767.98px) {

    .fc .fc-toolbar .fc-center,
    .fc .fc-toolbar .fc-left,
    .fc .fc-toolbar .fc-right {
        float: none;
        display: block;
        text-align: center;
        clear: both;
        margin: 10px 0
    }

    .fc .fc-toolbar>*>* {
        float: none
    }

    .fc .fc-toolbar .fc-today-button {
        display: none
    }
}

.fc .fc-toolbar .fc-button {
    text-transform: capitalize
}

.fc .fc-daygrid-day-top {
    flex-direction: column;
    padding: 14px
}

.fc .fc-col-header-cell {
    background-color: transparent;
    border: none
}

.fc .fc-col-header-cell-cushion {
    display: block;
    padding: 16px 4px
}

.fc .fc-scrollgrid-section .fc-scroller-harness {
    padding: 0
}

.fc .fc-daygrid-day-number {
    width: 24px;
    height: 24px;
    border-radius: 8px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    margin: 2px
}

.coupon-card.small-coupon-card::after,
.coupon-card.small-coupon-card::before,
.fc-daygrid-event-dot,
.fc-event-time {
    display: none
}

.fc .fc-daygrid-day-number:hover {
    background-color: #5b6b79;
    color: #fff
}

.fc .fc-daygrid-day.fc-day-today {
    /* background: url(../images/application/img-cal-bg.jpg); */
    background-size: cover
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
    background-color: var(--bs-primary);
    color: #fff
}

.fc .fc-daygrid-day.fc-day-today,
.fc .fc-timegrid-col.fc-day-today {
    background-color: rgba(var(--bs-primary-rgb), .1)
}

.fc .fc-col-header,
.fc .fc-daygrid-body,
.fc .fc-scrollgrid-sync-table {
    width: 100% !important
}

.fc .fc-scrollgrid-section>* {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)
}

.fc .fc-scrollgrid-section-liquid>td {
    border-top: 0
}

.fc a[data-navlink]:hover {
    text-decoration: none
}

.fc-theme-bootstrap a:not([href]) {
    color: var(--bs-body-color)
}

.fc-event,
.fc-h-event .fc-event-main {
    color: #5b6b79
}

.fc-theme-standard .fc-scrollgrid {
    border: none
}

.fc-event {
    border-radius: 50px;
    cursor: move;
    font-size: .8125rem;
    margin: 5px 7px;
    padding: 5px;
    text-align: center;
    border: 1px solid
}

.fc-event-title,
.fc-sticky {
    font-weight: 600 !important;
    text-overflow: ellipsis;
    white-space: nowrap
}

.fc-daygrid-event-dot {
    border-color: #fff !important
}

.fc-event .fc-content {
    color: #fff
}

#external-events .external-event {
    text-align: left;
    padding: 8px 16px;
    margin: 6px 0
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end,
.fc-toolbar-chunk .d-inline-flex .fc-button:not(:first-child) {
    margin-left: 5px
}

.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.bg-dark .fc-content {
    color: #f8f9fa
}

.fc-next-button.fc-button,
.fc-prev-button.fc-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 34px;
    height: 34px;
    border-radius: 4px;
    font-size: 14px;
    padding: 0
}

.fc-toolbar-chunk .d-inline-flex .fc-button {
    color: var(--bs-primary);
    background-color: rgba(var(--bs-primary-rgb), .15);
    border: none;
    box-shadow: none;
    border-radius: 50px !important
}

.fc-toolbar-chunk .d-inline-flex .fc-button.active,
.fc-toolbar-chunk .d-inline-flex .fc-button:focus,
.fc-toolbar-chunk .d-inline-flex .fc-button:hover {
    color: #fff;
    background-color: var(--bs-primary)
}

.fc-toolbar-chunk .fc-button-primary {
    background-color: rgba(var(--bs-primary-rgb), .1) !important;
    color: var(--bs-primary);
    border: var(--bs-primary);
    padding: 9px 16px
}

.fc-toolbar-chunk .fc-button-primary:focus,
.fc-toolbar-chunk .fc-button-primary:hover,
.fc-toolbar-chunk .fc-button-primary:not(:disabled).fc-button-active,
.fc-toolbar-chunk .fc-button-primary:not(:disabled):active,
.fc-toolbar-chunk .fc-today-button {
    background-color: var(--bs-primary) !important;
    color: #fff
}

.fc-toolbar-chunk .fc-button-primary:focus {
    box-shadow: none
}

.fc-toolbar-chunk .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc-toolbar-chunk .fc-button-primary:not(:disabled):active:focus {
    box-shadow: none
}

.fc-toolbar-chunk .fc-today-button {
    border-radius: 50px !important;
    border: var(--bs-primary)
}

@media (max-width:575.98px) {
    .fc-toolbar {
        flex-direction: column;
        gap: 16px
    }
}

.fc-daygrid-event-harness .fc-daygrid-event,
.fc-timegrid-event-harness .fc-timegrid-event {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px)
}

.fc-timegrid-slots table tr {
    border-color: #e7eaee !important
}

.fc-list-table {
    border-color: #e7eaee
}

.fc-event.event-theme {
    background: #ffebf0 !important;
    color: #f36 !important
}

.fc-event.event-theme.fc-h-event .fc-event-main {
    color: #f36
}

.fc-event.event-theme.fc-h-event:focus,
.fc-event.event-theme.fc-h-event:hover {
    background: #f36;
    color: #fff
}

.fc-event.event-primary {
    background: #edf2ff !important;
    color: #4680ff !important
}

.fc-event.event-primary.fc-h-event .fc-event-main {
    color: #4680ff
}

.fc-event.event-primary.fc-h-event:focus,
.fc-event.event-primary.fc-h-event:hover {
    background: #4680ff;
    color: #fff
}

.fc-event.event-secondary {
    background: #eff0f2 !important;
    color: #5b6b79 !important
}

.fc-event.event-secondary.fc-h-event .fc-event-main {
    color: #5b6b79
}

.fc-event.event-secondary.fc-h-event:focus,
.fc-event.event-secondary.fc-h-event:hover {
    background: #5b6b79;
    color: #fff
}

.fc-event.event-success {
    background: #eaf6f2 !important;
    color: #2ca87f !important
}

.fc-event.event-success.fc-h-event .fc-event-main {
    color: #2ca87f
}

.fc-event.event-success.fc-h-event:focus,
.fc-event.event-success.fc-h-event:hover {
    background: #2ca87f;
    color: #fff
}

.fc-event.event-info {
    background: #ecfafb !important;
    color: #3ec9d6 !important
}

.fc-event.event-info.fc-h-event .fc-event-main {
    color: #3ec9d6
}

.fc-event.event-info.fc-h-event:focus,
.fc-event.event-info.fc-h-event:hover {
    background: #3ec9d6;
    color: #fff
}

.fc-event.event-warning {
    background: #fcf3e6 !important;
    color: #e58a00 !important
}

.fc-event.event-warning.fc-h-event .fc-event-main {
    color: #e58a00
}

.fc-event.event-warning.fc-h-event:focus,
.fc-event.event-warning.fc-h-event:hover {
    background: #e58a00;
    color: #fff
}

.fc-event.event-danger {
    background: #fce9e9 !important;
    color: #dc2626 !important
}

.fc-event.event-danger.fc-h-event .fc-event-main {
    color: #dc2626
}

.fc-event.event-danger.fc-h-event:focus,
.fc-event.event-danger.fc-h-event:hover {
    background: #dc2626;
    color: #fff
}

.fc-event.event-light {
    background: #fefeff !important;
    color: #f8f9fa !important
}

.fc-event.event-light.fc-h-event .fc-event-main {
    color: #f8f9fa
}

.fc-event.event-light.fc-h-event:focus,
.fc-event.event-light.fc-h-event:hover {
    background: #f8f9fa;
    color: #fff
}

.fc-event.event-dark {
    background: #e9e9ea !important;
    color: #212529 !important
}

.fc-event.event-dark.fc-h-event .fc-event-main {
    color: #212529
}

.fc-event.event-dark.fc-h-event:focus,
.fc-event.event-dark.fc-h-event:hover {
    background: #212529;
    color: #fff
}

.fc .fc-popover {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .175);
    border-radius: 8px;
    border: none;
    overflow: hidden;
    z-index: 1040
}

.fc .fc-popover .fc-popover-body,
.fc .fc-popover .fc-popover-header {
    padding: 12px 14px
}

.fc .fc-popover .fc-popover-header {
    background: 0 0;
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)
}

.fc .fc-popover .fc-popover-header .fc-popover-close {
    color: #dc2626;
    width: 24px;
    height: 24px;
    font-size: 14px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center
}

.fc .fc-popover .fc-popover-header .fc-popover-close:focus,
.fc .fc-popover .fc-popover-header .fc-popover-close:hover {
    background: #fce9e9
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-soft-dark .fc-event-main,
.fc-daygrid-event-harness .fc-daygrid-event.bg-soft-dark .fc-event-title {
    color: var(--bs-dark) !important
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin-right: 5px
}

.fc-v-event .fc-event-main {
    color: inherit
}

.avtar.pc-cal-toggle {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    position: sticky;
    top: calc(100% - 80px);
    left: calc(100% - 120px);
    z-index: 1025
}

.cal-event-offcanvas {
    --bs-offcanvas-zindex: 1081
}

.ecomm-prod-slider .carousel-indicators [data-bs-target] {
    opacity: .3
}

.ecomm-prod-slider .carousel-indicators .active {
    opacity: 1
}

.ecomm-prod-slider .product-carousel-indicators {
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-start
}

.ecomm-prod-slider .product-carousel-indicators::-webkit-scrollbar {
    height: 6px;
    opacity: 0
}

.ecomm-prod-slider .product-carousel-indicators::-webkit-scrollbar:hover {
    opacity: 1
}

.ecomm-prod-slider .product-carousel-indicators::-webkit-scrollbar-track {
    background: 0 0
}

.ecomm-prod-slider .product-carousel-indicators::-webkit-scrollbar-thumb {
    background: #e9ecef
}

.ecomm-prod-slider .product-carousel-indicators::-webkit-scrollbar-thumb:hover {
    background: #aeb9c5
}

.offer-check-block {
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start
}

.address-check,
.offer-check {
    overflow: visible;
    margin: 10px
}

.offer-check-block::-webkit-scrollbar {
    height: 6px;
    opacity: 0
}

.offer-check-block::-webkit-scrollbar:hover {
    opacity: 1
}

.offer-check-block::-webkit-scrollbar-track {
    background: 0 0
}

.offer-check-block::-webkit-scrollbar-thumb {
    background: #e9ecef
}

.offer-check-block::-webkit-scrollbar-thumb:hover {
    background: #aeb9c5
}

.offer-check {
    position: relative;
    width: 180px;
    flex: 0 0 auto
}

.offer-check .offer-details {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    margin: 14px 0
}

.offer-check .form-check-label::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: var(--bs-border-radius)
}

.offer-check .form-check-input {
    position: relative;
    z-index: 8;
    float: right
}

.offer-check .form-check {
    padding-left: 0;
    margin-bottom: 0
}

.offer-check .form-check .form-check-input:checked+.form-check-label::after {
    background: rgba(var(--bs-primary-rgb), .1);
    box-shadow: 0 8px 24px rgba(27, 46, 94, .12), inset 0 0 0 2px rgba(var(--bs-primary-rgb), .5)
}

.divider {
    position: relative;
    text-align: center;
    margin: 8px 0
}

.divider::after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 1px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: var(--bs-border-color)
}

.divider span {
    position: relative;
    z-index: 5;
    padding: 8px 16px;
    background: var(--bs-card-bg);
    text-transform: uppercase
}

.coupon-card {
    overflow: hidden;
    position: relative;
    border: none
}

.coupon-card::after,
.coupon-card::before {
    content: "";
    position: absolute;
    border-radius: 50%;
    background: #fff;
    z-index: 1
}

.coupon-card::after {
    width: 180px;
    height: 180px;
    left: -23px;
    top: -64px;
    opacity: .2
}

.coupon-card::before {
    width: 60px;
    height: 60px;
    left: -10px;
    bottom: -20px;
    opacity: .15
}

.coupon-card .card-body {
    position: relative;
    z-index: 5
}

.coupon-card .card-body::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(213.66deg, transparent 25.46%, rgba(0, 0, 0, .2) 68.77%, rgba(0, 0, 0, .3) 81.72%);
    z-index: 1
}

.coupon-card .card-body>div {
    position: relative;
    z-index: 5
}

.coupon-card .btn-coupon {
    color: var(--bs-white);
    border-color: var(--bs-white);
    background: rgba(var(--bs-white-rgb), .2)
}

.coupon-card .btn-coupon:active,
.coupon-card .btn-coupon:focus,
.coupon-card .btn-coupon:hover {
    color: var(--bs-white);
    border-color: var(--bs-white);
    background: rgba(var(--bs-white-rgb), .4)
}

.coupon-label {
    margin: 16px 0 8px;
    padding: 24px 50px;
    position: relative;
    border-radius: var(--bs-border-radius);
    border: 1px dashed
}

.coupon-label:after,
.coupon-label:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    background: var(--bs-modal-bg);
    border-radius: 50%;
    border-top: 1px dashed
}

.ecom-wrapper .ecom-filter .scroll-block,
.ecom-wrapper .ecom-offcanvas.show .ecom-filter .scroll-block {
    height: calc(100vh - 120px)
}

.address-check .form-check,
.address-check .form-check-label::after,
.product-card .card-img-top .img-prod {
    border-radius: var(--bs-border-radius)
}

.coupon-label:before {
    border-right: 1px dashed;
    left: 0;
    transform: translate(-50%, -50%) rotate(45deg)
}

.coupon-label:after {
    right: 0;
    border-left: 1px dashed;
    transform: translate(50%, -50%) rotate(-45deg)
}

.coupon-label.coupon-label-primary {
    border-color: var(--bs-primary);
    background-color: rgba(var(--bs-primary-rgb), .05)
}

.coupon-label.coupon-label-primary:after,
.coupon-label.coupon-label-primary:before {
    border-color: var(--bs-primary)
}

.coupon-label.coupon-label-warning {
    border-color: var(--bs-warning);
    background-color: rgba(var(--bs-warning-rgb), .05)
}

.coupon-label.coupon-label-warning:after,
.coupon-label.coupon-label-warning:before {
    border-color: var(--bs-warning)
}

.address-check {
    position: relative;
    width: calc(100% - 20px)
}

.address-check .address-details {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    margin: 14px 0
}

.address-check .form-check-label::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.address-check .form-check-input {
    position: relative;
    z-index: 8;
    opacity: 0
}

.address-check .address-btns .avtar {
    position: relative;
    z-index: 6
}

.address-check .form-check {
    padding-left: 0;
    margin-bottom: 0
}

.address-check .form-check .form-check-input:checked+.form-check-label .address-btns {
    opacity: 0
}

.address-check .form-check .form-check-input:checked+.form-check-label::after {
    background: rgba(var(--bs-primary-rgb), .1);
    box-shadow: 0 8px 24px rgba(27, 46, 94, .12), inset 0 0 0 2px rgba(var(--bs-primary-rgb), .5)
}

.ecom-wrapper {
    display: flex
}

.ecom-wrapper .ecom-filter {
    width: 300px;
    margin-right: var(--bs-gutter-x)
}

.ecom-wrapper .ecom-offcanvas {
    --bs-offcanvas-width: 301px
}

.ecom-wrapper .ecom-offcanvas .sticky-xxl-top {
    top: 100px
}

.ecom-wrapper .ecom-offcanvas:not(.show) .btn-link-danger.btn-pc-default {
    display: none
}

.ecom-wrapper .ecom-offcanvas.show .collapse-horizontal.collapse:not(.show) {
    display: block
}

.ecom-wrapper .ecom-offcanvas.show .ecom-filter {
    margin-right: 0
}

.product-card {
    transition: all .3s ease-in-out
}

.product-card .card-img-top {
    position: relative;
    padding: 8px 8px 0;
    width: 100%;
    overflow: hidden
}

.product-card .prod-color {
    display: inline-flex;
    align-items: center
}

.product-card .prod-color span {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px var(--bs-card-bg)
}

.product-card .prod-color span:not(:first-child) {
    margin-left: -3px
}

.product-card .prod-content {
    -webkit-line-clamp: 2;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis
}

.product-card .btn-prod-cart {
    transition: all .3s ease-in-out
}

.introjs-tooltip .product-card .btn-prod-cart .introjs-button,
.product-card .btn-prod-cart .btn,
.product-card .btn-prod-cart .introjs-tooltip .introjs-button {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 50%
}

.introjs-tooltip .product-card .btn-prod-cart .introjs-button svg,
.product-card .btn-prod-cart .btn svg,
.product-card .btn-prod-cart .introjs-tooltip .introjs-button svg {
    width: 24px;
    height: 24px
}

.product-card:hover {
    box-shadow: 0 8px 24px rgba(27, 46, 94, .12)
}

.product-card:not(:hover) .btn-prod-cart {
    transform: scale(0) rotate(-90deg)
}

.product-sticky {
    top: 85px
}

.product-sticky .prod-likes {
    z-index: 5
}

.color-checkbox {
    position: relative;
    padding: 0;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer
}

.color-checkbox .form-check-input {
    margin: 0;
    position: absolute;
    top: 0;
    z-index: 5;
    font-size: 22px;
    opacity: 0
}

.color-checkbox .form-check-input:checked~i::after {
    content: "\ea5e";
    font-family: tabler-icons !important;
    line-height: 1;
    font-size: 14px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0 1px var(--bs-card-bg), 0 0 0 5px var(--bs-border-color)
}

.color-checkbox i {
    font-size: 28px
}

.checkout-tabs .nav-item {
    margin-bottom: 0
}

.checkout-tabs .nav-item .nav-link {
    position: relative;
    padding: .8rem;
    margin: 0 .2rem;
    font-weight: 500;
    color: var(--bs-body-color);
    display: flex;
    align-items: center;
    border-radius: var(--bs-border-radius) !important
}

.checkout-tabs .nav-item .nav-link p {
    margin-top: 0;
    font-weight: 400;
    opacity: .7
}

.checkout-tabs .nav-item .nav-link .avtar i {
    font-size: 18px
}

@media (max-width:575.98px) {
    .checkout-tabs .nav-item {
        width: 100%
    }
}

.checkout-tabs .nav-link:hover {
    border-color: transparent;
    color: var(--bs-primary)
}

.checkout-tabs .nav-link:hover .h5,
.checkout-tabs .nav-link:hover h5,
.checkout-tabs .nav-link:hover p {
    color: var(--bs-primary)
}

.checkout-tabs .nav-link:hover .avtar {
    background-color: rgba(var(--bs-primary-rgb), .15);
    color: var(--bs-primary)
}

.checkout-tabs .nav-item.show .nav-link,
.checkout-tabs .nav-link.active {
    border-color: transparent;
    background-color: transparent;
    color: #4680ff
}

.checkout-tabs .nav-item.show .nav-link .h5,
.checkout-tabs .nav-item.show .nav-link h5,
.checkout-tabs .nav-item.show .nav-link p,
.checkout-tabs .nav-link.active .h5,
.checkout-tabs .nav-link.active h5,
.checkout-tabs .nav-link.active p {
    color: #4680ff
}

.checkout-tabs .nav-item.show .nav-link .avtar,
.checkout-tabs .nav-link.active .avtar {
    background-color: var(--bs-primary);
    color: #fff
}

.prod-likes {
    position: relative;
    padding: 0
}

.prod-likes .prod-likes-icon {
    stroke: rgba(19, 25, 32, .5);
    fill: rgba(19, 25, 32, .2);
    z-index: 3
}

.prod-likes .form-check-input {
    width: 20px;
    height: 20px;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 5;
    cursor: pointer
}

.prod-likes .form-check-input:checked+.prod-likes-icon {
    stroke: var(--bs-danger);
    fill: rgba(var(--bs-danger-rgb), .8);
    animation: _26Tc6u .2s ease .3s forwards
}

.pc-like {
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 10px
}

.pc-like .pc-dots:nth-child(4):before {
    transform: translate3d(-4px, -5px, 0)
}

.pc-like .pc-dots:nth-child(4):after {
    transform: translate3d(4px, -5px, 0) scale(.7)
}

.pc-like .pc-dots:nth-child(3):before {
    transform: translate3d(-5px, -4px, 0)
}

.pc-like .pc-dots:nth-child(3):after {
    transform: translate3d(-5px, 4px, 0) scale(.7)
}

.pc-like .pc-dots:nth-child(2):before {
    transform: translate3d(-4px, 5px, 0)
}

.pc-like .pc-dots:nth-child(2):after {
    transform: translate3d(4px, 5px, 0) scale(.7)
}

.pc-like .pc-dots:first-child:before {
    transform: translate3d(5px, -4px, 0)
}

.pc-like .pc-dots:first-child:after {
    transform: translate3d(5px, 4px, 0) scale(.7)
}

.pc-like .pc-dots:after {
    background: #dc2626
}

.pc-like .pc-dots:before {
    background: #e58a00
}

.pc-like .like-wrapper {
    position: relative;
    display: inline-flex
}

.pc-like .pc-group {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0
}

.pc-like .pc-dots:after,
.pc-like .pc-dots:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    margin: -2px;
    border-radius: 50%
}

.pc-like.pc-like-animate .pc-dots:first-child:after {
    animation: _3z13ic .6s ease .3s
}

.pc-like.pc-like-animate .pc-dots:nth-child(4):before {
    animation: u9Mhrv .6s ease .3s
}

.pc-like.pc-like-animate .pc-dots:nth-child(4):after {
    animation: _2ERa1V .6s ease .3s
}

.pc-like.pc-like-animate .pc-dots:nth-child(3):before {
    animation: _3TtatR .6s ease .3s
}

.pc-like.pc-like-animate .pc-dots:nth-child(3):after {
    animation: _3UC1np .6s ease .3s
}

.pc-like.pc-like-animate .pc-dots:nth-child(2):before {
    animation: _33IoP_ .6s ease .3s
}

.pc-like.pc-like-animate .pc-dots:nth-child(2):after {
    animation: _2D5htx .6s ease .3s
}

.pc-like.pc-like-animate .pc-group {
    animation: r9OBmc .6s ease .3s
}

.pc-like.pc-like-animate .pc-dots:first-child:before {
    animation: _18Xo9B .6s ease .3s
}

@keyframes _26Tc6u {
    0% {
        transform: translateZ(0) scale(0)
    }

    40% {
        transform: translateZ(0) scale(1.2)
    }

    to {
        transform: translateZ(0) scale(1)
    }
}

@keyframes _3d25CI {
    0% {
        transform: translateZ(0) scale(0);
        opacity: 1
    }

    to {
        transform: translateZ(0) scale(1);
        opacity: 0
    }
}

@keyframes r9OBmc {

    0%,
    to {
        opacity: 0
    }

    50% {
        opacity: 1
    }
}

@keyframes _18Xo9B {
    to {
        transform: translate3d(22px, -4px, 0)
    }
}

@keyframes _3z13ic {
    to {
        transform: translate3d(14px, 4px, 0) scale(.7)
    }
}

@keyframes _33IoP_ {
    to {
        transform: translate3d(-4px, 22px, 0)
    }
}

@keyframes _2D5htx {
    to {
        transform: translate3d(4px, 18px, 0) scale(.7)
    }
}

@keyframes _3TtatR {
    to {
        transform: translate3d(-22px, -4px, 0)
    }
}

@keyframes _3UC1np {
    to {
        transform: translate3d(-18px, 4px, 0) scale(.7)
    }
}

@keyframes u9Mhrv {
    to {
        transform: translate3d(-4px, -22px, 0)
    }
}

@keyframes _2ERa1V {
    to {
        transform: translate3d(4px, -18px, 0) scale(.7)
    }
}

.invoice-tab,
.profile-tabs {
    border-bottom: 0
}

.invoice-tab .nav-item,
.profile-tabs .nav-item {
    margin-bottom: 0
}

.invoice-tab .nav-item .nav-link,
.profile-tabs .nav-item .nav-link {
    position: relative;
    padding: 1rem 0;
    margin: 0 1.5rem 0 0;
    font-weight: 500;
    color: var(--bs-body-color);
    display: inline-flex;
    align-items: center
}

@media (max-width:575.98px) {

    .invoice-tab .nav-item .nav-link,
    .profile-tabs .nav-item .nav-link {
        padding: .8rem 0
    }
}

.invoice-tab .nav-item .nav-link i,
.profile-tabs .nav-item .nav-link i {
    font-size: 18px
}

.invoice-tab .nav-item .nav-link .material-icons-two-tone,
.profile-tabs .nav-item .nav-link .material-icons-two-tone {
    font-size: 20px
}

.invoice-tab .nav-item .nav-link:after,
.profile-tabs .nav-item .nav-link:after {
    content: "";
    background: var(--bs-primary);
    position: absolute;
    transition: all .3s ease-in-out;
    left: 50%;
    right: 50%;
    bottom: -1px;
    height: 2px;
    border-radius: 2px 2px 0 0
}

.invoice-tab .nav-link:hover,
.profile-tabs .nav-link:hover {
    border-color: transparent;
    background: 0 0;
    color: var(--bs-primary)
}

.invoice-tab .nav-link:hover .material-icons-two-tone,
.profile-tabs .nav-link:hover .material-icons-two-tone {
    background-color: var(--bs-primary)
}

.invoice-tab .nav-item.show .nav-link,
.invoice-tab .nav-link.active,
.profile-tabs .nav-item.show .nav-link,
.profile-tabs .nav-link.active {
    border-color: transparent;
    color: var(--bs-primary);
    background: 0 0
}

.invoice-tab .nav-item.show .nav-link .material-icons-two-tone,
.invoice-tab .nav-link.active .material-icons-two-tone,
.profile-tabs .nav-item.show .nav-link .material-icons-two-tone,
.profile-tabs .nav-link.active .material-icons-two-tone {
    background-color: var(--bs-primary)
}

.invoice-tab .nav-item.show .nav-link:after,
.invoice-tab .nav-link.active:after,
.profile-tabs .nav-item.show .nav-link:after,
.profile-tabs .nav-link.active:after {
    left: 0;
    right: 0
}

.table tr td .row {
    margin-right: 50px
}

.table tr td .row .col {
    display: flex;
    flex-direction: column;
    width: 1%
}

.table-invoice-comp {
    border-spacing: 0 10px;
    border-collapse: separate;
    width: calc(100% - 10px);
    margin: 0 5px
}

.table-invoice-comp tbody tr {
    box-shadow: var(--pc-card-shadow);
    background: var(--bs-card-bg);
    border-radius: 4px;
    position: relative
}

.invoice-total {
    width: 100%;
    max-width: 400px
}

.widget-calender .datepicker-header {
    border-bottom: 1px solid var(--bs-border-color);
    padding-bottom: 10px;
    margin-bottom: 20px
}

.widget-calender .datepicker,
.widget-calender .datepicker-view {
    width: 100%
}

.widget-calender .datepicker {
    padding: 0
}

.widget-calender .datepicker span.datepicker-cell {
    border-radius: 25px
}

.course-price {
    display: flex;
    flex-direction: column;
    gap: 8px
}

.course-price .form-check label {
    border: 1px solid var(--bs-border-color);
    padding: 12px 20px;
    border-radius: var(--bs-border-radius)
}

.course-price .form-check label .badge {
    color: var(--bs-body-color);
    background: rgba(var(--bs-secondary-rgb), .1);
    border-radius: var(--bs-border-radius)
}

.course-price .form-check-input {
    display: none
}

.course-price .form-check-input:checked~label {
    background: var(--bs-primary);
    border-color: var(--bs-primary)
}

.course-price .form-check-input:checked~label span {
    color: #fff
}

.course-price .form-check-input:checked~label .badge {
    background: rgba(255, 255, 255, .1)
}

.course-wizard {
    position: relative;
    display: inline-flex;
    width: auto;
    margin-bottom: 30px
}

.course-wizard:after {
    content: "";
    position: absolute;
    left: 50px;
    right: 50px;
    top: 26px;
    height: 1px;
    background: var(--bs-border-color);
    z-index: 1
}

.course-wizard .nav-item .nav-link {
    display: block;
    color: var(--bs-body-color);
    text-align: center;
    min-width: 120px;
    position: relative;
    z-index: 5
}

.course-wizard .nav-item .nav-link .course-wizard-num {
    width: 34px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 14px;
    background-color: var(--bs-card-bg);
    border: 1px solid var(--bs-border-color)
}

.course-wizard.nav-pills .nav-link.active,
.course-wizard.nav-pills .show>.nav-link {
    background: 0 0;
    color: var(--bs-body-color)
}

.course-wizard.nav-pills .nav-link.active .course-wizard-num,
.course-wizard.nav-pills .show>.nav-link .course-wizard-num {
    background: var(--bs-primary);
    border-color: var(--bs-primary);
    color: #fff
}

.set-color-block {
    padding: 20px;
    border-radius: var(--bs-border-radius);
    border: 1px solid transparent;
    transition: all .2s ease-in-out;
    cursor: pointer
}

.set-color-block.active,
.set-color-block:hover {
    background-color: rgba(var(--bs-body-bg-rgb), .4);
    border-color: var(--bs-border-color)
}

.nav-price {
    border: 1px solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    padding: 10px
}

.nav-price.nav-pills .nav-link {
    padding: 16px 20px;
    color: var(--bs-body-color)
}

.nav-price.nav-pills .nav-link.active,
.nav-price.nav-pills .show>.nav-link {
    color: var(--bs-body-color);
    background-color: var(--bs-card-bg);
    box-shadow: 0 8px 24px rgba(27, 46, 94, .08)
}

.price-card.price-popular {
    border: 2px solid var(--bs-primary)
}

.price-card.price-popular .price-head {
    background: rgba(var(--bs-primary-rgb), .1);
    margin-bottom: 16px
}

.price-card.price-popular .price-head.v2 {
    border-radius: var(--bs-border-radius);
    margin: 0 0 16px
}

.price-card .price-head {
    border-radius: var(--bs-border-radius) var(--bs-border-radius) 60px 60px;
    padding: 20px 20px 30px;
    text-align: center;
    margin: 15px 15px 30px;
    position: relative
}

.price-card .price-head.v3 {
    margin: 0 0 25px;
    padding: 0
}

.price-card .price-price {
    font-size: 40px;
    font-weight: 700;
    line-height: 1
}

.price-card .price-price span {
    margin-top: 8px;
    font-weight: 400;
    font-size: .875rem;
    opacity: .8
}

.price-card .card-body.v3 {
    display: flex;
    flex-direction: column
}

.price-card .product-list.v3 {
    flex: 1 1 auto
}

.price-card .product-list.v3 .list-group-item {
    padding: 4px 0;
    background: 0 0;
    border: none
}

.price-card .product-list.v3 .list-group-item:before {
    display: none
}

.hd-body .excerpt {
    padding: 15px;
    background: #f8f9fa;
    border: 1px solid var(--bs-border-color)
}

.hd-body .ticket-customer,
.hd-body .ticket-type-icon {
    display: block;
    text-transform: capitalize
}

.hd-body:hover .hover-blk {
    transform: scale(1)
}

.md-view .col-auto ul li:nth-child(1),
.md-view .col-auto ul li:nth-child(3),
.md-view .excerpt,
.sm-view .card-body .excerpt,
.sm-view .card-body ul.list-inline,
.sm-view .col-auto ul li:nth-child(1),
.sm-view .col-auto ul li:nth-child(3) {
    display: none
}

.dicon-blk li,
.hover-blk .img-txt p,
.thumbnails li {
    display: inline-block
}

.hover-blk {
    background: #fff;
    width: 270px;
    left: calc(100% + 10px);
    transform: scale(0);
    z-index: 5;
    transition: all .1s ease-out
}

.topic-name .h1,
.topic-name h1 {
    float: left;
    font-weight: 400
}

.topic-name .btn-star {
    float: right
}

.dicon-blk {
    top: 0;
    right: 0
}

.help-main.md-view .help-md-hidden,
.help-main.sm-view .help-md-hidden,
.help-main.sm-view .help-sm-hidden,
body.anim-let-me-in .header-chat,
body.anim-let-me-in .header-user-list,
body.anim-let-me-in .menu-styler,
body.anim-let-me-in .page-header,
body.anim-make-way .header-chat,
body.anim-make-way .header-user-list,
body.anim-make-way .menu-styler,
body.anim-make-way .page-header,
body.anim-slip-from-top .header-chat,
body.anim-slip-from-top .header-user-list,
body.anim-slip-from-top .menu-styler,
body.anim-slip-from-top .page-header {
    display: none
}

.hd-detail .col-right .edit-del {
    opacity: 0
}

.hd-detail .col-right .edit-del i {
    opacity: .2
}

.hd-detail .col-right .edit-del i:hover,
.hd-detail:hover .edit-del {
    opacity: 1
}

.hdd-user i {
    bottom: 0
}

.ticket-block .hd-body {
    border-left: 3px solid #ccc
}

.ticket-block .col.border-right {
    border-color: #ccc !important
}

.ticket-block .ticket-type-icon {
    font-weight: 500;
    color: #111
}

.ticket-block .excerpt .h6,
.ticket-block .excerpt h6 {
    color: #686c71;
    font-weight: 500
}

.ticket-block .excerpt .h6 a,
.ticket-block .excerpt h6 a {
    color: #686c71
}

.ticket-block .excerpt .h6 a:hover,
.ticket-block .excerpt h6 a:hover {
    text-decoration: underline !important;
    color: #111
}

.ticket-block.open-tic .hd-body {
    border-color: var(--bs-danger)
}

.ticket-block.close-tic .hd-body {
    border-color: var(--bs-success)
}

.right-col .card-footer label {
    font-weight: 500
}

.hdd-right-inner .select2-container--default {
    margin-bottom: 10px
}

.hdd-right-inner .select2-container--default .select2-selection {
    border-color: var(--bs-border-color)
}

.hdd-right-side {
    width: 495px
}

.hdd-right-side .hdd-right-inner {
    position: fixed;
    width: 465px
}

.span-content a {
    padding: 0 7px
}

@media (max-width:992px) {
    .hd-detail .col-right .edit-del {
        opacity: 1
    }
}

@media (max-width:767px) {
    .hdd-right-side {
        width: 100%
    }

    .hdd-right-side .hdd-right-inner {
        position: static;
        width: 100%
    }
}

@media (max-width:575px) {
    .q-view .content {
        width: 450px
    }
}

@media (max-width:380px) {
    .q-view .content {
        width: 300px
    }
}

#qviewModal.fade .q-view-modal.modal-dialog {
    transform: translateX(550px)
}

#qviewModal.show {
    padding-right: 0 !important
}

#qviewModal.show .q-view-modal.modal-dialog {
    transform: none
}

.q-view-modal {
    margin: 0
}

.q-view-modal .introjs-tooltip,
.q-view-modal .modal-content {
    height: 100vh;
    border-radius: 0;
    border: none
}

.q-view-modal .introjs-tooltip .modal-body,
.q-view-modal .modal-content .modal-body {
    flex: 1 1 100%
}

.q-view-modal .introjs-tooltip .modal-body .trumbowyg-box,
.q-view-modal .introjs-tooltip .modal-body .trumbowyg-editor,
.q-view-modal .modal-content .modal-body .trumbowyg-box,
.q-view-modal .modal-content .modal-body .trumbowyg-editor {
    min-height: 110px
}

@media (min-width:576px) {
    .q-view-modal.modal-dialog {
        max-width: 550px;
        margin: 0 0 0 auto
    }
}

.ticket-card.open-ticket {
    border-left: 3px solid #dc2626
}

.ticket-card.close-ticket {
    border-left: 3px solid #2ca87f
}

.popup-trigger {
    cursor: pointer
}

.invoice-tab .nav-item .nav-link {
    padding: .9rem .8rem;
    margin: 0;
    font-size: 16px
}

.invoice-tab .avtar {
    font-size: 13px;
    width: 22px;
    height: 22px
}

.feed-card .h6,
.feed-card h6 {
    margin-top: 7px;
    font-size: 14px;
    transition: all .3s ease-in-out
}

.feed-card .h6>span,
.feed-card h6>span {
    font-size: 11px
}

.feed-card .h6:hover,
.feed-card h6:hover {
    color: #4680ff
}

.feed-card .feed-icon {
    border-radius: 50%;
    display: block;
    width: 34px;
    height: 34px;
    text-align: center;
    padding: 10px 8px
}

.feed-card .card-body {
    position: relative
}

.feed-card .card-body .border-feed {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    transition: all .3s ease-in-out;
    position: relative
}

.feed-card .card-body .border-feed i {
    position: absolute;
    top: calc(50% - 20px);
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
    transition: all .3s ease-in-out
}

.feed-card .card-body .border-feed i.material-icons-two-tone {
    background-color: #fff
}

.feed-card:hover .border-feed.bg-theme {
    background-color: rgba(255, 51, 102, .3) !important
}

.feed-card:hover .border-feed.bg-theme i {
    color: #f36
}

.feed-card:hover .border-feed.bg-theme i.material-icons-two-tone {
    background-color: #f36
}

.feed-card:hover .border-feed.bg-primary {
    background-color: rgba(70, 128, 255, .3) !important
}

.feed-card:hover .border-feed.bg-primary i {
    color: #4680ff
}

.feed-card:hover .border-feed.bg-primary i.material-icons-two-tone {
    background-color: #4680ff
}

.feed-card:hover .border-feed.bg-secondary {
    background-color: rgba(91, 107, 121, .3) !important
}

.feed-card:hover .border-feed.bg-secondary i {
    color: #5b6b79
}

.feed-card:hover .border-feed.bg-secondary i.material-icons-two-tone {
    background-color: #5b6b79
}

.feed-card:hover .border-feed.bg-success {
    background-color: rgba(44, 168, 127, .3) !important
}

.feed-card:hover .border-feed.bg-success i {
    color: #2ca87f
}

.feed-card:hover .border-feed.bg-success i.material-icons-two-tone {
    background-color: #2ca87f
}

.feed-card:hover .border-feed.bg-info {
    background-color: rgba(62, 201, 214, .3) !important
}

.feed-card:hover .border-feed.bg-info i {
    color: #3ec9d6
}

.feed-card:hover .border-feed.bg-info i.material-icons-two-tone {
    background-color: #3ec9d6
}

.feed-card:hover .border-feed.bg-warning {
    background-color: rgba(229, 138, 0, .3) !important
}

.feed-card:hover .border-feed.bg-warning i {
    color: #e58a00
}

.feed-card:hover .border-feed.bg-warning i.material-icons-two-tone {
    background-color: #e58a00
}

.feed-card:hover .border-feed.bg-danger {
    background-color: rgba(220, 38, 38, .3) !important
}

.feed-card:hover .border-feed.bg-danger i {
    color: #dc2626
}

.feed-card:hover .border-feed.bg-danger i.material-icons-two-tone {
    background-color: #dc2626
}

.feed-card:hover .border-feed.bg-light {
    background-color: rgba(248, 249, 250, .3) !important
}

.feed-card:hover .border-feed.bg-light i {
    color: #f8f9fa
}

.feed-card:hover .border-feed.bg-light i.material-icons-two-tone {
    background-color: #f8f9fa
}

.feed-card:hover .border-feed.bg-dark {
    background-color: rgba(33, 37, 41, .3) !important
}

.feed-card:hover .border-feed.bg-dark i {
    color: #212529
}

.feed-card:hover .border-feed.bg-dark i.material-icons-two-tone {
    background-color: #212529
}



@keyframes pulseWarning {
    0% {
        border-color: #f90
    }

    to {
        border-color: #e58a00
    }
}



.modal-lightbox .modal-body {
    padding: 0
}

.modal-lightbox .modal-body img {
    border-radius: 2px
}

@media (max-width:575.98px) {
    .modal-lightbox .modal-dialog {
        margin: var(--bs-modal-margin) 24px
    }
}

.modal-lightbox .introjs-tooltip,
.modal-lightbox .modal-content {
    border-radius: 2px;
    border: none
}

.modal-lightbox .btn-close {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%) scale(.8);
    background-color: var(--bs-modal-bg);
    padding: 8px;
    z-index: 5;
    opacity: 1;
    border-radius: 50%
}

.alert-dismissable .close,
.alert-dismissible .close {
    color: inherit;
    text-shadow: none
}


.modal.fade.modal-animate .modal-dialog {
    display: flex;
    align-items: center;
    height: calc(100% - 3.5rem)
}

.modal.fade.modal-animate.anim-fade-in-scale .modal-dialog {
    transform: scale(.7);
    opacity: 0;
    transition: all .3s
}

.modal.fade.modal-animate.anim-fade-in-scale.show .modal-dialog {
    transform: none;
    opacity: 1
}

.modal.fade.modal-animate.anim-slide-in-right .modal-dialog {
    transform: translateX(20%);
    opacity: 0;
    transition: all .3s cubic-bezier(.25, .5, .5, .9)
}

.modal.fade.modal-animate.anim-slide-in-right.show .modal-dialog {
    transform: translateX(0);
    opacity: 1
}

.modal.fade.modal-animate.anim-slide-in-bottom .modal-dialog {
    transform: translateY(20%);
    opacity: 0;
    transition: all .3s
}

.modal.fade.modal-animate.anim-slide-in-bottom.show .modal-dialog {
    transform: translateY(0);
    opacity: 1
}

.modal.fade.modal-animate.anim-newspaper .modal-dialog {
    transform: scale(0) rotate(720deg);
    opacity: 0;
    transition: all .5s
}

.modal.fade.modal-animate.anim-newspaper.show .modal-dialog {
    transform: none;
    opacity: 1
}

.modal.fade.modal-animate.anim-fall {
    perspective: 1300px
}

.modal.fade.modal-animate.anim-fall .modal-dialog {
    transform-style: preserve-3d;
    transform: translateZ(600px) rotateX(20deg);
    opacity: 0;
    transition: all .5s
}

.modal.fade.modal-animate.anim-fall.show .modal-dialog {
    transition: all .3s ease-in;
    transform: translateZ(0) rotateX(0);
    opacity: 1
}

.modal.fade.modal-animate.anim-side-fall {
    perspective: 1300px
}

.modal.fade.modal-animate.anim-side-fall .modal-dialog {
    transform-style: preserve-3d;
    transform: translate(30%) translateZ(600px) rotate(10deg);
    opacity: 0;
    transition: all .3s ease-in
}

.modal.fade.modal-animate.anim-side-fall.show .modal-dialog {
    transform: translate(0) translateZ(0) rotate(0);
    opacity: 1
}

.modal.fade.modal-animate.anim-sticky-up .modal-dialog {
    align-items: flex-start;
    margin-top: 0;
    transform: translateY(-200%);
    transition: all .3s;
    opacity: 0
}

.modal.fade.modal-animate.anim-sticky-up.show .modal-dialog {
    transform: translateY(0);
    border-radius: 0 0 3px 3px;
    opacity: 1
}

.modal.fade.modal-animate.anim-3d-flip-horizontal {
    perspective: 1300px
}

.modal.fade.modal-animate.anim-3d-flip-horizontal .modal-dialog {
    transform-style: preserve-3d;
    transform: rotateY(-70deg);
    transition: all .3s;
    opacity: 0
}

.modal.fade.modal-animate.anim-3d-flip-horizontal.show .modal-dialog {
    transform: rotateY(0);
    opacity: 1
}

.modal.fade.modal-animate.anim-3d-flip-vertical {
    perspective: 1300px
}

.modal.fade.modal-animate.anim-3d-flip-vertical .modal-dialog {
    transform-style: preserve-3d;
    transform: rotateX(-70deg);
    transition: all .3s;
    opacity: 0
}

.modal.fade.modal-animate.anim-3d-flip-vertical.show .modal-dialog {
    transform: rotateX(0);
    opacity: 1
}

.modal.fade.modal-animate.anim-3d-sign {
    perspective: 1300px
}

.modal.fade.modal-animate.anim-3d-sign .modal-dialog {
    transform-style: preserve-3d;
    transform: rotateX(-60deg);
    transform-origin: 50% 0;
    opacity: 0;
    transition: all .3s
}

.modal.fade.modal-animate.anim-3d-sign.show .modal-dialog {
    transform: rotateX(0);
    opacity: 1
}

.modal.fade.modal-animate.anim-super-scaled .modal-dialog {
    transform: scale(2);
    opacity: 0;
    transition: all .3s
}

.modal.fade.modal-animate.anim-super-scaled.show .modal-dialog {
    transform: scale(1);
    opacity: 1
}

.modal.fade.modal-animate.anim-just-me {
    background: #fff
}

.modal.fade.modal-animate.anim-just-me .modal-dialog {
    transform: scale(.8);
    opacity: 0;
    transition: all .3s;
    background: 0 0
}

.modal.fade.modal-animate.anim-just-me .modal-dialog .introjs-tooltip,
.modal.fade.modal-animate.anim-just-me .modal-dialog .modal-content {
    border: none
}

.modal.fade.modal-animate.anim-just-me .modal-dialog .introjs-tooltip .modal-header,
.modal.fade.modal-animate.anim-just-me .modal-dialog .modal-content .modal-header {
    background: 0 0 !important
}

.modal.fade.modal-animate.anim-just-me .modal-dialog .introjs-tooltip .modal-header .btn-close.btn-close-white,
.modal.fade.modal-animate.anim-just-me .modal-dialog .modal-content .modal-header .btn-close.btn-close-white {
    filter: none
}

.modal.fade.modal-animate.anim-just-me .modal-dialog .introjs-tooltip .modal-header .text-white.modal-title,
.modal.fade.modal-animate.anim-just-me .modal-dialog .modal-content .modal-header .text-white.modal-title {
    color: var(--pc-header-color) !important
}

.modal.fade.modal-animate.anim-just-me.show .modal-dialog {
    transform: scale(1);
    opacity: 1
}

.modal.fade.modal-animate.anim-3d-slit {
    perspective: 1300px
}

.modal.fade.modal-animate.anim-3d-slit .modal-dialog {
    transform-style: preserve-3d;
    transform: translateZ(-3000px) rotateY(90deg);
    opacity: 0
}

.modal.fade.modal-animate.anim-3d-slit.show .modal-dialog {
    animation: slit .7s forwards ease-out
}

.modal.fade.modal-animate.anim-3d-rotate-bottom {
    perspective: 1300px
}

.modal.fade.modal-animate.anim-3d-rotate-bottom .modal-dialog {
    transform-style: preserve-3d;
    transform: translateY(100%) rotateX(90deg);
    transform-origin: 0 100%;
    opacity: 0;
    transition: all .3s ease-out
}

.modal.fade.modal-animate.anim-3d-rotate-bottom.show .modal-dialog {
    transform: translateY(0) rotateX(0);
    opacity: 1
}

.modal.fade.modal-animate.anim-3d-rotate-InLeft {
    perspective: 1300px
}

.modal.fade.modal-animate.anim-3d-rotate-InLeft .modal-dialog {
    transform-style: preserve-3d;
    transform: translateZ(100px) translateX(-30%) rotateY(90deg);
    transform-origin: 0 100%;
    opacity: 0;
    transition: all .3s
}

.modal.fade.modal-animate.anim-3d-rotate-InLeft.show .modal-dialog {
    transform: translateZ(0) translateX(0) rotateY(0);
    opacity: 1
}

.modal.fade.modal-animate.anim-blur {
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px)
}

.modal.fade.modal-animate.anim-blur .modal-dialog {
    transform: scale(.7);
    opacity: 0;
    transition: all .3s
}

.modal.fade.modal-animate.anim-blur.show .modal-dialog {
    transform: none;
    opacity: 1
}

.modal.fade.modal-animate.anim-let-me-in .modal-dialog {
    opacity: 0;
    transform: translateY(200%);
    transition: all .3s .2s
}

.modal.fade.modal-animate.anim-let-me-in.show .modal-dialog {
    transform: translateY(0);
    opacity: 1
}

.modal.fade.modal-animate.anim-make-way .modal-dialog {
    opacity: 0;
    transform: translateX(200%);
    transition: all .3s .2s
}

.modal.fade.modal-animate.anim-make-way.show .modal-dialog {
    transform: translateY(0);
    opacity: 1
}

.modal.fade.modal-animate.anim-slip-from-top .modal-dialog {
    opacity: 0;
    transform: translateY(-200%);
    transition: all .5s .1s
}

.modal.fade.modal-animate.anim-slip-from-top.show .modal-dialog {
    transform: translateY(0);
    opacity: 1
}

body.anim-let-me-in {
    perspective: 600px;
    overflow: hidden;
    height: 100vh
}

body.anim-let-me-in .modal-backdrop,
body.anim-let-me-in .pc-container,
body.anim-let-me-in .pc-header,
body.anim-let-me-in .pc-sidebar {
    transition: transform .3s
}

body.anim-let-me-in.modal-open .modal-backdrop,
body.anim-let-me-in.modal-open .pc-container,
body.anim-let-me-in.modal-open .pc-header,
body.anim-let-me-in.modal-open .pc-sidebar {
    transform: rotateX(-3deg);
    transform-origin: 50% 0;
    transform-style: preserve-3d
}

body.anim-make-way {
    perspective: 600px;
    overflow: hidden;
    height: 100vh
}

body.anim-make-way .modal-backdrop,
body.anim-make-way .pc-container,
body.anim-make-way .pc-header,
body.anim-make-way .pc-sidebar {
    transition: all .5s
}

body.anim-make-way.modal-open .modal-backdrop,
body.anim-make-way.modal-open .pc-container,
body.anim-make-way.modal-open .pc-header,
body.anim-make-way.modal-open .pc-sidebar {
    transform-style: preserve-3d;
    transform-origin: 0 50%;
    animation: rotateRightSideFirst .5s forwards ease-in
}

body.anim-slip-from-top {
    perspective: 600px;
    overflow: hidden;
    height: 100vh
}

body.anim-slip-from-top .modal-backdrop,
body.anim-slip-from-top .pc-container,
body.anim-slip-from-top .pc-header,
body.anim-slip-from-top .pc-sidebar {
    transition: all .5s;
    transform-style: preserve-3d;
    transform-origin: 50% 100%;
    animation: OpenTop 1.5s forwards ease-in
}

body.anim-slip-from-top.modal-open .pc-header {
    opacity: 0
}

@keyframes slit {
    50% {
        transform: translateZ(-250px) rotateY(89deg);
        opacity: 1;
        animation-timing-function: ease-in
    }

    100% {
        transform: translateZ(0) rotateY(0);
        opacity: 1
    }
}

@keyframes rotateRightSideFirst {
    50% {
        transform: translateZ(-50px) rotateY(5deg);
        animation-timing-function: ease-out
    }

    100% {
        transform: translateZ(-200px)
    }
}

@keyframes OpenTop {
    50% {
        transform: rotateX(10deg);
        animation-timing-function: ease-out
    }
}

.introjs-tooltip {
    background: #fff;
    position: absolute
}

.introjs-tooltip .introjs-tooltipbuttons {
    border-top: none
}

.introjs-tooltip .introjs-button {
    text-shadow: none
}

.slider.slider-horizontal .slider-handle.triangle,
.slider.slider-horizontal .slider-tick.triangle {
    border-bottom-color: var(--bs-primary)
}

.slider.slider-vertical .slider-handle.triangle,
.slider.slider-vertical .slider-tick.triangle {
    border-left-color: var(--bs-primary);
    border-right-color: var(--bs-primary)
}

.slider.slider-disabled .slider-handle {
    background-image: linear-gradient(to bottom, #dfdfdf 0, #bebebe 100%);
    background-repeat: repeat-x
}

.slider-track,
.slider.slider-disabled .slider-track {
    background-image: linear-gradient(to bottom, #f8f9fa 0, #f8f9fa 100%);
    background-repeat: repeat-x
}

.slider-selection {
    background-image: linear-gradient(to bottom, #c6d8ff 0, #c6d8ff 100%);
    background-repeat: repeat-x
}

.slider-selection.tick-slider-selection {
    background-image: linear-gradient(to bottom, #acc6ff 0, #acc6ff 100%);
    background-repeat: repeat-x
}

.slider-handle {
    background-color: var(--bs-primary);
    background-image: linear-gradient(to bottom, var(--bs-primary) 0, var(--bs-primary) 100%);
    background-repeat: repeat-x
}

.slider-tick {
    background-image: linear-gradient(to bottom, #f9f9f9 0, #f5f5f5 100%);
    background-repeat: repeat-x
}

.slider-tick.in-selection {
    background-image: linear-gradient(to bottom, #acc6ff 0, #acc6ff 100%);
    background-repeat: repeat-x
}

.slider .tooltip {
    position: absolute
}

#ex7-enabled,
.customize-tools {
    position: relative
}

#ex7-enabled {
    top: 4px;
    height: 18px;
    width: 18px
}

#RGB {
    height: 10px;
    background: grey
}

#RC .slider-selection {
    background: #ff8282
}

#RC .slider-handle {
    background: red
}

#GC .slider-selection {
    background: #478f3b
}

#GC .slider-handle {
    background: green
}

#BC .slider-selection {
    background: #8283ff
}

#BC .slider-handle {
    border-bottom-color: #00f
}

#B,
#G,
#R {
    width: 300px
}

.slider-handle.custom {
    background: 0 0
}

.slider-handle.custom::before {
    line-height: 15px;
    font-size: 28px;
    content: "★";
    color: #726204
}

#slider12a .slider-track-high,
#slider12c .slider-track-high {
    background: #2ca87f
}

#slider12b .slider-track-low,
#slider12c .slider-track-low {
    background: #dc2626
}

#slider12c .slider-selection {
    background: #e58a00
}

#slider22 .slider-rangeHighlight {
    background: #f70616
}

#slider22 .slider-rangeHighlight.category1 {
    background: #f90
}

#slider22 .slider-rangeHighlight.category2 {
    background: #9c0
}

.vtree a.vtree-leaf-label {
    color: #131920
}

.vtree a.vtree-leaf-label:active,
.vtree a.vtree-leaf-label:focus,
.vtree a.vtree-leaf-label:hover {
    color: var(--bs-primary)
}

.daterangepicker {
    font-family: "Inter var", sans-serif;
    border: 1px solid rgba(0, 0, 0, .15);
    box-shadow: 0 0 25px rgba(70, 128, 255, .15);
    background-color: #fff
}

.daterangepicker .calendar-table {
    border: 1px solid #fff;
    background-color: #fff
}

.daterangepicker .calendar-table td,
.daterangepicker .calendar-table th {
    color: #5b6b79
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
    border-color: #8996a4
}

.daterangepicker td.in-range {
    background-color: #dae6ff;
    color: #3e4853
}

.daterangepicker td.off,
.daterangepicker td.off.end-date,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date {
    background-color: #fff;
    color: #131920;
    opacity: .5
}

.daterangepicker td.active,
.daterangepicker td.active:hover,
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
    background-color: #4680ff;
    color: #fff
}

.daterangepicker td.available:hover>span,
.daterangepicker th.available:hover>span {
    border-color: #fff
}

.daterangepicker:after {
    border-bottom: 6px solid #fff
}

.daterangepicker:before {
    border-bottom: 7px solid rgba(0, 0, 0, .15)
}

.daterangepicker .ranges ul {
    padding-top: 15px;
    padding-bottom: 15px
}

@media (min-width:564px) {
    .daterangepicker .ranges ul {
        width: 160px
    }
}

.daterangepicker .ranges ul li {
    margin: 5px 10px;
    transition: all .2s ease-in-out
}

.daterangepicker .ranges ul li.active {
    background-color: #4680ff
}

.daterangepicker .ranges ul li:hover {
    box-shadow: 0 15px 8px -9px rgba(0, 0, 0, .25);
    background-color: #4680ff;
    color: #fff
}

.daterangepicker .drp-buttons {
    border-top: 1px solid rgba(0, 0, 0, .15)
}

.daterangepicker.show-ranges .drp-calendar.left {
    border-left: 1px solid rgba(0, 0, 0, .15)
}

.datepicker-dropdown .datepicker-picker {
    box-shadow: 0 1px 10px 0 rgba(69, 90, 100, .2)
}

.datepicker-view .week {
    color: var(--bs-primary)
}

.datepicker-cell.selected,
.datepicker-cell.selected:hover {
    background-color: var(--bs-primary);
    color: #fff
}

.datepicker-cell.disabled {
    color: var(--bd-body-color);
    background-color: var(--bd-body-bg);
    opacity: .4
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
    background-color: var(--bs-primary-light);
    color: var(--bs-primary);
    border-radius: 0
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
    background-color: var(--bs-primary-light);
    color: var(--bs-primary)
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
    background-color: var(--bs-primary);
    color: #fff
}

.datepicker-input.in-edit {
    border-color: var(--bs-primary)
}

.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
    background: var(--bs-primary);
    border-color: var(--bs-primary)
}

.bootstrap-tagsinput {
    padding: 5px 10px;
    line-height: 28px;
    background: #f8f9fa;
    border: 1px solid #e7eaee;
    border-radius: 12px;
    width: 100%
}

.bootstrap-tagsinput .tag {
    background: #4680ff;
    padding: 5px 12px;
    color: #fff;
    border-radius: 12px
}

.tns-controls {
    text-align: center;
    margin-bottom: 10px
}

.tns-controls [aria-controls] {
    font-size: 15px;
    margin: 0 5px;
    padding: 0 1em;
    height: 2.5em;
    color: #fff;
    background: var(--bs-primary);
    border-radius: 3px;
    border: 0
}

[data-action] {
    display: block;
    margin: 10px auto;
    font-size: 17px;
    min-width: 3em;
    text-align: center;
    background: 0 0;
    border: 0
}

.tns-controls [disabled] {
    color: #999;
    background: #b3b3b3;
    cursor: not-allowed !important
}

.tns-nav {
    text-align: center;
    margin: 10px 0
}

.tns-nav>[aria-controls] {
    width: 9px;
    height: 9px;
    padding: 0;
    margin: 0 5px;
    border-radius: 50%;
    background: #ddd;
    border: 0
}

.tns-nav>.tns-nav-active {
    background: #999
}

.thumbnails {
    margin: 20px 0;
    text-align: center
}

.thumbnails li {
    cursor: pointer;
    border: 5px solid #fff
}

.thumbnails .tns-nav-active {
    background: 0 0;
    border-color: var(--bs-primary)
}

.thumbnails img {
    height: auto;
    width: 46px
}

.controls {
    text-align: center
}

.controls li {
    display: block;
    position: absolute;
    top: 50%;
    height: 60px;
    line-height: 60px;
    margin-top: -30px;
    padding: 0 15px;
    cursor: pointer;
    font-size: 30px;
    transition: background .3s
}

.controls .prev {
    left: 0
}

.controls .next {
    right: 0
}

.controls li:hover {
    background: #f2f2f2
}

@media (max-width:575.98px) {
    .customize-tools .controls {
        display: none
    }
}

.switch {
    margin-bottom: 8px;
    margin-right: 8px
}

.introjs-tooltip .switch-off.introjs-button,
.introjs-tooltip .switch-on.introjs-button,
.switch-off.btn,
.switch-on.btn {
    display: flex;
    align-items: center;
    justify-content: center
}

.introjs-tooltip .switch-off.introjs-button i,
.introjs-tooltip .switch-on.introjs-button i,
.switch-off.btn i,
.switch-on.btn i {
    margin: 0 4px;
    font-size: 12px
}

.slow .switch-group {
    transition: left .7s;
    -webkit-transition: left .7s
}

.fast .switch-group {
    transition: left .1s;
    -webkit-transition: left .1s
}

.quick .switch-group {
    transition: none;
    -webkit-transition: none
}

.table.switch-table td,
.table.switch-table th {
    vertical-align: middle;
    padding: .45rem .25rem
}

.introjs-tooltip .table.switch-table td .introjs-button,
.introjs-tooltip .table.switch-table th .introjs-button,
.table.switch-table td .btn,
.table.switch-table td .introjs-tooltip .introjs-button,
.table.switch-table th .btn,
.table.switch-table th .introjs-tooltip .introjs-button {
    margin-bottom: 0
}

.datatable-table>tbody>tr>td,
.datatable-table>tbody>tr>th,
.datatable-table>tfoot>tr>td,
.datatable-table>tfoot>tr>th,
.datatable-table>thead>tr>td,
.datatable-table>thead>tr>th {
    vertical-align: middle;
    padding: .7rem .75rem
}

.datatable-table td,
.datatable-table th,
.table td,
.table th {
    border-top: 1px solid #e7eaee;
    border-bottom: none;
    white-space: nowrap;
    padding: .7rem .75rem;
    vertical-align: middle
}

.datatable-table thead th,
.table thead th {
    border-bottom: 1px solid #e7eaee;
    font-size: 13px;
    color: #262626;
    background: rgba(248, 249, 250, .5);
    text-transform: uppercase
}

.datatable-table tbody+tbody,
.table tbody+tbody {
    border-top: 2px solid #e7eaee
}

.datatable-table.table-dark thead th,
.table.table-dark thead th {
    background-color: #11171a
}

.datatable-table.dataTable[class*=table-] thead th,
.table.dataTable[class*=table-] thead th {
    background: #f8f9fa
}

.datatable-table>:not(:last-child)>:last-child>*,
.table>:not(:last-child)>:last-child>* {
    border-bottom-color: #e7eaee
}

.datatable-table>:not(:first-child),
.table>:not(:first-child) {
    border-top: none
}

.table-bordered,
.table-bordered td,
.table-bordered th {
    border: 1px solid #e7eaee
}

.table .thead-dark th,
.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #0a0c0d
}

.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: rgba(70, 128, 255, .03)
}

.table-hover tbody tr:hover {
    background-color: rgba(91, 107, 121, .03)
}

.table .thead-dark th,
.table-dark {
    background-color: #212529;
    color: #fff
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: #1c2023
}

.table-dark.table-hover tbody tr:hover {
    background-color: #16181b
}

.table-dark thead th {
    color: #fff
}

table.dataTable.fixedHeader-floating {
    top: calc(74px + 55px) !important
}

@media screen and (max-width:992px) {

    .fixedHeader-locked,
    table.dataTable.fixedHeader-floating {
        display: none !important
    }
}

.table.table-xl td,
.table.table-xl th {
    padding: 1.25rem .8rem
}

.table.table-lg td,
.table.table-lg th {
    padding: .9rem .8rem
}

.table.table-de td,
.table.table-de th {
    padding: .75rem .8rem
}

.table.table-sm td,
.table.table-sm th {
    padding: .6rem .8rem
}

.table.table-xs td,
.table.table-xs th {
    padding: .4rem .8rem
}

.datatable-top {
    padding: 0 4px 14px
}

.datatable-bottom {
    padding: 14px 4px 0
}

.card.table-card .datatable-top {
    padding-top: 0
}

.card.table-card .datatable-bottom,
.card.table-card .datatable-top {
    padding-left: var(--bs-card-spacer-x);
    padding-right: var(--bs-card-spacer-x)
}

.datatable-bottom>div:first-child,
.datatable-bottom>nav:first-child,
.datatable-top>div:first-child,
.datatable-top>nav:first-child {
    float: left
}

.datatable-top>div:last-child,
.datatable-top>nav:last-child {
    float: right
}

@media (max-width:574.98px) {
    .datatable-top>div:last-child {
        float: left;
        width: 100%
    }
}

.datatable-bottom>div:last-child,
.datatable-bottom>nav:last-child {
    float: right
}

.dataTable-dropdown label select {
    margin-right: 8px
}

.dataTable-info {
    margin: 7px 0
}

.dataTable-pagination ul {
    margin: 0;
    padding-left: 0
}

.dataTable-pagination li {
    list-style: none;
    float: left
}

.dataTable-pagination a {
    border: 1px solid transparent;
    float: left;
    margin-left: 2px;
    padding: 6px 12px;
    position: relative;
    text-decoration: none;
    border-radius: 8px;
    color: #131920
}

.dataTable-pagination a:hover {
    background-color: var(--bs-primary-light);
    color: var(--bs-primary)
}

.dataTable-pagination .active a,
.dataTable-pagination .active a:focus,
.dataTable-pagination .active a:hover {
    background-color: var(--bs-primary);
    color: #fff;
    cursor: default
}

.dataTable-pagination .ellipsis a {
    cursor: not-allowed
}

.dataTable-pagination .disabled a,
.dataTable-pagination .disabled a:focus,
.dataTable-pagination .disabled a:hover {
    cursor: not-allowed;
    opacity: .4
}

.dataTable-pagination .pager a {
    font-weight: 700
}

.dataTable-table th,
.dataTable-table>tbody>tr>td,
.dataTable-table>tbody>tr>th,
.dataTable-table>tfoot>tr>td,
.dataTable-table>tfoot>tr>th,
.dataTable-table>thead>tr>td,
.dataTable-table>thead>tr>th {
    vertical-align: middle
}

.dataTable-table th a {
    text-decoration: none;
    color: inherit;
    padding-right: 16px
}

.dataTable-sorter {
    display: inline-block;
    height: 100%;
    position: relative;
    width: 100%
}

.dataTable-sorter::after,
.dataTable-sorter::before {
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    right: 4px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    opacity: .2
}

.dataTable-sorter::before {
    border-top: 4px solid #000;
    bottom: 0
}

.dataTable-sorter::after {
    border-bottom: 4px solid #000;
    border-top: 4px solid transparent;
    top: 0
}

.asc .dataTable-sorter::after,
.desc .dataTable-sorter::before {
    opacity: .6
}

.dataTables-empty {
    text-align: center
}

.datatable-bottom::after,
.datatable-top::after {
    clear: both;
    content: " ";
    display: table
}

.pct-c-btn,
.pct-c-btn a,
.pct-c-btn a i,
table.dataTable>tbody>tr.child ul.dtr-details {
    display: block
}

.dt-responsive .dt-row {
    overflow: auto
}

.dataTables_scrollFootInner,
.dataTables_scrollHeadInner {
    width: calc(100% - 6px) !important
}

.dataTables_scrollBody::-webkit-scrollbar {
    width: 6px;
    opacity: 0
}

.dataTables_scrollBody::-webkit-scrollbar:hover {
    opacity: 1
}

.datatable-pagination .disabled a,
.datatable-pagination .disabled a:focus,
.datatable-pagination .disabled a:hover {
    opacity: .4;
    cursor: not-allowed
}

.dataTables_scrollBody::-webkit-scrollbar-track {
    background: 0 0
}

.dataTables_scrollBody::-webkit-scrollbar-thumb {
    background: #e9ecef
}

.dataTables_scrollBody::-webkit-scrollbar-thumb:hover {
    background: #aeb9c5
}

div.dt-autofill-handle {
    background: var(--bs-primary)
}

table.dataTable:not(.dtcr-cloned) {
    width: 100% !important
}

table.dataTable {
    border-collapse: collapse !important
}

table.dataTable .custom-select,
table.dataTable .dataTable-input,
table.dataTable .dataTable-selector,
table.dataTable .datatable-input,
table.dataTable .form-control {
    min-width: 100px;
    padding-right: 30px
}

.dt-buttons~.dt-search {
    margin-bottom: 15px
}

@media (max-width:462px) {
    .datatable-pagination {
        margin-top: 6px
    }

    .datatable-bottom>div:last-child,
    .datatable-bottom>nav:last-child {
        float: left
    }
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: transparent
}

.dataTables_length select.datatable-selector,
.dataTables_length select.form-select {
    padding-right: 30px
}

@media screen and (max-width:767px) {
    div.dataTables_wrapper div.dataTables_filter input {
        width: 155px
    }
}

.dataTables_filter,
.dataTables_length {
    margin: 0 0 8px
}

table.DTFC_Cloned tr,
table.dataTable>tbody>tr {
    background-color: var(--bs-card-bg)
}

.DTFC_LeftBodyWrapper .table.dataTable.DTFC_Cloned thead .sorting:after,
.DTFC_LeftBodyWrapper .table.dataTable.DTFC_Cloned thead .sorting:before,
.DTFC_RightBodyLiner .table.dataTable.DTFC_Cloned thead .sorting:after,
.DTFC_RightBodyLiner .table.dataTable.DTFC_Cloned thead .sorting:before {
    display: none
}

.fixed-header-table {
    overflow-x: hidden
}

div.dt-button-info {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 400px;
    margin-top: -100px;
    margin-left: -200px;
    background-color: #dfe9ff;
    color: #4680ff;
    border: 2px solid #4680ff;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, .3);
    border-radius: 3px;
    text-align: center;
    z-index: 21
}

div.dt-button-info .h2,
div.dt-button-info h2 {
    padding: .5em;
    margin: 0;
    font-weight: 400;
    color: #fff;
    border-bottom: 1px solid #4680ff;
    background-color: #4680ff
}

div.dt-button-info>div {
    padding: 1em
}

.dtfh-floatingparenthead {
    top: 70px !important
}

.dt-buttons {
    margin-bottom: 5px
}

table.dataTable tbody td.focus,
table.dataTable tbody th.focus {
    outline: solid var(--bs-primary);
    box-shadow: inset 0 0 1px 2px #4680ff
}

div.dtk-focus-alt table.dataTable tbody td.focus,
div.dtk-focus-alt table.dataTable tbody th.focus {
    box-shadow: inset 0 0 1px 2px #e58a00;
    background: rgba(229, 138, 0, .15);
    color: #e58a00
}

table.dataTable>thead .sorting:after,
table.dataTable>thead .sorting:before,
table.dataTable>thead .sorting_asc:after,
table.dataTable>thead .sorting_asc:before,
table.dataTable>thead .sorting_asc_disabled:after,
table.dataTable>thead .sorting_asc_disabled:before,
table.dataTable>thead .sorting_desc:after,
table.dataTable>thead .sorting_desc:before,
table.dataTable>thead .sorting_desc_disabled:after,
table.dataTable>thead .sorting_desc_disabled:before {
    top: 13px
}

table.dataTable tbody>tr.selected td.select-checkbox:after,
table.dataTable tbody>tr.selected th.select-checkbox:after,
table.dataTable tbody>tr>.selected td.select-checkbox:after,
table.dataTable tbody>tr>.selected th.select-checkbox:after {
    margin-top: -21px;
    margin-left: -7px
}

.datatable-wrapper.no-footer .datatable-container {
    border-bottom: none
}

.datatable-wrapper .datatable-container {
    overflow-x: auto
}

.datatable-top>div {
    margin: 5px 0
}

@media (max-width:574.98px) {
    .datatable-top>div:last-child {
        float: left
    }
}

.dataTable-dropdown label,
.datatable-dropdown label {
    display: flex;
    align-items: center;
    width: 230px;
    white-space: nowrap
}

.dataTable-dropdown label select,
.datatable-dropdown label select {
    margin-right: 8px;
    margin-left: 0
}

.datatable-sorter {
    padding-right: 18px
}

.datatable-sorter:after {
    border-top: 0;
    top: 4px
}

.datatable-sorter::before {
    border-bottom: 0;
    bottom: 4px
}

.datatable-pagination ul {
    margin: 0;
    padding-left: 0
}

.datatable-pagination li {
    list-style: none;
    float: left
}

.datatable-pagination a {
    border: 1px solid transparent;
    float: left;
    margin-left: 2px;
    padding: 6px 12px;
    position: relative;
    text-decoration: none;
    border-radius: var(--bs-border-radius);
    color: var(--bs-card-color)
}

.datatable-pagination a:hover {
    background-color: var(--bs-primary-light);
    color: var(--bs-primary)
}

.datatable-pagination .active a,
.datatable-pagination .active a:focus,
.datatable-pagination .active a:hover,
.datatable-pagination .datatable-active a,
.datatable-pagination .datatable-active a:focus,
.datatable-pagination .datatable-active a:hover {
    background-color: var(--bs-primary);
    color: #fff;
    cursor: default
}

.datatable-pagination .ellipsis a {
    cursor: not-allowed
}

.datatable-pagination .pager a {
    font-weight: 700
}

.dt-buttons.btn-group.flex-wrap .btn,
.dt-buttons.btn-group.flex-wrap .introjs-tooltip .introjs-button,
.introjs-tooltip .dt-buttons.btn-group.flex-wrap .introjs-button {
    margin-bottom: 5px
}

.dataTable[aria-describedby=no-style_info] th {
    padding: 14px 0
}

div.dataTables_wrapper div.dt-row {
    margin-top: 8px !important;
    margin-bottom: 8px !important
}

.pct-c-btn {
    padding: 0;
    border: 4px solid #fff;
    border-right: none;
    position: fixed;
    overflow: hidden;
    right: -4px;
    top: 100px;
    z-index: 1030;
    border-radius: 50% 4px 4px 50%;
    box-shadow: -6px 0 14px 1px rgba(27, 46, 94, .04);
    background: #fff
}

.pct-c-btn a {
    padding: 12px 16px;
    transition: all 80ms cubic-bezier(.37, .24, .53, .99)
}

.pct-c-btn a i {
    font-size: 24px;
    animation: anim-rotate 2.5s infinite linear;
    line-height: 24px;
    color: var(--bs-primary)
}

.pct-c-btn a:hover {
    background-color: rgba(var(--bs-primary-rgb), .1)
}

@media (max-width:1024px) {
    .pct-c-btn {
        display: none
    }
}

@keyframes anim-rotate {
    from {
        transform: rotate(0)
    }

    to {
        transform: rotate(360deg)
    }
}

.pct-offcanvas {
    --bs-offcanvas-width: 320px;
    --bs-offcanvas-zindex: 1079;
    transition: transform .45s cubic-bezier(.37, .24, .53, .99);
    box-shadow: 8px 0 14px rgba(27, 46, 94, .4)
}

.pct-offcanvas~.offcanvas-backdrop {
    opacity: .2
}

.pct-offcanvas .offcanvas-body {
    padding: 25px
}

.pct-offcanvas .avtar.avtar-xs {
    width: 36px;
    height: 36px
}

.pct-offcanvas .list-group-item {
    padding: 20px 0
}

.introjs-tooltip .pct-offcanvas .list-group-item a.introjs-button,
.pct-offcanvas .list-group-item .introjs-tooltip a.introjs-button,
.pct-offcanvas .list-group-item a.btn,
.pct-offcanvas .list-group-item>div>.pct-content {
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x)
}

.preset-btn {
    height: 60px;
    border: 2px solid var(--bs-gray-300);
    --bs-btn-active-border-color: var(--bs-gray-300)
}

.preset-btn.active,
.preset-btn:active,
.preset-btn:hover {
    border-color: var(--bs-primary)
}

.preset-btn.btn-img {
    height: auto;
    border-radius: 8px
}

.theme-color>a,
.theme-color>a::after {
    border-radius: 4px;
    transition: all .15s ease-in-out
}

.preset-btn.btn-img img {
    width: 100%
}

.theme-color {
    display: flex;
    position: relative;
    padding: 0
}

.theme-color>a {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    margin-right: 4px;
    width: 20px;
    height: 50px;
    flex: none
}

.theme-color>a::after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    background: rgba(255, 255, 255, .3);
    z-index: 1;
    transform: scale(0)
}

.theme-color>a i {
    color: #fff;
    transform: scale(0);
    transition: all .15s ease-in-out
}

.theme-color>a.active i,
.theme-color>a.active::after,
.theme-color>a:hover::after {
    transform: scale(1)
}

.theme-color>a i::before {
    position: relative;
    z-index: 5
}

.theme-color>a.active {
    flex: 1
}

.theme-color.preset-color>a[data-value=preset-1] {
    background: #4680ff
}

.theme-color.preset-color>a[data-value=preset-2] {
    background: #6610f2
}

.theme-color.preset-color>a[data-value=preset-3] {
    background: #673ab7
}

.theme-color.preset-color>a[data-value=preset-4] {
    background: #e83e8c
}

.theme-color.preset-color>a[data-value=preset-5] {
    background: #dc2626
}

.theme-color.preset-color>a[data-value=preset-6] {
    background: #fd7e14
}

.theme-color.preset-color>a[data-value=preset-7] {
    background: #e58a00
}

.theme-color.preset-color>a[data-value=preset-8] {
    background: #2ca87f
}

.theme-color.preset-color>a[data-value=preset-9] {
    background: teal
}

.theme-color.preset-color>a[data-value=preset-10] {
    background: #3ec9d6
}

.theme-color.preset-color>a[data-value=preset-11] {
    background: #212529
}

.theme-main-layout a {
    padding: 5px;
    border: 2px solid var(--bs-gray-300);
    border-radius: 8px
}

.theme-main-layout a.active {
    border: 2px solid var(--bs-primary)
}

.customizer-body {
    height: calc(100% - 85px)
}

.preset-btn {
    padding: 5px;
    width: 100%;
    line-height: 1
}

.preset-btn.active {
    border-color: var(--bs-primary)
}

@media (max-width:1199.98px) {
    .pc-box-width {
        display: none
    }
}



/* off Canvas */
.slide-animate {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all .6s cubic-bezier(.5, -.5, .3, 1.4), opacity .6s ease;
    -moz-transition: all .6s cubic-bezier(.5, -.5, .3, 1.4), opacity .6s ease;
    -ms-transition: all .6s cubic-bezier(.5, -.5, .3, 1.4), opacity .6s ease;
    transition: all .6s cubic-bezier(.5, -.5, .3, 1.4), opacity .6s ease
}
.slide-animate.show {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0)
}

/* =========================== Sweet Alert  ==============================*/
.swal-container {
    z-index: 1099;
    position: fixed;
    top: 70px;
    right: 4px;
    padding: 4px;
    width: 350px;
    max-width: 98%;
    font-family: "Segoe UI", Tahoma, Calibri, Verdana, sans-serif;
    color: #999;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box
}



.success-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;
    
    .check-icon {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid #4CAF50;
        
        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px;
        }
        
        &::after {
            top: 0;
            left: 30px;
            width: 60px;
            transform-origin: 0 50%;
            border-radius: 0 100px 100px 0;
            animation: rotate-circle 4.25s ease-in;
        }
        
        &::before, &::after {
            content: '';
            height: 100px;
            position: absolute;
            background: #FFFFFF;
            transform: rotate(-45deg);
        }
        
        .icon-line {
            height: 5px;
            background-color: #4CAF50;
            display: block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;
            
            &.line-tip {
                top: 46px;
                left: 14px;
                width: 25px;
                transform: rotate(45deg);
                animation: icon-line-tip 0.75s;
            }
            
            &.line-long {
                top: 38px;
                right: 8px;
                width: 47px;
                transform: rotate(-45deg);
                animation: icon-line-long 0.75s;
            }
        }
        
        .icon-circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 4px solid rgba(76, 175, 80, .5);
        }
        
        .icon-fix {
            top: 8px;
            width: 5px;
            left: 26px;
            z-index: 1;
            height: 85px;
            position: absolute;
            transform: rotate(-45deg);
            background-color: #FFFFFF;
        }
    }
}

@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}


.circle,
.circle-border {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.circle {
  z-index: 1;
  position: relative;
  background: white;
  transform: scale(1);
  animation: success-anim 700ms ease;
}

.circle-border {
  z-index: 0;
  position: absolute;
  transform: scale(1.1);
  animation: circle-anim 400ms ease;
  background: #f86;	
}

@keyframes success-anim {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes circle-anim {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1.1);
  }
}

.error::before,
.error::after {
  content: "";
  display: block;
  height: 4px;
  background: #f86;
  position: absolute;
}

.error::before {
  width: 40px;
  top: 48%;
  left: 16%;
  transform: rotateZ(50deg);
}

.error::after {
  width: 40px;
  top: 48%;
  left: 16%;
  transform: rotateZ(-50deg);
}

/*  ============================ Sweet Alert End ======================== */